import { LeaseAgreementStatusTypesEnum, LeaseApplicationReviewVM, LeaseApplicationService, LeaseApplicationStatusTypesEnum, LoginService } from '@propertelligent/client-api';
import React, { useContext, useState } from 'react'
import OrganizationNameHeader from '../../common/OrganizationNameHeader/OrganizationNameHeader';
import AddButtonWithArrow from '../../firstTimeUserExperience/commonCom/AddButtonWithArrow';
import { GetImages } from '../../../utils/GetImages';
import CustomDropDownMenu from '../../common/CustomDropDownMenu';
import CommonConfirmation from '../../common/CommonConfirmation';
import apiHelper from '../../../utils/apiHelper';
import { NotificationType, PubSub, TopicsEnum } from '../../../utils/pubsub';
import { useNavigate } from 'react-router';
import { URLS } from '../../../constants/constants';
import { MyContext } from '../../../utils/context/LoginContext';
import RejectionLetter from './RejectionLetter';
import { handleDownloadPdf } from '../../../utils/validateHelper';
import { CustomInputTextArea } from '../../common/formControls';

const ApplicationStatusHeader = ({ userData, setSignLeaseAgreement, signLeaseAgreement, showApp, contentRef, setISPrint, isPrint, OrgDetail, leaseApplication }) => {
    const navigate = useNavigate()
    const [withdraw, setWithdraw] = useState(false)
    const [rejection, setRejection] = useState(false)
    const [logout, setlogout] = useState(false)
    const { isLogin, setIsLogin } = useContext(MyContext);
    const { currentLeaseInfo, currentLeaseApplicationInfo } = userData || {};
    const [leaseApplicationreview, setleaseApplicationreview] = useState<LeaseApplicationReviewVM>(
        { leaseApplicationStatusTypeId: LeaseApplicationStatusTypesEnum.Withdrawn, internalNotes: "", tenantNotes: "" })

    
    const withDrawApplication = () => {
        apiHelper(LeaseApplicationService.putApiLeaseApplicationReivew, {
            successMessage: "WithDraw Application successfully",
            failureMessage: "Unable to WithDraw Application", showNotification: true
        }, currentLeaseApplicationInfo?.id, leaseApplicationreview).then((res) => {
            if (res?.data?.success) { setWithdraw(false); setlogout(true) }
        }).catch((error) => { console.log(JSON.stringify(error)) })
    }

    const actions = [
        {
            id: 1,
            img: undefined,
            name: "Withdraw Application",
            onClick: () => setWithdraw(true),
            status: [LeaseApplicationStatusTypesEnum.Approved, LeaseApplicationStatusTypesEnum.Invited,],
            linkpath: undefined,
            textClass: 'sub_text fw-600 blue'
        },
        {
            id: 2,
            img: undefined,
            name: "Download  Application",
            onClick: () => handleDownloadPdf(setISPrint ,contentRef),
            status: [LeaseApplicationStatusTypesEnum.Invited,],
            linkpath: undefined,
            textClass: 'sub_text fw-600 blue'
        },
    ]
    const filterActions = actions.filter(action => action?.status?.includes(userData?.currentLeaseApplicationInfo?.leaseApplicationStatusTypeId))

    const getHeaderStatus = (status) => {
        switch (status) {
            case 1: return { headerName: "Your Lease Application is under process", subHeaderName: "" };
            case 3: return { headerName: "Your application is approved", subHeaderName: "Please sign lease agreement" };
            case 5: return { headerName: "Your Lease Application is under process", subHeaderName: "" };
            case 6: return { headerName: "Your application is approved", subHeaderName: "We will notify you when the lease agreement is ready for signature" };
            case 7: return { headerName: "Your Lease Application is Rejected", subHeaderName: "" };
            default: return { headerName: "Your Lease Application is Rejected", subHeaderName: "" };
        }
    };
    const headerStatus = getHeaderStatus(userData?.currentLeaseApplicationInfo?.leaseApplicationStatusTypeId)

    const handleSignLeaseAgreement = () => setSignLeaseAgreement(!signLeaseAgreement);
    const handleRejectionNotice = () => {
        setRejection(!rejection)
    };
    const handleGoToDashboard = () => {
        navigate(URLS.HOME);
    };

    const buttonConfigs = [
        {
            condition: currentLeaseInfo?.leaseAgreementStatusTypeId === LeaseAgreementStatusTypesEnum.PendingTenantSignature,
            name: "Sign Lease Agreement",
            onClick: handleSignLeaseAgreement,
        },
        {
            condition: currentLeaseApplicationInfo?.leaseApplicationStatusTypeId === LeaseApplicationStatusTypesEnum.Rejected,
            name: "Rejection Notice",
            onClick: handleRejectionNotice,
        },
        {
            condition: currentLeaseInfo?.leaseAgreementStatusTypeId === LeaseAgreementStatusTypesEnum.Signed,
            name: "Go to Dashboard",
            onClick: handleGoToDashboard,
        },
    ];

    const logoutFunction = () => {
        PubSub.publish(TopicsEnum.Logout.toString(), {
            message: "Successfully logged out",
            type: NotificationType.Success
        });
        LoginService.putApiLoginLogout()
            .then((res) => {
                localStorage.clear();
                setIsLogin(false);
                setTimeout(() => {
                    navigate(URLS.HOME, {
                        state: {
                            isComingFromLogout: true,
                        },
                    });
                    // navigate(URLS.HOME);
                }, 1000);
            })
            .catch((error) => { });
    };

    let ApplicantName = `${userData?.firstName} ${userData?.lastName}`


    return (
        <>
            <OrganizationNameHeader
                OrgName={OrgDetail?.name}
                OrgImg={`data:image/*;base64,${OrgDetail?.logoImageBase64}`}
                SubHeaderName="Tenant Onboarding"
            />
            {showApp && !signLeaseAgreement && (
                <OrganizationNameHeader OrgName={headerStatus.headerName}
                    element={
                        <div className='d-flex gap-2'>
                            {buttonConfigs
                                .filter(button => button.condition)
                                .map((button, index) => (
                                    <AddButtonWithArrow
                                        key={index}
                                        className="BackgroundlightBlue border-0"
                                        Icon={GetImages.WhiteForwardArrow}
                                        iconDirection="left"
                                        buttonname={button.name}
                                        onClick={button.onClick}
                                    />
                                ))}
                            {!isPrint && (
                                <div className="actionsDropdownContainer clickable">
                                    <CustomDropDownMenu Action={true} Element={<img src={GetImages.BlueChevronDown} alt="BlueChevronDown" />}
                                        data={filterActions} />
                                </div>)}
                        </div>}
                    headerNameClass="top_header fw-500"
                    subHeaderNameClass="sub_heading fw-normal" OrgImg={GetImages.folder} SubHeaderName={headerStatus.subHeaderName}
                />)}

            {withdraw && (
                <CommonConfirmation show={true} onConfirm={withDrawApplication}
                    onClose={() => { setWithdraw(false); setleaseApplicationreview(prev => ({ ...prev, tenantNotes: "" })) }}
                    heading="Withdraw Application"
                    subHeading="Are you sure you want to withdraw your application?"
                    confirmLabel="Yes Withdraw"
                    cancelLabel="Cancel"
                    Element={<><label className="normal_text">Please let us know why you want to withdraw (optional)</label>
                     <CustomInputTextArea
                          name="answer"                        
                          placeholder="Add your reason" 
                          required
                          isFormControl
                          value={leaseApplicationreview?.tenantNotes}
                          onChange={(e) => setleaseApplicationreview(prev => ({ ...prev, tenantNotes: e.target.value }))}
                          isError={ false}
                          errorMessages="reason required "
                          myClassName="normal_text black mt-2"
                          rows={4}
                        />
                        {/* <textarea 
                        className="form-control mt-2" 
                        placeholder="Add your reason" 
                        value={leaseApplicationreview?.tenantNotes}
                        onChange={(e) => setleaseApplicationreview(prev => ({ ...prev, tenantNotes: e.target.value }))}
                        rows={4} /> */}
                    </>}
                />)}
            {logout && (
                <CommonConfirmation
                    show={true}
                    onConfirm={logoutFunction}
                    onClose={() => { setlogout(false); setleaseApplicationreview(prev => ({ ...prev, tenantNotes: "" })) }}
                    heading={undefined}
                    subHeading={undefined}
                    confirmLabel="Logout"
                    cancelLabel={null}
                    Element={<div className="d-flex flex-column align-items-center justify-content-center gap-3">
                        <img alt="withdraw" src={GetImages.success} className="Icon60" />
                        <p className="main_heading">You have successfully withdrawn</p>
                    </div>}
                    buttonCenter={true} />)}

            {rejection && (
                <CommonConfirmation
                    show={true}
                    onConfirm={undefined}
                    onClose={handleRejectionNotice}
                    heading={undefined}
                    subHeading={undefined}
                    confirmLabel={undefined}
                    cancelLabel={null}
                    Element={<RejectionLetter propertyAddress={userData?.property?.address?.streetAddress} ApplicantName={ApplicantName} />}
                    buttonCenter={true} />)}

        </>
    )
}

export default ApplicationStatusHeader