import { useState } from "react";
import { generateMenuRoutes, USERS, USER_ROLES, generateAccountMenuRoutes, generateMaintananceMenuRoutes } from "../constants/constants";
import MenuItemCustom from "./MenuItemCustom";
import { GetImages } from "../utils/GetImages";
import { useMenu } from "../utils/context/MenuContext";
import "./CustomMenuStyles.css";

type Values = {
  noOfUnits: string;
  noOfTenants: string;
  noOfProperties: string;
  noOfServiceRequests: string;
  noOfUsers: string;
  noOfContractors: string;
};

const CustomMenu = ({ size, userRoleId }) => {
  const { isOpen, isHidden, setIsOpen, setIsHidden } = useMenu();
  const [values, setValues] = useState<Values>({
    noOfUnits: "",
    noOfTenants: "",
    noOfProperties: "",
    noOfServiceRequests: "",
    noOfUsers: "",
    noOfContractors: "",
  });

  const menuRoutes = generateMenuRoutes(values, userRoleId);
  const accountMenuRoutes = generateAccountMenuRoutes(values, userRoleId);
  const maintananceMenuRoutes = generateMaintananceMenuRoutes(values, userRoleId);

  return (
    <>
      <div className="pb-2">
        {USER_ROLES[1] === USERS.USER ? (
          <div className={isOpen ? "normalClass" : "collapsedBarClass"}
            data-title={!isOpen ? "Dashboard" : ""}
          >
            <MenuItemCustom
              primaryText="Dashboard"
              to="/"
              key={"newMenu-1"}
              className={
                location.pathname === "/"
                  ? "menuItemLinkClassSelected normal_text"
                  : "menuItemLinkClass normal_text"
              }
              isSelected={location.pathname === "/"}
              leftIcon={
                location.pathname === "/" ? GetImages.selectedDashboardIcon : GetImages.dashboardIcon
              }
              alt="Dashboard Icon"
            />
          </div>
        ) : null}

        <div className={isOpen ? "BorderTop mb-2" : "BorderTopHide"}>
          <p className="sub_text grey_subtext1 accountHeading"
            style={{ display: isOpen ? "block" : "none" }}>
            Leasing
          </p>
          {menuRoutes?.map((value, index) => {
            const isSelected = location.pathname.includes(value.path);

            return (
              <div
                key={index}
                className={isOpen ? "normalClass" : "collapsedBarClass"}
                data-title={!isOpen ? value.primaryText : ""}
              >
                <MenuItemCustom
                  to={value.path}
                  primaryText={value.primaryText}
                  leftIcon={!isSelected ? value.leftIcon : value.selectedIcon}
                  className={isSelected ? "menuItemLinkClassSelected normal_text" : "menuItemLinkClass normal_text"}
                  isSelected={isSelected}
                  alt={value.primaryText}
                />
              </div>
            );
          })}
        </div>

        <div className={isOpen ? "BorderTop mb-2" : "BorderTopHide"}>
          <p className="sub_text grey_subtext1 accountHeading"
            style={{ display: isOpen ? "block" : "none" }}>
            Maintenance
          </p>
          {maintananceMenuRoutes?.map((value, index) => {
            const isSelected = location.pathname.includes(value.path);

            return (
              <div
                key={index}
                className={isOpen ? "normalClass" : "collapsedBarClass"}
                data-title={!isOpen ? value.primaryText : ""}
              >
                <MenuItemCustom
                  to={value.path}
                  primaryText={value.primaryText}
                  leftIcon={!isSelected ? value.leftIcon : value.selectedIcon}
                  className={isSelected ? "menuItemLinkClassSelected normal_text" : "menuItemLinkClass normal_text"}
                  isSelected={isSelected}
                  alt={value.primaryText}
                />
              </div>
            );
          })}
        </div>

        <div className={isOpen ? "BorderTop mb-2" : "BorderTopHide"}>
          <p className="sub_text grey_subtext1 accountHeading"
            style={{ display: isOpen ? "block" : "none" }}>
            Accounts
          </p>
          {accountMenuRoutes?.map((value, index) => {
            const isSelected = location.pathname.includes(value.path);

            return (
              <div
                key={index}
                className={isOpen ? "normalClass" : "collapsedBarClass"}
                data-title={!isOpen ? value.primaryText : ""}
              >
                <MenuItemCustom
                  to={value.path}
                  primaryText={value.primaryText}
                  leftIcon={!isSelected ? value.leftIcon : value.selectedIcon}
                  className={isSelected ? "menuItemLinkClassSelected normal_text" : "menuItemLinkClass normal_text"}
                  isSelected={isSelected}
                  alt={value.primaryText}
                />
              </div>
            );
          })}
        </div>

        <div className={isOpen ? "collapseAble" : "collapseAbleClosed"}>
          <img
            onClick={() => {
              if (size < 400) {
                setIsHidden(true);
                setIsOpen(!isOpen);
              } else {
                setIsOpen(!isOpen);
              }
            }}
            className="collapseAbleIconStyle"
            src={isOpen ? GetImages.collapseAbleIcon : GetImages.alreadyCollapsed}
          />
        </div>
      </div>
    </>
  );
};

export default CustomMenu;
