import CapRateComponent from "./CapRateComponent";
import EmptyData from "../components/common/EmptyData";
import UtilHelper from "../utils/UtilHelper";

const CapRate = ({ data = [], type }) => {
  return (
    <div className="capRateComponentContainer w-100">
      {data.length > 0 ? (
        data.map((item, index) => {
          return (
            <CapRateComponent
              key={`${item.id}_${index}`}
              type={item?.formatted.PropertyType}
              address={item.streetAddress}
              percent={
                type === "Revenue"
                  ? `$ ${UtilHelper.CurrencyFormat(item?.formatted.RevenueRaw)}`
                  : `$ ${UtilHelper.CurrencyFormat(item?.formatted.ExpensesRaw)}`
              }
              img={item.mainImageBase64}
            />
          );
        })
      ) : (
        <EmptyData
          mainText={"No Data Found"}
          subText={undefined}
          button={undefined}
        />
      )}
    </div>
  );
};

export default CapRate;
