import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./tenantonboard.css";
import { DocumentEntityTypesEnum, DocumentService, DocumentTypesEnum, LeaseApplicationRentIncomeVM, LeaseApplicationService, LeaseApplicationTenantSignatureVM, RentPayTypesEnum, TemplateService } from "@propertelligent/client-api";
import apiHelper from "../../../utils/apiHelper";
import { OnboardingRentIncomeCheckForm, } from "../../../typings/onboarding";
import EmptyData from "../../common/EmptyData";
import SignatureCard from "./SignatureCard";
import { RentPayInfo } from "../component/RentPayInfo";
import { getEnumKeyByValue, staticTemplates } from "../../../utils/helper";
import { useError } from "../../../utils/context/ErrorContext";
import ApplicationCard from "../component/ApplicationCard";
import QuestionnairesInfo from "../component/Questionnaires";
import CreditCheckInfo from "../component/CreditCheckInfo";
import ReferencesInfo from "../component/ReferenceInfo";
import EmploymentHistoryInfo from "../component/EmploymentHistoryInfo";
import CurrentAddressInfo from "../component/CurrentAddressInfo";
import ContactInfo from "../component/ContactInfo";

const BoardingReview = ({ editable, isEditClick, postData, setPostData, leaseApplication = null, formData, setFormData,
  steps, landlord = false, setTenantEdit = null, tenantEdit = null, getTenantsData = undefined }): any => {
  const { errors, setErrors, errorMessages, setErrorMessages } = useError()
  const [templateData, setTemplateData] = useState<any>()
  const [leaseApplicationData, setleaseApplicationData] = useState<any>({
    BasicDetails: {}, CreditCheck: {} as any, CurrentAddress: {} as any, EmploymentHistory: [], References: [], RentIncomeCheck: {} as OnboardingRentIncomeCheckForm,
    Signature: { fullName: "", signedDateTime: "", } as LeaseApplicationTenantSignatureVM, Questionnaires: []
  })
 
  const [sectionToggles, setSectionToggles] = useState({});
  const fetchTemplateData = useCallback((templateId) => {
    if (templateId) {
      apiHelper(TemplateService.getApiTemplate, { showNotification: false }, templateId)
        .then((template) => { setTemplateData(template?.data?.success) })
    }
  }, [])

  const fetchLeaseData = () => {
    if (leaseApplication) {
      apiHelper(LeaseApplicationService.getApiLeaseApplication1, { showNotification: false }, leaseApplication)
        .then((response: any) => {
          if (response?.data?.success) {
            let leaseData = response?.data?.success;
            let leaseDataSections = response?.data?.success?.dataSections
            fetchTemplateData(response?.data?.success?.templateId)
            setleaseApplicationData(prevFormData => ({
              ...prevFormData,
              BasicDetails: leaseData?.tenant ? { firstName: leaseData?.tenant?.firstName, lastName: leaseData?.tenant?.lastName, email: leaseData?.tenant?.email, phoneNumber: leaseData?.tenant?.phoneNumber } : null,
              CreditCheck: leaseDataSections?.CreditCheck ? JSON.parse(leaseDataSections.CreditCheck) : null,
              CurrentAddress: leaseDataSections?.CurrentAddress ? JSON.parse(leaseDataSections.CurrentAddress) : null,
              EmploymentHistory: leaseDataSections?.EmploymentHistory ? JSON.parse(leaseDataSections.EmploymentHistory) : null,
              References: leaseDataSections?.References ? JSON.parse(leaseDataSections.References) : null,
              RentIncomeCheck: leaseDataSections?.RentIncomeCheck ? JSON.parse(leaseDataSections.RentIncomeCheck) : null,
              Questionnaires: leaseDataSections?.Questionnaires ? JSON.parse(leaseDataSections.Questionnaires) : null,
              Signature: leaseDataSections?.Signature ? JSON.parse(leaseDataSections.Signature) : null,
            }));
            setSectionToggles(
              Object.keys(leaseData?.reviewSections || {}).reduce((acc, key) => {
                acc[key] = leaseData.reviewSections[key] || { approved: false, notes: "" };
                return acc;
              }, {}));
          }
        }).catch((error) => { })
    }
  }

  useMemo(() => { if (getTenantsData) { getTenantsData() } }, [sectionToggles])

  useEffect(() => {
    const buildSectionArray = (range = 0, template) => Array.from({ length: range }, () => ({ ...template }));
    fetchLeaseData();
    if (postData) {
      const { leaseApplicationTemplateSections = {} } = postData;
      const {
        EmploymentHistory = { requiredRange: 0 },
        References = { requiredRange: 0 },
        Questionnaires = { questions: [] },
      } = leaseApplicationTemplateSections;
      setleaseApplicationData({
        BasicDetails: { ...staticTemplates.basicDetails },
        CreditCheck: { ...staticTemplates.creditCheck },
        CurrentAddress: { ...staticTemplates.currentAddress },
        EmploymentHistory: buildSectionArray(EmploymentHistory.requiredRange, staticTemplates.employment),
        References: buildSectionArray(References.requiredRange, staticTemplates.reference),
        RentIncomeCheck: {
          rentPayTypeId: RentPayTypesEnum.Both,
          employerDetails: { ...staticTemplates.contact },
          assistedDetails: { ...staticTemplates.contact },
          employmentIncome: 400,
          assistedAmount: 300,
        } as LeaseApplicationRentIncomeVM,
        Questionnaires: Questionnaires.questions,
        Signature: {
          fullName: "Murtaza Abdeali",
          signedDateTime: "Jun 29, 2023",
        },
      });
    }
  }, [postData, leaseApplication, tenantEdit, setSectionToggles]);
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((preValue) => ({
      ...preValue,
      Signature: {
        ...preValue.Signature,
        [name]: name === "signedDateTime" ? new Date().toISOString() : value,
      },
    }));
    const errorKey = `${name}`;
    setErrors((prevErrors) => ({ ...prevErrors, [errorKey]: false }));
    setErrorMessages((prevMessages) => ({ ...prevMessages, [errorKey]: "" }));
  };
  const handleEditClick = (stepName) => {
    const stepIndex = steps.indexOf(stepName);
    if (stepIndex !== -1) {
      isEditClick(stepIndex);
    } else { console.error("Step not found:") }
  };

  function isEmpty(value) {
    if (Array.isArray(value)) { return value.length === 0; }
    else if (typeof value === 'object' && value !== null) { return Object.keys(value).length === 0; } return false;
  }

  const allEmpty = Object.values(leaseApplicationData).every(isEmpty);


  const sharedProps = {
    leaseApplication,
    fetchLeaseData,
    editable,
    landlord,
    sectionToggles,
    setSectionToggles,
  };

  const renderRentPayInfo = () => {
    const rentPayTypeId = leaseApplicationData?.RentIncomeCheck?.rentPayTypeId;
    const rentPayInfoTypes = [{ id: 1, type: "Income" }, { id: 2, type: "Assisted" }];
    return (
      <>
        {rentPayInfoTypes.map((info) =>
            (info.id === rentPayTypeId || rentPayTypeId === 3) && (
              <RentPayInfo leaseApplication={leaseApplication} key={info.type} type={info.type} data={leaseApplicationData?.RentIncomeCheck} />
            )
        )}
      </>
    );
  };

  const renderConditionalSection = (headerName, sectionName, renderFunction) => {
    const dataExists = postData?.leaseApplicationTemplateSections?.[sectionName] || templateData?.leaseApplicationTemplateSections?.[sectionName];
    return (dataExists && (
      <ApplicationCard
        cardHeaderName={headerName}
        sectionName={sectionName}
        component={renderFunction()}
        handleEditClick={() => handleEditClick(sectionName)}
        {...sharedProps}
      />))
  };

  return (
    <>
      <section className="application-review-body">
        <div className="d-flex gap-2 align-items-center pb-3">
          <ApplicationCard
            cardHeaderName="CONTACT INFO"
            bname={landlord ? "EditTenant" : "Edit"}
            component={<ContactInfo BasicDetails={leaseApplicationData?.BasicDetails} leaseApplication={leaseApplication} />}
            sectionName="CONTACTINFO"
            handleEditClick={() => landlord ? setTenantEdit(!tenantEdit) : handleEditClick("BasicDetails")}
            {...sharedProps}
          />
        </div>
        {allEmpty ? (
          <EmptyData mainText="Lease Application Data not found" />
        ) : (
          <div className="flexColumn gap-3">
            {renderConditionalSection("CURRENT ADDRESS", "CurrentAddress",
              () => <CurrentAddressInfo
                CurrentAddress={leaseApplicationData?.CurrentAddress} />)
            }
            {renderConditionalSection("CREDIT CHECK", "CreditCheck",
              () => <CreditCheckInfo
                CreditCheck={leaseApplicationData?.CreditCheck} />)
            }
            {renderConditionalSection("RENT PAY INFORMATION", "RentIncomeCheck", renderRentPayInfo)}
            {renderConditionalSection("EMPLOYMENT HISTORY", "EmploymentHistory",
              () => <EmploymentHistoryInfo
                EmploymentHistory={leaseApplicationData?.EmploymentHistory} />)
            }
            {renderConditionalSection("REFERENCES", "References",
              () => <ReferencesInfo
                References={leaseApplicationData?.References} />)
            }
            {renderConditionalSection("QUESTIONNAIRE", "Questionnaires",
              () => <QuestionnairesInfo
                leaseApplicationData={leaseApplicationData?.Questionnaires}
                templateData={templateData?.leaseApplicationTemplateSections?.Questionnaires?.questions}
                leaseApplication={leaseApplication} />)
            }
            {renderConditionalSection("SIGNATURE", "Signature",
              () => (<SignatureCard
                clause={postData?.leaseApplicationTemplateSections?.Signature?.clause}
                formData={editable ? formData : leaseApplicationData?.Signature}
                setFormData={setFormData}
                editable={editable}
                handleChange={handleChange}
              />))
            }
          </div>
        )}
      </section>
    </>);
};
export default BoardingReview;
