import { Route, Routes } from "react-router";
import { TenantEdit } from "./edit";
import CreateForm from "./CreateForm";
import NewSingleTenantView from "./NewSingleTenantView";
import ContentTopBottom from "../common/listPageLayout/ContentTopBottom";
import TopFixedView from "./listView/topFixedView";
import MainScrollView from "./listView/mainScrollView";
import { useEffect, useState } from "react";
import { infiniteScrollHook } from "../../hooks/infiniteScrollHook";
import { TenantService, TenantStatusesEnum } from "@propertelligent/client-api";
import RenewalLease from "./RenewalLease";
import MoveOut from "./MoveOut";
import AddTenant from "./AddTenant";
import MoveIn from "./MoveIn";
import ApplicationDetails from "./ApplicationDetails";

export const Tenants = () => {
  const [fulllyQualifiedFilter, SetFullyQualifiedFilter] = useState(null);
  const [fulllyQualifiedSort, SetFulllyQualifiedSort] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectBasic, setSelectBasic] = useState<string>("2")
  const [selectedOption, setSelectedOption] = useState()
  const [unitId, setUnitId] = useState("")
  const initialOptions = [
    { name: "All", value: "all" },
    { name: "Prospects", value: "Prospects" },
    { name: "Current", value: "Current" },
    { name: "Past", value: "Past" },
  ]

  let {
    rows: rows1,
    loading: loading1,
    hasMore: hasMore1,
    rowCount: rowCount1,
    fetchData: fetchData1,
    currentSortDirection: currentSortDirection1,
    handleSort: handleSort1,
    currentPage: currentPage1
  } = infiniteScrollHook({
    httpFetchDataFn: TenantService.getApiTenantOrganizationlist,
    FullyQualifiedFilters: fulllyQualifiedFilter,
    FullyQualifiedSorts: fulllyQualifiedSort
  });

  useEffect(() => {
    let filter = "";

    if (selectedOption === "Prospects") {
      filter = `TenantStatus==${TenantStatusesEnum.Prospect}`;
    } else if (selectedOption === "Current") {
      filter = `TenantStatus==${TenantStatusesEnum.Current}`;
    } else if (selectedOption === "Past") {
      filter = `TenantStatus==${TenantStatusesEnum.Past}`;
    }

    if (searchValue !== "") {
      const searchFilter = `User.FirstName.ToLower().Contains("${searchValue.toLowerCase()}") 
                            or User.LastName.ToLower().Contains("${searchValue.toLowerCase()}")`;
      filter = filter ? `${filter} && (${searchFilter})` : searchFilter;
    }

    SetFullyQualifiedFilter(filter || null);
  }, [searchValue, selectedOption]);

  useEffect(() => {
    SetFulllyQualifiedSort('User.LastName ' + currentSortDirection1);
  }, [currentSortDirection1]);


  return (
    <Routes>
      <Route index element={
        <ContentTopBottom
          FixedTopContent={() =>
            <TopFixedView
              rowCount={rowCount1}
              currentSortDirection={currentSortDirection1}
              handleSort={handleSort1}
              setSearchValue={setSearchValue} searchValue={searchValue}
              SelectInitialOptions={initialOptions}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              isSelectDropdown={true}
            />
          }
          FixedBottomContent={() =>
            <MainScrollView rows={rows1}
              loading={loading1}
              hasMore={hasMore1}
              fetchData={fetchData1}
              currentPage={currentPage1}
            />
          }
          rows={rows1}
        />
      } />
      <Route path="/tenantType" element={<AddTenant selectBasic={selectBasic} setSelectBasic={setSelectBasic} />} />
      <Route path="/create" element={<CreateForm selectBasic={selectBasic} fetchData={fetchData1} />} />
      <Route path="/tenant/:id" element={<NewSingleTenantView setUnitId={setUnitId} />} />
      <Route path="/application-details/:id" element={<ApplicationDetails />} />
      <Route path="/edit/:id" element={<TenantEdit />} />
      <Route path="/renew-lease/:id" element={<RenewalLease unitId={unitId} />} />
      <Route path="/move-out/:id" element={<MoveOut />} />
      <Route path="/move-in/:id" element={<MoveIn />} />
    </Routes>
  );
};
