import React, { useCallback, useEffect, useState } from "react";
import "./tenantonboard.css";
import {
  OnboardingForms,
} from "../../../typings/onboarding";
import { OnboardingStepsEnums } from "../../../constants/onboarding";
import CustomInputs from "../../common/Inputs";
import RadioButton from "../../common/RadioButton";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";
import { useError } from "../../../utils/context/ErrorContext";
import DateHelper from "../../../utils/DateHelper";
import { LeaseApplicationCreditCheckVM } from "@propertelligent/client-api";
const StepThree: React.FC<any> = ({
  formData,
  setFormData,
  CreditCheck
}: {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<OnboardingForms>>;
  CreditCheck:any
}) => {
  const { errors, setErrors, errorMessages, setErrorMessages } = useError()
  const setdata = ()=>{
    if (!CreditCheck) return;  
    setFormData((prevFormData) => ({
      ...prevFormData,     
      CreditCheck: prevFormData?.CreditCheck ? prevFormData?.CreditCheck
      : {
        taxIdNumber: "",
        dateOfBirth: "",
        consentToRunCheck: true,
      } as LeaseApplicationCreditCheckVM,
    }));
  }
  useEffect(() => {setdata()}, [CreditCheck]);

  const handleChange = useCallback((key: any, value: any,) => {
    setFormData((prevData: OnboardingForms) => ({
      ...prevData,
      CreditCheck: {
        ...prevData?.CreditCheck,
        [key]: value,
      },
    }));
    const errorKey = `${key}`;
    setErrors((prevErrors) => ({ ...prevErrors, [errorKey]: false }));
    setErrorMessages((prevMessages) => ({ ...prevMessages, [errorKey]: "" }));

  }, [setFormData,]);

  const Radiodata = [
    {
      id: 1,
      label: "Yes",
      value: true,
      checked: formData?.consentToRunCheck == true,
    },
    {
      id: 2,
      label: "No",
      value: false,
      checked: formData?.consentToRunCheck == false,
    },
  ]

  return (
    <div className="onboarding-stepper">
      <div className="onboarding-steppercard">
        <p className="main_heading py-1">Credit Check</p>
        <div className="onboardingstepper-form">
          <CustomInputs
            type="text"
            labelText="Social Security Number"
            placeholder="xxx-xxx-xxxx"
            name="taxIdNumber"
            value={formData?.taxIdNumber}
            onChange={(e) => handleChange("taxIdNumber", e.target.value,)}
            isError={errors?.taxIdNumber}
            errorMessages={errorMessages?.taxIdNumber}
            maxLength={16}
          />
          <CustomInputs
            type={FORM_CONTROLFIELD_TYPES.DATE}
            labelText="Date Of Birth"
            placeholder="xxx-xxx-xxxx"
            name="dateOfBirth"
            value={DateHelper.convertUTCtoDateTime2(formData?.dateOfBirth)}
            onChange={(e) => handleChange("dateOfBirth", e.target.value,)}
            isError={errors?.dateOfBirth}
            errorMessages={errorMessages?.dateOfBirth}
            maxLength={16}
          />
          <p className="normal-text">
            Do you give us permission to run Credit Report on your
            behalf?
          </p>
          <div className="flexRow gap-4 py-2 ">
            {Radiodata?.map((item) => (
              <div key={item?.id}>
                <RadioButton
                  id={`${item.id}`}
                  label={item.label}
                  value={formData?.consentToRunCheck}
                  name={"consentToRunCheck"}
                  checked={item.checked}
                  onChange={() => handleChange("consentToRunCheck", item.value,)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

  );
};

export default StepThree;
