import { useNavigate } from "react-router";
import { AvatarWithLoading } from "../../common/avator";
import { GetImages } from "../../../utils/GetImages";

const CardView = (data) => {
  const navigate = useNavigate();
  const searchValue = data?.searchValue;

  const formatNoi = (noiValue) => {
    // Check if the value is a string and contains a numeric value with a 'K' suffix
    if (typeof noiValue === 'string') {
      // Extract the numeric value and check if it's negative
      const numericValue = parseFloat(noiValue.replace(/[^\d.-]/g, ''));

      if (numericValue < 0) {
        return `(${Math.abs(numericValue)}K)`; // Format negative values with parentheses
      }

      return `${numericValue}K`; // Return positive values as is
    }
    return noiValue; // Return the value if it's not in the expected format
  };

  return (
    <>
      <div
        key={data.item.id}
        className="propertyCardConatiner"
        onClick={() => navigate("/properties/details/" + data.item.id)}
      >
        <div className="propertCardLeft">
          <div className="Icon60  ">
            <AvatarWithLoading
              docImage={data.item?.mainImageBase64}
              docNumber={data.item?.mainImageId}
              avatar={true}
              Size="Icon60"
              isProperty={true}
            />
          </div>
          <div className="propertyCardDisc">
            <div className="propertyCardDetails">
              <div className="d-flex align-items-center">
                <p className="sub_text grey_subtext1 ">
                  {data.item?.formatted?.PropertyTypeId}
                </p>

                <div
                  className="redColorForBox simpleBox"
                  style={{
                    display: data?.item?.incomeDue === 0 ? "none" : "",
                  }}
                >
                  <img
                    src={GetImages.redArrow}
                    className="iconstyle"
                    style={{
                      display: data.item.incomeDue === 0 ? "none" : "",
                    }}
                  />
                  <p
                    className="small_text red"
                    style={{
                      display: data.item.incomeDue === 0 ? "none" : "",
                    }}
                  >
                    $ {data?.item?.formatted?.IncomeDueRaw} due
                  </p>
                </div>
              </div>
              <p className="propertyViewAddress normal_text black fw-500">
                {data.item.address.streetAddress}
              </p>
            </div>
            <div className="propertyCardQuantity">
              <div className="propertyCardQuantityDesc">
                <img src={GetImages.OrangeUnit} className="iconstyle14" />
                <p className="sub_text black">
                  {data.item.numberOfUnits > 1 ? `${data.item.numberOfUnits} Units` : `${data.item.numberOfUnits} Unit`}
                </p>
              </div>
              <div className="dividerSmallDiv"></div>
              <div className="propertyCardQuantityDesc">
                <img src={GetImages.OrangeUser} className="iconstyle14" />
                <p className="sub_text black">
                  {data.item.vacantUnits > 1 ? `${data.item.vacantUnits} Vacancies` : `${data.item.vacantUnits} Vacancy`}
                </p>
              </div>
              <div className="dividerSmallDiv"></div>
              <div className="propertyCardQuantityDesc">
                <img src={GetImages.OrangeList} className="iconstyle14" />
                <p className="sub_text black">
                  {data.item.openServiceRequests > 1 ? `${data.item.openServiceRequests} Requests` : `${data.item.openServiceRequests} Request`}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="propertCardRight">
          <div className="d-flex align-data.items-center flex-column justify-content-center">
            <p className="normal_text black fw-500">
              $ {formatNoi(data.item?.formatted?.Revenue)}
            </p>
            <p className="sub_text grey_subtext1">Revenue</p>
          </div>
          <div className="dividerMediumDiv"></div>
          <div className="d-flex align-data.items-center flex-column justify-content-center">
            <p
              className={`${data.item?.formatted?.CapRate >= 10
                ? `normal_text green fw-500`
                : data.item?.formatted?.CapRate >= 7 && data.item?.formatted?.CapRate < 10
                  ? `normal_text yellow fw-500`
                  : `normal_text red fw-500`
                }`}
            >
              $ {formatNoi(data.item?.formatted?.Noi)}
            </p>
            <p className="sub_text grey_subtext1">NOI</p>
          </div>
          <div className="dividerMediumDiv"></div>
          <div className="d-flex align-data.items-center flex-column justify-content-center">
            <p
              className={`${data.item?.formatted?.CapRate >= 10
                ? `normal_text green fw-500`
                : data.item?.formatted?.CapRate >= 7 &&
                  data.item?.formatted?.CapRate < 10
                  ? `normal_text fw-500 yellow fw-500`
                  : `normal_text fw-500 red fw-500`
                }`}
            >
              {data.item?.formatted?.CapRate}%
            </p>
            <p className="sub_text grey_subtext1">Cap Rate</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardView;
