
import { UserLoginData } from "../common/charts/Enums";
import { useNavigate } from "react-router";
import { Col, Row } from "react-bootstrap";
import { GetImages } from "../../utils/GetImages";
import AddButtonWithArrow from "./commonCom/AddButtonWithArrow";

const Welcome = () => {
  const firstName = localStorage.getItem(UserLoginData.user_name).split(" ")[0];
  const navigate = useNavigate();

  return (
    <div className="welcomestyle">
      <div className="welcomeCenter">
        <div className="welcomeTextBox">
          <p className="top_header black">Welcome, {firstName}</p>
          <p className="main_heading fw-normal grey_subtext1">
            Get started with propertelligent in just 3 easy steps
          </p>
        </div>
        <div className="midContainer">
          <div className="midportion">
            <Row
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Col item xs={12} md={4} sm={12}>
                <div className="imageTextGroup">
                  <div>
                    <img src={GetImages.AddProperty} />
                  </div>
                  <p className="sub_heading black text-start d-flex">Add property</p>
                  <p className="normal_text grey_subtext1">
                    Provide basic details of your property
                  </p>
                </div>
              </Col>
              <Col className="beforeafter" item xs={12} md={4} sm={12}>
                <div className="imageTextGroup ">
                  <div>
                    <img src={GetImages.AddUnitTannet} />
                  </div>
                  <p className="sub_heading black text-start d-flex">
                    Add unit and tenant
                  </p>
                  <p className="normal_text text-center black">
                    Add single or multiple units{" "}
                  </p>
                </div>
              </Col>
              <Col item xs={12} md={4} sm={12}>
                <div className="imageTextGroup">
                  <div>
                    <img src={GetImages.AddPayment} />
                  </div>
                  <p className="sub_heading black text-start d-flex">Add payment method</p>
                  <p className="normal_text text-center black">
                    Link your Plaid account in one click{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <AddButtonWithArrow
              buttonname="Get Started"
              onClick={() => navigate("/addProperty")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
