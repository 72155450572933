import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { useError } from '../../utils/context/ErrorContext';
import { DocumentEntityTypesEnum, DocumentService, DocumentTypesEnum, PropertyService, PropertyVM } from '@propertelligent/client-api';
import { GetImages } from '../../utils/GetImages';
import AddressAutocomplete from '../firstTimeUserExperience/commonCom/AddressAutocomplete';
// import OfficeAddressForm from '../my-organization/BasicDetails/common/OfficeAddressForm';
import { FORM_CONTROLFIELD_TYPES } from '../../constants/constants';
import apiHelper from '../../utils/apiHelper';
import { validateZipCode } from '../../utils/validateHelper';
import { Col, Row } from 'react-bootstrap';
import AddTraspButton from '../firstTimeUserExperience/commonCom/AddTraspButton';
import AddButtonWithArrow from '../firstTimeUserExperience/commonCom/AddButtonWithArrow';
import Uploader from '../common/CustomUploader/Uploader';
import EditCustomComponent from '../tenants/EditCustomComponent';

const PropertyEdit = () => {
  const [propertyValue, setPropertyvalue] = useState<PropertyVM>();
  const [fileName, setFileName] = useState("");
  const [progress, setProgress] = useState(0);
  const [mainImage, setMainImage] = useState(null);
  const [values, setValues] = useState({ streetAddress: "", city: "", state: "", zip: "" });
  const navigate = useNavigate();
  const { id } = useParams()
  const { errors, errorMessages, setErrors, setErrorMessages, clearErrors } = useError();

  const formDetails = [
    {
      label: "Street Address",
      placeholder: "Enter Street Address",
      value: values.streetAddress,
      name: "streetAddress",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      blurFunction: () => {
        if (values.streetAddress == "") {
          setErrors({ ...errors, streetAddress: true })
        } else {
          setErrors({ ...errors, streetAddress: false })
        }
      },
      errorMessages: errorMessages.streetAddress,
      isError: errors.streetAddress,
      linkWebsite: false,
      width: "289px",
      required: true
    },
    {
      label: "City",
      placeholder: "Enter City",
      value: values.city,
      name: "city",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      blurFunction: () => {
        if (values.city == "") {
          setErrors({ ...errors, city: true })
        } else {
          setErrors({ ...errors, city: false })
        }
      },
      errorMessages: errorMessages.city,
      isError: errors.city,
      linkWebsite: false,
      width: "150px",
      required: true
    },
    {
      label: "State",
      placeholder: "Enter State",
      value: values.state,
      name: "state",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      blurFunction: () => {
        if (values.state == "") {
          setErrors({ ...errors, state: true })
        } else {
          setErrors({ ...errors, state: false })
        }
      },
      errorMessages: errorMessages.state,
      isError: errors.state,
      linkWebsite: false,
      width: "150px",
      required: true
    },
    {
      label: "Zip",
      placeholder: "Enter Zip",
      value: values.zip,
      name: "zip",
      type: FORM_CONTROLFIELD_TYPES.TELEPHONE,
      blurFunction: () => validateZipCode(values.zip, setErrors, setErrorMessages),
      errorMessages: errorMessages.billingZipCode,
      isError: errors.billingZipCode,
      linkWebsite: false,
      width: "150px",
      maxLength: 5,
      required: true
    }
  ]

  const checkEmptyFields = (): boolean => {
    let hasErrors = false;
    clearErrors();

    if (values.streetAddress?.trim() === "" || values.streetAddress?.trim() === undefined) {
      setErrors(({ ...errors, streetAddress: true }));
      hasErrors = true;
    } else {
      setErrors(({ ...errors, streetAddress: false }));
    }

    if (values.state?.trim() === "" || values.state?.trim() === undefined) {
      setErrors((prevErrors) => ({ ...prevErrors, state: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, state: false }));
    }

    if (values.city?.trim() === "" || values.city?.trim() === undefined) {
      setErrors((prevErrors) => ({ ...prevErrors, city: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, city: false }));
    }

    if (values.zip?.trim() === "" || values.zip?.trim() === undefined) {
      setErrors((prevErrors) => ({ ...prevErrors, billingZipCode: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, billingZipCode: false }));
    }

    if (!/^\d{5}$/.test(values.zip?.trim())) {
      setErrors((prevErrors) => ({ ...prevErrors, billingZipCode1: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        billingZipCode1: "Zip code must be 5 digits",
      }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, billingZipCode1: false }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        billingZipCode1: "",
      }));
    }

    return hasErrors;
  };

  const handleDelete = () => {
    setMainImage(null);
    setFileName(null);
    setProgress(0);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "streetAddress":
        setErrors({ ...errors, streetAddress: false });
        break;
      case "city":
        setErrors({ ...errors, city: false });
        break;
      case "state":
        setErrors({ ...errors, state: false });
        break;
      case "zip":
        setErrors({ ...errors, billingZipCode: false });
        break;
      default:
        break;
    }
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    if (checkEmptyFields) {
      clearErrors()
    }
  }, [])

  useEffect(() => {
    if (propertyValue?.address) {
      setValues({
        ...values,
        streetAddress: propertyValue?.address?.streetAddress,
        city: propertyValue?.address?.city,
        state: propertyValue?.address?.state,
        zip: propertyValue?.address?.zip
      })
      setErrors(({ ...errors, streetAddress: false, state: false, city: false, billingZipCode: false }));
    }
  }, [propertyValue])

  const handleSubmit = async (e: any) => {
    checkEmptyFields();
    if (!values) { }
    else {
      const propertyObject = {
        id: id,
        address: values,
        propertyTypeId: propertyValue.propertyTypeId
      }

      const response = await apiHelper(PropertyService.putApiProperty, { showNotification: false }, id, propertyObject);
      if (response?.data?.success) {
        if (mainImage && mainImage instanceof Blob) {
          const imgData = {
            EntityTypeId: DocumentEntityTypesEnum.Property,
            EntityId: Number(response?.data?.success?.id),
            DocumentTypeId: DocumentTypesEnum.BeforeImage,
            FileName: fileName,
            Document: mainImage,
            IsMain: true,
          };
          try {
            const res = await apiHelper(DocumentService.postApiDocument,
              {
                successMessage: "Property Updated Successfully",
                failureMessage: "Unable to Update Property",
                showNotification: true,
              }, imgData);
            if (res?.data?.success) {
              navigate(`/properties/details/${id}`)
            }
          } catch (imageUploadError) { }
        } else {
          navigate(`/properties/details/${id}`);
        }
      }
    }
  };

  useEffect(() => {
    getPropertyDetails()
  }, [])

  const getPropertyDetails = () => {
    apiHelper(PropertyService.getApiProperty1, { showNotification: false }, id)
      .then((res) => {
        if (res?.data?.success) {
          setPropertyvalue({ propertyTypeId: res?.data?.success?.propertyTypeId });
          setMainImage("data:image/*;base64," + res?.data?.success?.mainImageBase64)
          setValues({
            ...values,
            streetAddress: res?.data?.success?.address?.streetAddress,
            city: res?.data?.success?.address?.city,
            state: res?.data?.success?.address?.state,
            zip: res?.data?.success?.address?.zip
          })
        }
      }).catch((err) => { })
  }

  return (
    <div className="AddProperty" id='editProperty'>
      <div className="formWrapper">
        <p className="main_heading black">Add image and address of property</p>
        <div className="addressUploader">
          <div className="uploader clickable">
            <Uploader
              name="Add Property Image"
              setMainImage={setMainImage}
              mainImage={mainImage}
              setFileName={setFileName}
              progress={progress}
              setProgress={setProgress}
              Size={{
                size: "Icon120",
                iconSize: "Icon32",
                pSize: 56,
                uploadimage: "iconstyle18",
              }}
            />
            <div>
              {mainImage ? (
                <>
                  <div className="deletereplacemain">
                    <div onClick={() => setMainImage(null)} className="deletereplace">
                      <img src={GetImages.BlueReplace} className="iconstyle" alt='' />
                      <p className="replace">Replace</p>
                    </div>
                    <div>
                      <div className="dividerSmallDiv"></div>
                    </div>
                    <div onClick={handleDelete} className="deletereplace">
                      <img src={GetImages.deleteIconRed} className="iconstyle" alt='' />
                      <p className="delete">Delete</p>
                    </div>
                  </div>
                </>
              ) : (
                <p className="sub_text grey_subtext1">
                  Image format jpeg and png. Max image size 2 MB
                </p>
              )}
              {(errors.ImageType || errors.ImageSize) && (
                <div style={{ marginTop: "8px" }}>
                  {errors.ImageType && (
                    <p className="errorMessageStyles">{errorMessages.ImageType}</p>
                  )}
                  {errors.ImageSize && (
                    <p className="errorMessageStyles">{errorMessages.ImageSize}</p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="addressDetail">
            <div className="d-flex flex-column gap-2">
              <AddressAutocomplete
                propertyValue={propertyValue}
                setPropertyvalue={setPropertyvalue}
                showSelectedValue={false}
              />
            </div>

            <div className="office-address-form">
              <div className="d-flex addContractorInputContainer1">
                {formDetails?.map((item: any) => (
                  <EditCustomComponent
                    key={item.label}
                    label={item.label}
                    placeholder={item.placeholder}
                    value={item.value}
                    name={item.name}
                    onChangeFunction={handleChange}
                    onBlurFunction={item.blurFunction}
                    errorMessages={item.errorMessages}
                    isError={item.isError}
                    linkWebsite={item.linkWebsite}
                    width={item.width}
                    borderRadius="4px"
                    type={item.type}
                    required={item.required}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Row className="paymentinputrow p-0">
        <Col>
          <div className="tenantFormFooter">
            <div className="footerRightButtons d-flex justify-content-between">
              <AddTraspButton
                type="button"
                bname={"Cancel"}
                onClick={() => navigate(-1)}
                border={true}
                width={"127px"}
              />
              <AddButtonWithArrow
                type="button"
                buttonname={"Save Changes"}
                onClick={(e) => handleSubmit(e)}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default PropertyEdit