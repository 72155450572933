export const ServiceNotes = ({ serviceReqInfo }) => {
  return (
    <div
      className="p-0 singleTenantNameDetailsLeftSide"
    // style={{ marginTop: "12px", marginBottom: "12px" }}
    >
      <p className="singleTenantDetailsName d-flex gap-1">
        <span className="sub_text black fw-500"> Description: </span>
        <p className="sub_text black fw-normal">  {serviceReqInfo}</p>
      </p>
    </div>
  );
};
