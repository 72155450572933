import React, { useEffect, useState } from 'react'
import OrganizationNameHeader from '../../common/OrganizationNameHeader/OrganizationNameHeader'
import { GetImages } from '../../../utils/GetImages';
import { LeaseAgreementService, LeaseAgreementStatusTypesEnum, LeaseApplicationService, TemplateService } from '@propertelligent/client-api';
import apiHelper from '../../../utils/apiHelper';
import AddTraspButton from '../../firstTimeUserExperience/commonCom/AddTraspButton';
import AddButtonWithArrow from '../../firstTimeUserExperience/commonCom/AddButtonWithArrow';
import SignatureCard from '../../TenantOnboard/RenderSteps/SignatureCard';

const ShowLeaseAgreement = ({ postLeaseAgreement, setPostLeaseAgreement, onCancel, onClick, buttonName, selectedTemplateid, userData, role = "landlord" ,selectedTemplates }) => {
    
    const { currentLeaseInfo, currentLeaseApplicationInfo } = userData || {};
    const [selectedTemplate, setSelectedTemplate] = useState<any>(selectedTemplates)
    const [OrgDetail, setOrgDetail] = useState<any>()
    const getTempleteData = () => {
        if (selectedTemplateid || currentLeaseInfo?.templateId) {
            apiHelper(TemplateService.getApiTemplate, { showNotification: false }, Number(selectedTemplateid || currentLeaseInfo?.templateId))
                .then((res) => {
                    if (res?.data?.success) {
                        const templateData = res.data.success;
                        setSelectedTemplate(templateData)
                    }}).catch((err) => { console.error("Error fetching template data:", err); })
        }
    };
    const tenantEdit = role == "tenant" && currentLeaseInfo?.leaseAgreementStatusTypeId == LeaseAgreementStatusTypesEnum.PendingTenantSignature
    const landlordEdit = role == "landlord" && currentLeaseInfo?.leaseAgreementStatusTypeId == LeaseAgreementStatusTypesEnum.PendingLandlordSignature
    const getLeaseAgreement = () => {
        if (Number(currentLeaseInfo?.id)) {
            apiHelper(LeaseAgreementService.getApiLeaseAgreement1, { showNotification: false }, Number(Number(currentLeaseInfo?.id))).then((res) => {
                setPostLeaseAgreement((prevState) => ({
                    ...prevState,
                    templateId: selectedTemplateid,
                    dataAttributes: res?.data?.success?.dataAttributes || {},
                }));
            }).catch((error) => { })
        }
    }
    useEffect(() => {
        if(!selectedTemplates) {
        if (currentLeaseApplicationInfo?.id) {
            apiHelper(LeaseApplicationService.getApiLeaseApplicationOrganizationHeaderInfo, { showNotification: false }, currentLeaseApplicationInfo?.id).then((res) => {
                setOrgDetail(res?.data?.success)
            }).catch((error) => { })
        }
        getLeaseAgreement();
        getTempleteData()
    }
    }, [Number(currentLeaseInfo?.id), selectedTemplateid]);

    const replacePlaceholders = (htmlString, dataAttributes) => {
        const regex = /\[([^\]]+)\]/g;
        return htmlString?.replace(/<span class="close-btn">×<\/span>/g, "")?.replace(regex, (_, key) => {
            const value = dataAttributes[key.trim()] || `[${key}]`;
            // return `<span style="color: blue;">${value}</span>`;
            return `${value}`;
        })
    };
    const disablelandlord = currentLeaseInfo?.leaseAgreementStatusTypeId === LeaseAgreementStatusTypesEnum.PendingLandlordSignature || role == "landlord"

    return (
        <>
            <div className='leaseAgreementSettings_main px-4'>
                {!selectedTemplates && (<OrganizationNameHeader
                    OrgName={OrgDetail?.name}
                    OrgImg={`data:image/*;base64,${OrgDetail?.logoImageBase64}`}
                    SubHeaderName="Lease Agreement"
                />)}
                
                <div className='flexColumn gap-2 '>
                    <div className='flexColumn gap-3'>
                        <p className='normal_text lh-lg'> The word "Landlord" as used in this Lease means all of the landlords above listed. In all instances in which the Landlord may exercise rights or perform obligations under this Lease, it may do so through its authorized agents or representatives.</p>
                        <p className='normal_text lh-lg'>The word "Tenant" as used in this Lease means all of the tenants above listed.</p>
                    </div>
                    <div className='leaseAgreementTemplateSections'>
                         {selectedTemplate?.leaseAgreementTemplateSections?.map((section, index) => (
                            <div key={index} style={{ marginBottom: "20px" }}>
                                <p className='main_heading text-uppercase'>{index + 1}. {section?.sectionName}:</p>
                                <div dangerouslySetInnerHTML={{ __html: replacePlaceholders(section?.sectionData, postLeaseAgreement?.dataAttributes || {}), }} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='flexRow'>
                    <SignatureCard
                        clause={undefined}
                        formData={{ fullName: "Tenant", signedDateTime: "Jun 29, 2023", permission: true }}
                        setFormData={undefined}
                        editable={tenantEdit}
                        handleChange={undefined}
                    />
                    {landlordEdit && (
                        <SignatureCard
                            clause={undefined}
                            formData={{ fullName: "Landlord", signedDateTime: "Jun 29, 2023", permission: true }}
                            setFormData={undefined}
                            editable={landlordEdit}
                            handleChange={undefined}
                        />)}
                </div>
            </div>

            <div className="application-section-bttom-view1 ">
                <AddTraspButton
                    type="button"
                    bname={"Cancel"}
                    onClick={onCancel}
                    border={true}
                />
                <AddButtonWithArrow
                    className={disablelandlord ? "bg-secondary" : ""}
                    buttonname={buttonName}
                    onClick={onClick}
                    disabled={disablelandlord}
                    iconDirection={"left"}
                    Icon={GetImages.WhiteForwardArrow} />
            </div>
        </>
    )
}

export default ShowLeaseAgreement