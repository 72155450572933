import React from 'react'
import { getEnumKeyByValue } from '../../../utils/helper'
import { LeaseApplicationReferenceTypesEnum } from '@propertelligent/client-api'
import EmptyData from '../../common/EmptyData'

const ReferencesInfo = ({References}) => {
   
  return (
    <>
   { References?.length ? (
      References?.map((item, i) => (
        <div key={i} className="Employment_history">
          <div className="w-100">
            <p className="normal_text fw-bold black">
              Type :{" "}
              <span className="normal_text green">
                {getEnumKeyByValue(LeaseApplicationReferenceTypesEnum, item?.referenceTypeId)}
              </span>
            </p>
          </div>
          <div className="w-100">
            <p className="sub_heading fw-normal black text-center">
              {`${item?.firstName} ${item?.lastName}`}
            </p>
            <p className="normal_text blue text-center">{item?.email}</p>
          </div>
          <div className="w-100 text-end">
            <p className="sub_heading fw-normal black">{item?.phoneNumber}</p>
          </div>
        </div>
      ))
    ) : ( <EmptyData mainText="Data not found" /> )}
    </>
  )
}

export default ReferencesInfo