import { useState } from "react";
import CircularProgress from "./CircularProgress";
import "./uploader.css";
import { GetImages } from "../../../utils/GetImages";
import CustomInputs from "../Inputs";
import { useError } from "../../../utils/context/ErrorContext";

const Uploader = ({
  name,
  setMainImage,
  mainImage,
  setFileName,
  progress,
  setProgress,
  Size,
}) => {
  const [loading, setLoading] = useState(false);
  const { errors, setErrors } = useError();
  const img = mainImage instanceof Blob ? URL.createObjectURL(mainImage) : mainImage;

  const clearProgress = () => {
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? clearInterval(interval) : prevProgress + 10
      );
    }, 100);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file || !["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
      setErrors({ ...errors, ImageType: true })
      return;
    }

    const maxSize = 2 * 1024 * 1024;
    if (file.size > maxSize) {
      setErrors({ ...errors, ImageType: false, ImageSize: true })
      return;
    }

    setLoading(true);
    setErrors({ ...errors, ImageType: false, ImageSize: false })
    clearProgress();

    setTimeout(() => {
      setLoading(false);
      setMainImage(new Blob([file], { type: "image/png|jpg" }));
      setFileName(file.name);
    }, 2000);
  };

  return (
    <div className="uploaderContainer">
      <div className={`box ${Size.size}`}>
        <CustomInputs
          type="file"
          id="upload"
          myClassName="upload-input"
          name="fileName"
          placeholder={name}
          onImageUpload={handleImageUpload}
          onChange={undefined}
        />
        {loading ? (
          <div className="d-flex flex-column align-items-center gap-2">
            <CircularProgress
              size={Size.pSize}
              thickness={2}
              color="blue"
              progress={progress}
              uploadImage={Size.uploadimage}
            />
            <p className="sub_text fw-500 blue">Uploading...</p>
          </div>
        ) : mainImage ? (
          <img
            src={img}
            alt=""
            className={`${Size.size}`}
          />
        ) : (
          <label htmlFor="upload" className="upload-label">
            <div className="emptyImage">
              <img
                className={Size.iconSize}
                src={GetImages.BlueAdd}
                alt="add"
              />
              <div className="sub_text fw-500 blue">{name}</div>
            </div>
          </label>
        )}
      </div>
    </div>
  );
};

export default Uploader;
