import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
import EmploymentHistory from "./EmployementHistory";
import { OnboardingStepsEnums } from "../../../constants/onboarding";
import { OnboardingForms } from "../../../typings/onboarding";
import EmptyData from "../../common/EmptyData";
import AddButtonWithArrow from "../../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import { GetImages } from "../../../utils/GetImages";
import CurrentAddress from "./CurrentAddress";
import SignatureConfirmation from "./SignatureConfirmation";

export const renderSteps = ({
  currentStepIndex,
  setpreStepIndex,
  errors,
  formData,
  setFormData,
  steps,
  isEditClick,
  isEdit,
  docsArr,
  setDocsArr,
  leaseApplicationid,
  goToApplicationView,
  leaseApplicationTemplateSections
}: {
  currentStepIndex: number;
  setpreStepIndex: any;
  errors: any;
  //  errors: OnboardingFormErrorsType;
 
  formData: OnboardingForms;
  setFormData: React.Dispatch<React.SetStateAction<OnboardingForms>>;
  //  steps: OnboardingStepsEnums[];
  steps: any;
  isEditClick: any;
  isEdit: boolean
  docsArr: any;
  setDocsArr: any;
  leaseApplicationid: Number
  goToApplicationView: any,
  leaseApplicationTemplateSections:any
}) => {


  switch (steps[currentStepIndex]) {
    case OnboardingStepsEnums.BasicDetails:
      return (
        <StepOne
          formData={formData?.BasicDetails}
          setFormData={setFormData}
          errors={errors?.BasicDetails}        
          docsArr={docsArr}
          setDocsArr={setDocsArr}
          isEdit={isEdit}
          leaseApplicationid={leaseApplicationid}
        />
      );
    case OnboardingStepsEnums.CurrentAddress:
      return (
        <CurrentAddress
          formData={formData?.CurrentAddress}
          setFormData={setFormData}
          CurrentAddress={leaseApplicationTemplateSections?.CurrentAddress}
       
        />
      )
    case OnboardingStepsEnums.RentIncomeCheck:
      return (
        <StepTwo
          formData={formData?.RentIncomeCheck}
          setFormData={setFormData}
          errors={errors?.RentIncomeCheck}
          currentStepIndex={currentStepIndex}
          leaseApplicationid={leaseApplicationid}
          docsArr={docsArr}
          setDocsArr={setDocsArr}
          RentIncomeCheck={leaseApplicationTemplateSections?.RentIncomeCheck}
        />
      );
    case OnboardingStepsEnums.CreditCheck:
      return (
        <StepThree
          formData={formData?.CreditCheck}
          setFormData={setFormData}
          errors={errors?.CreditCheck}
          CreditCheck={leaseApplicationTemplateSections?.CreditCheck}
        

        />
      );
    case OnboardingStepsEnums.EmploymentHistory:
      return (
        <EmploymentHistory
          formData={formData?.EmploymentHistory}
          setFormData={setFormData}
          EmploymentHistory={leaseApplicationTemplateSections?.EmploymentHistory}
          requiredRange={leaseApplicationTemplateSections?.EmploymentHistory?.requiredRange}
        
        />
      );
    case OnboardingStepsEnums.References:
      return (
        <StepFour
          formData={formData?.References}
          setFormData={setFormData}
          References={leaseApplicationTemplateSections?.References}
          requiredRange={leaseApplicationTemplateSections?.References?.requiredRange}
        
        />
      );
    case OnboardingStepsEnums.Questionnaires:
      return (
        <StepFive
          formData={formData?.Questionnaires}
          setFormData={setFormData}
          Questionnaires={leaseApplicationTemplateSections?.Questionnaires?.questions}
        />
      );
    case OnboardingStepsEnums.Signature:
      return (
        <SignatureConfirmation
          formData={formData?.Signature}
          setFormData={setFormData}
          leaseApplication={leaseApplicationid}
          errors={errors?.Signature}
          
          isEditClick={isEditClick}
          currentStepIndex={currentStepIndex}
          setpreStepIndex={setpreStepIndex}
          steps={steps}
          Signature={leaseApplicationTemplateSections?.Signature}
        />
      )
    case OnboardingStepsEnums.ApplicationFees:
      return (
        <StepSix
          formData={formData?.ApplicationFees}
          setFormData={setFormData}
          errors={errors?.ApplicationFees}
          ApplicationFees={leaseApplicationTemplateSections?.ApplicationFees}
          fee={leaseApplicationTemplateSections?.ApplicationFees?.fee}
        
        />
      );
    case OnboardingStepsEnums.Thankyou:
      return (
        <EmptyData
          ImageSrc={GetImages.folder}
          mainText={"Thank You"}
          subText="for Submitting Your Tenant Onboarding Form! Your form has been received. We'll be in touch soon!"
          button={<AddButtonWithArrow buttonname={"View Your Application"} onClick={goToApplicationView} iconDirection={"left"} Icon={GetImages.WhiteForwardArrow} />} />
      )
    // Add more cases for additional pages
    default:
      return null;
  }
};



