import DisplayNamewithLeftBorder from '../../common/DisplayNamewithLeftBorder'

const View = ({ values }) => {
    const getOrdinalSuffix = (day) => {
        if (day % 10 === 1 && day % 100 !== 11) {
            return `${day}st`;
        } else if (day % 10 === 2 && day % 100 !== 12) {
            return `${day}nd`;
        } else if (day % 10 === 3 && day % 100 !== 13) {
            return `${day}rd`;
        } else {
            return `${day}th`;
        }
    };
    return (
        <div className='my-4'>
            <DisplayNamewithLeftBorder
                HeaderName="Rent Collection"
            />
            <div className='my-4'>
                <div className='mb-4'>
                    <p className='mb-1 normal_text grey_subtext1'>Rent Due Day</p>
                    <p className='normal_text fw-bold grey_subtext1'>
                        {values?.rentDueDay ? `${getOrdinalSuffix(values.rentDueDay)} of Month` : 'N/A'}
                    </p>
                </div>
                <div>
                    <p className='mb-1 normal_text grey_subtext1'>Rent Grace Period</p>
                    <p className='normal_text fw-bold grey_subtext1'>
                        {Number(values?.gracePeriods) === 1 ?
                            `${values?.gracePeriods} Day`
                            : `${values?.gracePeriods} Days`
                        }
                    </p>
                </div>
            </div>
            <DisplayNamewithLeftBorder
                HeaderName="Statements"
            />
            <div className='my-4'>
                <div>
                    <p className='mb-1 normal_text grey_subtext1'>Generated Monthly PNL</p>
                    <p className='normal_text fw-bold grey_subtext1'>
                        {values?.monthlyPnl ? `${getOrdinalSuffix(values.monthlyPnl)} day of Month` : 'N/A'}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default View