import { GetImages } from "../../utils/GetImages";
import { useMenu } from "../../utils/context/MenuContext";
import selectedDashboardIcon from "../../assets/selectedDashboard.svg";
import arrowBlackIcon from "../../assets/arrow_back_left.svg";
import MenuItemCustom from "../../layout/MenuItemCustom";
import { useNavigate } from "react-router";

const OrganizationSidebar = ({ selectedTab, setSelectedTab }) => {
  const { isOpen, isHidden, setIsOpen, setIsHidden } = useMenu();
  const navigate = useNavigate();

  return (
    <div className="organizationSidebarContainer d-flex flex-column gap-2">
      <div className={isOpen ? "normalClass" : "collapsedBarClass"}>
        <MenuItemCustom
          primaryText="Dashboard"
          to="/"
          key={"newMenu-1"}
          className={
            location.pathname === "/"
              ? "menuItemLinkClassSelected normal_text"
              : "menuItemLinkClass normal_text"
          }
          leftIcon={
            location.pathname === "/" ? selectedDashboardIcon : arrowBlackIcon
          }
          isSelected={location.pathname === "/"}
          alt="Dashboard Icon"
        />
      </div>
      <div
        className={
          selectedTab === "/organization/overview"
            ? "organizationSidebarTabSelected clickable"
            : "organizationSidebarTab clickable"
        }
        onClick={() => {
          navigate("overview");
          setSelectedTab("/organization/overview");
        }}
      >
        <img
          height={20}
          width={20}
          src={
            selectedTab === "/organization/overview" ? GetImages.homeIconBlue : GetImages.homeIconBlack
          }
        />
        <p>Overview</p>
      </div>
      <div
        onClick={() => {
          navigate("payment-account");
          setSelectedTab("/organization/payment-account");
        }}
        className={
          selectedTab === "/organization/payment-account"
            ? "organizationSidebarTabSelected clickable"
            : "organizationSidebarTab clickable"
        }
      >
        <img
          height={20}
          width={20}
          src={
            selectedTab === "/organization/payment-account"
              ? GetImages.blueDollarIcon
              : GetImages.sharpDollarIcon
          }
        />
        <p>Payment account</p>
      </div>
      <div
        onClick={() => {
          navigate("payee-accounts");
          setSelectedTab("/organization/payee-accounts");
        }}
        className={
          selectedTab === "/organization/payee-accounts"
            ? "organizationSidebarTabSelected clickable"
            : "organizationSidebarTab clickable"
        }
      >
        <img
          height={20}
          width={20}
          src={
            selectedTab === "/organization/payee-accounts"
              ? GetImages.BluePayeeIcon
              : GetImages.BlackPayeeIcon
          }
        />
        <p>Payee Accounts</p>
      </div>
      <div
        onClick={() => {
          navigate("manage-users");
          setSelectedTab("/organization/manage-users")
        }}
        className={
          selectedTab === "/organization/manage-users"
            ? "organizationSidebarTabSelected clickable"
            : "organizationSidebarTab clickable"
        }
      >
        <img
          height={20}
          width={20}
          src={selectedTab === "/organization/manage-users" ? GetImages.blueUser : GetImages.BlackUser}
        />
        <p>Manage users</p>
      </div>
      <div
        onClick={() => {
          navigate("fees-and-commissions");
          setSelectedTab("/organization/fees-and-commissions")
        }}
        className={
          selectedTab === "/organization/fees-and-commissions"
            ? "organizationSidebarTabSelected clickable"
            : "organizationSidebarTab clickable"
        }
      >
        <img
          height={20}
          width={20}
          src={selectedTab === "/organization/fees-and-commissions" ? GetImages.BlueFees : GetImages.GreyFees}
        />
        <p>Fees and Commissions</p>
      </div>
      <div
        onClick={() => {
          navigate("payment-processing-dates");
          setSelectedTab("/organization/payment-processing-dates")
        }}
        className={
          selectedTab === "/organization/payment-processing-dates"
            ? "organizationSidebarTabSelected clickable"
            : "organizationSidebarTab clickable"
        }
      >
        <img
          height={20}
          width={20}
          src={selectedTab === "/organization/payment-processing-dates" ? GetImages.CalendarBlue : GetImages.calenderIcon}
        />
        <p>Payment Processing Dates</p>
      </div>
      <div
        onClick={() => {
          navigate("reports");
          setSelectedTab("/organization/reports");
        }}
        className={
          selectedTab === "/organization/reports"
            ? "organizationSidebarTabSelected clickable"
            : "organizationSidebarTab clickable"
        }
      >
        <img
          height={20}
          width={20}
          src={
            selectedTab === "/organization/reports" ? GetImages.reportsBlue : GetImages.GrayReport
          }
        />
        <p>Reports</p>
      </div>
      <div
        onClick={() => {
          navigate("templates");
          setSelectedTab("/organization/templates");
        }}
        className={
          selectedTab === "/organization/templates"
            ? "organizationSidebarTabSelected clickable"
            : "organizationSidebarTab clickable"
        }
      >
        <img
          height={16}
          width={16}
          src={
            selectedTab === "/organization/templates"
              ? GetImages.BlueTemplate
              : GetImages.templatesBlack
          }
        />
        <p>Templates</p>
      </div>
    </div>
  );
};

export default OrganizationSidebar;
