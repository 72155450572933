import { ADD_UNIT, GET_ALL_UNIT, GET_UNIT, SUCCESS_GET_UNIT } from "../actions/types";





export const unitListReducer = (state = [], action) => {
  switch (action.type) {

    case ADD_UNIT:
      return {
        ...state,
        unitList: [action.payload, ...state.unitList],
      };
    case GET_ALL_UNIT:
      return {
        ...state,
        unitList: action.payload,
      };
    default:
      return state;
  }
}


export const SingleUnitReducer = (state = {}, action) => {


  switch (action.type) {
    case SUCCESS_GET_UNIT:
      return {
        ...state,
        singleUnit: action.payload,
      };
    default:
      return state;
  }
}



