import { GetImages } from "../../../utils/GetImages";

const AddTraspButton = ({
  bname,
  iconPosition = undefined,
  imageValue = null,
  onClick = undefined,
  border = undefined,
  disabled = undefined,
  textclass = "normal_text fw-600 blue clickable",
  width = undefined,
  id = undefined,
  className = undefined,
  IconClassName = "iconstyle",
  type = "submit"
}: any) => {
  return (
    <>
      <button
        type={type}
        onClick={onClick}
        className={`addtrasparent ${className} ${textclass}`}
        id={id}
        style={{
          border: !border ? "none" : "",
          boxShadow: !border ? "none" : "",
          background: !border ? "none" : "",
          padding: !border ? "unset" : "",
          borderRadius: !border ? "unset" : "",
          minWidth: width,
        }}
        disabled={disabled}
      >
        {iconPosition === "right" && imageValue !== null && (
          <img src={imageValue} className={` ${IconClassName}`} />
        )}
        {bname}
        {iconPosition === "left" && imageValue !== null && (
          <img src={GetImages.blueForwardArrow} className={` ${IconClassName}`} />
        )}
      </button>
    </>
  );
};

export default AddTraspButton;
