import React from 'react'

const DisplayNamewithLeftBorder = ({ HeaderName, className = undefined, buttons = null }) => {
  return (
    <>
      <div>
        <p className={`text-uppercase ps-2 leftborder normal_text black ${className}`}>
          {HeaderName}
        </p>
      </div>
    </>
  )
}

export default DisplayNamewithLeftBorder