import { PayPaymentDueAccountVM } from "@propertelligent/client-api";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { isValidPositiveNumber } from "../../../utils/helper";
import AddButtonWithArrow from "../../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";

const PayForm = ({ onConfirm, paymentMethodsArr, onClose }) => {
  const [values, setValues] = useState<PayPaymentDueAccountVM>();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onConfirm(values);
  };

  return (
    <>
      <div className="paymentContainer">
        <Form className="paymentForm" onSubmit={(e) => handleFormSubmit(e)}>
          <Row className="paymentinputrow">
            <Col>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label className="sub_text  grey_subtext1">Amount</Form.Label>
                <Form.Control
                  className="sub_text  grey_subtext1"
                  required
                  type="number"
                  value={values?.amount}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      amount: isValidPositiveNumber(e?.target?.value),
                    })
                  }
                  placeholder="$   Enter Amount"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label className="sub_text  grey_subtext1">
                  Payment Method
                </Form.Label>
                <Form.Select
                  className="sub_text  grey_subtext1"
                  aria-label="Default select Payee Account"
                  required
                  value={values?.fromPaymentMethodId}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      fromPaymentMethodId: Number(e.target.value),
                    })
                  }
                >
                  <option value="">Select Payment Method</option>
                  {paymentMethodsArr?.map((item) => (
                    <option
                      className="normal_text black"
                      key={item.id}
                      value={item.id}
                    >
                      {item.accountName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <div className="editFormFooter">
            <div className="footerRightButtons">
              <AddTraspButton
                type="button"
                bname="Cancel"
                iconPosition={undefined}
                imageValue={undefined}
                onClick={onClose}
                border={false}
                width="fit-content"
              />
              <AddButtonWithArrow
                type="submit"
                buttonname="Add Payment"
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default PayForm;

