import NameAvatarComponent from "../tenants/NameAvatarComponent";
import { useNavigate } from "react-router";
import StatusTag from "../common/StatusTag";
import SimpleSpinner from "../loader";

const UsersRowComponent = (dataHookState) => {
  const navigate = useNavigate();

  return (
    <>
      {dataHookState.rows &&
        dataHookState.rows.map((item, i) => {
          return (
            <div
              onClick={() => navigate(`edit/${item.organizationUserId}`)}
              key={i}
            >
              <div className="contractorRowContainer clickable">
                <div className="nameDetails d-flex flex-row gap-2">
                  <div style={{ position: "relative" }}>
                    <NameAvatarComponent
                      firstName={item.firstName}
                      lastName={item.lastName}
                      heightWidth={40}
                    />
                  </div>
                  <div>
                    <p className="sub_heading black">{`${item.firstName} ${item.lastName}`}</p>
                    <div className="leftSideBox d-flex flex-wrap">
                      <p className="sub_text text-nowrap grey_subtext1">
                        {item.formatted.OrganizationUserTypeId ===
                          "Property Manager"
                          ? "Property Manager"
                          : "Organization Admin"}
                      </p>
                      <p className="sub_text text-nowrap grey_subtext1">•</p>
                      <p className="sub_text text-nowrap grey_subtext1">
                        {item.email && item.email}
                      </p>
                      <p className="sub_text text-nowrap grey_subtext1">•</p>
                      <p className="sub_text text-nowrap grey_subtext1">
                        {item.phoneNumber && item.phoneNumber}
                      </p>
                    </div>
                  </div>
                </div>

                {item.formatted.UserStatus === "Active" && (
                  <StatusTag color={""} statusText={"Active "} />
                )}
                {item.formatted.UserStatus === "Invitation Pending" && (
                  <StatusTag color={"yellow"} statusText={"Invitation pending"} />
                )}
                {item.formatted.UserStatus === "InActive" && (
                  <StatusTag color={"red"} statusText={"Inactive "} />
                )}

              </div>
            </div>
          );
        })}
      {dataHookState.loading && (
        <>
          <SimpleSpinner myClassName={dataHookState.rows?.length == 0 ? "Icon50" : "Icon40"} />
        </>
      )}
      <div ref={dataHookState.sentryRef} />
    </>
  );
};

export default UsersRowComponent;
