import React from "react";
import { Col, Form, Row, Image } from "react-bootstrap";
import { useNavigate } from "react-router";
import { UnitTypesEnum } from "@propertelligent/client-api";
import Uploader from "../../firstTimeUserExperience/commonCom/Uploader";
import CounterBox from "../../firstTimeUserExperience/commonCom/IncDecBox";
import GenericSearchAbleDropdown from "../../common/GenericSearchAbleDropdown";
import AddButtonWithArrow from "../../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import { GetImages } from "../../../utils/GetImages";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";
import { convertENUMtoJSONArray } from "../../../utils/helper";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import CustomInputs from "../../common/Inputs";
import { useError } from "../../../utils/context/ErrorContext";
import DisplayNamewithLeftBorder from "../../common/DisplayNamewithLeftBorder";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";

interface Property {
  id: number;
  name: string;
  address?: {
    streetAddress: string;
  };
}

interface UnitFormProps {
  properties?: Property[];
  handleDelete?: any;
  mainImage?: any;
  setFileName?: any;
  unitVal?: any;
  handleChangeImage?: any;
  setUnitVal?: any;
  setShow?: any;
  isEditForm?: boolean;
}

const UnitForm: React.FC<UnitFormProps> = ({
  properties,
  handleDelete,
  mainImage,
  setFileName,
  unitVal,
  handleChangeImage,
  setUnitVal,
  setShow,
  isEditForm = false,
}) => {
  const navigate = useNavigate();
  const unitTypesOptions = convertENUMtoJSONArray(UnitTypesEnum);
  const { errors, setErrors, errorMessages } = useError();

  return (
    <>
      <div className="paymentContainer mt-4 unitForm">
        <div className="paymentInputsContainer">
          <DisplayNamewithLeftBorder HeaderName={"Select Property"} className="sub_text" />
          <Row className="paymentinputrow">
            <Col className="p-0">
              <Form.Group className="mb-2">
                <Form.Label className="sub_text black">
                  Select Property *
                </Form.Label>
                <Form.Select
                  className="sub_text grey_subtext1"
                  value={unitVal?.propertyId}
                  onChange={(e) => {
                    setErrors({ ...errors, property: false })
                    setUnitVal((prevUnitVal) => ({
                      ...prevUnitVal,
                      propertyId: Number(e.target.value), // Ensure propertyId is a number
                    }));
                  }}
                  style={{ height: "48px", border: errors.property ? "1px solid #F05252" : "1px solid #e5e7eb" }}
                >
                  <option value="">Select Property</option>
                  {properties?.map((property) => (
                    <option key={property?.id} value={property?.id}>
                      {property?.name}
                    </option>
                  ))}
                </Form.Select>
                {errors.property && <p className="errorMessage">{errorMessages.property}</p>}
              </Form.Group>
              <Form.Label className="black">
                Can't see Property? To create new Property
                <span
                  className="navigateMe fw-600 ms-1"
                  onClick={() => navigate("/properties/create")}
                >
                  click here
                </span>
              </Form.Label>
            </Col>
          </Row>
          <DisplayNamewithLeftBorder HeaderName={"unit Details"} className="sub_text" />
          {/* Image component here */}

          <Row className="paymentinputrow">
            <Col className="p-0">
              <div className="uploader-add-tenant ps-0">
                <Uploader
                  handleChange={handleChangeImage}
                  mainImage={mainImage}
                  name="Add Image"
                  Size={{ size: "Icon80", iconSize: "Icon20" }}
                />
                {mainImage && (
                  <div>
                    <div className="deletereplacemain">
                      <div
                        onClick={() => setFileName(null)}
                        className="deletereplace"
                      >
                        <Image src={GetImages.BlueReplace} className="iconstyle" />
                        <p className="replace">Replace</p>
                      </div>
                      <div className="divider"></div>
                      <div onClick={handleDelete} className="deletereplace">
                        <Image src={GetImages.deleteIconRed} className="iconstyle" />
                        <p className="delete">Delete</p>
                      </div>
                    </div>
                  </div>
                )}
                {(errors.ImageType || errors.ImageSize) && (
                  <div style={{ marginTop: "8px" }}>
                    {errors.ImageType && (
                      <p className="errorMessageStyles">{errorMessages.ImageType}</p>
                    )}
                    {errors.ImageSize && (
                      <p className="errorMessageStyles">{errorMessages.ImageSize}</p>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row className="paymentinputrow">
            <Col sm="6">
              <Form.Group>
                <Form.Label className="sub_text black">
                  Select Unit Type *
                </Form.Label>

                <GenericSearchAbleDropdown
                  fieldName={"name"}
                  initialOptions={unitTypesOptions}
                  name={"unitTypeId"}
                  isFormControl={true}
                  value={unitVal?.unitTypeId || ""}
                  onChange={(e) => {
                    setErrors({ ...errors, unitTypeId: false })
                    setUnitVal({
                      ...unitVal,
                      unitTypeId: parseInt(e.target.value, 10),
                    })
                  }
                  }
                  placeholder="Select Unit Type"
                  myClassName="sub_text grey_subtext1"
                  style={{ height: "48px", width: "204px", border: errors.unitTypeId ? "1px solid #F05252" : "1px solid #e5e7eb" }}
                />
                {errors.unitTypeId && <p className="errorMessage">{errorMessages.unitTypeId}</p>}
              </Form.Group>
            </Col>
            <Col sm="6">
              <Form.Group>
                <CustomInputs
                  labelText="Unit Number"
                  name={"unitNumber"}
                  isFormControl
                  value={unitVal?.unitNumber}
                  required
                  type={FORM_CONTROLFIELD_TYPES.TEXT}
                  onChange={(e) => {
                    setErrors({ ...errors, unitNumber: false })
                    setUnitVal({ ...unitVal, unitNumber: e.target.value })
                  }}
                  placeholder="Enter Unit Number"
                  myClassName="sub_text  grey_subtext1"
                  id="unitVal"
                  isError={errors.unitNumber}
                  errorMessages={errorMessages.unitNumber}
                  height={"48px"}
                  onBlur={() => {
                    if (unitVal?.unitNumber == "") {
                      setErrors({ ...errors, unitNumber: true })
                    } else {
                      setErrors({ ...errors, unitNumber: false })
                    }
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <Form.Group>
                <CustomInputs
                  labelText="Area Sqft"
                  name={"size"}
                  isFormControl={true}
                  value={unitVal?.size}
                  type={FORM_CONTROLFIELD_TYPES.NUMBER}
                  required={true}
                  onChange={(e) => {
                    setErrors({ ...errors, unitNumberSize: false })
                    setUnitVal({ ...unitVal, size: e.target.value })
                  }}
                  placeholder="Enter Unit Size in Sqft"
                  myClassName="sub_text  grey_subtext1"
                  id="unitVal"
                  isError={errors.unitNumberSize}
                  errorMessages={errorMessages.unitNumberSize}
                  onBlur={() => {
                    if (unitVal?.size == "") {
                      setErrors({ ...errors, unitNumberSize: true })
                    } else {
                      setErrors({ ...errors, unitNumberSize: false })
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <Row className="paymentinputrow">
            {unitVal?.unitTypeId == UnitTypesEnum.CondoApartment ? (
              <>
                <div className="parentDiv">
                  <Col sm="6">
                    <Form.Label className="normal_text text-start">
                      Bathroom
                    </Form.Label>
                    <CounterBox
                      unitval={unitVal}
                      setUnitVal={setUnitVal}
                      type="bathroom"
                      initialValue={unitVal?.numberOfBathrooms}
                    />
                  </Col>
                  <Col sm="6">
                    <Form.Label className="normal_text text-start">
                      Bedroom
                    </Form.Label>
                    <CounterBox
                      unitval={unitVal}
                      setUnitVal={setUnitVal}
                      type="bedroom"
                      initialValue={unitVal?.numberOfBedrooms}
                    />
                  </Col>
                </div>
              </>
            ) : unitVal?.unitTypeId == UnitTypesEnum.Room ? (
              <div className="parentDiv">
                <div className="mb-3">
                  <Form.Label className="normal_text text-start">
                    Bathroom
                  </Form.Label>
                  <Form.Check
                    inline
                    label="Attached"
                    type="radio"
                    id="attached"
                    style={{ marginLeft: 20 }}
                    checked={unitVal?.isBathAttached === true}
                    onChange={() =>
                      setUnitVal({ ...unitVal, isBathAttached: true })
                    }
                    className="normal_text"
                  />
                  <Form.Check
                    inline
                    label="Common"
                    type="radio"
                    id="common"
                    checked={unitVal?.isBathAttached === false}
                    onChange={() =>
                      setUnitVal({ ...unitVal, isBathAttached: false })
                    }
                    className="normal_text"
                  />
                </div>
              </div>
            ) : unitVal?.unitTypeId == UnitTypesEnum.Studio ? null : (
              // (
              //   <div className="parentDiv">
              //     <Form.Label className="labelTextAddUnitTenant">
              //       Studio
              //     </Form.Label>
              //   </div>
              // )
              ""
            )}
          </Row>
          <Row className="paymentinputrow mt-4">
            <Col>
              <div className="tenantFormFooter">
                <div className="footerRightButtons">
                  <AddTraspButton
                    bname="Cancel"
                    type="button"
                    onClick={() => setShow(false)}
                    border={false}
                    width="fit-content"
                  />
                  <AddButtonWithArrow
                    buttonname={`${isEditForm ? "Edit" : "Add"} Unit`}
                    onClick={undefined}
                    type="submit"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default UnitForm;
