import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the shape of the error state
interface ErrorState {
  BasicDetails: {
    firstName: boolean;
    lastName: boolean;
    phoneNumber: boolean;
    email: boolean;
    password: boolean;
    confirmPassword: boolean;
  };
  EmploymentHistory: {
  }
  References: {

  }
  cardHolderName: boolean;
  cardNumber: boolean;
  cvvNumber: boolean;
  billingZipCode: boolean;
  expMonth: boolean;
  routingNumber: boolean;
  accountingNumber: boolean;
  accountingName: boolean;
  email: boolean;
  password: boolean;
  confirmPassword: boolean;
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  organizationName: boolean;
  signUpError: boolean;
  billingZipCode1: boolean;
  serviceType: boolean;
  subServiceType: boolean;
  unitNumber: boolean;
  unitNumberSize: boolean;
  dueDate: boolean;
  gracePeriods: boolean;
  amount: boolean;
  name: boolean;
  website: boolean;
  address: boolean;
  emergencyPhoneNumber: boolean;
  startDate: boolean;
  endDate: boolean;
  unit: boolean;
  document: boolean;
  selectedServiceType: boolean;
  selectedSubServiceType: boolean;
  contractorName: boolean;
  estimate: boolean;
  description: boolean;
  property: boolean;
  unitTypeId: boolean;
  reportName: boolean;
  leaseAgreement: boolean;
  leaseApplication: boolean;
  template: boolean;
  city: boolean;
  state: boolean;
  zip: boolean;
  moveOutDate: boolean;
  payeeAccountId: boolean;
  refrenceNum: boolean;
  streetAddress: boolean;
  title: boolean;
  securityDeposit: boolean;
  rent: boolean;
  attachment: boolean;
  paymentType: boolean;
  payee: boolean;
  tenant: boolean;
  checkNo: boolean;
  transactionId: boolean;
  paymentMethod: boolean;
  movingDate: boolean;
  movingReason: boolean;
  securityDepositType: boolean;
  securityDepositAccount: boolean;
  rentDepositAccount: boolean;
  commissionType: boolean;
  rentType: boolean;
  monthsRent: boolean;
  rentLateFee: boolean;
  leaseApplicationFee: boolean;
  commissionAmount: boolean;
  commissionPercentage: boolean;
  perUnitAmount: boolean;
  securityDepositamount: boolean;
  rentDueDay: boolean;
  monthlyPnl: boolean;
  ImageType: boolean;
  ImageSize: boolean;
  FileType: boolean;
  FileSize: boolean;
  dateOfBirth: boolean;
  taxIdNumber: boolean
  payeeName: boolean;
  accountType: boolean;
  moveOutReason: boolean;
  laborCost: boolean;
  materialCost: boolean;
  fixNote: boolean;
}

// Define the shape of the error messages
interface ErrorMessages {
  cardHolderName: string;
  cardNumber: string;
  cvvNumber: string;
  billingZipCode: string;
  expMonth: string;
  routingNumber: string;
  email: string;
  accountingNumber: string;
  accountingName: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  organizationName: string;
  signUpError: string;
  billingZipCode1: string;
  serviceType: string;
  subServiceType: string;
  unitNumber: string;
  unitNumberSize: string;
  dueDate: string;
  gracePeriods: string;
  amount: string;
  name: string;
  website: string;
  address: string;
  emergencyPhoneNumber: string;
  startDate: string;
  endDate: string;
  unit: string;
  document: string;
  selectedServiceType: string;
  selectedSubServiceType: string;
  contractorName: string;
  estimate: string;
  description: string;
  property: string;
  unitTypeId: string;
  reportName: string;
  leaseAgreement: string;
  leaseApplication: string;
  template: string;
  city: string;
  state: string;
  zip: string;
  moveOutDate: string;
  payeeAccountId: string;
  refrenceNum: string;
  streetAddress: string;
  title: string;
  securityDeposit: string;
  rent: string;
  attachment: string;
  paymentType: string;
  payee: string;
  tenant: string;
  checkNo: string;
  transactionId: string;
  paymentMethod: string;
  movingDate: string;
  movingReason: string;
  securityDepositType: string;
  securityDepositAccount: string;
  rentDepositAccount: string;
  commissionType: string;
  rentType: string;
  monthsRent: string;
  rentLateFee: string;
  leaseApplicationFee: string;
  commissionAmount: string;
  commissionPercentage: string;
  perUnitAmount: string;
  securityDepositamount: string;
  rentDueDay: string;
  monthlyPnl: string;
  ImageType: string;
  ImageSize: string;
  FileType: string;
  FileSize: string;
  dateOfBirth: string;
  taxIdNumber: string;
  payeeName: string;
  accountType: string;
  moveOutReason: string;
  laborCost: string;
  materialCost: string;
  fixNote: string;
}

// Default values for error state and messages
const defaultErrors: ErrorState = {
  BasicDetails: {
    email: false,
    password: false,
    confirmPassword: false,
    firstName: false,
    lastName: false,
    phoneNumber: false,
  },
  EmploymentHistory: {
  },
  References: {},
  cardHolderName: false,
  cardNumber: false,
  cvvNumber: false,
  billingZipCode: false,
  expMonth: false,
  routingNumber: false,
  accountingNumber: false,
  accountingName: false,
  email: false,
  password: false,
  confirmPassword: false,
  firstName: false,
  lastName: false,
  phoneNumber: false,
  organizationName: false,
  signUpError: false,
  billingZipCode1: false,
  serviceType: false,
  subServiceType: false,
  unitNumber: false,
  unitNumberSize: false,
  dueDate: false,
  gracePeriods: false,
  amount: false,
  name: false,
  website: false,
  address: false,
  emergencyPhoneNumber: false,
  startDate: false,
  endDate: false,
  unit: false,
  document: false,
  selectedServiceType: false,
  selectedSubServiceType: false,
  contractorName: false,
  estimate: false,
  description: false,
  property: false,
  unitTypeId: false,
  reportName: false,
  leaseAgreement: false,
  leaseApplication: false,
  template: false,
  city: false,
  state: false,
  zip: false,
  moveOutDate: false,
  payeeAccountId: false,
  refrenceNum: false,
  streetAddress: false,
  title: false,
  securityDeposit: false,
  rent: false,
  attachment: false,
  paymentType: false,
  payee: false,
  tenant: false,
  checkNo: false,
  transactionId: false,
  paymentMethod: false,
  movingDate: false,
  movingReason: false,
  securityDepositType: false,
  securityDepositAccount: false,
  rentDepositAccount: false,
  commissionType: false,
  rentType: false,
  monthsRent: false,
  rentLateFee: false,
  leaseApplicationFee: false,
  commissionAmount: false,
  commissionPercentage: false,
  perUnitAmount: false,
  securityDepositamount: false,
  rentDueDay: false,
  monthlyPnl: false,
  ImageType: false,
  ImageSize: false,
  FileType: false,
  FileSize: false,
  dateOfBirth: false,
  taxIdNumber: false,
  payeeName: false,
  accountType: false,
  moveOutReason: false,
  laborCost: false,
  materialCost: false,
  fixNote: false,
};

const defaultErrorMessages: ErrorMessages = {
  cardHolderName: "Card Holder Name is required",
  cardNumber: "Credit Card Number is required",
  cvvNumber: "CVV Number is required",
  billingZipCode: "Zip Code is required",
  billingZipCode1: "Zip Code must be 5 digits",
  expMonth: "Expiry Date is required",
  routingNumber: "Routing Number is required",
  accountingNumber: "Account Number is required",
  accountingName: "Account Name is required",
  email: "Email is required",
  password: "Password is required",
  confirmPassword: "Plaese Enter Confirm Password ",
  firstName: "First Name is required",
  lastName: "Last Name is required",
  phoneNumber: "Number is required",
  organizationName: "Organization Name is required",
  signUpError: "Some error occured, please make sure that email and phone number has not already been registered",
  serviceType: "Please select service type",
  subServiceType: "Please select sub service type",
  unitNumber: "Unit Number is required", // New error message
  unitNumberSize: "Unit Number must be at least 2 characters long",
  dueDate: "Due date is required",
  gracePeriods: "Grace period is required",
  amount: "Amount is required",
  name: "Name is required",
  website: "Website link is required",
  address: "Address is required",
  emergencyPhoneNumber: "Emergency phone number is required",
  startDate: "Start date is required",
  endDate: "End date is required",
  unit: "Unit is required",
  document: "Document is required",
  selectedServiceType: "Please select service type",
  selectedSubServiceType: "Please select sub service type",
  contractorName: "Contractor name is required",
  estimate: "Estimate is required",
  description: "Description is required",
  property: "Property is required",
  unitTypeId: "Unit Type is required",
  reportName: "Report Name is required",
  leaseAgreement: "Lease Agreement is required",
  leaseApplication: "Lease Application is required",
  template: "Template is required",
  city: "City is required",
  state: "State is required",
  zip: "Zip code required",
  moveOutDate: "moveOutDate is required",
  payeeAccountId: "Payee Account is required",
  refrenceNum: "Refrence Number is required",
  streetAddress: "Street Address is required",
  title: "Title is required",
  securityDeposit: "Security Deposit is required",
  rent: "Rent is required",
  attachment: "Please select attachment",
  paymentType: "Payment Type is required",
  payee: "Please select payee",
  tenant: "Please select tenant",
  checkNo: "Check Number is Required",
  transactionId: "Transaction ID is required",
  paymentMethod: "Payment Method is required",
  movingDate: "Moving Date is required",
  movingReason: "Moving Reason is required",
  securityDepositType: "Security Deposit Type is required",
  securityDepositAccount: "Security Deposit Account is required",
  rentDepositAccount: "Rent Deposit Account is required",
  commissionType: "Commission Type is required",
  rentType: "Rent Type is required",
  monthsRent: "Months is required",
  rentLateFee: "Rent Late Fee is required",
  leaseApplicationFee: "Lease Application Fee is required",
  commissionAmount: "Amount is required",
  commissionPercentage: "Precentage is required",
  perUnitAmount: "Amount is required",
  securityDepositamount: "Amount is required",
  rentDueDay: "Rent Due Day is required",
  monthlyPnl: "Day is required",
  ImageType: "Please upload a valid image file (PNG, JPG, JPEG)",
  ImageSize: "File size should not exceed 2MB",
  FileType: "Please upload a valid file (PDF, PNG, JPG)",
  FileSize: "File size should not exceed 5MB",
  dateOfBirth: "Date of birth is required",
  taxIdNumber: "Tax Id Number is required",
  payeeName: "Payee Name is required",
  accountType: "Account Type is required",
  moveOutReason: "Move Out Reason is required",
  laborCost: "Labor Cost is required",
  materialCost: "Material Cost is required",
  fixNote: "Fix Note is required",
};

// Create the ErrorContext
const ErrorContext = createContext<{
  errors: ErrorState;
  errorMessages: ErrorMessages;
  setErrors: React.Dispatch<React.SetStateAction<ErrorState>>;
  setErrorMessages: React.Dispatch<React.SetStateAction<ErrorMessages>>;
  clearErrors: () => void;
} | null>(null);

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error("useError must be used within an ErrorProvider");
  }
  return context;
};

export const ErrorProvider = ({ children }: { children: ReactNode }) => {
  const [errors, setErrors] = useState<ErrorState>(defaultErrors);
  const [errorMessages, setErrorMessages] =
    useState<ErrorMessages>(defaultErrorMessages);

  const clearErrors = () => {
    setErrors(defaultErrors); // Reset to default values
    setErrorMessages(defaultErrorMessages); // Reset to default messages
  };

  return (
    <ErrorContext.Provider
      value={{
        errors,
        errorMessages,
        setErrors,
        setErrorMessages,
        clearErrors,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};
