import { useEffect } from 'react'
import RequestBreadcrumb from '../../common/RequestBreadcrumb'
import TemplateMainHeader from '../Templete/CommonComp/TemplateMainHeader'
import FiltersForm from './FiltersForm'
import { AnalyticsService, FilterEntityEnum } from '@propertelligent/client-api'
import { useParams } from 'react-router'
import DateHelper from '../../../utils/DateHelper'
import apiHelper from '../../../utils/apiHelper'


const ReportFilters = ({
    selectedOption,
    setSelectedOption,
    selectedValues,
    setSelectedValues,
    selectedIds,
    setSelectedIds,
    range,
    setRange,
    relativeDateString,
    setRelativeDateString,
    setReportId = undefined,
    setReportName = undefined
}) => {
    const { id } = useParams()

    useEffect(() => {
        setSelectedOption(FilterEntityEnum.Organization)
        setSelectedValues([])
        setSelectedIds([])
        setRange([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            }
        ])
        setRelativeDateString("")
    }, [])

    const getFilterData = () => {
        let clientDate = DateHelper.getClientDate()
        apiHelper(AnalyticsService.getApiReport, { showNotification: false }, id, clientDate)
            .then((response: any) => {
                setReportName(response?.data?.success?.name)
                setSelectedOption(response?.data?.success?.queryData?.filterEntityTypeId)
                setSelectedIds(response?.data?.success?.queryData?.entityIds)
                setSelectedValues(response?.data?.success?.queryData?.entityNames)
                setRange([{
                    startDate: new Date(response?.data?.success?.queryData?.dateFilter?.startDate),
                    endDate: new Date(response?.data?.success?.queryData?.dateFilter?.endDate),
                    key: 'selection',
                }])
                setRelativeDateString(response?.data?.success?.queryData?.dateFilter?.formatted?.DateFilterTypeId)
            })
            .catch((Err) => {
                console.error(Err);
            });
    }

    useEffect(() => {
        if (id !== undefined) {
            getFilterData()
            setReportId(id)
        }
    }, [id])

    return (
        <div className='Template_View_Main'>
            <div className="application-section">
                <RequestBreadcrumb
                    firstName={"Reports"}
                    link={"/organization/reports"}
                    currentStep={"Report Filters"}
                />

                <TemplateMainHeader
                    headerName={"Report Filters"}
                    searchTerm={undefined}
                    placeholder={undefined}
                    setSearchTerm={undefined}
                    isSearch={false}
                />
                <FiltersForm
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    selectedValues={selectedValues}
                    setSelectedValues={setSelectedValues}
                    range={range}
                    setRange={setRange}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    relativeDateString={relativeDateString}
                    setRelativeDateString={setRelativeDateString}
                />
            </div>
        </div>
    )
}

export default ReportFilters