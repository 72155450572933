import { GetImages } from "../../utils/GetImages";

const EmptyData = ({
  mainText,
  subText = undefined,
  button = undefined,
  ImageSrc = GetImages.NoDataFound,
}) => {
  return (
    <>
      <div className="EmptyScreen">
        <div className="d-flex flex-column align-items-center justify-content-center gap-2 h-100">
          <img src={ImageSrc} />
          <h6 className="sub_heading black">{mainText}</h6>
          <p className="text-center grey_subtext1">{subText}</p>
          {!button ? "" : button}
        </div>
      </div>
    </>
  );
};

export default EmptyData;
