import "../tenants/tenantStyles.css";
import { TenantLeaseTypesEnum } from "@propertelligent/client-api";
import { GetImages } from "../../utils/GetImages";
import { useState } from "react";

const ProgressBar = ({ monthsRemaining, leaseType }) => {
  const [showInfoBox, setShowInfoBox] = useState(false);
  const isMonthToMonth =
    leaseType === TenantLeaseTypesEnum.MonthToMonth ||
    leaseType === "Month-to-Month";

  return (
    <div className="rightSideContainer" id="progress_bar">
      <div className="monthsLeftContainer position-relative">
        <div className="imgContainer">
          <img
            src={monthsRemaining > 5 || isMonthToMonth ? GetImages.BlackUser : GetImages.RedUser}
            alt="User Status"
          />
        </div>
        <p
          style={{
            color:
              isMonthToMonth || monthsRemaining > 5 ? "#4b5563" : "#E02424",
          }}
          className="sub_text ms-1">
          {isMonthToMonth ? "MTM Lease" : `${monthsRemaining} months left`}
        </p>
        <div className="imgContainer ms-1"
          onMouseOver={() => setShowInfoBox(true)}
          onMouseLeave={() => setShowInfoBox(false)}
        >
          <img src={GetImages.InfoIcon} alt="Info" />
        </div>
        {showInfoBox && (
          <div className="toolTipContainer1" style={{ height: "46px", bottom: "34px", right: "0px", width: "220px" }}>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <p className="normal_text fw-500 black">{monthsRemaining} month left out of 12 months</p>
            </div>
          </div>
        )}
      </div>
      <div className="progressBarContainer" style={{ background: isMonthToMonth || monthsRemaining > 5 ? "#def7ec" : "#FDE8E8" }}>
        <div
          className="progressBarCompleted"
          style={{
            width: isMonthToMonth ? "100%" : monthsRemaining > 12 ? "100%" : `${(monthsRemaining * 100) / 12}%`,
            background: isMonthToMonth || monthsRemaining > 5 ? "#057a55" : "#E02424",
          }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
