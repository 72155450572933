import { useState } from 'react';
import DisplayNamewithLeftBorder from '../../common/DisplayNamewithLeftBorder';
import CustomInputs from '../../common/Inputs';
import { Form } from 'react-bootstrap';
import { useError } from '../../../utils/context/ErrorContext';

const MoveOutDetails = ({ moveOutDate, setMoveOutDate, moveOutReason, setMoveOutReason }) => {
    const { errors, setErrors, errorMessages, setErrorMessages, clearErrors } = useError();
    return (
        <div className="ledgerNotesSection leaseDetailsSection">
            <div className="rentLedgerContainer">
                <div className="d-flex justify-content-between">
                    <div className='d-flex align-items-baseline gap-2'>
                        <DisplayNamewithLeftBorder
                            HeaderName="Move Out Details"
                        />
                    </div>
                </div>

                <div className="mt-3">
                    <div className="moveOutDataContainer">
                        <div>
                            <CustomInputs
                                type='date'
                                labelText={"Move Out Date"}
                                value={moveOutDate}
                                onChange={(e) => setMoveOutDate(e.target.value)}
                                isError={errors.moveOutDate}
                                errorMessages={errorMessages.moveOutReason}
                            />
                        </div>

                        <div className='flex-grow-1'>
                            <p className="normal_text black fw-400 pb-2">Move Out Reason</p>
                            <Form.Control
                                as="textarea"
                                placeholder="Add move out reason...."
                                style={{ height: '46px' }}
                                value={moveOutReason}
                                onChange={(e) => setMoveOutReason(e.target.value)}
                                rows={1}
                                className={errors.moveOutReason ? "errorBorder errorOutline normal_text" : "normal_text"}
                            />
                            {errors.moveOutReason &&
                                <div style={{ marginTop: "8px" }}>
                                    <p className="errorMessageStyles">{errorMessages.moveOutReason}</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoveOutDetails