import React from "react";
import { GetImages } from "../../utils/GetImages";

interface Props {
  docImage?: any;
  docNumber?: number;
  avatar: boolean;
  Size: String;
  isProperty?: boolean;
}

export const AvatarWithLoading: React.FC<Props> = React.memo(
  ({ docImage = null, avatar = false, Size, isProperty }) => {
    // if (docNumber === 0 || docNumber === undefined) {
    //   return <img
    //   className={`${Size}`}
    //   style={{ width: 60, height: 60, borderRadius: 4 }}
    //   src="https://via.placeholder.com/60x60"
    // />;
    // }

    // const fetchDoc = async () => {
    //   return await DocumentService.getApiDocumentBase641(docNumber);
    // };

    // const { isLoading, error, data } = useResolveHttpPromise({
    //   httpFetchDataFn: fetchDoc,
    // });

    // if (isLoading) {
    //   return null;
    // }

    // if (error) {
    //   console.error("Error fetching or processing image:", error);
    //   return <img
    //     style={{ width: 60, height: 60, borderRadius: 4 }}
    //     src="https://via.placeholder.com/60x60"
    //   />
    // }
    return (
      <>
        <img
          className={`${Size}`}
          style={{ width: 60, height: 60, borderRadius: 4 }}
          src={
            avatar && docImage
              ? `data:image/*;base64,${docImage}`
              : isProperty ? GetImages.PropertyPlaceholder
                : GetImages.UnitPlaceholder
          }
        />
      </>
    );
  }
);
