import React from 'react'
import { GetImages } from '../../../utils/GetImages'
import DateHelper from '../../../utils/DateHelper'
import { getEnumKeyByValue } from '../../../utils/helper'
import { LeaseApplicationMoveOutReasonTypesEnum } from '@propertelligent/client-api'

const CurrentAddressInfo = ({CurrentAddress }) => {

  return (
    <>
     <div className="current_address">
      <div className="current_address_Fdetail">
        <img src={GetImages.homeIconOrange} alt="Home Icon" />
        <p className="normal_text black">
          {[
            CurrentAddress?.unitNumber,
            CurrentAddress?.streetAddress,
            CurrentAddress?.city,
            CurrentAddress?.state,
            CurrentAddress?.zip,
          ].join(", ")}
        </p>
      </div>
      <div className="current_address_Sdetail">
        {["Move out date", "Reason to move"].map((label, idx) => (
          <div key={idx} className="current_address_Sdetail_box">
            <p className="normal_text fw-bold black">{label}</p>
            <p className="normal_text black">
              {idx === 0 ? DateHelper.convertUTCtoDateTime2(CurrentAddress?.moveOutDate)
                : getEnumKeyByValue(LeaseApplicationMoveOutReasonTypesEnum, CurrentAddress?.moveOutReasonTypeId)}
            </p>
          </div>
        ))}
      </div>
    </div>
    </>
  )
}

export default CurrentAddressInfo