import React from 'react'
import RequestBreadcrumb from '../../../components/common/RequestBreadcrumb'
import { Col, Row } from 'react-bootstrap'
import ServiceIcon from '../../../components/common/serviceTypeImage'
import DateHelper from '../../../utils/DateHelper'
import StatusTag from '../../../components/common/StatusTag'
import { GetImages } from '../../../utils/GetImages'

const ServiceRequestDetailHeader = ({ serviceReqInfo }) => {
    const getStatusColorStyle = (status) => {
        switch (status) {
            case "Completed":
                return { backgroundColor: "#DEF7EC", color: "#057A55", label: 'green' }
            case "Payment Pending":
                return { backgroundColor: "#EDEBFE", color: "#7E3AF2", label: 'purple' }
            case "In Progress":
                return { backgroundColor: "#FDF6B2", color: "#C58C07", label: 'yellow' }
            case "New Request":
                return { backgroundColor: "#E5EDFF", color: "#5850EC", label: 'lightBlue' }
            case "Open":
                return { backgroundColor: "#FDF6B2", color: "#C58C07", label: 'yellow' }
        }
    }
    return (
        <>
            <Row style={{ borderBottom: "1px solid #E5E7EB" }}>
                <RequestBreadcrumb firstName={"Service request"} link={"/servicerequest"} currentStep={serviceReqInfo?.formatted?.ServiceType} />
            </Row>
            <div className="serviceCardConatiner">
                <div className="propertCardLeft">
                    <div className="imgcommonstyle" style={{ borderRadius: "50%", overflow: "hidden" }}>
                        <ServiceIcon serviceType={serviceReqInfo?.formatted?.ServiceType} />
                    </div>

                    <div className="propertyCardDisc">
                        <div className="propertyCardDetails ">
                            <div className="d-flex align-items-center ">
                                <span className="sub_text grey_subtext1">{serviceReqInfo?.formatted?.ServiceSubType}</span>
                            </div>
                            <div>
                                <span className="normal_text fw-bold">{serviceReqInfo?.formatted?.ServiceType}</span>
                            </div>
                        </div>
                        <div className="propertyCardQuantity">
                            <div className="d-flex align-items-center ">
                                <img src={GetImages.calenderIcon} className="iconstyle " style={{ marginRight: "4px" }} />
                                <span className="sub_text  grey_subtext1 ml-1">Raised on : {DateHelper.formatDateonly(serviceReqInfo?.formatted?.RequestDateDateOnly)}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <Row className="w-50">
                    <Col>
                        <Row className="justify-content-end">
                            <div className="propertCardRight">
                                <StatusTag color={getStatusColorStyle(serviceReqInfo?.formatted?.ServiceRequestStatus)?.label}
                                    statusText={serviceReqInfo?.formatted?.ServiceRequestStatus} isLong />

                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ServiceRequestDetailHeader