import React, { useCallback, useEffect } from "react";
import { Form } from "react-bootstrap";
import "./tenantonboard.css";
import { OnboardingForms } from "../../../typings/onboarding";
import CustomInputs from "../../common/Inputs";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";
import DisplayNamewithLeftBorder from "../../common/DisplayNamewithLeftBorder";
import { LeaseApplicationReferenceTypesEnum } from "@propertelligent/client-api";
import { useError } from "../../../utils/context/ErrorContext";


const StepFour: React.FC<any> = ({
  formData,
  setFormData,
  requiredRange,
  References
}: {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<OnboardingForms>>;
  requiredRange:any,
  References:any

   
}) => {
  const {errors,setErrors, errorMessages ,setErrorMessages} = useError()
  const setdata = ()=>{
    if (!References ) return;    
    setFormData((prevFormData) => ({
      ...prevFormData,
      References: 
        prevFormData?.References?.length ? prevFormData?.References
        :
        Array.from({ length: References?.requiredRange }, () => ({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          referenceTypeId: LeaseApplicationReferenceTypesEnum.CoWorker,        
        })),
    }));
  }
  useEffect(() => { setdata()}, [References]);
  const handleContactDetailChange = useCallback((index: number, key: string, value: any) => {
    const updatedContactDetails = [...formData];
    updatedContactDetails[index][key] = value;
    setFormData({ ...formData, References: updatedContactDetails });
    const errorKey = `${key}[${index}]`;
    setErrors((prevErrors) => ({ ...prevErrors, [errorKey]: false }));
    setErrorMessages((prevMessages) => ({ ...prevMessages, [errorKey]: "" }));
  }, [formData]);

  const generateBasicDetails = (index: number) => [
    {
      id: 1,
      name: "firstName",
      labelText: "First Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData[index]?.firstName,
      placeholder: "Enter first name",
      myClassName: "containerTextField",
      isError: errors[`firstName[${index}]`],
      errorMessages: errorMessages?.firstName,
    },
    {
      id: 2,
      name: "lastName",
      labelText: "Last Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData[index]?.lastName,
      placeholder: "Enter last name",
      myClassName: "containerTextField",
      isError: errors[`lastName[${index}]`],
      errorMessages: errorMessages?.lastName,
    },
    {
      id: 3,
      name: "email",
      labelText: "Email",
      type: FORM_CONTROLFIELD_TYPES.EMAIL,
      value: formData[index]?.email,
      placeholder: "Enter email",
      myClassName: "containerTextField",
      isError: errors[`email[${index}]`],
      errorMessages: errorMessages?.email,
    },
    {
      id: 4,
      name: "phoneNumber",
      labelText: "Phone",
      type: FORM_CONTROLFIELD_TYPES.TELEPHONE,
      value: formData[index]?.phoneNumber,
      placeholder: "Enter phone no",
      myClassName: "containerTextField",
      isError: errors[`phoneNumber[${index}]`],
      errorMessages: errorMessages?.phoneNumber,
      maxLength: 10
    },
  ];

  const Radiodata = (index) => [
    {
      id: LeaseApplicationReferenceTypesEnum.CoWorker,
      label: "Co-Worker",
      value: LeaseApplicationReferenceTypesEnum.CoWorker,
      checked: formData[index]?.referenceTypeId === LeaseApplicationReferenceTypesEnum.CoWorker,
    },
     {
      id: LeaseApplicationReferenceTypesEnum.Landlord,
      label: "Current or Previous Landlord(s)",
      value: LeaseApplicationReferenceTypesEnum.Landlord,
      checked: formData[index]?.referenceTypeId === LeaseApplicationReferenceTypesEnum.Landlord,
    }
  ]

  return (
    <>
      <div className="onboarding-stepper">
        <div className="onboarding-steppercard">
          <p className="main_heading pb-2">References</p>
          {formData?.map((items, index) => (
            <div key={index} className="flexColumn gap-3 py-3">
              <DisplayNamewithLeftBorder HeaderName={"Reference Type"} />
              <div className="flexRow gap-2">
                {Radiodata(index)?.map((item, ind) => (
                  <div key={ind}>
                    <Form.Check
                      checked={item.checked}
                      label={item.label}
                      name={`referenceTypeId-${index}`}
                      type="radio"
                      id={`info-radio-employer-${index}-${item.value}`}
                      value={item.value}
                      onChange={(e) => handleContactDetailChange(index, "referenceTypeId", item.id)}
                    />
                  </div>
                ))}
              </div>
              <div key={index} className={`social-worker`}>
                {generateBasicDetails(index)?.map((item, i) => (
                  <div key={i}>
                    <CustomInputs
                      name={`${item.name}-${index}`}
                      type={item.type}
                      value={item.value}
                      labelText={item.labelText}
                      isFormControl={true}
                      onChange={(e) => handleContactDetailChange(index, item.name, e.target.value)}
                      placeholder={item.placeholder}
                      myClassName={item.myClassName}
                      isError={item.isError}
                      errorMessages={item.errorMessages}
                      maxLength={item.maxLength}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default StepFour;




