import Image1 from "../../../assets/Rectangle1.png"
import Image2 from "../../../assets/Rectangle2.png"
import Image3 from "../../../assets/Rectangle3.png"
import Image4 from "../../../assets/Rectangle4.png"
import Image5 from "../../../assets/Rectangle5.png"

const UploadedImageView = () => {
    const data = [
        {
            url: Image1
        },
        {
            url: Image2
        },
        {
            url: Image3
        },
        {
            url: Image4
        },
        {
            url: Image5
        }
    ]
    return (
        <div className='d-flex mt-4 flex-wrap' style={{ gap: "10px" }}>
            {
                data?.map((item, i) => {
                    return (
                        <img src={item.url} alt='' style={{ width: "95px", height: "95px" }} />
                    )

                })
            }
        </div>
    )
}

export default UploadedImageView