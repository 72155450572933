import React from 'react'

const AnalyticsCard = ({ icon, data, title }) => {
    return (
        <div className="overviewfirstrightsecond">
            <div className="firstbox">
                <div className="CommonRoundBackground BackgroundlightOrange">
                    <img src={icon} className="Icon20" />
                </div>

                <div className="commonbox">
                    <p className="heading black">
                        {data}
                    </p>
                    <p className="sub_text grey_subtext1">{title}</p>
                </div>
            </div>
        </div>
    )
}

export default AnalyticsCard