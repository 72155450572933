import { useCallback, useEffect, useState } from 'react'
import AddressAutocomplete from '../../firstTimeUserExperience/commonCom/AddressAutocomplete'
import { FORM_CONTROLFIELD_TYPES } from '../../../constants/constants';
import { useError } from '../../../utils/context/ErrorContext';
import CustomInputs from '../../common/Inputs';
import { LeaseApplicationCurrentAddressVM, LeaseApplicationMoveOutReasonTypesEnum, PropertyVM } from '@propertelligent/client-api';
import { OnboardingForms } from '../../../typings/onboarding';
import NewSelectAuto from '../../tenants/NewSelectAuto';
import { convertENUMtoJSONArray } from '../../../utils/helper';
import DateHelper from '../../../utils/DateHelper';

const CurrentAddress = ({ formData, setFormData, CurrentAddress }) => {
  const { errors, setErrors, errorMessages, setErrorMessages } = useError()
  const setdata = ()=>{
     if (!CurrentAddress) return;
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      CurrentAddress: prevFormData?.CurrentAddress
      ? prevFormData?.CurrentAddress
      : {
          unitNumber: "",
          streetAddress: "",
          city: "",
          state: "",
          zip: "",
          moveOutDate: "",
          moveOutReasonTypeId: LeaseApplicationMoveOutReasonTypesEnum.BetterNeighborhood,
        } as LeaseApplicationCurrentAddressVM, 
    }));
  }
  useEffect(() => {
     setdata()  
  }, [CurrentAddress]);
  const [propertyValue, setPropertyvalue] = useState<PropertyVM>()
  useEffect(() => {
    if (propertyValue?.address) {
      const updatedAddress = {
        streetAddress: propertyValue?.address?.streetAddress || "",
        city: propertyValue?.address?.city || "",
        state: propertyValue?.address?.state || "",
        zip: propertyValue?.address?.zip || "",
      };

      setFormData((prevData: OnboardingForms) => ({
        ...prevData,
        CurrentAddress: {
          ...prevData.CurrentAddress,
          ...updatedAddress,
        },
      }));

      Object.entries(updatedAddress).forEach(([subKey, value]) => {
        if (value) {
          const errorKey = `${subKey}`;
        
          setErrors((prevErrors) => ({ ...prevErrors, [errorKey]: false }));
          setErrorMessages((prevMessages) => ({ ...prevMessages, [errorKey]: "" }));

        }
      });
    }
  }, [propertyValue, setFormData]);

  const handleChange = useCallback((key: string, value: string,) => {
    setFormData((prevData: OnboardingForms) => ({
      ...prevData,
      CurrentAddress: {
        ...prevData?.CurrentAddress,
        [key]: value,
      },
    }));
    const errorKey = `${key}`;
    setErrors((prevErrors) => ({ ...prevErrors, [errorKey]: false }));
    setErrorMessages((prevMessages) => ({ ...prevMessages, [errorKey]: "" }));
    // handleRemoveError(key, OnboardingStepsEnums.CurrentAddress, {key: key, });

  }, [setFormData, propertyValue]);

  const AddressDetails = [
    {
      id: 1,
      name: "unitNumber",
      labelText: "Unit Number",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.unitNumber,
      isFormControl: true,
      onChange: (e) => handleChange("unitNumber", e.target.value),
      placeholder: "Enter unit number",
      myClassName: "containerTextField",
      isError: errors?.unitNumber,
      errorMessages: errorMessages?.unitNumber,
    },
    {
      id: 2,
      name: "streetAddress",
      labelText: "Street Address",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.streetAddress,
      isFormControl: true,
      onChange: (e) => handleChange("streetAddress", e.target.value,),
      placeholder: "Enter street address",
      myClassName: "containerTextField",
      isError: errors?.streetAddress,
      errorMessages: errorMessages?.streetAddress,
    },
    {
      id: 3,
      name: "city",
      labelText: "City",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.city,
      isFormControl: true,
      onChange: (e) => handleChange("city", e.target.value,),
      placeholder: "Enter city",
      myClassName: "containerTextField",
      isError: errors?.city,
      errorMessages: errorMessages?.city,
    },
    {
      id: 4,
      name: "state",
      labelText: "State",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.state,
      isFormControl: true,
      onChange: (e) => handleChange("state", e.target.value,),
      placeholder: "Enter state",
      myClassName: "containerTextField",
      isError: errors?.state,
      errorMessages: errorMessages?.state,
    },
    {
      id: 5,
      name: "zip",
      labelText: "ZIP Code",
      type: FORM_CONTROLFIELD_TYPES.TELEPHONE,
      value: formData?.zip,
      isFormControl: true,
      onChange: (e) => handleChange("zip", e.target.value,),
      placeholder: "Enter ZIP code",
      myClassName: "containerTextField",
      isError: errors?.zip,
      errorMessages: errorMessages?.billingZipCode,
    },
    {
      id: 6,
      name: "moveOutDate",
      labelText: "Moving Date",
      type: FORM_CONTROLFIELD_TYPES.DATE,
      value: DateHelper.convertUTCtoDateTime2(formData?.moveOutDate),
      isFormControl: true,
      onChange: (e) => handleChange("moveOutDate", e.target.value),
      placeholder: "Select moving date",
      myClassName: "containerTextField",
      isError: errors?.moveOutDate,
      errorMessages: errorMessages?.movingDate,
    },
  ];

  return (
    <>
      <div className="onboarding-stepper">
        <div className="onboarding-steppercard">
          <p className="main_heading py-3">Current Address</p>
          <div className="onboardingstepper-form ">
            <div className=''>
              <AddressAutocomplete
                propertyValue={propertyValue}
                setPropertyvalue={setPropertyvalue}
                required={true}
                showSelectedValue={false}
              />
            </div>
            <div>
              <div className="w-100 basic-form py-3" >
                {AddressDetails?.map((item) => (
                  <div key={item.id}>
                    <CustomInputs
                      name={item.name}
                      type={item.type}
                      value={item.value}
                      labelText={item.labelText}
                      isFormControl={item.isFormControl}
                      onChange={item.onChange}
                      placeholder={item.placeholder}
                      myClassName={item.myClassName}
                      isError={item.isError}
                      errorMessages={item.errorMessages}
                    // width={"322px"}
                    />
                  </div>
                ))}
              </div>
              <NewSelectAuto
                disabled={undefined}
                setDisabled={undefined}
                selected={formData?.moveOutReasonTypeId}
                setSelected={(value) => handleChange("moveOutReasonTypeId", value)}
                setSelectedOption={undefined}
                selectedOption={undefined}
                data={convertENUMtoJSONArray(LeaseApplicationMoveOutReasonTypesEnum)}
                title={"Wait till the data fetches"}
                type="Reason for Moving"
                error={false}
                setError={() => { }}
                fromContractor={true}
                label={"Reason for Moving"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CurrentAddress