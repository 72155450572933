import RequestBreadcrumb from '../../common/RequestBreadcrumb'
import './payeeAccount.css'
import AddTraspButton from '../../firstTimeUserExperience/commonCom/AddTraspButton'
import { GetImages } from '../../../utils/GetImages'
import PayeeAccountCard from '../../common/PayeeAccountCard'
import { useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import AddEditPayeeAccount from './AddEditPayeeAccount'
import CommonConfirmation from '../../common/CommonConfirmation'
import { infiniteScrollHook } from '../../../hooks/infiniteScrollHook'
import { PaymentService } from '@propertelligent/client-api'
import { UserLoginData } from '../../common/charts/Enums'
import apiHelper from '../../../utils/apiHelper'
import BasicDetailsCard from '../BasicDetails/common/BasicDetailsCard'

const PayeeAccounts = () => {
    const [showModal, setShowModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [id, setId] = useState()
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [accountName, setAccountName] = useState("")
    const orgId = localStorage.getItem(UserLoginData.organization_id)

    const { rows, loading, hasMore, fetchData, currentPage } = infiniteScrollHook({
        httpFetchDataFn: PaymentService.getApiPayeeAccountOrganizationlist,
        parentEntityId: orgId,
        FullyQualifiedFilters: undefined,
        FullyQualifiedSorts: undefined
    });

    const handleModalShow = () => {
        setShowModal(true)
        setIsEdit(false)
    }

    const handleClose = () => setShowConfirmation(false);

    const handleDelete = () => {
        apiHelper(PaymentService.deleteApiPayeeAccount,
            {
                showNotification: true,
                successMessage: "Payee Account Deleted Successfully",
                failureMessage: "Unable to Delete Payee Account",
            }, id)
            .then((delRes) => {
                if (delRes?.data?.success) {
                    setShowConfirmation(false);
                    fetchData(1)
                }
            })
            .catch((delErr) => { });
    };

    return (
        <div className="basic_details payee_accounts">
            <div className="breadcrumb">
                <RequestBreadcrumb
                    firstName={"Dashboard"}
                    link={"/"}
                    lastName={"My Organization"}
                    currentStep={"Payee Accounts"}
                />
            </div>
            <div className="organization_card_container">
                <BasicDetailsCard />
            </div>
            <div className='payee_accounts_fixed'>
                <div>
                    <h6 className='heading black text-uppercase'>Payee Accounts</h6>
                </div>
                <div>
                    <AddTraspButton
                        bname="Add Payee"
                        border={true}
                        iconPosition="right"
                        imageValue={GetImages.BlueAdd}
                        onClick={handleModalShow}
                    />
                </div>
            </div>

            <PayeeAccountCard
                rows={rows}
                loading={loading}
                hasMore={hasMore}
                currentPage={currentPage}
                fetchData={fetchData}
                setShowModal={setShowModal}
                setIsEdit={setIsEdit}
                setId={setId}
                setShowConfirmation={setShowConfirmation}
                setAccountName={setAccountName}
            />

            <Offcanvas
                className="editTanentOffcanvas"
                show={showModal}
                onHide={() => setShowModal(false)}
                placement={"end"}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{isEdit ? 'Edit Payee Account' : 'Add Payee Account'}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <AddEditPayeeAccount
                        setShowModal={setShowModal}
                        isEdit={isEdit}
                        id={id}
                        fetchData={fetchData}
                    />
                </Offcanvas.Body>
            </Offcanvas>

            {showConfirmation && (
                <CommonConfirmation
                    show={true}
                    onConfirm={handleDelete}
                    onClose={handleClose}
                    heading="Delete Payee Account"
                    subHeading={undefined}
                    Element={<p className='sub_heading fw-normal'>Are you sure you want to delete <span className='fw-bold'>{accountName}</span> account?</p>}
                    confirmLabel="Yes"
                    cancelLabel="No"
                    buttonCenter={true}
                    border={true}
                />
            )}
        </div>
    )
}

export default PayeeAccounts