import { useState, useEffect } from "react";
import UtilHelper from "../utils/UtilHelper";

type Props = {
  httpExpensebycategoryFn?: any;
  httpCashflowFn?: any;
  customMonthField: string;
  parentEntityId: number;
  fullyQualifiedFilters: any;
};

export const useAnalyticsHook = ({
  httpExpensebycategoryFn,
  httpCashflowFn,
  customMonthField,
  parentEntityId,
  fullyQualifiedFilters,
}: Props) => {
  const [cashFlow, setCashFlow] = useState([]);
  const [expensebycategoryArr, setExpensebycategoryArr] = useState([]);
  const [filter, setFilters] = useState();
  const [loading, setLoading] = useState(true);


  const fetchAndFormatExpensebycategory = () => {
    if (typeof httpExpensebycategoryFn === "function" && parentEntityId) {
      setLoading(true);
      httpExpensebycategoryFn(
        parentEntityId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        fullyQualifiedFilters?.fin
      )
        .then((response: any) => {
          if (response?.success?.length > 0) {
            let formatedExpCatArr: any[] = [];

            for (const expCat of response.success) {
              const expenseType = expCat.expenseType;
              const expenseName = expCat.formatted?.ExpenseType;
              const expenseAmount = Number(expCat.amount);

              let foundIndex = formatedExpCatArr.findIndex(
                (t) => t.name === expenseName
              );

              if (foundIndex > -1) {
                formatedExpCatArr[foundIndex].value += expenseAmount;
              } else {
                const assignedColor = UtilHelper.ExpenseTypeColors(expenseType);
                // ||  "#" + (((1 << 24) * Math.random()) | 0).toString(16);

                formatedExpCatArr.push({
                  name: expenseName,
                  value: expenseAmount,
                  color: assignedColor,
                });
              }
            }

            setExpensebycategoryArr(formatedExpCatArr);
          } else {
            setExpensebycategoryArr([]);
          }
          setLoading(false);
        })
        .catch((error: any) => {
          console.error("Fetch error:", error);
          setLoading(false);
        });
    }
  };

  const fetchAndFormatCashflow = () => {
    if (typeof httpCashflowFn === "function" && parentEntityId) {
      setLoading(true);

      httpCashflowFn(
        parentEntityId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        fullyQualifiedFilters?.rev
      )
        .then((response: any) => {
          const upd = response?.success?.map(
            (c: { expense: any; income: number; month: any; year: any }) => ({
              expense: c?.expense,
              income: c?.income + 500,
              [customMonthField]: `${c?.month}/${c?.year}`,
            })
          );

          setCashFlow(upd);
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchAndFormatCashflow();
  }, [fullyQualifiedFilters?.rev]);

  useEffect(() => {
    fetchAndFormatExpensebycategory();
  }, [fullyQualifiedFilters?.fin]);

  // useEffect(() => {
  //   fetchAndFormatCashflow();
  //   // fetchAndFormatExpensebycategory();
  // }, []);

  return {
    loading,
    cashFlow,
    expensebycategoryArr,
    fetchAndFormatExpensebycategory,
  };
};
