import { useEffect, useState } from "react";
import { AnalyticsService } from "@propertelligent/client-api";
import { UserLoginData } from "../components/common/charts/Enums";
import { GetImages } from "../utils/GetImages";
import apiHelper from "../utils/apiHelper";

const TopSection = ({ loadingStates, setLoadingStates }) => {
  const organization_id = Number(localStorage.getItem(UserLoginData.organization_id));
  const [analyticsData, setAnalyticsData] = useState<any>({});

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = () => {
    apiHelper(AnalyticsService.getApiAnalyticsDashboard, { showNotification: false }, organization_id)
      .then((res) => {
        setAnalyticsData(res?.data?.success);
        setLoadingStates((loadingStates) => ({
          ...loadingStates,
          topSectionLoading: false,
        }));
      })
      .catch((err) => { });
  };

  return (
    <div className="topSectionContainer d-flex flex-wrap">
      <div className="topSectionComponent">
        <div className="upperComponent d-flex align-items-center">
          <img width={50} height={50} src={GetImages.homeAvatar} />
          <div className="rightSideTextComponent">
            <p className="heading ">
              ${" "}
              {analyticsData?.formatted?.PortfolioValue
                ? analyticsData?.formatted?.PortfolioValue
                : "0"}{" "}
            </p>
            <p className="sub_text grey_subtext1">Total Assets Value</p>
          </div>
        </div>
        <div className="lowerComponent d-flex align-items-center">
          <div className="statContainer">
            <p className="normal_text black fw-500">
              {analyticsData?.numberOfProperties
                ? analyticsData?.numberOfProperties
                : "0"}
            </p>
            <p className="sub_text grey_subtext1">Properties</p>
          </div>
          <div className="statContainer">
            <p className="normal_text black fw-500">
              {analyticsData?.numberOfUnits ? analyticsData?.numberOfUnits : "0"}
            </p>
            <p className="sub_text grey_subtext1">Total Units</p>
          </div>
          <div className="statContainer">
            <p className="normal_text black fw-500">
              {analyticsData?.numberOfVacantUnits
                ? analyticsData?.numberOfVacantUnits
                : "0"}
            </p>
            <p className="sub_text grey_subtext1">Vacant Units</p>
          </div>
        </div>
      </div>
      <div className="topSectionComponent">
        <div className="upperComponent d-flex align-items-center">
          <img width={50} height={50} src={GetImages.totalTenantAvatar} />
          <div className="rightSideTextComponent">
            <p className="heading ">
              {analyticsData?.numberOfTenants
                ? analyticsData?.numberOfTenants
                : "0"}
            </p>
            <p className="sub_text grey_subtext1">Total Tenants</p>
          </div>
        </div>
        <div className="lowerComponent d-flex align-items-center">
          <div className="statContainer">
            <p className="normal_text black fw-500">
              {analyticsData?.tenantsLeaseExpiring
                ? analyticsData?.tenantsLeaseExpiring
                : "0"}
            </p>
            <p className="sub_text grey_subtext1">Lease Expiring</p>
          </div>
          <div className="statContainer">
            <p className="normal_text black fw-500">
              {analyticsData?.tenantsMovingOut
                ? analyticsData?.tenantsMovingOut
                : "0"}
            </p>
            <p className="sub_text grey_subtext1">Moving Out</p>
          </div>
          <div className="statContainer">
            <p className="normal_text black fw-500">
              {analyticsData?.tenantsMovingIn
                ? analyticsData?.tenantsMovingIn
                : "0"}
            </p>
            <p className="sub_text grey_subtext1">Prospect</p>
          </div>
        </div>
      </div>
      <div className="topSectionComponent">
        <div className="upperComponent d-flex align-items-center">
          <img width={50} height={50} src={GetImages.serviceRequestAvatar} />
          <div className="rightSideTextComponent">
            <p className="heading black">
              {analyticsData?.openServiceRequests
                ? analyticsData?.openServiceRequests
                : "0"}{" "}
            </p>
            <p className="sub_text grey_subtext1">Open Service Requests</p>
          </div>
        </div>
        <div className="gap-5 lowerComponent d-flex align-items-center justify-content-start">
          <div style={{ width: "40%" }} className="statContainer">
            <p className="normal_text black fw-500">
              ${" "}
              {analyticsData?.formatted?.ServiceRequestsEstimatedExpenses
                ? analyticsData?.formatted?.ServiceRequestsEstimatedExpenses?.toLocaleString("en-US")
                : "0"}
            </p>
            <p className="sub_text grey_subtext1">Estimated Expense</p>
          </div>
          <div style={{ width: "40%" }} className="statContainer">
            <p className="normal_text black fw-500">
              ${" "}
              {analyticsData?.formatted?.ServiceRequestsPaymentPending
                ? analyticsData?.formatted?.ServiceRequestsPaymentPending?.toLocaleString(
                  "en-US"
                )
                : "0"}
            </p>
            <p className="sub_text grey_subtext1">Payment Pending</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
