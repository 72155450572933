import { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { GetImages } from '../../../utils/GetImages'
import apiHelper from '../../../utils/apiHelper';
import { DateFilterTypesEnum, OrganizationService, PaymentService } from '@propertelligent/client-api';
import DateHelper from '../../../utils/DateHelper';
import { PaymentContext } from '../../../utils/context/PaymentContext';
import EmptyData from '../../common/EmptyData';
import { UserLoginData } from '../../common/charts/Enums';
import SimpleSpinner from '../../loader';

const LastTransaction = ({
  onClose,
  heading,
  show,
  duePayment,
  subheading = undefined,
  payeeAccountId
}) => {
  const [rev_monthValue, setRev_MonthValue] = useState(4);
  const [monthFilter, setMonthFilter] = useState(null)
  const { payeeAccountsArr, setPayeeAccountsArr, selectedItems, setSelectedItems, selectedOption, setSelectedOption } = useContext(PaymentContext);
  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
  const [lastTransaction, setLastTransaction] = useState([])
  const [loading, setLoading] = useState(false)

  const month = [
    { value: 4, label: "All" },
    { value: 30, label: "30 Days" },
    { value: 3, label: "3 months" },
    { value: 6, label: "6 months" }
  ]

  const setMonthFilters = (month: any) => {
    setRev_MonthValue(month);

    const filters =
      month == 30
        ? DateFilterTypesEnum.LastMonth
        : month == 3
          ? DateFilterTypesEnum.Last3Months
          : month == 6
            ? DateFilterTypesEnum.Last6Months
            : null;
    setMonthFilter(filters);
  };

  const payeeList = () => {
    setLoading(true)
    let clientDate = DateHelper.getClientDate();
    if (monthFilter !== null) {
      apiHelper(
        PaymentService.getApiPaymentDueAccountPayeelist,
        { showNotification: false },
        payeeAccountId,
        undefined, undefined, undefined, undefined, undefined, undefined, undefined,
        monthFilter, clientDate)
        .then((res) => {
          if (res?.data?.success) {
            setPayeeAccountsArr(res?.data?.success);
            setLoading(false)
          }
        })
        .catch((ptErr) => { })
    } else {
      apiHelper(PaymentService.getApiPaymentDueAccountPayeelist, { showNotification: false }, payeeAccountId)
        .then((res) => {
          if (res?.data?.success) {
            setPayeeAccountsArr(res?.data?.success);
            setLoading(false)
          }
        })
        .catch((ptErr) => { });

    }
  }


  const lastFiveTransaction = () => {
    setLoading(true)
    const fullyQualifiedFilters = `PayeeAccountId = ${payeeAccountId}`
    const fulllyQualifiedSort = "PaymentDate desc"
    apiHelper(OrganizationService.getApiOrganizationTransactionOrganizationlist, { showNotification: false },
      orgId,
      5, 1,
      undefined, undefined, undefined,
      fullyQualifiedFilters,
      fulllyQualifiedSort
    )
      .then((res) => {
        if (res?.data?.success) {
          setLastTransaction(res?.data?.success)
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    payeeList()

  }, [payeeAccountId, monthFilter])

  useEffect(() => {
    if (!duePayment) {
      lastFiveTransaction()
    }
  }, [payeeAccountId])

  return (
    <>
      <div className="p-3">
        <Modal
          className="commonConfirmationModal d-flex align-items-center justify-content-center"
          show={show}
          onHide={onClose}
        >
          <div className="p-3">
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <p className="heading black">{heading}</p>
                <p className="sub_text black">{subheading}</p>
                <img
                  className="clickable"
                  onClick={() => {
                    onClose()
                    setLastTransaction([])
                  }}
                  src={GetImages.closeIconBlack}
                />
              </div>
            </div>

            <div className='my-3 d-flex flex-column gap-2'>
              {
                duePayment &&
                <>
                  <div className="monthhead">
                    {month.map((button) => {
                      return (
                        <button
                          key={button.value}
                          onClick={() => setMonthFilters(button.value)}
                          className={rev_monthValue === button.value ? "monthSelectedButton sub_text fw-500 blue" : "monthButton sub_text fw-500 grey_subtext1"}
                        >
                          {button.label}
                        </button>
                      )
                    })}
                  </div>
                </>
              }
              <div className='my-2 borderWithRadius duePaymentCard'>
                {
                  loading ? (
                    <div className='loading-card'>
                      <SimpleSpinner myClassName='Icon40' />
                    </div>
                  ) : (
                    payeeAccountsArr && payeeAccountsArr.length > 0 && duePayment ? (
                      payeeAccountsArr.map((item) => (
                        <div className="Last_transaction gap-5" key={item.id || item.payerPayeeName}> {/* Use a unique key */}
                          <div className="d-flex flex-column">
                            <p className="normal_text fw-500">
                              {item.payerPayeeName}
                            </p>
                            <p className="sub_text grey_subtext1">
                              Due on: {DateHelper.formatDateonly(item?.dueDate)}
                            </p>
                          </div>
                          <div className='d-flex gap-3'>
                            <p className='normal_text fw-500'>${item.amount}</p>
                          </div>
                        </div>
                      ))
                    ) : lastTransaction.length > 0 ? (
                      lastTransaction.map((item) => (
                        <div className="Last_transaction gap-5" key={item.id || item.payeeAccountName}>
                          <div className="d-flex flex-column">
                            <p className="normal_text fw-500">
                              {item.payeeAccountName}
                            </p>
                            <p className="sub_text grey_subtext1">
                              Paid on: {DateHelper.formatDateonly(item?.paymentDate)}
                            </p>
                          </div>
                          <div className='d-flex gap-3'>
                            <p className='normal_text fw-500'>${item.amount}</p>
                          </div>
                        </div>
                      ))
                    ) : (lastTransaction.length == 0 || payeeAccountsArr.length == 0) && (
                      <EmptyData mainText="No Due Payments" ImageSrc={GetImages.DuePayments} button={undefined} />
                    )
                  )
                }
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default LastTransaction