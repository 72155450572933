import React, { useCallback, useEffect, } from "react";
import "./tenantonboard.css";
import { OnboardingForms, } from "../../../typings/onboarding";
import CustomInputs from "../../common/Inputs";
import { Form } from "react-bootstrap";
import { QuestionTypeEnum } from "@propertelligent/client-api";
import { useError } from "../../../utils/context/ErrorContext";
import RadioButton from "../../common/RadioButton";
import { CustomInputTextArea } from "../../common/formControls";

const StepFive: React.FC<any> = ({
  formData,
  setFormData,
  Questionnaires,
}: {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<OnboardingForms>>;
  Questionnaires:any
}) => {
  const { errors, setErrors, errorMessages, setErrorMessages } = useError()
  const setdata = ()=>{
    if (!Questionnaires) return;
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      Questionnaires: prevFormData?.Questionnaires?.length
        ? prevFormData?.Questionnaires
        : Questionnaires?.map(question => ({
            questionId: question?.id,
            questionTypeId: question?.questionTypeId,
            answer: "provide answer",
            followUpAnswer: "Provide details",
            selectedOptionIds: [1],
          })),
    }));
  }
  useEffect(() => {
     setdata()
   
   
  }, [Questionnaires]);

  const handleOnClickElement = (id, optionIndex, questionTypeId, isChecked, value) => {  
    setFormData((prevState) => {
      const updatedQuestionnaire = prevState?.Questionnaires?.map((question, index) => {
        if (question?.questionId === id) {
          const updatedSelectedOptions =
            questionTypeId === QuestionTypeEnum.SingleChoice
              ? [Number(value)] : isChecked
              ? [...question?.selectedOptionIds, Number(value)] 
              : question?.selectedOptionIds?.filter((id) => id !== Number(value)); 
          return {
            ...question,
            selectedOptionIds: updatedSelectedOptions,
          }}
        return question;
      }); 
      return {
        ...prevState,
        Questionnaires: updatedQuestionnaire,
      };
    });
  };
  
  const handleChangeFollowup = (questionIndex, event) => {
    const { value ,name } = event.target; 
    setFormData((prevState) => {
      const updatedQuestionnaire = prevState?.Questionnaires?.map((question, index) => {
        if (index === questionIndex) {
          return {
            ...question,
            [name]: value,
          };
        }
        return question;
      });
  
      return {
        ...prevState,
        Questionnaires: updatedQuestionnaire,
      };
    });
    const errorKey = `${name}[${questionIndex}]`;
    setErrors((prevErrors) => ({ ...prevErrors, [errorKey]: false }));
    setErrorMessages((prevMessages) => ({ ...prevMessages, [errorKey]: "" }));
  };
  
  return (
    <>
      <div className="onboarding-stepper">
        <div className="onboarding-steppercard">
          <p className="main_heading">Questionnaire</p>
          <div className="onboardingstepper-form">
            <div className="question-list">
              {Array.isArray(Questionnaires) &&
              Questionnaires?.map((item, index) => 
                {                
                  return(
                <div key={index} className="questionaire_question-row">
                  <p className="normal_text black fw-500">{item?.questionText}</p>
                  {(item?.questionTypeId === QuestionTypeEnum.SingleChoice || item?.questionTypeId === QuestionTypeEnum.MultipleChoice) && (
                    <div className="question-option">
                      {item?.choices?.map((option, optionIndex) => (
                         <RadioButton
                         type={item?.questionTypeId === QuestionTypeEnum.SingleChoice ? "radio" : "checkbox"}
                         id={`info-${item?.questionTypeId === QuestionTypeEnum.SingleChoice ? 'radio' : 'checkbox'}-${index}-${optionIndex}`}
                         label={option?.optionText}
                         value={Number(option?.id)}
                         name={`question-${index}`}
                        //  checked={undefined}
                        //  checked={formData?.Questionnaires?.[index]?.selectedOptionIds
                        //   ? formData?.Questionnaires[index]?.selectedOptionIds?.includes(option.id)
                        //   : false}
                          checked={ formData?.length ? formData[index]?.selectedOptionIds?.includes(option.id) : false}
                          
                         onChange={(e) => handleOnClickElement(item?.id, optionIndex, item?.questionTypeId, e.target.checked, e.target.value)}
                       />                    
                      ))}
                    </div>)}
                  {(item?.questionTypeId === QuestionTypeEnum.SingleChoice 
                 && ( formData?.length ? formData[index]?.selectedOptionIds?.includes(item?.followUpQuestionChoiceId) :"")
                ) && (
                    <div>
                      <CustomInputs
                        labelText={"Provide additional details"}
                         name="followUpAnswer"
                        type="text"
                        placeholder="Additional Details"
                        width={"100%"}
                         value={formData?.length ? formData[index]?.followUpAnswer : "" }
                        onChange={(e) => handleChangeFollowup(index, e)}
                        isError={ errors[`followUpAnswer[${index}]`]}
                        errorMessages="Answer is required "
                      />
                    </div>
                  )} 

                  {item?.questionTypeId === QuestionTypeEnum.Paragraph && (
                    <div>
                       <CustomInputTextArea
                          name="answer"                        
                          placeholder="Enter a detailed response"
                          required
                          isFormControl
                          value={  formData?.length  ? formData[index]?.answer : "" } 
                          onChange={(e) => handleChangeFollowup(index, e)}
                          isError={ errors[`answer[${index}]`]}
                          errorMessages=" Answer is required "
                          myClassName="normal_text grey"
                          rows={4}
                        />                    
                    </div>
                  )}
                  {item?.questionTypeId === QuestionTypeEnum.ShortAnswer && (
                    <div>
                      <CustomInputTextArea
                          name="answer"                        
                          placeholder="Enter a brief response"
                          required
                          isFormControl
                          value={  formData?.length  ? formData[index]?.answer : "" } 
                          onChange={(e) => handleChangeFollowup(index, e)}
                          isError={ errors[`answer[${index}]`]}
                          errorMessages="Short Answer required "
                          myClassName="normal_text black"
                          rows={5}
                        />
                       </div>)}
                </div>
              )}
              
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepFive;

