import PropTypes from "prop-types";

const Stepper = ({ currentStepIndex, steps, totalSteps }) => {
  // Generate stepsData array
  const stepsData = Array.from(
    { length: totalSteps},
    (_, index) => index + 1
  );
  if (!steps[currentStepIndex]) {
    return null; 
  }
 
  return (
    <section className="tenantOnboardStepper">
      <div className="titleStep">
        <p className="FW400_LH16_small_text">{"On Boarding"}</p>
        <p className="FW400_LH16_small_text">
          Step {currentStepIndex+1} / {totalSteps }
        </p>
      </div>
      <div className="progressbaradd">
        {stepsData.map((step) => (
          
          <div
            key={step}
            className="progressBarElementsadd"
            style={{
              background: step <= currentStepIndex+1 ? "#1a56db" : "#E1EFFE",
            }}
          ></div>
        ))}
      </div>

    </section>
  );
};

Stepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default Stepper;
