import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { GetImages } from '../../utils/GetImages';
import SelectType from './common/SelectType';
import SelectLeaseAndUploader from '../common/SelectLeaseAndUploader';
import AddButtonWithArrow from '../firstTimeUserExperience/commonCom/AddButtonWithArrow';
import { useError } from '../../utils/context/ErrorContext';
import { Form } from 'react-bootstrap';
import apiHelper from '../../utils/apiHelper';
import { DocumentEntityTypesEnum, DocumentService, DocumentTypesEnum, LeaseAgreementService, TenantLeaseTypesEnum } from '@propertelligent/client-api';

const RenewalLease = ({ unitId }) => {
    const [selectLease, setSelectLease] = useState<string>("0");
    const [selectedOption, setSelectedOption] = useState(TenantLeaseTypesEnum.FixedTermLease)
    const [leaseDetails, setLeaseDetails] = useState<any>({
        startDate: "",
        endDate: "",
        amount: "",
        securityDeposit: ""
    })
    // const [fileName, setFileName] = useState<any>("");
    // const [uploadDocument, setUploadDocument] = useState<any>();
    const [agreementFileName, setAgreementFileName] = useState<any>("");
    const [uploadAgreement, setUploadAgreement] = useState<any>();
    const [uploadAgreementError, setUploadAgreementError] = useState<any>();
    const { id } = useParams()

    const { errors, setErrors, clearErrors } = useError();
    const navigate = useNavigate()

    const checkEmptyFields = (): boolean => {
        let hasError = false;
        clearErrors();
        const newErrors = { ...errors };

        if (leaseDetails.startDate.trim() === "") {
            newErrors.startDate = true;
            hasError = true;
        }

        if (leaseDetails.endDate.trim() === "") {
            newErrors.endDate = true;
            hasError = true;
        }

        if (!leaseDetails.amount) {
            newErrors.rent = true;
            hasError = true;
        }

        if (!leaseDetails.securityDeposit) {
            newErrors.securityDeposit = true;
            hasError = true;
        }

        if (!uploadAgreement) {
            setUploadAgreementError(true)
            hasError = true;
        }
        setErrors(newErrors);
        return hasError; // Return true if there are errors
    };

    const submitTimeError = () => {
        if (selectLease == "1") {
            return false;
        } else {
            if (selectedOption === TenantLeaseTypesEnum.FixedTermLease) {
                return (
                    errors.startDate || errors.endDate || errors.rent || errors.securityDeposit ||
                    leaseDetails.amount === "" || errors.leaseAgreement || leaseDetails.securityDeposit === ""
                )
            } else if (selectedOption === TenantLeaseTypesEnum.MonthToMonth) {
                return (
                    errors.startDate || errors.rent || errors.securityDeposit ||
                    leaseDetails.amount === "" || errors.leaseAgreement || leaseDetails.securityDeposit === ""
                )
            }
        }
    }

    const formSubmitHandler = (event: React.FormEvent) => {
        checkEmptyFields();
        event.preventDefault();
        if (submitTimeError()) { }
        else {
            let formattedEndDate = null;
            const formattedStartDate = new Date(leaseDetails.startDate).toISOString();
            if (leaseDetails.endDate) {
                formattedEndDate = new Date(leaseDetails.endDate).toISOString();
            }

            const requestBody = {
                unitId: Number(unitId),
                tenantId: Number(id),
                dataAttributes: {
                    LeaseType: `${selectedOption}`,
                    SecurityDeposit: leaseDetails.securityDeposit,
                    Rent: leaseDetails.amount,
                    StartDate: formattedStartDate,
                    EndDate: formattedEndDate
                }
            };

            apiHelper(LeaseAgreementService.postApiLeaseAgreement, { showNotification: false }, requestBody)
                .then((res) => {
                    if (res?.data?.success) {
                        const leaseAgreementData = {
                            EntityTypeId: DocumentEntityTypesEnum.Tenant,
                            EntityId: res?.data?.success?.id,
                            DocumentTypeId: DocumentTypesEnum.LeaseAgreement,
                            IsMain: false,
                            FileName: agreementFileName,
                            Document: uploadAgreement,
                        };
                        apiHelper(DocumentService.postApiDocument, { showNotification: false }, leaseAgreementData)
                            .then((res1) => {
                                if (res1?.data?.success) {
                                    setLeaseDetails({
                                        startDate: "",
                                        endDate: "",
                                        amount: "",
                                        securityDeposit: ""
                                    })
                                    setUploadAgreement(null);
                                    setAgreementFileName("")
                                }
                            })
                    }
                }).catch((err) => { })
        }
    }

    useEffect(() => {
        return () => {
            clearErrors();
            setUploadAgreementError(false)
        };
    }, [selectedOption]);

    return (
        <>
            {selectLease === "0" ? (
                <SelectType
                    title="Lease"
                    firstOption="eLease"
                    secondOption="Upload Lease Agreement"
                    icon1={GetImages.BlueFile}
                    icon2={GetImages.BlueCloud}
                    selectBasic={selectLease}
                    setSelectBasic={setSelectLease}
                    handleNext={undefined}
                    showNextBtn={false}
                />
            ) : null}

            {selectLease === "2" && (
                <Form className="createTenantForm pt-4" onSubmit={formSubmitHandler} id="tenantFormContainer">
                    <div className="newTenantFormTopBar d-flex align-items-center">
                        <img className="clickable me-2"
                            onClick={() => {
                                navigate(-1);
                            }}
                            src={GetImages.leftChevronBlack}
                        />
                        <p className="main_heading black">
                            Add Existing Tenant
                        </p>
                    </div>
                    <div className="newTenantFormContainer">
                        <SelectLeaseAndUploader
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            values={leaseDetails}
                            setValues={setLeaseDetails}
                            uploadDocument={undefined}
                            setUploadDocument={undefined}
                            fileName={undefined}
                            setFileName={undefined}
                            uploadAgreement={uploadAgreement}
                            setUploadAgreement={setUploadAgreement}
                            agreementFileName={agreementFileName}
                            setAgreementFileName={setAgreementFileName}
                            isShowLeaseApplicationUploader={false}
                            uploadAgreementError={uploadAgreementError}
                        />
                    </div>
                    <div className="newTanentBottomBar gap-3">
                        <div>
                            <AddButtonWithArrow
                                type="submit"
                                buttonname="Next"
                            />
                        </div>
                    </div>
                </Form>
            )}

            {selectLease === "1" && (
                <div>E-Lease</div>
            )}
        </>
    )
}

export default RenewalLease