import CustomInputs from '../../common/Inputs'

const TenantBasicDetails = ({ formDetails, handleChange }) => {
    return (
        <>
            <div className="newTenantDesciptionContainer">
                <p className="newTenantDescriptionText sub_text black">
                    Enter basic details of tenant
                </p>
            </div>

            <div className="tenantFormContainer">
                {formDetails.map((item) => (
                    <div className="customTenantInputContainerStyles">
                        <div className="inputContainer">
                            <CustomInputs
                                key={item.label}
                                labelText={item.label}
                                placeholder={item.placeholder}
                                value={item.value}
                                name={item.name}
                                myClassName="sub_heading fw-400"
                                onChange={handleChange}
                                onBlur={item.blurFunction}
                                errorMessages={item.errorMessages}
                                isError={item.isError}
                                required={item.required}
                                type={item.type}
                                maxLength={item.maxLength}
                                isFormControl={true}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default TenantBasicDetails