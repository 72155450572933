import React from 'react'
import CommonConfirmation from '../../common/CommonConfirmation'
import { useNavigate } from 'react-router'
import { URLS } from '../../../constants/constants'
import { GetImages } from '../../../utils/GetImages'

const FullLoginScreen = () => {
    const navigate = useNavigate()
  return (
    <>
     <div className="loginFullScreen"
          style={{ backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5)), url(${GetImages.background})`, }} >
          <CommonConfirmation
            show={true}
            onConfirm={() => navigate(URLS.LOGIN)}
            onClose={undefined}
            heading="Invitation Code Invalid"
            subHeading={`Lease Application Code is Invalid, is Expired, or is already been used. If used, please login with your email and password`}
            confirmLabel="Login"
            buttonCenter={true}
          />
        </div>
    </>
  )
}

export default FullLoginScreen