import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useAnalyticsHook } from "../hooks/useAnalyticsHook";
import { AnalyticsService } from "@propertelligent/client-api";
import { UserLoginData } from "../components/common/charts/Enums";
import UtilHelper from "../utils/UtilHelper";

const RevenueOverviewContainer = ({ loadingStates, setLoadingStates }) => {
  const organization_id = Number(
    localStorage.getItem(UserLoginData.organization_id)
  );
  const customMonthField = "monthYear";
  const [rev_monthValue, setRev_MonthValue] = useState<any>(-12);
  const [totalExpenseValue, setTotalExpenseValue] = useState(0);
  const [totalRevenueValue, setTotalRevenueValue] = useState(0);
  const [totalProfitValue, setTotalProfitValue] = useState(0);
  const [isTooltip, setIsTooltip] = useState(false);

  const { cashFlow, loading: dataLoading } = useAnalyticsHook({
    httpCashflowFn: AnalyticsService.getApiAnalyticsOrganizationCashflow,
    customMonthField,
    parentEntityId: organization_id,
    fullyQualifiedFilters: {
      rev: `Created>DateTime.UtcNow.AddMonths(${rev_monthValue})`,
    },
  });

  useEffect(() => {
    if (cashFlow.length > 0) {
      const { totalExpense, totalRevenue } = cashFlow.reduce(
        (accumulator, current) => {
          accumulator.totalExpense += current.expense || 0;
          accumulator.totalRevenue += current.income || 0;
          return accumulator;
        },
        { totalExpense: 0, totalRevenue: 0 }
      );

      setTotalExpenseValue(totalExpense);
      setTotalRevenueValue(totalRevenue);
      setTotalProfitValue(totalRevenue - totalExpense);
    }

    setLoadingStates((loadingStates) => ({
      ...loadingStates,
      revenueLoading: false,
    }));
  }, [cashFlow, dataLoading]);

  return (
    <div>
      <div className="overviewContainerMain">
        <div className="overviewContainerRightSide">
          <div
            style={{ borderBottom: "none" }}
            className="overviewContainerRightSideTopComponent"
          >
            <p className="heading">Revenue Overview</p>
            <div className="overviewButtons">
              <Form.Select
                className="sub_text grey_subtext1"
                aria-label="Default select Service Sub Type"
                required
                onChange={(e) => {
                  setRev_MonthValue(e.target.value);
                }}
                value={rev_monthValue}
              >
                <option value={-1}>Last month </option>
                <option value={-3}>Last 3 months </option>
                <option value={-6}>Last 6 months </option>
                <option value={-12}>Last 12 months </option>
              </Form.Select>
            </div>
          </div>
          <div className="overviewContainerRightSideSecondComponent">
            <div>
              <div
                style={
                  {
                    "--percentage":
                      totalProfitValue && totalRevenueValue
                        ? (totalProfitValue * 100) / totalRevenueValue
                        : 50,
                  } as React.CSSProperties
                }
                className="semi-donut1 margin position-relative"
                onMouseMove={() => setIsTooltip(true)}
                onMouseLeave={() => setIsTooltip(false)}
              >
                <div className="textBoxInsideCircularProgressBar">
                  <p className="main_heading black">{UtilHelper.CurrencyFormat(totalRevenueValue)}</p>
                  <p className="sub_text grey_subtext1">Total Revenue</p>
                </div>
                {isTooltip && (
                  <div className="normal_text grey_subtext1 tooltipStyle">
                    <div className="expenseItem flex-wrap">
                      <div className="propertyCardQuantityDesc gap-1">
                        <p className="normal_text grey_subtext1 fw-bold">
                          {UtilHelper.CurrencyFormat(totalProfitValue)}
                        </p>
                      </div>
                    </div>
                    <div className="caretStyle"></div>
                  </div>
                )}
              </div>
            </div>
            <div className="rentDetailsBox">
              <div className="totalRentBoxContainer">
                <div className="totalRentBox">
                  <div className="blueBox"></div>
                  <p className="sub_text grey_subtext1">Net Profit</p>
                </div>
                <p className="heading black">
                  {UtilHelper.CurrencyFormat(totalProfitValue)}
                </p>
              </div>
              <div className="horizontalBar"></div>
              <div className="totalRentBoxContainer">
                <div className="totalRentBox">
                  <div className="orangeBox"></div>
                  <p className="sub_text grey_subtext1">Total Expense</p>
                </div>
                <p className="heading black">
                  {UtilHelper.CurrencyFormat(totalExpenseValue)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueOverviewContainer;
