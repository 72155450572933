import MoveInAndOutContentBlocks from './common/MoveInAndOutContentBlocks'

const MoveOut = () => {
    return (
        <>
            <MoveInAndOutContentBlocks isMoveOut={true} />
        </>
    )
}

export default MoveOut