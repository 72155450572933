import React from "react";
import { useNavigate } from "react-router";
import ProgressBar from "../../common/ProgressBar";
import NameAvatarComponent from "../NameAvatarComponent";
import StatusTag from "../../common/StatusTag";
import { GetImages } from "../../../utils/GetImages";
import "./../tenantStyles.css";
import { TenantLeaseTypesEnum, TenantStatusesEnum } from "@propertelligent/client-api";

const CardView = (data) => {
  const navigate = useNavigate();
  const disable = false;

  return (
    <>
      <div
        key={data?.item?.id}
        onClick={() => { data?.item?.tenantStatus !== TenantStatusesEnum.Prospect ? navigate(`tenant/${data.item.id}`) : navigate(`application-details/${data.item.id}`); }}
        className={`singleRowContainer ${disable ? 'disable' : 'clickable'}`}
      >
        <div className="nameDetails d-flex flex-row gap-2 align-data.items-center ">
          <NameAvatarComponent
            firstName={data.item.firstName}
            lastName={data.item.lastName}
            heightWidth={40}
          />
          <div>
            <div className="nameDueContainer">
              <p className="sub_heading black">{`${data.item.firstName} ${data.item.lastName}`}</p>
              {data.item?.expensesDue !== 0 && (
                <StatusTag
                  color={"red"} icon={<img src={GetImages.redArrow} />} isLarge={false}
                  statusText={`$${data.item.expensesDue} due`} disabled={disable}
                />)}
            </div>
            <div className="leftSideBox">
              <p className="sub_text text-nowrap grey_subtext1">{data.item.email && data.item.email} </p>
              <p className="sub_text text-nowrap grey_subtext1">•</p>
              <p className="sub_text text-nowrap grey_subtext1">
                {data.item.phoneNumber && data.item.phoneNumber}
              </p>
            </div>
          </div>
        </div>
        <div className="middleBoxDetails">
          <p className="sub_text">
            {data.item.unitNumber !== "" ? data.item.unitNumber : ""}
          </p>
          {data.item.property?.address?.streetAddress?.trim() && <div className="dashLine"></div>}
          <p className="sub_text black">
            {data.item.property?.address?.streetAddress?.trim()
              ? data.item.property.address.streetAddress
              : "N/A"}
          </p>
        </div>

        {!disable &&
          (data?.item?.tenantStatus == TenantStatusesEnum.Prospect ? (
            <div className="rightSideContainer">
              <StatusTag
                color={
                  {
                    Invited: "lightBlue",
                    Approved: "green",
                    Pending: "red",
                    "In Review": "yellow",
                    "In Progress": "yellow",
                    Submitted: "blue",
                    Rejected: "red",
                    "Payment Pending": "red",
                    Closed: "purple",
                    Withdrawn: "gray",
                  }[data.item?.currentLeaseApplicationInfo?.formatted?.LeaseApplicationStatusTypeId]
                }
                statusText={data.item?.currentLeaseApplicationInfo?.formatted?.LeaseApplicationStatusTypeId}
              />
            </div>
          )
            : data.item.leaseRemainingMonths !== null || data?.item?.currentLeaseInfo?.tenantLeaseTypeId === TenantLeaseTypesEnum.MonthToMonth ? (
              <ProgressBar
                monthsRemaining={data.item.leaseRemainingMonths}
                leaseType={
                  data?.item?.currentLeaseInfo?.formatted?.TenantLeaseTypeId
                }
              />
            ) : (
              <div className="rightSideContainer">
                <StatusTag color={"yellow"} statusText={data.item?.formatted?.UserStatus} />
              </div>
            )
          )
        }
      </div>
    </>
  );
};

export default CardView;
