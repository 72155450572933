import { GetImages } from '../../../utils/GetImages'

const OrganizationNameHeader = ({
  SubHeaderName,
  OrgName,
  OrgImg = GetImages.HomeBlackFilled,
  element = undefined,
  headerNameClass = "top_header fw-normal grey_subtext1",
  subHeaderNameClass ="main_heading grey_subtext1",
  status = null,
  statusClass = null,
  statusElement=null
}) => {
 
  return (
    <>
      <div className="organization-header">
        <div className="flexFullRow itemContainer">
          <div className="flexColumn">
            <div className="flexFullRow align-items-center">
              <div className="flexColumn itemContainerimg">
                <img
                  alt={"unit"}
                  className='iconstyle88'
                  src={OrgImg ? OrgImg : GetImages.HomeBlackFilled}
                />
              </div>
              <div className="flexColumn itemContainerimgDetail">
                <p className={headerNameClass}>
                  {OrgName}{status && (<span className={statusClass}>{status}</span>)}
                </p >
                {statusElement && (statusElement)}
                <p className={subHeaderNameClass}>{SubHeaderName}</p>
              </div>
            </div>
          </div>
          <div className='d-flex gap-2 right_section'>
            {element}
          </div>
        </div>
      </div>
    </>
  )
}

export default OrganizationNameHeader