import React, { createContext, useState } from "react";

// Create a context
const MyContext = createContext(null);

// Create a provider component
const MyProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [userRole, setUserRole] = useState(null);



  const values = { isLogin, setIsLogin, userRole, setUserRole };

  return <MyContext.Provider value={values}>{children}</MyContext.Provider>;
};

export { MyProvider, MyContext };
