import { FeeCommissionTypesEnum } from '@propertelligent/client-api'

const View = ({
    securityDepositType,
    securityDepositAccount,
    rentDepositAccount,
    commissionType,
    rentType,
    AccountOptions,
    rentTypeOptions,
    values
}) => {
    const checkType = (type: number) => {
        switch (type) {
            case FeeCommissionTypesEnum.Fixed:
                return "Fixed Amount";

            case FeeCommissionTypesEnum.Variable:
                return "Variable";

            case FeeCommissionTypesEnum.PerUnit:
                return "Per Unit"

            default:
                break;
        }
    }

    const getAccountName = (accountId: number) => {
        const account = AccountOptions?.find((option) => option.id === accountId);
        return account ? account.name : "N/A";
    };

    const getRentType = (accountId: number) => {
        const account = rentTypeOptions?.find((option) => option.id === accountId);
        return account ? account.name : "N/A";
    };
    console.log("AccountOptions", AccountOptions);

    return (
        <>
            <div className='mt-3'>
                <h5 className='heading black'>Security Deposit</h5>
                <div className='mt-3'>
                    <div className='mb-3'>
                        <p className='mb-1 normal_text grey_subtext1'>{checkType(securityDepositType)}</p>
                        <p className='normal_text fw-bold grey_subtext1'>
                            {securityDepositType === FeeCommissionTypesEnum.Fixed ?
                                `$ ${values?.securityDepositamount}` : `${values?.monthsRent} Months Rent`
                            }
                        </p>
                    </div>
                    <div>
                        <p className='mb-1 normal_text grey_subtext1'>Security Deposit Account</p>
                        <p className='normal_text fw-bold grey_subtext1'>{getAccountName(securityDepositAccount)}</p>
                    </div>
                </div>
            </div>
            <div className='my-4'>
                <h5 className='heading black'>Rent Collection</h5>
                <div className='mt-3 d-flex gap-4'>
                    <div>
                        <p className='mb-1 normal_text grey_subtext1'>Rent Late Fee</p>
                        <p className='normal_text fw-bold grey_subtext1'>$ {values?.rentLateFee}</p>
                    </div>
                    <div>
                        <p className='mb-1 normal_text grey_subtext1'>Rent Deposit Account</p>
                        <p className='normal_text fw-bold grey_subtext1'>{getAccountName(rentDepositAccount)}</p>
                    </div>
                </div>
            </div>
            <div className='mb-3 my-4'>
                <h5 className='heading black'>Leasing</h5>
                <div className='mt-3'>
                    <p className='mb-1 normal_text grey_subtext1'>Lease Application Fee</p>
                    <p className='normal_text fw-bold grey_subtext1'>$ {values?.leaseApplicationFee}</p>
                </div>
            </div>
            <div className='my-4'>
                <h5 className='heading black'>Property Management Commission</h5>
                <div className='mt-3'>
                    <p className='mb-1 normal_text grey_subtext1'>{checkType(commissionType)}</p>
                    <p className='normal_text fw-bold grey_subtext1'>
                        {commissionType === FeeCommissionTypesEnum.Fixed ?
                            `$ ${values?.commissionAmount} Per Month`
                            : commissionType === FeeCommissionTypesEnum.Variable ?
                                `${values?.commissionPercentage}% Per Month of ${getRentType(rentType)}`
                                : `$ ${values?.perUnitAmount} Per Unit`
                        }
                    </p>
                </div>
            </div>
        </>
    )
}

export default View