import { TemplateService, TemplateTypeEnum } from "@propertelligent/client-api";
import TemplateCard from "./CommonComp/TemplateCard";
import TemplateMainHeader from "./CommonComp/TemplateMainHeader";
import { infiniteScrollHook } from "../../../hooks/infiniteScrollHook";
import SimpleSpinner from "../../loader";
import SeparaterLine from "../../common/SeparaterLine";

const TempleteView = () => {
  const {
    loading,
    rows,
    fetchData,
    hasMore,
    currentPage
  } = infiniteScrollHook({
    httpFetchDataFn: TemplateService.getApiTemplateOrganizationlist,
    FullyQualifiedFilters: `templateTypeId == ${TemplateTypeEnum.LeaseApplication}`
  });

  const {
    loading: loading1,
    rows: rows1,
    hasMore: hasMore1,
    fetchData: fetchData1,
    currentPage: currentPage1
  } = infiniteScrollHook({
    httpFetchDataFn: TemplateService.getApiTemplateOrganizationlist,
    FullyQualifiedFilters: `templateTypeId == ${TemplateTypeEnum.LeaseAgreement}`
  });

  return (
    <div className="Template_View_Main">
      <TemplateMainHeader searchTerm={undefined} placeholder={"Search Templates"} setSearchTerm={undefined} headerName={"Template"} />
      <div className="Template_View_Card_Section gap-3">
        {loading && loading1 ? <SimpleSpinner /> : <>
          <TemplateCard
            rows={rows}
            list_Name="LEASE APPLICATION"
            addNewRedirection={"create"}
            loading={loading}
            fetchData={fetchData}
            hasMore={hasMore}
            currentPage={currentPage}
          />
          <SeparaterLine height={"100%"} background={"#e5e7eb"} />
          <TemplateCard
            rows={rows1}
            list_Name="LEASE AGREEMENT"
            addNewRedirection={"create-lease-agreement"}
            loading={loading1}
            fetchData={fetchData1}
            hasMore={hasMore1}
            currentPage={currentPage1}
            editNewRedirection="lease-agreement"
          />
        </>
        }
      </div>
    </div>

  );
};

export default TempleteView;
