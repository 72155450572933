import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import apiHelper from '../../utils/apiHelper';
import { DocumentEntityTypesEnum, DocumentService, DocumentTypesEnum, EntityType, 
  LeaseAgreementService, 
  LeaseAgreementStatusTypesEnum, 
  LeaseApplicationService, 
  LeaseApplicationStatusTypesEnum,
  OrganizationService,
  PaidFlowTypesEnum,
  PaymentService,
  TenantService
} from '@propertelligent/client-api';
import BoardingReview from '../TenantOnboard/RenderSteps/BoardingReview';
import RequestBreadcrumb from '../common/RequestBreadcrumb';
import { Offcanvas } from 'react-bootstrap';
import EditTenantForm from './EditTenantForm';
import LeaseAgreementSettings from './LeaseAgreement/LeaseAgreementSettings';
import ShowLeaseAgreement from './LeaseAgreement/ShowLeaseAgreement';
import { UserLoginData } from '../common/charts/Enums';
import ApplicationDetailsStatusHeader from './component/ApplicationDetailsStatusHeader';

const ApplicationDetails = () => {
  const { id } = useParams();
  const navigation = useNavigate()
  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const { currentLeaseInfo, currentLeaseApplicationInfo } = userData || {};
  const [tenantEdit, setTenantEdit] = useState(false);
  const [initiateLeaseAgreement, setInitiateLeaseAgreement] = useState(false)
  const [previewLeaseAgreement, setPreviewLeaseAgreement] = useState(false)
  const [selectedTemplateid, setSelectedTemplateid] = useState()
  const [postLeaseAgreement, setPostLeaseAgreement] = useState({ tenantId: id, unitId: currentLeaseApplicationInfo?.unit?.id, templateId: selectedTemplateid, dataAttributes: {}, })
  const [formData, setFormData] = useState([{ id: 1, paymentType: "", amount: 0, dueDate: "", gracePeriods: 0, description: "" }]);
  const [isPrint, setISPrint] = useState(false)
  const [leaseApplicationData, setleaseApplicationData] = useState({
    CreditCheck: {} , CurrentAddress: {} , EmploymentHistory: [], References: [],
    RentIncomeCheck: {} , Signature: {} , Questionnaire: []
  })

  useEffect(() => {
    setPostLeaseAgreement((prev) => ({ ...prev, templateId: currentLeaseInfo?.id, unitId: currentLeaseApplicationInfo?.unit?.id }))
    setSelectedTemplateid(selectedTemplateid)
  }, [currentLeaseInfo?.id])

  const contentRef = useRef()
  const getPayeeAccountId = async (orgId) => {
    try {
      const res = await apiHelper(OrganizationService.getApiOrganizationFeeCommissions, { showNotification: false }, orgId);
      if (res?.data?.success) {
        return res?.data?.success?.securityDepositSetting?.depositAccountId;
      } else { return null; }
    } catch (error) { return null; }
  };
  const getTenantsData = async () => {
    const tenantsResponse = await apiHelper(TenantService.getApiTenant1, { showNotification: false }, Number(id));
    setPostLeaseAgreement((prev) => ({
      ...prev, unitId: tenantsResponse?.data?.success?.currentLeaseApplicationInfo?.unitId, templateId: selectedTemplateid
    }))
    setUserData(tenantsResponse?.data?.success)
  };

 
  const getLeaseApplication =()=>{
    if(currentLeaseApplicationInfo?.id){
      apiHelper(LeaseApplicationService.getApiLeaseApplication1, { showNotification: false }, currentLeaseApplicationInfo?.id)
      .then((response: any) => {
        setleaseApplicationData(prevFormData => ({
          ...prevFormData,
          Signature: response?.data?.success?.dataSections?.Signature ? JSON.parse(response.data.success.dataSections.Signature) : null,
        })); 
      })
    }
  }

  useEffect(() => { 
    // getDocuments()
    getTenantsData() 
    getLeaseApplication()
  
  }, [ tenantEdit, initiateLeaseAgreement, currentLeaseInfo?.id, selectedTemplateid ,currentLeaseApplicationInfo?.id])

  const sendLeaseAgreement = async () => {
    const payeeAccId = await getPayeeAccountId(orgId);
    const paymentPromises = formData.map((item, index) => {
      const updatedValues = {
        amount: Number(formData[index].amount),
        description: formData[index].description,
        dueDate: new Date(formData[index]?.dueDate)?.toISOString(),
        entityId: Number(id),
        entityTypeId: EntityType.Tenant,
        payeeAccountId: payeeAccId,
        gracePeriodInDays: Number(formData[index].gracePeriods),
        paymentTypeId: Number(formData[index].paymentType),
        paidFlowTypeId: PaidFlowTypesEnum.Payable,
      };
      return apiHelper(PaymentService.postApiPaymentDueAccount, {
        successMessage: "Lease Agreement sent successfully",
        failureMessage: "Unable to Sent Lease Agreement", showNotification: true
      }, updatedValues
      ).then((res) => {
        if (res?.data?.success?.id) { return res.data.success.id; }
        else { return null }
      }).catch((error) => { return null });
    });
    Promise.all(paymentPromises)
      .then((paymentIds) => {
        const validPaymentIds = paymentIds.filter((id) => id !== null);
        if (validPaymentIds.length > 0) {
          const leaseData = { ...postLeaseAgreement, paymentDueAccountIds: validPaymentIds, };
          apiHelper(LeaseAgreementService.postApiLeaseAgreement, { showNotification: true }, leaseData)
            .then((response) => {
              if (response?.data?.success) {
                setInitiateLeaseAgreement(false)
                getTenantsData()

              } else { console.error("Error posting Lease Agreement:",); }
            }).catch((err) => { console.error("API error for lease agreement:", err); });
        } else { console.error("No valid payment IDs to create Lease Agreement."); }
      }).catch((error) => { console.error("Error in processing payment IDs:", error); });
  }

 

 return (
    <>
      <div className="report_fixed_header1 z-1">
        <RequestBreadcrumb
          firstName={"Tenant"}
          link={"/tenants"}
          currentStep={previewLeaseAgreement ? "Lease Agreement Template" : "Tenant Detail"}
        />
      </div>
      <div className='outer-container1 px-0 mt-5 pt-3' >
        {(currentLeaseApplicationInfo?.leaseApplicationStatusTypeId == LeaseApplicationStatusTypesEnum.Approved && initiateLeaseAgreement) ?
          (<>
            {(previewLeaseAgreement) && (
              <ShowLeaseAgreement
                userData={userData}
                selectedTemplateid={selectedTemplateid || currentLeaseInfo?.templateId}
                postLeaseAgreement={postLeaseAgreement}
                setPostLeaseAgreement={setPostLeaseAgreement}
                onClick={sendLeaseAgreement}
                onCancel={() => { navigation(-1) }}
                role="landlord"
                buttonName={isLoading ? "Saving..." : ((currentLeaseInfo?.leaseAgreementStatusTypeId === LeaseAgreementStatusTypesEnum.PendingLandlordSignature) ||( currentLeaseInfo?.leaseAgreementStatusTypeId === LeaseAgreementStatusTypesEnum.PendingTenantSignature)) ? "Sign & Sent" : "Send Lease Agreement"}
                selectedTemplates={undefined}
             />)}
            {(currentLeaseInfo == null && !previewLeaseAgreement) && (
              <LeaseAgreementSettings
                selectedTemplateid={selectedTemplateid}
                setSelectedTemplateid={setSelectedTemplateid}
                setPostLeaseAgreement={setPostLeaseAgreement}
                setInitiateLeaseAgreement={setInitiateLeaseAgreement}
                previewLeaseAgreement={previewLeaseAgreement}
                setPreviewLeaseAgreement={setPreviewLeaseAgreement}
                formData={formData} setFormData={setFormData}
              />)}
          </>) : <>
            <div className='onboarding-header px-3'>
              <ApplicationDetailsStatusHeader
                getTenantsData={getTenantsData}
                setInitiateLeaseAgreement={setInitiateLeaseAgreement}
                userData={userData} contentRef={contentRef}
                isPrint={isPrint}
                setISPrint={setISPrint}
                setPreviewLeaseAgreement={setPreviewLeaseAgreement} />
            </div>
            <div ref={contentRef} className="onboarding-stepper mh-100 px-3 mt-0 w-100">
              {isPrint && (
                <div className='onboarding-header px-3'>
                  <ApplicationDetailsStatusHeader
                    getTenantsData={getTenantsData}
                    setInitiateLeaseAgreement={setInitiateLeaseAgreement}
                    userData={userData}
                    contentRef={contentRef}
                    isPrint={isPrint}
                    setISPrint={setISPrint}
                    setPreviewLeaseAgreement={setPreviewLeaseAgreement} />
                </div>
              )}
              <BoardingReview landlord={true} leaseApplication={currentLeaseApplicationInfo?.id} editable={undefined} isEditClick={undefined} postData={undefined} setPostData={undefined} formData={leaseApplicationData?.Signature} setFormData={undefined} steps={undefined}
                setTenantEdit={setTenantEdit}
                tenantEdit={tenantEdit}
                getTenantsData={getTenantsData}
              />
            </div>
          </>
        }
      </div>
      <Offcanvas
        className="offcanvas serviceRequestForm"
        show={tenantEdit}
        onHide={() => setTenantEdit(false)}
        placement={"end"}
        scroll={true} >
        <Offcanvas.Header className="canvasHeader" closeButton>
          <Offcanvas.Title>Edit Tenant</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <EditTenantForm id={userData?.id} setShowModal={setTenantEdit} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
export default ApplicationDetails