import { useEffect, useState } from "react";
import ChatBox from "../../../components/Messaging/Chat/ChatBox";
import {
  CommunicationService,
  MessageVM,
  TenantService,
} from "@propertelligent/client-api";
import { UserLoginData } from "../../../components/common/charts/Enums";
import apiHelper from "../../../utils/apiHelper";

const Chat = () => {
  const [searchValues, setSearchValues] = useState("");
  const [entityId, setEntityId] = useState<any>();
  const [entityTypeId, setEntityTypeId] = useState<any>();
  const [showSection, setShowSection] = useState<any>("");
  const [messageChannel, setMessageChannel] = useState([]);
  const [tenantId, setTenantId] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [managerFirstName, setManagerFirstName] = useState("");
  const [managerLastName, setManagerLastName] = useState("");

  const tenantInfo = localStorage.getItem("tenantInfo");
  const parsedPropertyId = JSON.parse(tenantInfo);

  // const propertyId = parsedPropertyId.propertyId;


  // apiHelper(
  //   PropertyService.getApiPropertyPropertyManager,
  //   { showNotification: false },
  //   propertyId
  // )
  //   .then((res) => {
  //     console.log("resssssss", res);
  //     setManagerFirstName(res?.data?.success?.firstName);
  //     setManagerLastName(res?.data?.success?.lastName);
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //   });

  const chatname = (managerFirstName + " " + managerLastName).trim();

  const [messageData, setmessageData] = useState<MessageVM>({
    entityTypeId: entityTypeId,
    entityId: entityId,
  });

  const fetchMessageChannel = (fromClick = false) => {
    setLoading(true);
    const searchQuery =
      typeof searchValues === "string" ? searchValues.toLowerCase() : "";

    const tanantId = Number(localStorage.getItem(UserLoginData.tenant_id));
    apiHelper(
      CommunicationService.getApiMessageTenantlist,
      { showNotification: false },
      tanantId
    )
      .then((res) => {
        setMessageChannel(res?.data?.success);
        setEntityTypeId(res?.data?.success[0].entityTypeId);
        setEntityId(res?.data?.success[0]?.entityId);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchMessageChannel();
  }, [messageChannel?.length, tenantId]);

  const getTenantId = () => {
    const tanantId = Number(localStorage.getItem(UserLoginData.tenant_id));

    apiHelper(
      TenantService.getApiTenant1,
      { showNotification: false },
      tanantId
    )
      .then((res) => {
        setFirstName(res?.data?.success?.firstName);
        setLastName(res?.data?.success?.lastName);
      })
      .catch((error) => {
      });
  };
  useEffect(() => {
    getTenantId();
  }, []);

  return (
    <>
      <div className="chatMainContainer">
        <div className="chatSubContainer ">
          <div className="chatRightSection ">
            <ChatBox
              setShowMessage={setShowMessage}
              entityId={entityId}
              setEntityId={setEntityId}
              entityTypeId={entityTypeId}
              chatname={chatname}
              setChatName={undefined}
              setEntityTypeId={setEntityTypeId}
              fetchData={fetchMessageChannel}
              setShowSection={setShowSection}
              messageData={messageData}
              setmessageData={setmessageData}
              messageChannel={messageChannel}
              tenantId={tenantId}
              firstName={firstName}
              lastName={lastName}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
