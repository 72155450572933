import { useEffect, useState } from "react";
import RequestBreadcrumb from "../../common/RequestBreadcrumb";
import AnalyticsCard from "../../common/AnalyticsCard";
import { GetImages } from "../../../utils/GetImages";
import BasicDetailsCard from "./common/BasicDetailsCard";
import apiHelper from "../../../utils/apiHelper";
import { AnalyticsService } from "@propertelligent/client-api";
import { UserLoginData } from "../../common/charts/Enums";
import SimpleSpinner from "../../loader";
import { useAnalyticsHook } from "../../../hooks/useAnalyticsHook";
import UtilHelper from "../../../utils/UtilHelper";

const BasicDetailsComponent = () => {
  const [analyticData, setAnalyticData] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [totalExpenseValue, setTotalExpenseValue] = useState(0);
  const [totalRevenueValue, setTotalRevenueValue] = useState(0);
  const [totalLastMonthRevenueValue, setTotalLastMonthRevenueValue] = useState(0);
  const [totalProfitValue, setTotalProfitValue] = useState(0);
  const customMonthField = "monthYear";
  const organizationId = Number(localStorage.getItem(UserLoginData.organization_id));

  const cardData = [
    { id: 1, data: analyticData?.NumberOfProperties, icon: GetImages.homeIconOrange, title: "Properties" },
    { id: 2, data: analyticData?.NumberOfUnits, icon: GetImages.OrangeUnit, title: "Units" },
    { id: 3, data: analyticData?.NumberOfTenants, icon: GetImages.OrangeUser, title: "Tenants" },
    { id: 4, data: analyticData?.NumberOfVacantUnits, icon: GetImages.OrangeUnit, title: "Vacant Units" },
    { id: 5, data: UtilHelper.CurrencyFormat(totalProfitValue), icon: GetImages.OrangeDoller, title: "Net Profit" },
    { id: 6, data: UtilHelper.CurrencyFormat(totalExpenseValue), icon: GetImages.OrangeDoller, title: "Total Expense" },
    { id: 7, data: UtilHelper.CurrencyFormat(totalLastMonthRevenueValue), icon: GetImages.OrangeDoller, title: "Last Month Revenue" },
    { id: 8, data: UtilHelper.CurrencyFormat(totalRevenueValue), icon: GetImages.OrangeDoller, title: "Total Revenue" }
  ]

  const getAnalyticsData = () => {
    setLoading(true)
    apiHelper(AnalyticsService.getApiAnalyticsDashboard, { showNotification: false }, organizationId)
      .then((res) => {
        if (res?.data?.success) {
          setAnalyticData(res?.data?.success?.formatted)
          setLoading(false)
        }
      }).catch((err) => { })
  }

  useEffect(() => {
    getAnalyticsData()
  }, [])

  const { cashFlow, loading: dataLoading } = useAnalyticsHook({
    httpCashflowFn: AnalyticsService.getApiAnalyticsOrganizationCashflow,
    customMonthField,
    parentEntityId: organizationId,
    fullyQualifiedFilters: {
      rev: `Created>DateTime.UtcNow.AddMonths(-12)`,
    },
  });

  const { cashFlow: cashFlow1, loading: dataLoading1 } = useAnalyticsHook({
    httpCashflowFn: AnalyticsService.getApiAnalyticsOrganizationCashflow,
    customMonthField,
    parentEntityId: organizationId,
    fullyQualifiedFilters: {
      rev: `Created>DateTime.UtcNow.AddMonths(-1)`,
    },
  });

  useEffect(() => {
    if (cashFlow.length > 0) {
      const { totalExpense, totalRevenue } = cashFlow.reduce(
        (accumulator, current) => {
          accumulator.totalExpense += current.expense || 0;
          accumulator.totalRevenue += current.income || 0;
          return accumulator;
        },
        { totalExpense: 0, totalRevenue: 0 }
      );

      setTotalExpenseValue(totalExpense);
      setTotalRevenueValue(totalRevenue);
      setTotalProfitValue(totalRevenue - totalExpense);
    }
  }, [cashFlow, dataLoading]);


  useEffect(() => {
    if (cashFlow1.length > 0) {
      const { totalRevenue } = cashFlow1.reduce(
        (accumulator, current) => {
          accumulator.totalRevenue += current.income || 0;
          return accumulator;
        },
        { totalExpense: 0, totalRevenue: 0 }
      );

      setTotalLastMonthRevenueValue(totalRevenue);
    }
  }, [cashFlow1, dataLoading1]);

  return (
    <div className="basic_details">
      <div className="basic_detail_breadcrumb">
        <RequestBreadcrumb
          firstName={"Dashboard"}
          link={"/"}
          lastName={"My Organization"}
          currentStep={"Overview"}
        />
      </div>
      {loading ? (
        <SimpleSpinner />
      ) : (
        <div className="basic_details_container pt-3">
          <BasicDetailsCard />
          <div className="d-flex gap-3 flex-wrap">
            {cardData?.map((elem, index) => (
              <div key={elem.id} className="mb-3" style={{ width: "305px" }}>
                <AnalyticsCard icon={elem.icon} data={elem.data} title={elem.title} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BasicDetailsComponent;
