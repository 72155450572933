import { QuestionTypeEnum } from '@propertelligent/client-api';
import React, { useEffect, useState } from 'react'
import { Badge } from 'react-bootstrap';
import EmptyData from '../../common/EmptyData';

const QuestionnairesInfo = ({leaseApplicationData ,templateData ,leaseApplication}) => {
    const [processedData, setProcessedData] = useState([]);
    
// Function to process questionnaires
const processQuestionnaires = () => {

    if (leaseApplicationData?.length) {
      return leaseApplicationData?.map(response => {
        const question = templateData?.find(q => q.id === response?.questionId);
        if (!question) return null;
        const selectedOptions = response?.selectedOptionIds?.map(optionId => {
          const option = question?.choices?.find(choice => choice.id === optionId);
          return option ? option.optionText : null;
        }).filter(Boolean);
        let displayText = '';
        let followUpText = '';
        switch (question.questionTypeId) {
          case 1: displayText = question.questionText;
            if (response.selectedOptionIds.includes(question.followUpQuestionChoiceId)) {
              followUpText = question.followUpQuestionText;
            }
            break;
          case 2:
          case 3:
          case 4:
          default:
            displayText = question.questionText;
        }  
        return {
          questionTypeId: question.questionTypeId,
          questionText: displayText,
          selectedOptions: selectedOptions,
          followUpQuestion: followUpText,
          followUpAnswer: response?.followUpAnswer,
          answerText: response?.answer
        };
      }).filter(Boolean);
    } 
    return [];
  };
    useEffect(() => {
        if (leaseApplicationData) {
          try {
            const data = processQuestionnaires();
            setProcessedData(data);
          } catch (error) {
            console.error('Error processing questionnaires:', error);
          }
        }
      }, [leaseApplicationData, templateData]);
      const renderQuestionContent = (item) => {
        switch (item.questionTypeId) {
          case 1: 
            return (
              <>
                <div className="question-row">
                  <p className="normal_text black fw-500">{item.questionText}</p>
                  <p className="d-flex gap-1">
                    {item.selectedOptions ? (
                      item.selectedOptions.map((option, idx) => (
                        <Badge key={idx} bg="primary"> {option} </Badge>
                      ))
                    ) : ( item.choices?.map((choice) => (
                        <Badge key={choice.id || choice.optionText}  bg="primary"> {choice.optionText} </Badge>
                      ))
                    )}
                  </p>
                </div>
                {item.followUpQuestion && item.followUpAnswer && (
                  <div className="BackgroundlightRed py-3 my-3 px-2 rounded-1">
                    <p className="normal_text fw-bold black">
                      Additional Details:
                      <span className="normal_text fw-400 px-2">
                        <i>{item.followUpAnswer}</i>
                      </span>
                    </p>
                  </div>
                )}
              </>
            );
      
          case 2:
            return (
              <div className="question-row">
                <p className="normal_text black fw-500">{item.questionText}</p>
                <p className="d-flex gap-1">
                  {item.selectedOptions ? (item.selectedOptions.map((option, idx) => (<Badge key={idx} bg="primary"> {option} </Badge>))
                  ) : (item.choices?.map((choice) => (
                    <Badge
                      key={choice.id || choice.optionText}
                      bg="primary"
                    >
                      {choice.optionText}
                    </Badge>
                  ))
                  )}
                </p>
              </div>
            );
          case 3:
          case 4:
            return (
              <div className="answer">
                <p className="normal_text black fw-500 py-3">{item.questionText}</p>
                {item.answerText && (
                  <p className="normal_text italic">{item.answerText}</p>
                )}
              </div>
            );
      
          default:
            return null;
        }
      };
      
  return (
    <> {leaseApplicationData?.length ? (
        <div className="application-card">
        <div className="question-list">      
            {leaseApplication ? ( processedData?.map((item, index) => ( <div key={index}>{ renderQuestionContent(item) }</div> ))
            ) : (
              leaseApplicationData?.map((item, index) => (
                <div key={index}>
                  {item?.questionTypeId === QuestionTypeEnum.SingleChoice ||
                  item?.questionTypeId === QuestionTypeEnum.MultipleChoice ? (
                    <div className="question-row">
                      <p className="normal_text black fw-500">{item?.questionText}</p>
                      <p className="d-flex gap-1">
                        {item?.choices?.map((subItem) => (
                           <React.Fragment key={subItem?.id || subItem?.optionText}>
                            {!leaseApplication ? ( <Badge bg="primary">{subItem?.optionText}</Badge>
                            ) : (subItem?.isRadio && <Badge bg="primary">{subItem?.optionText}</Badge> )}
                          </React.Fragment>
                        ))}
                      </p>
                    </div>
                  ) : item?.questionTypeId === QuestionTypeEnum.ShortAnswer ? (
                    <div className="answer">
                      <p className="normal_text black fw-500 py-2">{item?.questionText}</p>
                      <p className="normal_text">{item?.shortAnswer}</p>
                    </div>
                  ) : item?.questionTypeId === QuestionTypeEnum.Paragraph ? (
                    <div className="answer">
                      <p className="normal_text black fw-500 py-2">{item?.questionText}</p>
                      <p className="normal_text italic">{item?.paragraph}</p>
                    </div>
                  ) : null}
                </div>
              ))
            ) }
          </div>
        </div>) : ( <EmptyData mainText="Data not found" /> ) }
  </>)
}

export default QuestionnairesInfo