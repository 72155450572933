import React, { useEffect, useMemo, useState } from "react";
import { Pagination } from "react-bootstrap";
import { debouncedFunction } from "../utils/helper";
import { UserLoginData } from "../components/common/charts/Enums";

interface DistinctFilterValues {
  Category: any;
  From: any;
  To: any;
  PaymentType: any;
  PaymentMethod: any;
}

export const usePaginationHook = ({
  httpFetchDataFn,
  paginationDataMapper,
  parentEntityId = null,
  isDependentEntity = false,
  FullyQualifiedFilters = null,
  FullyQualifiedSorts = null,
  sortArrayForHTTPFn = null,
  PageSize = 10
}) => {
  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
  const reqID = isDependentEntity ? parentEntityId : orgId;
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [filters, setFilters] = useState([]);
  const [distinctFilterValues, setDistinctFilterValues] = useState<any>([]);
  const [aggregateValues, setAggregateValues] = useState<any>();
  const [calculateValue, setcalculateValue] = useState<any>();
  const [sorts, setSorts] = useState();

  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PageSize,
  });
  const [currentSortDirection, setCurrentSortDirection] = useState("asc");

  const handleSort = (fieldName: any) => {
    const newSortDirection = currentSortDirection === "asc" ? "desc" : "asc";
    setCurrentSortDirection(newSortDirection);
  };

  const fetchData = () => {
    if (reqID) {
      setLoading(true);
      httpFetchDataFn(
        reqID,
        pagination?.pageSize,
        pagination?.page + 1,
        undefined,
        undefined,
        undefined,
        FullyQualifiedFilters,
        FullyQualifiedSorts,
        filters
      )
        .then((res) => {
          if (distinctFilterValues.length == 0) {
            setDistinctFilterValues(res?.distinctFilterValues);
          }
          setAggregateValues(res?.formattedAggregateValues);
          setcalculateValue(res?.formattedCalculatedValues);
          let paginationData = res?.success;

          if (paginationDataMapper) {
            paginationData = paginationDataMapper(res?.success);
          }
          setRows(paginationData);
          setRowCount((prevCounter) =>
            !isNaN(res?.recordCount) ? res?.recordCount : prevCounter
          );
        })
        .catch((e) => { })
        .finally(() => {
          window.setTimeout(() => {
            setLoading(false);
          }, 500);
        });
    }
  };
  useMemo(() => { }, [FullyQualifiedFilters, rows?.length, FullyQualifiedSorts]);

  useEffect(() => {
    debouncedFunction(fetchData, 500)();
  }, [reqID, filters, sorts, pagination, FullyQualifiedFilters, FullyQualifiedSorts, distinctFilterValues?.length]);

  const handleFilterModelChange = (newModel) => {
    if (newModel?.items?.length > 0) {
      setFilters(newModel?.items);
    } else {
      setFilters([]);
    }
  };

  const handleSortModelChange = (sortModelArr) => {
    if (sortModelArr?.length > 0) {
      setSorts(sortModelArr);
    }
  };

  const handlePaginationModelChange = (model) => {
    setPagination(model);
  };

  return {
    loading,
    rows,
    rowCount,
    distinctFilterValues,
    aggregateValues,
    calculateValue,
    fetchData,
    handleFilterModelChange,
    handleSortModelChange,
    handlePaginationModelChange,
    handleSort,
    hasMore,
    currentSortDirection,

    CustomPagination: () => {
      const totalPages = Math.ceil(rowCount / pagination.pageSize);

      const handlePrevClick = () => {
        if (pagination.page > 0) {
          setPagination((prevPagination) => ({
            ...prevPagination,
            page: prevPagination.page - 1,
          }));
        }
      };

      const handleNextClick = () => {
        if (pagination.page < totalPages - 1) {
          setPagination((prevPagination) => ({
            ...prevPagination,
            page: prevPagination.page + 1,
          }));
        }
      };

      const handlePageClick = (pageNumber) => {
        setPagination((prevPagination) => ({
          ...prevPagination,
          page: pageNumber - 1,
        }));
      };

      const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageNumbers = 5;
        const halfMaxPageNumbers = Math.floor(maxPageNumbers / 2);

        let startPage = Math.max(1, pagination.page + 1 - halfMaxPageNumbers);
        let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

        // Adjust startPage when near the end
        if (endPage - startPage + 1 < maxPageNumbers) {
          startPage = Math.max(1, endPage - maxPageNumbers + 1);
        }

        for (let number = startPage; number <= endPage; number++) {
          pageNumbers.push(
            <Pagination.Item
              key={number}
              active={number === pagination.page + 1}
              onClick={() => handlePageClick(number)}
            >
              {number}
            </Pagination.Item>
          );
        }

        return pageNumbers;
      };

      return (
        <Pagination>
          <Pagination.Prev
            disabled={pagination.page === 0}
            onClick={handlePrevClick}
          />
          {renderPageNumbers()}
          <Pagination.Next
            disabled={pagination.page >= totalPages - 1}
            onClick={handleNextClick}
          />
        </Pagination>
      );
    },
  };
};
