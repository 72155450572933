import { useState } from "react";
import CustomDropDownMenu from "../../common/CustomDropDownMenu";
import { GetImages } from "../../../utils/GetImages";
import CustomInputs from "../../common/Inputs";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import AddButtonWithArrow from "../../firstTimeUserExperience/commonCom/AddButtonWithArrow";

const QuestionHeader = ({ postData, setPostData, duplicateTemplete, deleteTemplete ,previewApplication=false }) => {
  const [editMode, setEditMode] = useState(false);

  const handleSave = () => {
    setEditMode(false);
  };

  const data = [
    {
      id: 1,
      img: GetImages.CopyBlue,
      onClick: () => duplicateTemplete(),
      name: "Duplicate",
      linkpath: undefined,
      textClass: 'sub_text fw-600 blue'

    },
    {
      id: 2,
      img: postData?.isInUse == false ? GetImages.deleteIconRed : GetImages.greyDelete,
      name: "Delete",
      onClick: () => deleteTemplete(),
      linkpath: undefined,
      textClass: postData?.isInUse == false ? 'sub_text fw-600 red' : 'sub_text  fw-600 grey'
    },

  ]

  return (
    <>
      <div className="application-top-card">
        {!editMode ? (<>
          <div className="application-top-card-left">
            <div className="flexRowStart gap-2">
              <p className="normal_text black">Template Name*  </p>
              <p className="main_heading black">{postData?.name} </p>
              <p className="sub_text green">{postData?.isInUse ? "In use" : ""}</p>
             {!previewApplication && (
            <AddTraspButton
            width={0}
            bname={undefined}
            imageValue={GetImages.GreyPencilEdit}
            iconPosition={"right"}
            onClick={() => setEditMode(true)}
            border={false}
          />
             )}
              
            </div>
            <p className="sub_heading black">Template Description</p>
            <p className="normal_text black fst-italic">{postData?.description}</p>
          </div>
          <div className="application-top-card-right">
            <>
              <div className="actionsDropdownContainer clickable">
                < CustomDropDownMenu Action={true} Element={<img src={GetImages.BlueChevronDown} alt='BlueChevronDown' />} data={data} />
              </div>             
            </>
          </div>
        </>):( <>
              <div className="application-top-card-edit">
                <div className="FullFlexRowStart gap-5 ">
                  <p className="normal_text black me-3">Template Name* </p>
                  <CustomInputs
                    name={undefined}
                    type={FORM_CONTROLFIELD_TYPES?.TEXT}
                    value={postData?.name}
                    // labelText={"Template Name*"}
                    isFormControl
                    onChange={(e) => {
                      setPostData({ ...postData, name: e.target.value })
                    }}
                    disabled={undefined}
                    placeholder={undefined}
                    myClassName={undefined}
                    isError={false}
                    errorMessages={undefined}
                     width={"226px"}
                  />
                </div>
                <div className="FullFlexRowStart gap-2 ">
                  <div className="flexRowStart gap-2">
                  <p className="sub_heading black ">Template Description </p>
                  <CustomInputs
                    name={undefined}
                    type={FORM_CONTROLFIELD_TYPES?.TEXT}
                    value={postData?.description}
                    // labelText={"Template Description:"}
                    isFormControl
                    onChange={(e) => {
                      setPostData({ ...postData, description: e.target.value })
                    }}
                    disabled={undefined}
                    placeholder={undefined}
                    myClassName={undefined}
                    isError={false}
                    errorMessages={undefined}
                    width={"423px"}
                  />
                  </div>
                  <AddButtonWithArrow
                  onClick={handleSave} buttonname={"Save"}                  />
                </div>
              </div>
            </>)}
      </div>
    </>
  );
};

export default QuestionHeader;
