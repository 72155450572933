import { DocumentService } from "@propertelligent/client-api";
import { GetImages } from "../../../utils/GetImages";
import { convertStreamAndDownload } from "../../../utils/helper";
import apiHelper from "../../../utils/apiHelper";

const DocumentDetails = ({ documentId }) => {
  const handleDownload = () => {
    apiHelper(
      DocumentService.getApiDocumentBase641,
      { showNotification: false },
      documentId
    )
      .then((docRes) => {
        //Conversion alogorithm
        const format = getFileTypeFromBase64(docRes?.data?.success);
        convertStreamAndDownload(
          docRes?.data?.success,
          `LeaseAgreement.${format}`
        );
      })
      .catch((docErr) => {
        console.error(docErr);
      });
  };

  const getFileTypeFromBase64 = (base64String) => {
    const jpegBase64Prefix = "/9j/";
    const pdfBase64Prefix = "JVBERi0xL";
    const pngBase64Prefix = "iVBORw0KG";

    if (base64String.startsWith(jpegBase64Prefix)) {
      return "jpeg";
    } else if (base64String.startsWith(pdfBase64Prefix)) {
      return "pdf";
    } else if (base64String.startsWith(pngBase64Prefix)) {
      return "png";
    }
    return null;
  };

  return (
    <div className="duePaymentSection">
      <p className="sub_heading black text-start pt-4 mb-3">Documents</p>
      <div className="px-3">
        {documentId !== null ? (
          <div className="card" style={{ width: "14rem" }}>
            <img
              src={GetImages.pdfImage}
              className="card-img-top"
              alt="pdf"
            />
            <div className="card-body d-flex justify-content-between">
              <p className="card-text normal_text grey_subtext1">Lease Agreement</p>
              <img
                src={GetImages.blueDownloadIcon}
                className="clickable"
                alt="download"
                style={{ width: "20px", height: "20px" }}
                onClick={handleDownload}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DocumentDetails;
