import useInfiniteScroll from 'react-infinite-scroll-hook';
import { GetImages } from '../../utils/GetImages'
import ServiceIcon from './serviceTypeImage'
import EmptyData from './EmptyData';
import SimpleSpinner from '../loader';

const PayeeAccountCard = (dataHookState) => {
    const loadMoreRecords = () => {
        if (!dataHookState.loading) {
            dataHookState.fetchData(dataHookState.currentPage + 1);
        }
    };

    const [sentryRef] = useInfiniteScroll({
        loading: dataHookState.loading,
        hasNextPage: dataHookState.hasMore,
        onLoadMore: loadMoreRecords,
        rootMargin: "0px 0px 100px 0px",
    });

    return (
        <div className='payee_accounts_list'>
            {(dataHookState?.rows?.length == 0 && !dataHookState.loading) ? (
                <EmptyData
                    mainText={"No Contractor Found"}
                    subText={undefined}
                    button={undefined}
                    ImageSrc={GetImages.NoTenantFound}
                />
            ) : (
                dataHookState?.rows?.map((item, i) => {
                    return (
                        <div key={item.id} className="paymentCardContainer">
                            <div className="paymentCardLeft">
                                <div className="imgcommonstyle">
                                    <ServiceIcon serviceType={item?.PaymentTypeId} />
                                </div>

                                <div className="paymentCard">
                                    <div className="propertyCardDetails">
                                        <div className="d-flex align-items-center">
                                            <span className="normal_text fw-500">
                                                {item.accountName}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="sub_text grey_subtext1">
                                                <p> {item.formatted?.PayeeAccountTypeId || 'N/A'}</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex gap-3'>
                                <img src={GetImages.BlueEditIcon} className="clickable"
                                    onClick={() => {
                                        dataHookState.setShowModal(true)
                                        dataHookState.setIsEdit(true)
                                        dataHookState.setId(item.id)
                                    }}
                                />
                                <img src={GetImages.ReportDeleteIcon} className="clickable"
                                    onClick={() => {
                                        dataHookState.setId(item.id)
                                        dataHookState.setShowConfirmation(true)
                                        dataHookState.setAccountName(item?.accountName)
                                    }} />
                            </div>
                        </div>
                    );
                })
            )}
            {dataHookState.loading && (
                <>
                    <SimpleSpinner
                        myClassName={dataHookState?.rows?.length == 0 ? "Icon50" : "Icon40"}
                    />
                </>
            )}
            <div ref={sentryRef} />
        </div>
    )
}

export default PayeeAccountCard