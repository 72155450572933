import { Row, Col, Button } from 'react-bootstrap';
import GenericSearchAbleDropdown from './GenericSearchAbleDropdown';
import CustomInputs from './Inputs';
import { FORM_CONTROLFIELD_TYPES } from '../../constants/constants';
import { useError } from '../../utils/context/ErrorContext';
import { GetImages } from '../../utils/GetImages';

const DynamicAddMoreForm = ({ headers, data, setData, initialOptions, handleAddPayment, id = undefined }) => {
  const { errors, setErrors, errorMessages, setErrorMessages } = useError();

  const dataArray = Array.isArray(data) ? data : data ? [data] : [];

  const handleDeletePayment = (id) => {
    if (Array.isArray(dataArray) && dataArray.length > 1) {
      setData(dataArray.filter(payment => payment?.id !== id));
    }
  };

  const handleChange = (id, field, value, index) => {
    if (Array.isArray(dataArray)) {
      setData(dataArray.map(payment =>
        payment?.id === id ? { ...payment, [field]: value } : payment
      ));
      const errorKey = `${field}-${index}`;
      setErrors((prevErrors) => ({ ...prevErrors, [errorKey]: false }));
      setErrorMessages((prev) => ({ ...prev, [errorKey]: "" }));
    }
  };

  return (
    <>
      <div className='dynamicAddMoreForm'>
        <Row className="align-items-center justify-content-start mb-3 paymentRow normal_text">
          {headers && headers.map((header, index) => (
            <Col key={index} md={2}>{header}</Col>
          ))}
        </Row>

        {Array.isArray(dataArray) && dataArray.length > 0 ? (
          dataArray.map((payment, index) => (
            <Row key={payment?.id} className="align-items-baseline justify-content-start paymentRow">
              {headers.includes("Payment Type") && (
                <Col md={2}>
                  <GenericSearchAbleDropdown
                    fieldName={"name"}
                    initialOptions={initialOptions}
                    name={"paymentType"}
                    isFormControl={true}
                    value={payment?.paymentType}
                    onChange={(e) => handleChange(payment?.id, 'paymentType', e.target.value, index)}
                    placeholder="Select an Option"
                    myClassName="normal_text grey_subtext1"
                    style={{ height: "46px" }}
                    isError={errors[`paymentType-${index}`]}
                    errorMessage={errorMessages[`paymentType-${index}`]}
                  />
                </Col>
              )}
              {headers.includes("Amount ($)") && (
                <Col md={2}>
                  <CustomInputs
                    name={"amount"}
                    type={FORM_CONTROLFIELD_TYPES.NUMBER}
                    value={payment?.amount}
                    isFormControl={true}
                    currencySymbol="$"
                    onChange={(e) => handleChange(payment?.id, 'amount', e.target.value, index)}
                    placeholder="Enter Amount"
                    myClassName="containerTextField normal_text"
                    isError={errors[`amount-${index}`]}
                    errorMessages={errorMessages[`amount-${index}`]}
                  />
                </Col>
              )}
              {headers.includes("Due Date") && (
                <Col md={2}>
                  <CustomInputs
                    name={"startDate"}
                    type={FORM_CONTROLFIELD_TYPES.DATE}
                    isFormControl={true}
                    value={payment?.dueDate || null}
                    onChange={(e) => {
                      handleChange(payment?.id, 'dueDate', e.target.value, index)
                    }}
                    placeholder="Select a Date"
                    myClassName="containerTextField normal_text"
                    isError={errors[`dueDate-${index}`]}
                    errorMessages={errorMessages[`dueDate-${index}`]}
                  />
                </Col>
              )}
              {headers.includes("Grace Period") && (
                <Col md={2}>
                  <CustomInputs
                    name={"graceperiods"}
                    type={FORM_CONTROLFIELD_TYPES.NUMBER}
                    value={payment?.gracePeriods}
                    isFormControl={true}
                    currencySymbol="Days"
                    onChange={(e) => handleChange(payment?.id, 'gracePeriods', e.target.value, index)}
                    placeholder="0"
                    myClassName="containerTextField normal_text"
                    isError={errors[`gracePeriods-${index}`]}
                    errorMessages={errorMessages[`gracePeriods-${index}`]}
                  />
                </Col>
              )}
              {headers.includes("Description") && (
                <Col md={3}>
                  <CustomInputs
                    name={"description"}
                    type={FORM_CONTROLFIELD_TYPES.TEXT}
                    value={payment?.description}
                    isFormControl={true}
                    onChange={(e) => handleChange(payment?.id, 'description', e.target.value, index)}
                    placeholder="Enter Description"
                    myClassName="containerTextField normal_text"
                    isError={errors[`description-${index}`]}
                    errorMessages={errorMessages[`description-${index}`]}
                  />
                </Col>
              )}
              {dataArray.length > 1 && (
                <Col style={{ width: "fit-content" }} md={1}>
                  <img src={GetImages.DeleteRedBg} alt='' onClick={() => handleDeletePayment(payment.id)} />
                </Col>
              )}
            </Row>
          ))
        ) : (
          <p>No data available</p>
        )}
      </div>
      {
        !id ? (
          <Button variant="link" onClick={handleAddPayment} className="p-0 text-decoration-none sub_heading blue pt-3">
            Add More
          </Button>
        ) : ""
      }
    </>
  );
};

export default DynamicAddMoreForm;


