import { DocumentEntityTypesEnum, DocumentService, DocumentTypesEnum } from '@propertelligent/client-api';
import React, { useEffect, useState } from 'react'
import { GetImages } from '../../../utils/GetImages';

const ContactInfo = ({ BasicDetails, leaseApplication }) => {
  if (!BasicDetails) return null;
  const fieldMappings = { fullName: "Full Name", email: "Email Address", phoneNumber: "Phone Number", };
  const [documents, setDocuments] = useState([])
  const getDocuments = (docType) => {
    if (leaseApplication) {
      DocumentService.getApiDocumentList(Number(leaseApplication), DocumentEntityTypesEnum.LeaseApplication,
        docType)
        .then((res) => {
          let documentArray = res?.success;
          if (documentArray) {
            // Create an array of promises for fetching base64 data
            const promises = documentArray.map((item) => {
              if (["png", "jpg", "jpeg"].includes(item?.fileName?.split(".")[1].toLowerCase())) {
                return DocumentService.getApiDocumentBase641(item.id).then((res) => {
                  item["docData"] = "data:image/png;base64," + res.success;
                }).catch((error) => { });
              }
              return Promise.resolve();
            });
            Promise.all(promises)
              .then(() => {
                setDocuments(documentArray);
              }).catch((error) => { });
          }
        }).catch((error) => { });
    }
  };
  useEffect(() => {
    getDocuments(DocumentTypesEnum.Identification)
  }, [leaseApplication])

  return (
    <div className='flexColumn'>
      <div className="Employment_history border-0 m-0">
        {Object.keys(fieldMappings)?.map((key, idx) => (
          <div key={idx}>
            <p className="normal_text fw-bold black">{fieldMappings[key]}</p>
            <p className="normal_text">
              {key === "fullName"
                ? `${BasicDetails?.firstName || ""} ${BasicDetails?.lastName || ""}`.trim() || "N/A"
                : BasicDetails?.[key] || "N/A"}
            </p>
          </div>
        ))}

      </div>
        {documents?.length > 0 && (
      <div className="FlexRow px-3">
        <p className='normal_text py-2'>Documents</p>
          <div className="fileDeleteSection">
            {documents?.map((doc, index) => (
              <div
                key={index}
                className="d-flex gap-2 align-items-center "
              >
                <img width={20} height={24} src={GetImages.pdfImage} alt="Document" />
                <p className="licenseDocumentText featureType">{doc?.fileName}</p>
              </div>
            ))}
          </div>
      </div>
        )}
    </div>
  )
}  

export default ContactInfo