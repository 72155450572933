import Spinner from "react-bootstrap/Spinner";

function SimpleSpinner({ myClassName = "Icon50" }) {
  return (
    <div className={`d_grid justify-content-center align-items-center mx-auto my-0 mh-100`}>
      <Spinner animation="border" className={`custom-spinner ${myClassName}`} />
    </div>
  );
}

export default SimpleSpinner;