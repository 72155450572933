import React, { useCallback, useEffect, useRef, useState } from 'react'
import RequestBreadcrumb from '../../../common/RequestBreadcrumb'
import { TemplateEntityTypesEnum, TemplateService, TemplateTypeEnum, LeaseAgreementTemplateSectionVM, LeaseAgreementDataAttributesEnum } from '@propertelligent/client-api'
import { UserLoginData } from '../../../common/charts/Enums'
import QuestionHeader from '../QuestionHeader'
import "../AddTemplate/AddTemplate.css"
import { useNavigate, useParams } from 'react-router'
import apiHelper from '../../../../utils/apiHelper'
import AddTraspButton from '../../../firstTimeUserExperience/commonCom/AddTraspButton'
import AddButtonWithArrow from '../../../firstTimeUserExperience/commonCom/AddButtonWithArrow'
import { GetImages } from '../../../../utils/GetImages'
import CustomInputs from '../../../common/Inputs'
import { FORM_CONTROLFIELD_TYPES } from '../../../../constants/constants'
import "./LeaseTemplate.css"
import CustomEditor from './CustomEditor'
import EmptyData from '../../../common/EmptyData'
import { convertENUMtoJSONArray } from '../../../../utils/helper'
import ShowLeaseAgreement from '../../../tenants/LeaseAgreement/ShowLeaseAgreement'

const AddLeaseAgreementTemplate = () => {
  const navigate = useNavigate();
  const editorRef = useRef([]);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [previewApplication, setPreviewApplication] = useState(false);
  const [editSection, setEditSection] = useState<Number>();
  const [postData, setPostData] = useState<any | undefined>(
    {
      name: "form 1 - draft ",
      description: "Lease agreement for prospective tenants",
      templateTypeId: TemplateTypeEnum.LeaseAgreement,
      entityTypeId: TemplateEntityTypesEnum.Organization,
      entityId: parseInt(localStorage.getItem(UserLoginData.organization_id)),
      isInUse: false,
      leaseAgreementTemplateSections: [] as LeaseAgreementTemplateSectionVM,
    })
  const AddSection = () => {
    const newSection = { sectionName: `Section ${postData.leaseAgreementTemplateSections.length + 1}`, sectionData: "" };
    setPostData(prevData => ({
      ...prevData,
      leaseAgreementTemplateSections: [...prevData.leaseAgreementTemplateSections, newSection]
    }));
  }

  const copySection = useCallback(
    (index) => {
      const sectionToCopy = { ...postData.leaseAgreementTemplateSections[index] };
      sectionToCopy.sectionName = `${sectionToCopy.sectionName} (Copy)`;
      setPostData((prevData) => ({
        ...prevData,
        leaseAgreementTemplateSections: [
          ...prevData.leaseAgreementTemplateSections,
          sectionToCopy,
        ],
      }));
    },
    [postData]
  );
  const deleteSection = useCallback(
    (index) => {
      setPostData((prevData) => ({
        ...prevData,
        leaseAgreementTemplateSections: prevData.leaseAgreementTemplateSections.filter((_, idx) => idx !== index),
      }));
    },
    [postData]
  );
  useEffect(() => { if (id) { getTempleteData() } }, [id, editorRef]);
  const toggleSection = (index) => {
    setPostData(prevPostData => ({
      ...prevPostData,
      leaseAgreementTemplateSections: prevPostData.leaseAgreementTemplateSections?.map((section, idx) =>
        idx === index ? { ...section, isOpen: !section.isOpen } : section
      ),
    }));
  };
  const toggleAllSections = () => {
    setPostData(prevPostData => {
      const areAllExpanded = prevPostData.leaseAgreementTemplateSections?.every(section => section.isOpen);
      return {
        ...prevPostData,
        leaseAgreementTemplateSections: prevPostData.leaseAgreementTemplateSections?.map(section => ({
          ...section,
          isOpen: !areAllExpanded,
        })),
      };
    });
  };

  const handleSectionChange = useCallback((id, key, value) => {
    setPostData(prevData => {
      const updatedSections = prevData.leaseAgreementTemplateSections?.map((section, idx) =>
        idx === id ? { ...section, [key]: value } : section
      );
      return {
        ...prevData,
        leaseAgreementTemplateSections: updatedSections,
      };
    });
  }, [postData]);

  const dataAttribute = convertENUMtoJSONArray(LeaseAgreementDataAttributesEnum);
  const enrichedData = dataAttribute.map(item => {
    let icon;
    if (/(Date|Day|Period)/i.test(item.name)) {
      icon = GetImages.BlueCalendar;
    } else if (/(Rent|Fee)/i.test(item.name)) {
      icon = GetImages.blueDollarIcon;
    } else if (/(Deposit|LessorName|LesseeName)/i.test(item.name)) {
      icon = GetImages.selectedTenantIconMenu;
    } else if (/Address/i.test(item.name)) {
      icon = GetImages.DarkBlueHome;
    } else {
      icon = GetImages.selectedTenantIconMenu;
    }
    return {
      ...item,
      icon,
      placeholder: `[ ${item.name} ]`,
    };
  });

  const buttonClose = () => { navigate("/organization/templates"); }
  const getTempleteData = () => {
    apiHelper(
      TemplateService.getApiTemplate,
      { showNotification: false },
      Number(id)
    ).then((res) => {
      if (res?.data?.success) {
        const templateData = res.data.success;
        setPostData(templateData);
      }
    }).catch((err) => { console.error("Error fetching template data:", err); });
  };
  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      const apiCall = id
        ? apiHelper(TemplateService.putApiTemplate, {
          successMessage: "Template Updated",
          failureMessage: "Unable to Add Template",
          showNotification: true,
        }, Number(id), postData)
        : apiHelper(TemplateService.postApiTemplate, {
          successMessage: "Template Created",
          failureMessage: "Unable to updated Template",
          showNotification: true,
        }, postData)
      const res = await apiCall;
      if (res?.data?.success) { navigate("/organization/templates"); }
    } catch (error) { } finally { setIsLoading(false); }
  }, [id, postData, navigate]);

  const duplicateTemplete = () => {
    if (id) {
      apiHelper(TemplateService.postApiTemplateDuplicate, {
        successMessage: "Template Duplicated",
        failureMessage: "Unable to Duplicate Template",
        showNotification: true,
      }, Number(id)).then((res) => {
        if (res?.data?.success) { navigate(`/organization/templates/lease-agreement/${res?.data?.success.id}`); }
      }).catch((err) => { });
    }
  };

  const deleteTemplete = () => {
    if (id) {
      apiHelper(TemplateService.deleteApiTemplate, {
        successMessage: "Template Deleted",
        failureMessage: "Unable to Delete Template",
        showNotification: true,
      }, Number(id))
        .then((res) => {
          navigate("/organization/templates");
        })
        .catch((err) => { });
    }
  };
  const buttonConfigs = [
    {
      imageValue: GetImages.BluePencilEdit,
      onClick: (index) => setEditSection(index),
    },
    {
      imageValue: GetImages.deleteIconRed,
      onClick: (index) => deleteSection(index),
    },
    {
      imageValue: GetImages.CopyBlue,
      onClick: (index) => copySection(index),
    },
    {
      imageValue: GetImages.BlueChevronDown,
      onClick: (index) => toggleSection(index),
      dynamicClass: (isOpen) => (isOpen ? 'chevron-rotated' : ''),
    },
  ];

  return (
    <div className="application-section">
      <RequestBreadcrumb firstName={"Template"} link={"/organization/templates"} currentStep={"Lease Agreement template"} />
      {!previewApplication ?
        <>
          <QuestionHeader postData={postData} setPostData={setPostData} duplicateTemplete={duplicateTemplete} deleteTemplete={deleteTemplete} />
          <div className='lease-agreement-section p-2'>
            <div className="lease-agreement-section-left">
              <div className='lease-agreement-section-left-header'>
                <div className='d-flex justify-content-between pb-2'>
                  <p className='sub_heading '>Lease Agreement Sections</p>
                  <AddTraspButton
                    width={0}
                    bname={"Expand All"}
                    imageValue={GetImages?.BlueChevronDown}
                    iconPosition={"right"}
                    onClick={toggleAllSections}
                    border={false}
                    // IconClassName={"iconstyle12"}
                    IconClassName={postData.leaseAgreementTemplateSections?.every((section) => section.isOpen)
                      ? "chevron-rotated iconstyle12"
                      : "iconstyle12"}
                    className={"sub_text fw-600 blue gap-2"
                    }
                  />
                </div>
                {postData?.leaseAgreementTemplateSections?.length !== 0 ?
                  <>
                    <div className='lease-agreement-section-left-item'>
                      {Array.isArray(postData?.leaseAgreementTemplateSections) && postData?.leaseAgreementTemplateSections?.map((section, index) => (
                        <div key={index} className="accordion">
                          <div className="accordion-header">
                            {(!section?.isOpen || editSection !== index) ? <p className='sub_heading black'>{section?.sectionName}</p> :
                              <> {(editSection == index && section?.isOpen) && (
                                <CustomInputs
                                  name={undefined}
                                  type={FORM_CONTROLFIELD_TYPES.TEXT}
                                  value={section?.sectionName}
                                  labelText={undefined}
                                  isFormControl
                                  onChange={(e) => handleSectionChange(index, "sectionName", e.target.value)}
                                  onBlur={() => setEditSection(null)}
                                  containerClassName="w-100  "
                                  placeholder="Add section name"
                                  id="customInputBorder"
                                  myClassName="m-0 border-0 outline-0 main_heading black"
                                  isError={undefined}
                                  errorMessages={undefined}
                                />
                              )}
                              </>}
                            <div className='flexRow'>
                              <div className="options_buttons">
                                {buttonConfigs?.map((button, i) => (
                                  <AddTraspButton
                                    key={i}
                                    width={0}
                                    bname={undefined}
                                    imageValue={button?.imageValue}
                                    iconPosition={"right"}
                                    onClick={() => button?.onClick(index)}
                                    IconClassName={"iconstyle12"}
                                    border={false}
                                    className={button.dynamicClass ? button.dynamicClass(section.isOpen) : ''}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>

                          {section?.isOpen && (
                            <div className="accordion-content" style={{ padding: "10px" }}  >
                              <CustomEditor
                                enrichedData={enrichedData}
                                section={section?.sectionData}
                                setPostData={setPostData}
                                postData={postData}
                                index={index} />
                            </div>)}
                        </div>
                      ))}
                      <AddTraspButton
                        bname={"Add Section"}
                        iconPosition={"right"}
                        imageValue={GetImages.BlueAdd}
                        onClick={AddSection}
                        border={true} />
                    </div>
                  </>
                  : <EmptyData mainText={"No Section Available"} button={<AddTraspButton bname={"Section"} iconPosition={"right"}
                    imageValue={GetImages.BlueAdd} onClick={AddSection} border={true} />} />}

              </div>
            </div>
            <div className="lease-agreement-section-right">
              <div className='data-attribute px-3 py-2'>
                <p className="main_heading">Data Attributes</p>
                <p className='normal_text'><i>Click below attribute to add in the agreement:</i></p>
              </div>
              <div className='lease-agreement-section-right-menu-main'>
                {enrichedData?.map((attr, i) => (
                  <div className='lease-agreement-section-right-menu'
                    draggable
                    onDragStart={(e: React.DragEvent) => {
                      e.dataTransfer.clearData();
                      e.dataTransfer.setData("text", attr?.placeholder);
                      e.dataTransfer.setData("application/json", JSON.stringify(attr));
                    }}
                    key={i}>
                    <img className='Icon20' alt={attr?.name} src={attr?.icon} />
                    <p className='normal_text black'>{attr?.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="application-section-bttom-view1">
            <AddTraspButton type="button" bname={"Cancel"} onClick={buttonClose} border={true} />
            <AddButtonWithArrow buttonname={isLoading ? "Saving..." : "Preview"} onClick={() => setPreviewApplication(true)} />
          </div>
        </>
        :
        <ShowLeaseAgreement
          postLeaseAgreement={undefined}
          setPostLeaseAgreement={undefined}
          onCancel={() => setPreviewApplication(false)}
          onClick={handleSubmit}
          buttonName={"Save"}
          role="tenant"
          selectedTemplateid={undefined}
          userData={undefined} selectedTemplates={postData}
        />
      }
    </div>
  )
}

export default AddLeaseAgreementTemplate

