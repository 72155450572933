import { AnalyticsService } from "@propertelligent/client-api";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { UserLoginData } from "../components/common/charts/Enums";
import EmptyData from "../components/common/EmptyData";
import apiHelper from "../utils/apiHelper";

const VacancyRate = ({ loadingStates, setLoadingStates }) => {
  const organization_id = Number(
    localStorage.getItem(UserLoginData.organization_id)
  );
  const [vacancyData, setVacancyData] = useState([]);
  const [months, setMonths] = useState<any>(12);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    getVacancyData();
  }, []);

  useEffect(() => {
    getVacancyData();
  }, [months]);

  const getVacancyData = () => {
    apiHelper(AnalyticsService.getApiAnalyticsOrganizationVacancyrates, { showNotification: false }, organization_id, months)
      .then((res) => {
        if (res?.data?.success) {
          setLoadingStates((loadingStates) => ({
            ...loadingStates,
            vacancyRateLoading: false,
          }));
          const convertedData = res?.data?.success?.dataSet.map(convertToObject);
          setVacancyData(convertedData);
        }
      })
      .catch((error) => { });
  };

  const convertToObject = (obj) => ({
    name: `${new Date(obj.year, obj.month - 1).toLocaleString("default", {
      month: "short",
    })} ${obj.year.toString().slice(2)}`,
    Vacant: obj.unOccupiedUnits,
    Occupied: obj.occupiedUnits,
  });

  const handleBarMouseOver = (e) => {
    if (e.payload) {
      const barWidth = 24; // Width of the bar
      const xPos = e.background.x - 100; // Adjust tooltip position to center it on the bar
      setTooltipPosition({ x: xPos, y: e.background.y - 20 });
    }
  };

  const CustomTooltip = ({
    active,
    payload,
  }: {
    active?: boolean;
    payload?: any[];
  }) => {
    if (active && payload && payload.length) {
      const data1 = payload[1];
      const data2 = payload[0];
      return (
        <div className="toolTipContainer">
          <div>
            <p className="sub_text fw-bold">{`${data1.payload.name}`}</p>
          </div>
          <div className="d-flex gap-2 align-items-center vacancyDataContainer ">
            <div>
              <p className="sub_text mb-2">Occupied units</p>
              <p className="normal_text">{data1.value}</p>
            </div>
            <div>
              <p className="sub_text mb-2">Vacant units</p>
              <p className="normal_text">{data2.value}</p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-0 vacancyRateContainer">
            <p className="sub_text mb-0">Vacancy Rate</p>
            <p className="sub_text fw-bold mb-0 ">0%</p>
          </div>
        </div>
      );
    }

    return null;
  };

  // Helper function to check if all data is zero
  const isDataEmpty = (data) => {
    return data.every((item) => item.Vacant === 0 && item.Occupied === 0);
  };

  return (
    <div>
      <div className="overviewContainerMain">
        <div className="overviewContainerRightSide">
          <div
            style={{ borderBottom: "none" }}
            className="overviewContainerRightSideTopComponent"
          >
            <p className="sub_heading">Vacancy Rate</p>
            <div className="overviewButtons">
              <Form.Select
                className="sub_text  grey_subtext1"
                aria-label="Default select Service Sub Type"
                required
                onChange={(e) => {
                  setMonths(e.target.value);
                }}
                value={months}
              >
                <option value={3}>Last 3 months </option>
                <option value={6}>Last 6 months </option>
                <option value={12}>Last 12 months </option>
              </Form.Select>
            </div>
          </div>
          <div
            className="d-flex justify-content-center firstChartStyles"
            style={{ width: "100%", height: 250 }}
          >
            {vacancyData.length > 0 && !isDataEmpty(vacancyData) ? (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  height={300}
                  data={vacancyData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  maxBarSize={24}
                >
                  <XAxis className="sub_text grey_subtext1" dataKey="name">
                    <Label
                      className="sub_text grey_subtext1"
                      value="Time"
                      position="bottom"
                      offset={-5}
                    />
                  </XAxis>
                  <YAxis className="sub_text grey_subtext1">
                    <Label
                      value="Number Of Units"
                      position="left"
                      angle={-90}
                      offset={-15}
                      style={{ textAnchor: "middle" }}
                      className="sub_text grey_subtext1"
                    />
                  </YAxis>
                  <Tooltip
                    position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
                    content={<CustomTooltip />}
                    cursor={false}
                  />
                  <Legend
                    className="sub_text grey_subtext1"
                    order="reverse"
                    iconType="circle"
                    iconSize={8}
                    wrapperStyle={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      paddingLeft: 60,
                      fontFamily: "Instrument Sans",
                      fontSize: 12,
                      fontWeight: 400,
                    }}
                  />
                  <Bar
                    dataKey="Vacant"
                    stackId="a"
                    fill="#D61F69"
                    maxBarSize={24}
                    onMouseMove={handleBarMouseOver}
                  />
                  <Bar
                    dataKey="Occupied"
                    stackId="a"
                    fill="#057A55"
                    maxBarSize={24}
                    radius={[5, 5, 0, 0]}
                    onMouseMove={handleBarMouseOver}
                  />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <EmptyData
                mainText={"No Data Found"}
                subText={undefined}
                button={undefined}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacancyRate;
