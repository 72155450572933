import React, { useCallback, useEffect } from "react";
import "./tenantonboard.css";
import { OnboardingStepsEnums } from "../../../constants/onboarding";
import CustomInputs from "../../common/Inputs";
import DisplayNamewithLeftBorder from "../../common/DisplayNamewithLeftBorder";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";
import { useError } from "../../../utils/context/ErrorContext";
import DateHelper from "../../../utils/DateHelper";

const EmploymentHistory: React.FC<any> = ({
  formData,
  setFormData,
  requiredRange,
 EmploymentHistory
}: {
  formData: any;
  setFormData: any;
  requiredRange:any;
 EmploymentHistory:any
}) => {
  const {errors,  setErrors, errorMessages ,setErrorMessages} = useError()

  const setdata = ()=>{
    if (!EmploymentHistory ) return;    
    setFormData((prevFormData) => ({
      ...prevFormData,
      EmploymentHistory: prevFormData?.EmploymentHistory?.length
          ? prevFormData?.EmploymentHistory
          : Array.from({ length:EmploymentHistory?.requiredRange }, () => ({
              name: "",
              startDate: "",
              endDate: "",
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
            })),
    }));
  }
  useEffect(() => { setdata()}, [EmploymentHistory]);

  const handleContactDetailChange = useCallback((index: number, key: string, value: string) => {
    const updatedContactDetails = [...formData];
    updatedContactDetails[index][key] = value;
    setFormData({ ...formData, EmploymentHistory: updatedContactDetails });
    const errorKey = `${key}[${index}]`;
    setErrors((prevErrors) => ({ ...prevErrors, [errorKey]: false }));
    setErrorMessages((prevMessages) => ({ ...prevMessages, [errorKey]: "" }));
  }, [formData])

  const generateBasicDetails = (index: number) => [
    {
      id: 1,
      name: "firstName",
      labelText: "First Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData[index].firstName,
      placeholder: "Enter First Name",
      myClassName: "containerTextField",
      isError: errors[`firstName[${index}]`],
      errorMessages: errorMessages?.firstName,
    },
    {
      id: 2,
      name: "lastName",
      labelText: "Last Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData[index].lastName,
      placeholder: "Enter Last Name",
      myClassName: "containerTextField",
      isError: errors[`lastName[${index}]`],
      errorMessages: errorMessages?.lastName,
    },
    {
      id: 3,
      name: "email",
      labelText: "Email",
      type: FORM_CONTROLFIELD_TYPES.EMAIL,
      value: formData[index].email,
      placeholder: "Enter Email",
      myClassName: "containerTextField",
      isError:  errors[`email[${index}]`],
      errorMessages: errorMessages?.email,
    },
    {
      id: 4,
      name: "phoneNumber",
      labelText: "Phone",
      type: FORM_CONTROLFIELD_TYPES.TELEPHONE,
      value: formData[index].phoneNumber,
      placeholder: "Enter Phone No",
      myClassName: "containerTextField",
      isError: errors[`phoneNumber[${index}]`],
      errorMessages: errorMessages?.phoneNumber,
      maxLength: 10
    },
  ];

  const employeeHistory = (index: number) => [
    {
      id: 1,
      name: "name",
      labelText: "Employer Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData[index].name,
      placeholder: "Enter Employer Name",
      myClassName: "containerTextField",
      isError: errors[`name[${index}]`],
      errorMessages: errorMessages?.name,
    },
    {
      id: 2,
      name: "startDate",
      labelText: "Start Date",
      type: FORM_CONTROLFIELD_TYPES.DATE,
      value: DateHelper.convertUTCtoDateTime2(formData[index].startDate),
      placeholder: "startDate",
      myClassName: "containerTextField",
      isError: errors[`startDate[${index}]`],
     errorMessages: errorMessages?.startDate,
    },
    // },
    {
      id: 3,
      name: "endDate",
      labelText: "End Date",
      type: FORM_CONTROLFIELD_TYPES.DATE,
      value: DateHelper.convertUTCtoDateTime2(formData[index].endDate),
      placeholder: "endDate",
      myClassName: "containerTextField",
      isError: errors[`endDate[${index}]`],
      errorMessages: errorMessages?.endDate,
    },
  ]

  return (
    <>
      <div className="onboarding-stepper">
        <div className="onboarding-steppercard">
          <p className="main_heading pb-2">Employment History</p>
          <div className="onboardingstepper-form flexColumn gap-3">
            {formData?.map((_, index) => (
              <div className="flexColumn gap-3 py-3 " key={`${index}`}>
                <DisplayNamewithLeftBorder HeaderName={"Add previous employment history"} />
                <div className="basic-form" >
                  {employeeHistory(index)?.map((item) => (
                    <div key={`${item.id}`}>
                      <CustomInputs
                        name={`${item.name}-${index}`}
                        type={item.type}
                        value={item.value}
                        labelText={item.labelText}
                        isFormControl={true}
                        onChange={(e) => handleContactDetailChange(index, item.name, e.target.value)}
                        placeholder={item.placeholder}
                        myClassName={item.myClassName}
                        isError={item.isError}
                        errorMessages={item.errorMessages}
                      // width={"322px"}
                      />
                    </div>
                  ))
                  }
                </div>
                <DisplayNamewithLeftBorder HeaderName={"Contact Details"} />
                <div className={`social-worker`}>
                  {generateBasicDetails(index).map((item) => (
                    <div key={`${item.id}-${index}`}>
                      <CustomInputs
                        name={`${item.name}-${index}`}
                        type={item.type}
                        value={item.value}
                        labelText={item.labelText}
                        isFormControl={true}
                        onChange={(e) => handleContactDetailChange(index, item.name, e.target.value)}
                        placeholder={item.placeholder}
                        myClassName={item.myClassName}
                        isError={item.isError}
                        errorMessages={item.errorMessages}
                        maxLength={item?.maxLength}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmploymentHistory;


