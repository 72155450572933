import { useEffect, useRef, useState } from "react";
import "./LeaseTemplate.css";
import AddTraspButton from "../../../firstTimeUserExperience/commonCom/AddTraspButton";
import { GetImages } from "../../../../utils/GetImages";

const CustomEditor = ({ section, setPostData, index, postData, enrichedData }) => {
  const editorRef = useRef(null);
  const [content, setContent] = useState("");

  useEffect(() => {
    if (section) {
      setContent(section);
    }
  }, [section]);

  const saveCursorPosition = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0); // Save the current selection range
    }
    return null;
  };

  const restoreCursorPosition = (range) => {
    const selection = window.getSelection();
    if (range && selection) {
      selection.removeAllRanges(); // Clear existing selection
      selection.addRange(range);  // Restore the saved range
    }
  };

  const handleInput = (e) => {
    setPostData((prevData) => ({
      ...prevData,
      leaseAgreementTemplateSections: prevData.leaseAgreementTemplateSections.map((section, idx) =>
        idx === index ? { ...section, sectionData: e.target.innerHTML } : section
      ),
    }));
    setContent(e.target.innerHTML);
  };

  useEffect(() => {
    const savedCursorPosition = saveCursorPosition();
    if (editorRef.current && content !== editorRef.current.innerHTML) {
      editorRef.current.innerHTML = content;
    }
    restoreCursorPosition(savedCursorPosition);
  }, [content]);

  const fontSizes = Array.from({ length: 14 }, (_, i) => i + 1); // Sizes 1 to 14
  const applyCommand = (command, value = null) => {
    document.execCommand(command, false, value);
  };

  const changeFontSize = (e) => {
    const size = e.target.value;
    if (size) applyCommand("fontSize", size);
  };

  useEffect(() => {
    const editor = editorRef.current;
    const handleClick = (e) => {
      if (e.target && e.target.classList.contains("close-btn")) {
        const wrapper = e.target.parentNode;
        const id = wrapper.getAttribute("data-id");
        wrapper.remove();
        // Update postData state
        setPostData((prevData) => ({
          ...prevData,
          leaseAgreementTemplateSections: prevData.leaseAgreementTemplateSections.map((section, idx) =>
            idx === index
              ? {
                ...section,
                sectionData: editor.innerHTML,
                dataAttributes: section.dataAttributes.filter((attrId) => attrId !== Number(id)),
              }
              : section
          ),
        }));
      }
    };
    editor.addEventListener("click", handleClick);
    return () => {
      editor.removeEventListener("click", handleClick);
    };
  }, [index, setPostData]);

  const handleDrop = (e) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("application/json");
    const draggedObject = JSON.parse(data);
    if (!draggedObject) return;
    const editor = editorRef.current;
    const selection = window.getSelection();
    // Ensure the drop target is inside the contentEditable div
    if (!editor || !editor.contains(e.target)) {
      console.warn("Drop target is outside the editable div");
      return;
    }
    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    if (!range || !editor.contains(range.commonAncestorContainer)) {
      console.warn("Cursor is not inside the editable div");
      return;
    }
    // Create and insert the dropped element
    const wrapper = document.createElement("span");
    wrapper.className = "dropped-item";
    wrapper.setAttribute("data-id", draggedObject?.id);
    const placeholderNode = document.createTextNode(draggedObject?.placeholder || "");
    wrapper.appendChild(placeholderNode);

    // Create the close button
    const closeButton = document.createElement("span");
    closeButton.className = "close-btn";
    closeButton.innerHTML = "&times;"; // Cross symbol
    closeButton.onclick = (event) => {
      event.stopPropagation(); // Prevent the click from triggering other events
      handleRemove(wrapper, draggedObject?.id);
    };
    wrapper.appendChild(closeButton);

    // Insert the wrapper at the cursor position
    range.deleteContents();
    range.insertNode(wrapper);
    range.setStartAfter(wrapper);
    range.setEndAfter(wrapper);
    selection.removeAllRanges();
    selection.addRange(range);

    // Update postData state
    setPostData((prevData) => ({
      ...prevData,
      leaseAgreementTemplateSections: prevData.leaseAgreementTemplateSections.map((section, idx) =>
        idx === index
          ? {
            ...section,
            sectionData: editor.innerHTML,
            dataAttributes: section.dataAttributes
              ? [...section.dataAttributes, Number(draggedObject?.id)]
              : [Number(draggedObject?.id)],
          }
          : section
      ),
    }));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    const editor = editorRef.current;
    const range = document.caretRangeFromPoint(e.clientX, e.clientY);

    if (range && editor.contains(range.commonAncestorContainer)) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  // const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
  //   e.preventDefault(); 
  //   const editor = editorRef.current;
  //   let range: Range | null = null;
  //   if (typeof document.caretRangeFromPoint === "function") {
  //     range = document.caretRangeFromPoint(e.clientX, e.clientY);
  //   } 
  //   else {
  //     const caretPosition = (document as any).caretPositionFromPoint?.(e.clientX, e.clientY);
  //     if (caretPosition) {
  //       range = document.createRange();
  //       range.setStart(caretPosition.offsetNode, caretPosition.offset);
  //     }
  //   }

  //   if (range && editor?.contains(range.commonAncestorContainer)) {
  //     const selection = window.getSelection();
  //     if (selection) {
  //       selection.removeAllRanges(); 
  //       selection.addRange(range); 
  //     }
  //   }
  // };

  const handleRemove = (element, id) => {
    element.remove();
    setPostData((prevData) => ({
      ...prevData,
      leaseAgreementTemplateSections: prevData.leaseAgreementTemplateSections.map((section, idx) =>
        idx === index
          ? {
            ...section,
            sectionData: editorRef.current.innerHTML,
            dataAttributes: section.dataAttributes.filter((attrId) => attrId !== id),
          }
          : section
      ),
    }));
  };

  return (
    <div className="editor-container">
      <div className="toolbar">
        <select className="toolbar-select" onChange={changeFontSize}>
          {fontSizes.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
        <div className="divider"></div>
        <input
          type="color"
          className="toolbar-color-picker"
          onChange={(e) => applyCommand("foreColor", e.target.value)}
        />
        <div className="divider"></div>
        <AddTraspButton
          width={0}
          bname={<b>B</b>}
          textclass="normal_text grey fw-600"
          className="px-1 toolbar-button"
          onClick={() => applyCommand("bold")}
          border={false}
        />
        <AddTraspButton
          width={0}
          bname={<i>I</i>}
          textclass="normal_text grey fw-600"
          className="px-1 toolbar-button"
          onClick={() => applyCommand("italic")}
          border={false}
        />
        <AddTraspButton
          width={0}
          bname={<s>S</s>}
          textclass="normal_text grey fw-600"
          className="p-0"
          onClick={() => applyCommand("strikeThrough")}
          border={false}
        />
        <AddTraspButton
          width={0}
          bname={<u>U</u>}
          className="p-0"
          textclass="normal_text grey fw-600"
          onClick={() => applyCommand("underline")}
          border={false}
        />
        <div className="divider"></div>
        <AddTraspButton
          width={0}
          bname={undefined}
          className="p-0"
          imageValue={GetImages.GreyLeftAlign}
          iconPosition={"right"}
          onClick={() => applyCommand("justifyLeft")}
          border={false}
        />
        <AddTraspButton
          width={0}
          bname={undefined}
          className="p-0"
          imageValue={GetImages.GreyCenterAlign}
          iconPosition={"right"}
          onClick={() => applyCommand("justifyCenter")}
          border={false}
        />
        <AddTraspButton
          width={0}
          bname={undefined}
          className="p-0"
          imageValue={GetImages.GreyRightAlign}
          iconPosition={"right"}
          onClick={() => applyCommand("justifyRight")}
          border={false}
        />
        <div className="divider"></div>
        <AddTraspButton
          width={0}
          bname={undefined}
          className="p-0"
          imageValue={GetImages.GreyNumberList}
          iconPosition={"right"}
          onClick={() => applyCommand("insertOrderedList")}
          border={false}
        />
        <AddTraspButton
          width={0}
          bname={undefined}
          className="p-0"
          imageValue={GetImages.GreyDotList}
          iconPosition={"right"}
          onClick={() => applyCommand("insertUnorderedList")}
          border={false}
        />

      </div>
      <div
        ref={editorRef}
        className="editor"
        contentEditable={true}
        suppressContentEditableWarning={true}
        onInput={handleInput}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        //  onDragOver={(e)=>e.preventDefault()}
        placeholder="Start writing here..."

      />
    </div>
  );
};

export default CustomEditor;



