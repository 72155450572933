import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { GetImages } from '../../utils/GetImages'
import AddTraspButton from '../firstTimeUserExperience/commonCom/AddTraspButton'
import AddButtonWithArrow from '../firstTimeUserExperience/commonCom/AddButtonWithArrow'
import { Col, Row, Form } from 'react-bootstrap'
import CustomInputs from '../common/Inputs'
import { FORM_CONTROLFIELD_TYPES, URLS } from '../../constants/constants'
import PaymentListCard from './PaymentListCard'
import EmptyData from '../common/EmptyData'
import { PaymentContext } from '../../utils/context/PaymentContext'
import PaymentDetail from './Modals/PaymentDetail'
import apiHelper from '../../utils/apiHelper'
import { PaymentService, TenantService } from '@propertelligent/client-api'
import Electrical from '../../assets/electrical_services.svg'
import Rent from "../../assets/rent.svg"
import { UserLoginData } from '../common/charts/Enums'
import { useError } from '../../utils/context/ErrorContext'
import CommonConfirmation from '../common/CommonConfirmation'
import UtilHelper from '../../utils/UtilHelper'

interface PaymentContextType {
    selectedItems: any;
    setSelectedItems: (items: any) => void;
    setSelectedOption: (items: any) => void;
    selectedOption: any;
    selectedOption2: { [key: string]: string };
    payeeAccountsArr: any[];
    payeeAccountsArr2: PayeeAccount[];
    paymentData: any;
    setPaymentData: (data: any) => void;
    entityId: number;
    selectEntityId: number;
    amount: number;
    totalOutStanding: any,
    setTotalOutStanding: any
}

type PayeeAccount = {
    id: number;
    formatted: {
        PaymentTypeId: string;
        Amount: any;
    };
    amount: number;
    description: string;
};

const SettlePayment = () => {
    const location = useLocation();
    const { selectedPayment, paymentMode } = location.state || {};
    const [currentPaymentMode, setCurrentPaymentMode] = useState(paymentMode || 'Payables');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [show, setShow] = useState(false)
    const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
    const [tenantData, setTenantData] = useState([])
    const [payeeName, setPayeeName] = useState("")
    const { id } = useParams()
    const navigate = useNavigate()
    const [payablesData, setPayablesData] = useState()
    const [paymentMethod, setPaymentMethod] = useState('Cash');
    const { selectedItems, setSelectedItems, selectedOption, payeeAccountsArr, payeeAccountsArr2, paymentData, setPaymentData, selectEntityId, setSelectedOption, totalOutStanding, setTotalOutStanding } = useContext(PaymentContext) as PaymentContextType;
    const { errors, setErrors, errorMessages, setErrorMessages, clearErrors } = useError();
    const [formData, setFormData] = useState(
        {
            id: 1,
            date: "",
            transactionId: "",
            checkNum: "",
            paymentMethodId: "",
        },
    )
    const disabled = formData?.date == "" || formData?.paymentMethodId == "";

    const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));

    const checkEmptyFields = (): boolean => {
        clearErrors();
        const newErrors = { ...errors };
        const newErrorMessages = { ...errorMessages };
        let hasErrors = false;

        if (formData?.date === "") {
            newErrors.dueDate = true;
            newErrorMessages.dueDate = "Date of Payment is required"
            hasErrors = true;
        }

        if (paymentMethod !== "Cash" && paymentMethod !== "Check" && formData?.transactionId === "") {
            newErrors.transactionId = true;
            newErrorMessages.transactionId = "TransactionId is required"
            hasErrors = true;
        }
        if (paymentMethod === "Check" && formData?.checkNum === "") {
            newErrors.checkNo = true;
            newErrorMessages.checkNo = "Check Number is required"
            hasErrors = true;
        }
        setErrors(newErrors);
        setErrorMessages(newErrorMessages);
        return hasErrors;
    };
    const transactionId = formData?.transactionId || crypto.randomUUID().replaceAll('-', '').toUpperCase();

    const handleSubmit = () => {
        // e.preventDefault()
        if (checkEmptyFields()) {
            setShowConfirmation(false)
        } else
            if (paymentMode === "Payables") {
                selectedItems.map((item) => {
                    const payload = {
                        payDueAccountId: Number(item?.id),
                        amount: Number(item.amount),
                        fromPaymentMethodId: Number(formData?.paymentMethodId),
                        checkNumber: formData?.checkNum,
                        paymentDate: new Date(formData?.date).toISOString(),
                        transactionId: transactionId,
                    };
                    if (paymentMethod !== "Check" && paymentMethod !== "Cash") {
                        delete payload.checkNumber;
                    }
                    apiHelper(PaymentService.putApiPaymentDueAccountPay, {
                        successMessage: "Payment settled successfully",
                        failureMessage: "Unable to settle successfully",
                        showNotification: true,
                    }, payload)
                        .then((res) => {
                            if (res?.data?.success) {
                                setSelectedItems([])
                                setTimeout(() => {
                                    navigate(URLS.PAYMENT)
                                }, 1000)
                            }
                        })
                        .catch((err) => {
                            console.log("error in paying ====", err);
                        });
                });
            } else if (paymentMode === "Receivables") {
                Object.entries(selectedOption).forEach(([id, value]) => {
                    const item = payeeAccountsArr.find((acc) => acc.id === Number(id));
                    if (!item) return;
                    const payload = {
                        payDueAccountId: Number(item?.id),
                        amount: Number(item.amount),
                        fromPaymentMethodId: Number(formData?.paymentMethodId),
                        checkNumber: formData?.checkNum,
                        paymentDate: new Date(formData?.date).toISOString(),
                        transactionId: transactionId,
                    };
                    apiHelper(PaymentService.putApiPaymentDueAccountPay, {
                        successMessage: "Payment settled successfully",
                        failureMessage: "Unable to settle payment",
                        showNotification: true,
                    }, payload)
                        .then((res) => {
                            if (res?.data?.success) {
                                setSelectedOption([])
                                setTimeout(() => {
                                    navigate(URLS.PAYMENT)
                                }, 1000)
                            }
                        })
                        .catch((err) => {
                            console.log("error while paying ===", err);
                        });
                });
            }
    }

    const handleEdit = () => {
        setShow(true)
    }

    const getOutstandingTotal = () => {
        if (paymentMode === "Payables") {
            const totalOutstandingAmount = payeeAccountsArr.reduce((total, item) => Number(total) + Number(item.amount), 0);
            const totalAmount = getTotalPayableAmount()
            return UtilHelper.formatNegativeValue(totalOutstandingAmount - totalAmount);

        } else if (paymentMode === "Receivables" && payeeAccountsArr.length > 0) {
            const totalOutstandingAmount = payeeAccountsArr.reduce((total, item) => total + item.amount, 0);
            const paymentReceivedTotal = payeeAccountsArr.reduce((total, item) => {
                if (selectedOption[item.id] === "Payment Received") {
                    return total + item.amount;
                }
                return total;
            }, 0);

            const waivedTotal = payeeAccountsArr.reduce((total, item) => {
                if (selectedOption[item.id] === "Waived") {
                    return total + item.amount;
                }
                return total;
            }, 0);
            const totalReceivable = paymentReceivedTotal + waivedTotal
            return UtilHelper.formatNegativeValue(totalOutstandingAmount - totalReceivable);

        } else if (paymentMode === "Receivables" && payeeAccountsArr2.length > 0) {
            const totalOutstandingAmount = payeeAccountsArr2.reduce((total, item) => total + item.amount, 0);
            const paymentReceivedTotal = payeeAccountsArr2.reduce((total, item) => {
                if (selectedOption[item.id] === "Payment Received") {
                    return total + item.amount;
                }
                return total;
            }, 0);
            const waivedTotal = payeeAccountsArr.reduce((total, item) => {
                if (selectedOption[item.id] === "Waived") {
                    return total + item.amount;
                }
                return total;
            }, 0);
            const totalReceivable = paymentReceivedTotal + waivedTotal
            return UtilHelper.formatNegativeValue(totalOutstandingAmount - totalReceivable);
        }
    };

    const getTotalPayableAmount = () => {
        const payingAmount = selectedItems.reduce((total, item) => total + item.amount, 0);
        return payingAmount
    }

    const getPayingAmount = () => {
        if (paymentMode === "Payables") {
            const payingAmount = selectedItems.reduce((total, item) => total + Number(item.amount), 0);
            return payingAmount
        } else if (paymentMode === "Receivables" && payeeAccountsArr.length > 0) {
            const paymentReceivedTotal = payeeAccountsArr.reduce((total, item) => {
                if (selectedOption[item.id] === "Payment Received") {
                    return Number(total) + Number(item.amount);
                }
                return total;
            }, 0);

            const waivedTotal = payeeAccountsArr.reduce((total, item) => {
                if (selectedOption[item.id] === "Waived") {
                    return Number(total) + Number(item.amount);
                }
                return total;

            }, 0);
            return paymentReceivedTotal + waivedTotal;


        } else if (paymentMode === "Receivables" && payeeAccountsArr2.length > 0) {
            const paymentReceivedTotal = payeeAccountsArr2.reduce((total, item) => {
                if (selectedOption[item.id] === "Payment Received") {
                    return Number(total) + Number(item.amount);
                }
                return total;
            }, 0);

            const waivedTotal = payeeAccountsArr2.reduce((total, item) => {
                if (selectedOption[item.id] === "Waived") {
                    return Number(total) + Number(item.amount);
                }
                return total;
            }, 0);
            return paymentReceivedTotal + waivedTotal;
        }
    }

    const getTenantId = () => {
        apiHelper(
            TenantService.getApiTenant1,
            { showNotification: false },
            selectEntityId
        )
            .then((res) => {
                const tenantData = res?.data?.success;
                sessionStorage.setItem('tenantData', tenantData);
                setPayeeName(tenantData?.accountName)
                setTenantData(tenantData)
            })
            .catch((error) => {
                console.log("Error parsing tenant data from sessionStorage:", error);
            });
    };

    const getPayeeAccount = () => {
        apiHelper(PaymentService.getApiPayeeAccount, { showNotification: false }, id)
            .then((res) => {
                if (res?.data?.success) {
                    setPayablesData(res?.data?.success)
                }
            }).catch((err) => {
                console.log("error ===>", JSON.stringify(err));
            })
    }

    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const PaymentMethodList = () => {
        apiHelper(
            PaymentService.getApiPaymentMethodOrganizationlist,
            { showNotification: false },
            orgId
        )
            .then((res) => {
                if (res?.data?.success) {
                    setFormData({ ...formData, paymentMethodId: res?.data?.success[0]?.id })
                    setPaymentMethodsArr(res?.data?.success);
                }
            })
            .catch((ptErr) => { });
    }

    useEffect(() => {
        if (checkEmptyFields()) {
            clearErrors()
        }
    }, [])

    const renderPaymentFields = () => {
        switch (paymentMethod) {
            case 'Cash':
                return;

            case 'Check':
                return (
                    <>
                        <Row className="paymentinputrow">
                            <Col className='paymentinputCol p-0'>
                                <Form.Group controlId="ChequeNo">
                                    <CustomInputs
                                        labelText="Check No."
                                        placeholder="Enter Check No."
                                        name="checkNum"
                                        type={FORM_CONTROLFIELD_TYPES.TEXT}
                                        isFormControl
                                        myClassName="normal_text w-50"
                                        height="37px"
                                        value={formData?.checkNum}
                                        isError={errors.checkNo}
                                        errorMessages={errorMessages.checkNo}
                                        onChange={(e) => {
                                            setFormData({ ...formData, checkNum: e.target.value })
                                            setErrors({ ...errors, checkNo: false })
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                );

            default:
                return (
                    <>
                        <Row className="paymentinputrow p-0">
                            <Col className='paymentinputCol p-0'>
                                <Form.Group controlId="payeeAccountSelect">
                                    <CustomInputs
                                        labelText="Transaction ID"
                                        placeholder="Enter Transaction ID"
                                        name="transactionId"
                                        type={FORM_CONTROLFIELD_TYPES.TEXT}
                                        isFormControl
                                        myClassName="normal_text  w-50"
                                        height="37px"
                                        value={formData?.transactionId}
                                        isError={errors.transactionId}
                                        errorMessages={errorMessages.transactionId}
                                        onChange={(e) => {
                                            setFormData({ ...formData, transactionId: e.target.value })
                                            setErrors({ ...errors, transactionId: false })
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                );
        }
    };

    useEffect(() => {
        if (paymentMode === "Receivables") {
            getTenantId();
        }
        PaymentMethodList();
        getPayeeAccount();
    }, []);

    return (
        <>
            <div className='m-3 settlePayment'>
                <div className="newTenantFormTopBar d-flex align-items-center">
                    <img
                        onClick={() => {
                            navigate(-1);
                            setPaymentData([])
                            setSelectedItems([])
                            setSelectedOption([])
                        }}
                        height={28}
                        width={28}
                        src={GetImages.leftChevronBlack}
                        className="clickable"
                    />
                    <p className="main_heading black">Settle Payment</p>
                </div>

                <div className='settlePaymentCard'>
                    <div className="settlePaymentLeft">
                        {selectedPayment ? (
                            <PaymentListCard
                                tenantData={tenantData}
                                data={[selectedPayment]}
                                selectedItems={selectedItems}
                                paymentMode={currentPaymentMode}
                                onCardClick={undefined}
                                payablesData={payablesData}
                            />
                        ) : (
                            <p>No Data Available</p>
                        )}

                        <Form className="paymentForm">
                            <div className="paymentMode p-0">
                                <span className='sub_heading black mb-1'>Select Payment Mode</span>
                            </div>

                            <Row className="paymentinputrow p-0">
                                <Col className='p-0'>
                                    <Form.Group controlId="paymentMethodSelect">
                                        <Form.Label className="normal_text black">
                                            Payment Method *
                                        </Form.Label>
                                        <Form.Select
                                            required
                                            value={paymentMethod}
                                            onChange={(e) => {
                                                handlePaymentMethodChange(e)
                                                clearErrors()
                                            }}
                                        >
                                            <option value="">Select Payment Method</option>
                                            {paymentMethodsArr.map((item) => (
                                                <option key={item.id} value={item.name}>
                                                    {item.accountName}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback
                                            type="invalid"
                                            className="invalid-feedback"
                                        >
                                            Please select a Payment Method.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                            </Row>

                            {paymentMethod && renderPaymentFields()}
                            <Row className="paymentinputrow BorderTop pt-3">
                                <Col className='paymentinputCol p-0'>
                                    <Form.Group controlId="dueDateInput">
                                        <CustomInputs
                                            name="dueDate"
                                            labelText="Date of Payment"
                                            type={FORM_CONTROLFIELD_TYPES.DATE}
                                            isFormControl
                                            placeholder="Select Date"
                                            myClassName="normal_text  w-50"
                                            height="37px"
                                            value={formData?.date}
                                            isError={errors.dueDate}
                                            errorMessages={errorMessages.dueDate}
                                            onChange={(e) => {
                                                setFormData({ ...formData, date: e.target.value })
                                                setErrors({ ...errors, dueDate: false })
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <div className="editFormFooter ">
                                <AddButtonWithArrow
                                    type={"button"}
                                    className={disabled ? "disabled-btn" : ""}
                                    buttonname="Confirm Payment"
                                    disabled={disabled}
                                    onClick={() => {
                                        setShow(false)
                                        if (checkEmptyFields()) {
                                            setShowConfirmation(false)
                                        } else {
                                            setShowConfirmation(true)
                                        }
                                    }}
                                />
                                {showConfirmation && (
                                    <CommonConfirmation
                                        show={true}
                                        onConfirm={() => {
                                            setShowConfirmation(false)
                                            handleSubmit()
                                        }
                                        }
                                        onClose={() => {
                                            setShow(false)
                                            setShowConfirmation(false)
                                        }}
                                        heading="Confirm payment"
                                        subHeading="Do you want to confirm payment?"
                                        confirmLabel="Confirm"
                                    />
                                )}
                            </div>
                        </Form>
                    </div>

                    <div className='settlePaymentRight'>
                        <div className='borderWithRadius'>
                            <div className='d-flex justify-content-between p-3 BorderBottom'>
                                <p className='heading black'>
                                    Payment Overview
                                </p>
                                <AddTraspButton bname="Edit" width="unset" id="editPayment" onClick={() => handleEdit()} />
                                {
                                    show && (selectedPayment || selectEntityId) &&
                                    (
                                        <PaymentDetail
                                            onClose={() => setShow(false)}
                                            heading={"Edit Payment Details"}
                                            show={true}
                                            paymentMode={paymentMode}
                                            payeAccountId={selectedPayment}
                                            handleClick={undefined}
                                            isEdit={true}
                                        />
                                    )
                                }
                            </div>

                            {paymentMode === "Payables" && selectedItems.length === 0 ? (
                                <EmptyData
                                    mainText="No Due Payments"
                                    button={undefined}
                                    ImageSrc={GetImages.DuePayments}
                                />
                            ) :
                                paymentMode === "Payables" ?
                                    (
                                        selectedItems && selectedItems.map((item, index) => {
                                            return (
                                                <div key={index} className="Last_transaction">
                                                    <div className="d-flex gap-1 align-items-start">
                                                        <img src={paymentMode === "Payables" ? Electrical : Rent} className='Icon20' />
                                                        <div className="d-flex flex-column">
                                                            <p className="normal_text">
                                                                {`${item.description} for ${item.payerPayeeName}`}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex gap-3">
                                                        <p className="normal_text fw-500">${item.amount}</p>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) :
                                    paymentMode === "Receivables" && payeeAccountsArr.length > 0 ? (
                                        payeeAccountsArr && Object.entries(selectedOption).map(([id, value], index) => {
                                            const item = payeeAccountsArr.find((acc) => acc.id === Number(id));
                                            if (!item) return null;
                                            return (
                                                <div key={index} className="Last_transaction">
                                                    <div className="d-flex gap-1 align-items-start">
                                                        <img src={Rent} alt="Receivables Icon" className='Icon20' />
                                                        <div className="d-flex flex-column">
                                                            <p className="normal_text">
                                                                {`${item?.formatted?.PaymentTypeId} - ${value}`}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex gap-3">
                                                        <p className="normal_text fw-500">${item.amount}</p>
                                                    </div>
                                                </div>
                                            );
                                        }))
                                        : (
                                            payeeAccountsArr2 && Object.entries(selectedOption).map(([key, value], index) => {
                                                let item = payeeAccountsArr2[key];
                                                if (!item) {
                                                    return null;
                                                }
                                                return (
                                                    <div key={index} className="Last_transaction">
                                                        <div className="d-flex gap-1 align-items-start">
                                                            <img src={Rent} alt="Receivables Icon" className='Icon20' />
                                                            <div className="d-flex flex-column">
                                                                <p className="normal_text black">
                                                                    {`${item.formatted.PaymentTypeId} `}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <p className="normal_text fw-600 black">${item.amount}</p>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        )
                            }

                            <div className="d-flex w-100 flex-column p-3 BorderTop gap-3">
                                <div className='d-flex justify-content-between'>
                                    <p className='normal_text black'>Outstanding amount due in 30 days</p>
                                    <p className='normal_text fw-600 black'>${getOutstandingTotal()}</p>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <p className='normal_text fw-600 black'>You're Paying</p>
                                    <p className='normal_text fw-600 black'>${getPayingAmount()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default SettlePayment