import { memo, useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Offcanvas } from "react-bootstrap";
import { EntityType, PropertyService } from "@propertelligent/client-api";
import { getProperty } from "../../redux/actions/propertyAction";
import Tabs from "./components/tabs";
import UnitTanentForm from "../firstTimeUserExperience/UnitTanentForm";
import { GetImages } from "../../utils/GetImages";
import { AvatarWithLoading } from "../common/avator";
import { URLS } from "../../constants/constants";
import { MenuContext } from "../../utils/context/MenuContext";
import RequestBreadcrumb from "../common/RequestBreadcrumb";
import SimpleSpinner from "../loader";
import CustomDropDownMenu from "../common/CustomDropDownMenu";
import apiHelper from "../../utils/apiHelper";
import CommonConfirmation from "../common/CommonConfirmation";

const PropertyDetails = () => {
  const [showModel, setShowModel] = useState(false);
  const [loadingStates, setLoadingStates] = useState({
    overview: true,
    unit: true,
    serviceRequest: true,
  });
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false)

  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { id } = useParams();

  const { isOpen } = useContext(MenuContext);
  const { singleProperty } = useSelector((state: any) => state?.singleProperty);

  const addPayment = () => {
    navigate(URLS.CREATE_PAYMENT + `?entityId=${id && Number(id)}&entityTypeId=${EntityType.Property}`)
  }

  const deleteProperty = () => {
    apiHelper(PropertyService.deleteApiProperty, {
      successMessage: "Property Deleted Successfully",
      failureMessage: "Unable to delete property",
      showNotification: true
    }, id).then((res) => {
      if (res?.data?.success) {
        navigate("/properties")
        setShowConfirmation(false)
      }
    }).catch((err) => { })
  }

  const actions = [
    {
      id: 1,
      img: undefined,
      onClick: () => setShowModel(true),
      name: "Add Unit",
      linkpath: undefined,
      textClass: "sub_text fw-600 blue",
    },
    {
      id: 2,
      img: undefined,
      name: "Add Payment",
      onClick: () => addPayment(),
      linkpath: undefined,
      textClass: "sub_text fw-600 blue",
    },
    {
      id: 3,
      img: undefined,
      name: "Edit Property",
      onClick: () => navigate(`/properties/edit/${id}`),
      linkpath: undefined,
      textClass: "sub_text fw-600 blue",
    },
    {
      id: 4,
      img: undefined,
      name: "Delete Property",
      onClick: () => setShowConfirmation(true),
      linkpath: undefined,
      textClass: "sub_text fw-600 blue",
    }
  ];

  const handleClick = (index) => setActiveIndex(index);
  const checkActive = (index, className) => activeIndex === index ? className : "";
  const handleClose = () => setShowConfirmation(false);

  useEffect(() => {
    if (!loadingStates.overview && !loadingStates.unit && !loadingStates.serviceRequest) {
      setLoading(false);
    }
  }, [loadingStates]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getProperty(id));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {loading && (
        <div className="loading-container">
          <SimpleSpinner />
        </div>
      )}

      <div
        style={{ display: loading == true && "none" }}
        className="property-details-container"
      >
        <div className={`sticky-top-property ${isOpen ? "sidebar-open" : "sidebar-closed"}`}>
          <RequestBreadcrumb
            firstName={"Property"}
            currentStep={singleProperty?.address?.streetAddress}
            link={URLS.PROPERTIES}
            ellipsis={true}
          />
          <div className="about-property">
            <div className="propertyFirstColumn">
              <div className="p-image">
                <AvatarWithLoading
                  docImage={singleProperty?.mainImageBase64}
                  docNumber={singleProperty?.mainImageId}
                  avatar={true}
                  Size="Icon60"
                  isProperty={true}
                />
              </div>
              <div className="property-details">
                <p className="sub_text grey_subtext1">
                  {singleProperty?.formatted?.PropertyTypeId}
                </p>
                <p className="heading black propertyAddress">
                  {singleProperty?.address?.streetAddress}
                </p>
              </div>
            </div>

            <div className="actionsDropdownContainer clickable">
              <CustomDropDownMenu
                Action={true}
                Element={
                  <img
                    src={GetImages.BlueChevronDown}
                    alt="BlueChevronDown"
                  />
                }
                data={actions}
              />
            </div>
            <Offcanvas
              className="offcanvas"
              show={showModel}
              onHide={() => setShowModel(false)}
              placement={"end"}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Add Unit</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <UnitTanentForm
                  id={singleProperty?.id}
                  setShowModel={setShowModel}
                  unitId={undefined}
                  isEdit={undefined}
                  setISEdit={undefined}
                  canvasbody={true}
                />
              </Offcanvas.Body>
            </Offcanvas>
          </div>

          <div className="p-tabs">
            <button
              className={`tab normal_text grey_subtext1 ${checkActive(1, "active")}`}
              onClick={() => handleClick(1)}
            >
              Overview
            </button>
            <button className={`tab normal_text grey_subtext1 ${checkActive(2, "active")}`}
              onClick={() => handleClick(2)}
            >
              Units
            </button>
            <button
              className={`tab normal_text grey_subtext1 ${checkActive(3, "active")}`}
              onClick={() => handleClick(3)}
            >
              Service Requests
            </button>
          </div>
        </div>
        <div
          className="w-100 property-bottom-container"
          style={{
            marginTop: "160px",
            paddingTop: "24px",
          }}
        >
          <div className="panels w-100 property-bottom-container-inside">
            <Tabs
              id={singleProperty?.id}
              loadingStates={loadingStates}
              setLoadingStates={setLoadingStates}
              checkActive={checkActive}
            />
          </div>
        </div>
        {showConfirmation && (
          <CommonConfirmation
            show={true}
            onConfirm={deleteProperty}
            onClose={handleClose}
            heading="Delete Property"
            subHeading={"Do you want to delete this property."}
            confirmLabel="Delete"
            cancelLabel="Cancel"
          />
        )}
      </div>
    </>
  );
};

export default memo(PropertyDetails);
