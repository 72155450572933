import React, { useEffect, useState } from "react";
import EmptyData from "./EmptyData";
import CardView from "../servicerequest/listView/cardView";
import Search from "./search";
import { ServiceRequestStatusesEnum } from "@propertelligent/client-api";
import { GetImages } from "../../utils/GetImages";
import apiHelper from "../../utils/apiHelper";

type Props = {
  id?: string;
  httpFetchDataFn: any;
  setTotalServiceRequest: any;
  loadingStates: any;
  setLoadingStates: any;
  isAddress: boolean;
};

const ServiceRequestList = React.memo(
  ({
    id,
    httpFetchDataFn,
    setTotalServiceRequest = null,
    loadingStates,
    setLoadingStates,
    isAddress
  }: Props) => {
    const [requestList, setRequestList] = useState([])
    const [filterRequestlist, setFilterRequestlist] = useState([]);
    const [filterSearchTerm, setFilterSearchTerm] = useState("");
    const [unitstate, setUnitstate] = useState(1);
    const [requestCount, setRequestCount] = useState({ all: 0, open: 0, inProgress: 0, completed: 0 });
    const [fulllyQualifiedFilter, SetFullyQualifiedFilter] = useState(null);

    const getServiceRequestList = (fulllyQualifiedFilter) => {
      apiHelper(httpFetchDataFn, { showNotification: false },
        Number(id),
        undefined, undefined, undefined, undefined, undefined, fulllyQualifiedFilter
      ).then((res) => {
        if (res?.data?.success) {
          setLoadingStates({
            ...loadingStates,
            serviceRequest: false,
          })
          setRequestList(res?.data?.success)
        }
      }).catch((err) => { })
    }

    useEffect(() => {
      if (id !== undefined) {
        getServiceRequestList(fulllyQualifiedFilter)
      }
    }, [id, filterSearchTerm, fulllyQualifiedFilter])

    useEffect(() => {
      setTotalServiceRequest && setTotalServiceRequest(requestList?.length);
    }, []);

    useEffect(() => {
      setFilterRequestlist(requestList);
      let openRequest = requestList?.filter((item) => item.serviceRequestStatus === ServiceRequestStatusesEnum.Open || item.serviceRequestStatus === ServiceRequestStatusesEnum.Estimating);
      let inProgressRequest = requestList?.filter((item) => item.serviceRequestStatus === ServiceRequestStatusesEnum.InProgress);
      let completedRequest = requestList?.filter((item) => item.serviceRequestStatus === ServiceRequestStatusesEnum.Completed);

      setRequestCount({
        ...requestCount,
        all: requestList?.length,
        open: openRequest?.length,
        inProgress: inProgressRequest?.length,
        completed: completedRequest?.length
      });
    }, [requestList?.length]);

    const filterFunction = (value: any) => {
      if (value === "all") {
        setFilterRequestlist(requestList);
      } else if (value === "open") {
        let open = requestList?.filter((item) => item.serviceRequestStatus === ServiceRequestStatusesEnum.Open || item.serviceRequestStatus === ServiceRequestStatusesEnum.Estimating);
        setFilterRequestlist(open);
      } else if (value === "inProgress") {
        let inProgress = requestList?.filter((item) => item.serviceRequestStatus === ServiceRequestStatusesEnum.InProgress);
        setFilterRequestlist(inProgress);
      } else if (value === "completed") {
        let complete = requestList?.filter((item) => item.serviceRequestStatus === ServiceRequestStatusesEnum.Completed);
        setFilterRequestlist(complete);
      }
    };

    useEffect(() => {
      let filter = "";

      if (filterSearchTerm !== "") {
        const searchFilter = `Description.ToLower().Contains("${filterSearchTerm.toLowerCase()}")`;
        filter = searchFilter
      }

      SetFullyQualifiedFilter(filter || null);
    }, [filterSearchTerm]);

    return (
      <>
        <div className="single_pro_UnitList pt-0">
          <div className="pro_UnitList_header">
            <div className="monthhead">
              <button
                onClick={() => {
                  setUnitstate(1);
                  filterFunction("all");
                }}
                className={
                  unitstate === 1
                    ? "overviewSelectedButton sub_text fw-500 blue"
                    : "overviewUnselectedButton sub_text fw-500 grey_subtext1"
                }
              >
                All ({requestCount.all})
              </button>
              <button
                onClick={() => {
                  setUnitstate(2);
                  filterFunction("open");
                }}
                className={
                  unitstate === 2
                    ? "overviewSelectedButton sub_text fw-500 blue"
                    : "overviewUnselectedButton sub_text fw-500 grey_subtext1"
                }
              >
                Open ({requestCount.open})
              </button>
              <button
                onClick={() => {
                  setUnitstate(3);
                  filterFunction("inProgress");
                }}
                className={
                  unitstate === 3
                    ? "overviewSelectedButton sub_text fw-500 blue"
                    : "overviewUnselectedButton sub_text fw-500 grey_subtext1"
                }
              >
                In progress ({requestCount.inProgress})
              </button>
              <button
                onClick={() => {
                  setUnitstate(4);
                  filterFunction("completed");
                }}
                className={
                  unitstate === 4
                    ? "overviewSelectedButton sub_text fw-500 blue"
                    : "overviewUnselectedButton sub_text fw-500 grey_subtext1"
                }
              >
                Completed ({requestCount.completed})
              </button>
            </div>
            <div className="search_filter_UnitList">
              <Search
                placeholderName="Search by Description"
                searchFilterChange={(value) => setFilterSearchTerm(value)}
                searchValue={filterSearchTerm}
              />
            </div>
          </div>
          {filterRequestlist?.length === 0 ? (
            <EmptyData
              mainText={"No Service Request Found"}
              subText={undefined}
              button={undefined}
              ImageSrc={GetImages.NoServiceReqFound}
            />
          ) : (
            <>
              {filterRequestlist?.map((item, i) => {
                return (
                  <div className="d-flex w-100" key={i}>
                    <CardView item={item} isAddress={isAddress} />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </>
    );
  }
);

export default ServiceRequestList;
