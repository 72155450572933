import { GetImages } from "../../utils/GetImages";

const PaymentAccountViewComponent = ({
  rows,
  setShowModal,
  showModal,
  id,
  setId,
  mode,
  setMode,
  setShowConfirmation,
  setPaymentMethod,
}) => {
  const showEditModal = (id) => {
    setMode("edit");
    setId(id);
    setShowModal(true);
  };

  return (
    <div className="d-flex flex-column gap-12">
      {rows.map((item, index) => {
        let image, accountNumber;

        function hideDigits(number) {
          var numberStr = String(number);
          var numHiddenDigits = Math.max(0, numberStr.length - 4);
          var hiddenNumber = "x".repeat(numHiddenDigits) + numberStr.slice(-4);
          return hiddenNumber;
        }

        switch (item?.formatted?.PaymentMethodTypeId) {
          case "ACH":
            image = GetImages.achIcon;
            accountNumber = item?.achPaymentMethod?.accountNumber
              ? hideDigits(item?.achPaymentMethod?.accountNumber)
              : null;
            break;
          case "Credit Card":
            image = GetImages.creditCardIcon;

            accountNumber = item?.creditCardPaymentMethod?.cardNumber
              ? hideDigits(item?.creditCardPaymentMethod?.cardNumber)
              : null;
            break;
          default:
            image = GetImages.plaidIcon;
            accountNumber = null;
            break;
        }

        return (
          <div
            key={index}
            className="d-flex align-items-center justify-content-between paymentComponentContainer"
          >
            <div className="d-flex align-items-center justify-content-center gap-2">
              <img width={40} height={40} src={image} />
              <div className="ps-1">
                <p className="sub_text grey text-start">
                  {item?.formatted?.PaymentMethodTypeId
                    ? item?.formatted?.PaymentMethodTypeId
                    : "Plaid"}
                </p>
                <p className="sub_heading fw-500 text-start">{item?.accountName}</p>
                <p className="sub_text grey_subtext1 text-start">
                  {accountNumber ? accountNumber : ""}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center align-itmes-center gap-2">
              {item?.formatted?.PaymentMethodTypeId && (
                <img
                  onClick={() => {
                    showEditModal(item.id);
                  }}
                  className="clickable"
                  width={16}
                  height={16}
                  src={GetImages.edit}
                />
              )}
              <img
                onClick={() => {
                  setId(item.id);
                  setShowConfirmation(true);
                  item?.formatted?.PaymentMethodTypeId === "ACH" &&
                    setPaymentMethod("ACH");
                  item?.formatted?.PaymentMethodTypeId === "Credit Card" &&
                    setPaymentMethod("Credit Card");
                  !item?.formatted?.PaymentMethodTypeId &&
                    setPaymentMethod("Plaid");
                }}
                className="clickable"
                width={16}
                height={16}
                src={GetImages.delete1}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PaymentAccountViewComponent;
