import React from 'react'
import DateHelper from '../../../utils/DateHelper'
import EmptyData from '../../common/EmptyData'

const EmploymentHistoryInfo = ({EmploymentHistory}) => {
  return (
    <>
    { EmploymentHistory?.length ? (
      EmploymentHistory?.map((item, i) => (
        <div key={i} className="Employment_history ">
          <div className="w-100">
            <p className="heading black">{item?.name}</p>
            <p className="normal_text grey">
              <i>{`${DateHelper.convertUTCtoDateTime2(item?.startDate)} - ${DateHelper.convertUTCtoDateTime2(item?.endDate)}`}</i>
            </p>
          </div>
          <div className="w-100 ">
            <p className="sub_heading fw-normal black text-center"> {`${item?.firstName} ${item?.lastName}`} </p>
            <p className="normal_text blue text-center">{item?.email}</p>
          </div>
          <div className="w-100 "> <p className="sub_heading fw-normal black text-end">{item?.phoneNumber}</p>
          </div>
        </div>
      ))
    ) : (<EmptyData mainText="Data not found" />)}
    </>
  )
}

export default EmploymentHistoryInfo