
import { useNavigate } from 'react-router-dom';
import { GetImages } from '../../utils/GetImages';

const RequestBreadcrumb = ({ firstName, lastName = undefined, link, link1 = undefined, currentStep = "Detail", ellipsis = false }) => {
  const navigate = useNavigate();
  return (
    <div className="singleTenantTopbar">
      <div
        onClick={() => {
          navigate(link);
        }}
        className="clickable"
      >
        <p className="normal_text fw-500 black">
          {firstName}
        </p>
      </div>
      <img src={GetImages.BlackChevronRight} />
      {lastName && (
        <>
          <div onClick={() => navigate(link1)} className='clickable'>
            <p className="normal_text fw-500 grey">
              {lastName}
            </p>
          </div>
          <img src={GetImages.BlackChevronRight} />
        </>
      )}
      <p className={ellipsis ? "normal_text fw-500 grey wrapemailSubject" : "normal_text fw-500 grey"}>
        {currentStep}
      </p>
    </div>
  );
};

export default RequestBreadcrumb;
