import { useEffect, useState } from "react";
import TenantCards from "../../common/cards/TenantCards";
import { GetImages } from "../../../utils/GetImages";
import { UnitService, TenantService, PaymentService } from "@propertelligent/client-api";
import DuePayments from "../../common/duePayment/DuePayments";
import "./dashboard.css";
import ComponentHeading from "../../common/componentHeading";
import UnitDetails from "../../common/unitDetails";
import { infiniteScrollHook } from "../../../hooks/infiniteScrollHook";
import useInfiniteScroll from "react-infinite-scroll-hook";
import SimpleSpinner from "../../../components/loader";
import EmptyData from "../../../components/common/EmptyData";
import apiHelper from "../../../utils/apiHelper";

const Dashboard = () => {
  const [unitData, setUnitData] = useState<any>({});
  const [tenantData, setTenantData] = useState<any>({});
  const tenantInfoString = localStorage.getItem("tenantInfo");
  const tenantInfo = JSON.parse(tenantInfoString);
  const unitId = tenantInfo && tenantInfo.unitId;
  const tenantId = tenantInfo && tenantInfo.tenantId;

  const { rows, loading, hasMore, rowCount, fetchData, currentPage } = infiniteScrollHook({
    httpFetchDataFn: PaymentService.getApiPaymentDueAccountTenantlist,
    FullyQualifiedFilters: undefined,
    FullyQualifiedSorts: undefined,
  });

  const loadMoreRecords = () => {
    if (!loading) {
      fetchData(currentPage + 1);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasMore,
    onLoadMore: loadMoreRecords,
    rootMargin: "0px 0px 100px 0px",
  });

  const filteredRows = rows?.filter(
    (data: any) => data?.formatted?.PaymentDueAccountStatusId !== "Paid"
  );

  const getUnitData = (unitId: number) => {
    apiHelper(UnitService.getApiUnit1, { showNotification: false }, unitId)
      .then((res: any) => {
        setUnitData(res?.data?.success);
      })
      .catch((err) => {
        console.error("error fetching data", err);
      });
  };

  const getTenantData = (tenantId: number) => {
    apiHelper(TenantService.getApiTenant1, { showNotification: false }, tenantId)
      .then((res: any) => {
        setTenantData(res?.data?.success);
      })
      .catch((err) => {
        console.error("error fetching data", err);
      });
  };

  useEffect(() => {
    getUnitData(unitId);
    getTenantData(tenantId);
    fetchData(currentPage);
  }, []);

  return (
    <div className="dashboardContainer" id="tenantDashboard">
      <ComponentHeading title="Dashboard" button={false} onClick={undefined} />
      <div className="about-tenant-property">
        <UnitDetails unitData={unitData} />
        <div className="pt-4 d-flex gap-0 sm-gap-2 justify-content-start align-items-center card_container row">
          <div className="col-md-6 col-lg-4 mb-2 mb-lg-0">
            <TenantCards
              title="Lease Type"
              icon={GetImages.TenantFile}
              userName={undefined}
              phoneNumber={undefined}
              leaseType={tenantData?.currentLeaseInfo?.formatted?.TenantLeaseTypeId}
              background="#ebf5ff"
              leaseStartDate={undefined}
              leaseEndDate={undefined}
              amount={undefined}
              border="none"
              borderRadius="8px"
            />
          </div>
          <div className="col-md-6 col-lg-4 mb-2 mb-lg-0">
            <TenantCards
              title="Lease Expiry"
              icon={GetImages.TenantCalendar}
              userName={undefined}
              phoneNumber={undefined}
              leaseType={undefined}
              background="#ebf5ff"
              leaseStartDate={undefined}
              leaseEndDate={tenantData?.currentLeaseInfo?.formatted?.LeaseEndDateOnly}
              amount={undefined}
              border="none"
              borderRadius="8px"
            />
          </div>
          <div className="col-md-6 col-lg-4 mb-2 mb-lg-0">
            <TenantCards
              title="Landlord"
              icon={GetImages.TenantPerson}
              userName={`${tenantInfo?.propertyManagerInfo?.firstName} ${tenantInfo?.propertyManagerInfo?.lastName}`}
              phoneNumber={tenantInfo?.propertyManagerInfo?.phoneNumber}
              leaseType={undefined}
              background="#ebf5ff"
              leaseStartDate={undefined}
              leaseEndDate={undefined}
              amount={undefined}
              border="none"
              borderRadius="8px"
            />
          </div>
        </div>
      </div>
      <div className="duePaymentSection">
        <p className="heading black mb-4 pt-4">Due Payments</p>
        <div className="duePaymentContainer px-3">

          {rows && rows.length !== 0 ? (
            rows
              .filter(
                (data: any) =>
                  data?.formatted?.PaymentDueAccountStatusId !== "Paid"
              )
              .map((data: any, index: number) => (
                <>
                  <DuePayments
                    key={index}
                    dueAccountid={data.id}
                    icon={GetImages.RedArrowOutward}
                    title={data.formatted.PaymentTypeId}
                    timeLine={data.formatted.DueDateDateOnly}
                    amount={data.amount}
                    date={data.formatted.DueDateDateOnly}
                    serviceName={undefined}
                    expiringDate={undefined}
                    fetchData={fetchData}
                    data={data}
                  />
                  <div ref={sentryRef} />
                </>
              ))
          ) : (
            <EmptyData
              mainText={"No Data Found"}
              subText={undefined}
              button={undefined}
            />
          )}
          {loading && (
            <>
              <SimpleSpinner myClassName={rows.length == 0 ? "Icon50" : "Icon40"} />
            </>
          )}
          <div ref={sentryRef} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
