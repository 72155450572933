import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { getTenantList } from "../../../redux/actions/unitAction";
import EmptyData from "../../common/EmptyData";
import { ViewItem } from "../../units/ViewItem/ViewItem";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../../constants/constants";
import "../../units/style.css";
import Search from "../../common/search";
import { GetImages } from "../../../utils/GetImages";

const UnitList = ({ loadingStates, setLoadingStates }) => {
  const { id } = useParams();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [filterunitlist, setFilterunitlist] = useState([]);
  const [unitstate, setUnitstate] = useState(1);
  const [filterSearchTerm, setFilterSearchTerm] = useState("");
  const [fulllyQualifiedFilter, SetFullyQualifiedFilter] = useState(null);
  const [unitCount, setUnitCount] = useState({ all: 0, vaccant: 0, occupied: 0 });

  const { unitList } = useSelector((state: any) => state?.unitList);
  const { isLoading } = useSelector((state: any) => state?.Comman);

  useEffect(() => {
    if (unitList?.length >= 0) {
      setLoadingStates({
        ...loadingStates,
        unit: false,
      });
    }
  }, [unitList]);

  useEffect(() => {
    dispatch(getTenantList(id, fulllyQualifiedFilter));
  }, [dispatch, filterSearchTerm, fulllyQualifiedFilter, unitList?.length]);


  useEffect(() => {
    let filter = "";

    if (filterSearchTerm !== "") {
      const searchFilter = `UnitNumber.ToLower().Contains("${filterSearchTerm.toLowerCase()}")`;
      filter = searchFilter
    }

    SetFullyQualifiedFilter(filter || null);
  }, [filterSearchTerm]);

  useEffect(() => {
    setFilterunitlist(unitList);
    let vaccantunit = unitList?.filter((item) => item.tenantIds === null);
    let leasevalue = unitList?.filter((item) => item.tenantIds !== null);

    setUnitCount({
      ...unitCount,
      all: unitList?.length,
      vaccant: vaccantunit?.length,
      occupied: leasevalue?.length,
    });
  }, [unitList?.length]);

  const filterFunction = (value: any) => {
    if (value === "all") {
      setFilterunitlist(unitList);
    } else if (value === "vacant") {
      let vaccantunit = unitList?.filter((item) => item.tenantIds === null);
      setFilterunitlist(vaccantunit);
    } else if (value === "occupied") {
      let leasevalue = unitList?.filter((item) => item.tenantIds !== null);
      setFilterunitlist(leasevalue);
    }
  };

  const handleItemClick = (id: string | undefined) => {
    navigate(`${URLS.EDIT_UNIT}/${id}`);
  };

  const handleInviteTenantClick = (
    event: React.MouseEvent<HTMLParagraphElement>
  ) => {
    event.stopPropagation();
    navigate(URLS.CREATE_TENANT);
  };

  return (
    <>
      <div className="single_pro_UnitList gap-0" style={{ padding: "0px 24px 24px" }}>
        <div className="pro_UnitList_header">
          <div className="monthhead">
            <button
              onClick={() => {
                setUnitstate(1);
                filterFunction("all");
              }}
              className={
                unitstate === 1
                  ? "overviewSelectedButton sub_text fw-500 blue"
                  : "overviewUnselectedButton sub_text fw-500 grey_subtext1"
              }
            >
              All ({unitCount.all})
            </button>
            <button
              onClick={() => {
                setUnitstate(2);
                filterFunction("vacant");
              }}
              className={
                unitstate === 2
                  ? "overviewSelectedButton sub_text fw-500 blue"
                  : "overviewUnselectedButton sub_text fw-500 grey_subtext1"
              }
            >
              Vacant ({unitCount.vaccant})
            </button>
            <button
              onClick={() => {
                setUnitstate(3);
                filterFunction("occupied");
              }}
              className={
                unitstate === 3
                  ? "overviewSelectedButton sub_text fw-500 blue"
                  : "overviewUnselectedButton sub_text fw-500 grey_subtext1"
              }
            >
              Occupied ({unitCount.occupied})
            </button>
          </div>
          <div className="search_filter_UnitList">
            <Search
              placeholderName="Search by Unit Number"
              searchFilterChange={(value) =>
                setFilterSearchTerm(value)
              }
              searchValue={filterSearchTerm}
            />
          </div>
        </div>
        {filterunitlist?.length === 0 && !isLoading ? (
          <EmptyData
            mainText={"No Unit Found"}
            subText={undefined}
            button={undefined}
            ImageSrc={GetImages.NoUnitFound}
          />
        ) : (
          <div className="tenantListContainer unsetOverflow w-100 p-0">
            <div style={{ marginBottom: "8px" }} className="unit-item-list">
              <ViewItem
                rows={filterunitlist}
                loading={isLoading}
                handleItemClick={handleItemClick}
                handleInviteTenantClick={handleInviteTenantClick}
                showTarget={true}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UnitList;
