import React from "react";
import { FormSelect } from "react-bootstrap";
import RecentTransactionComponent from "./RecentTransactionComponent";
import { PaymentService, TenantService } from "@propertelligent/client-api";
import { usePaginationHook } from "../../hooks/usePaginationHook";
import Transactions from "../properties/components/transactions";

const TransactionComponent = ({ id }) => {
  const {
    loading,
    rows,
    rowCount,
    fetchData,
    CustomPagination,
    handleSortModelChange,
    handleFilterModelChange,
    handlePaginationModelChange,
  } = usePaginationHook({
    httpFetchDataFn: PaymentService.getApiPaymentDueAccountContractorlist,
    paginationDataMapper: null,
    parentEntityId: Number(id),
    isDependentEntity: true,
    FullyQualifiedFilters: null,
  });

  return (
    <div className="transactionComponentContainer pt-0">
      <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
        <p className="heading black">Transactions ({rowCount})</p>
        <div className="selectTagContainer d-flex align-items-center justify-content-center">

          <div className="monthSelection">
            <FormSelect>
              <option>Date: Last 6 Month</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </FormSelect>
          </div>
        </div>
      </div>
      <div className="transactionContainer">
        <Transactions
          rows={rows}
          sorting={null}
          paymentMethodsArr={null}
          fetchData={fetchData}
          fetchAndFormatExpensebycategory={null}
        />
        {/* <RecentTransactionComponent />
        <RecentTransactionComponent />
        <RecentTransactionComponent />
        <RecentTransactionComponent />
        <RecentTransactionComponent />
        <RecentTransactionComponent />
        <RecentTransactionComponent />
        <RecentTransactionComponent />
        <RecentTransactionComponent />
        <RecentTransactionComponent /> */}
      </div>

    </div>
  );
};

export default TransactionComponent;
