import { useEffect, useState } from 'react'
import SeparaterLine from '../../common/SeparaterLine'
import RadioButton from '../../common/RadioButton'
import { Form } from 'react-bootstrap'
import MultiselectDropdown from '../../common/MultiselectDropdown'
import AddTraspButton from '../../firstTimeUserExperience/commonCom/AddTraspButton'
import AddButtonWithArrow from '../../firstTimeUserExperience/commonCom/AddButtonWithArrow'
import RangeDatePicker from '../../common/formControls/RangeDatePicker'
import { useNavigate } from 'react-router'
import { PropertyService, FilterEntityEnum, ReportTypesEnum, UnitService } from '@propertelligent/client-api'
import { UserLoginData } from '../../common/charts/Enums'
import apiHelper from '../../../utils/apiHelper'
import { StaticRange } from 'react-date-range';
import { subDays, subMonths, startOfMonth, endOfMonth } from 'date-fns';

const FiltersForm = ({
    selectedOption,
    setSelectedOption,
    selectedValues,
    setSelectedValues,
    range,
    setRange,
    selectedIds,
    setSelectedIds,
    relativeDateString,
    setRelativeDateString
}) => {
    const [multiSelectOptions, setMultiSelectOptions] = useState([])
    const [formValidated, setFormValidated] = useState({
        isSelectedProperties: false,
        isSelectDuration: false
    })

    const navigate = useNavigate()
    const organizationId = parseInt(localStorage.getItem(UserLoginData.organization_id));
    const reportType = parseInt(localStorage.getItem("ReportType"))

    const customRanges: StaticRange[] = [
        {
            label: 'Last 7 Days',
            isSelected: () => false,
            range: () => ({
                startDate: subDays(new Date(), 7),
                endDate: new Date(),
            }),
        },
        {
            label: 'Last 14 Days',
            isSelected: () => false,
            range: () => ({
                startDate: subDays(new Date(), 14),
                endDate: new Date(),
            }),
        },
        {
            label: 'Last Month',
            isSelected: () => false,
            range: () => ({
                startDate: startOfMonth(subMonths(new Date(), 1)),
                endDate: endOfMonth(subMonths(new Date(), 1)),
            }),
        },
        {
            label: 'Last 3 Months',
            isSelected: () => false,
            range: () => ({
                startDate: startOfMonth(subMonths(new Date(), 4)),
                endDate: endOfMonth(subMonths(new Date(), 1)),
            }),
        },
        {
            label: 'Last Year',
            isSelected: () => false,
            range: () => ({
                startDate: new Date(new Date().getFullYear() - 1, 0, 1),
                endDate: new Date(new Date().getFullYear() - 1, 11, 31),
            }),
        },
        {
            label: 'Custom Range',
            isSelected: () => false,
            range: () => ({
                startDate: range[0].startDate,
                endDate: range[0].endDate,
            }),
        },
    ];

    const radioOnChangeHandler = (e) => {
        setSelectedOption(e.target.value)
        setSelectedIds([])
        setSelectedValues([])
        setRange([{
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }])
    }

    const radioOptions = [
        {
            label: "Organization",
            value: FilterEntityEnum.Organization,
            checked: selectedOption == FilterEntityEnum.Organization,
            onChange: radioOnChangeHandler,
            name: "properties"
        },
        {
            label: "Properties",
            value: FilterEntityEnum.Property,
            checked: selectedOption == FilterEntityEnum.Property,
            onChange: radioOnChangeHandler,
            name: "properties"
        },
        {
            label: "Units",
            value: FilterEntityEnum.Unit,
            checked: selectedOption == FilterEntityEnum.Unit,
            onChange: radioOnChangeHandler,
            name: "properties"
        }
    ]

    const getData = (option: number) => {
        if (option == FilterEntityEnum.Property) {
            apiHelper(PropertyService.getApiPropertyOrganizationlookup, { showNotification: false }, organizationId)
                .then((response: any) => {
                    setMultiSelectOptions(response?.data?.success);
                })
                .catch((Err) => {
                    console.error(Err);
                });

        } else if (option == FilterEntityEnum.Unit) {
            setMultiSelectOptions([])
            apiHelper(UnitService.getApiUnitOrganizationlookup, { showNotification: false }, organizationId)
                .then((response: any) => {
                    setMultiSelectOptions(response?.data?.success);
                })
                .catch((Err) => {
                    console.error(Err);
                });
        } else {
            setMultiSelectOptions([])
        }
    }

    useEffect(() => {
        getData(selectedOption);
    }, [selectedOption])

    const handleSubmit = (e) => {
        e.preventDefault();

        if (selectedOption == FilterEntityEnum.Organization) {
            const isDurationSelected = relativeDateString !== "";
            setFormValidated({
                ...formValidated,
                isSelectDuration: !isDurationSelected,
            });
            if (isDurationSelected) {
                navigate('/organization/reports/statement');
            }
        } else {
            const isPropertiesSelected = selectedValues.length > 0;
            const isDurationSelected = relativeDateString !== "";

            setFormValidated({
                ...formValidated,
                isSelectedProperties: !isPropertiesSelected,
                isSelectDuration: !isDurationSelected,
            });

            if (isPropertiesSelected && isDurationSelected) {
                navigate('/organization/reports/statement');
            }
        }
    };

    const handleBack = (e) => {
        e.preventDefault()
        navigate(-1)
    }

    const onChangeHandler = (value) => {
        setRange(value);
        setFormValidated({
            ...formValidated,
            isSelectDuration: false,
        });
    }

    const handleCheckboxChange = (value, id) => {
        setSelectedValues((prevSelected) =>
            prevSelected.includes(value)
                ? prevSelected.filter((item) => item !== value)
                : [...prevSelected, value]
        );

        setSelectedIds((prevIds) =>
            prevIds.includes(id)
                ? prevIds.filter((item) => item !== id)
                : [...prevIds, id]
        );
        setFormValidated({
            ...formValidated,
            isSelectedProperties: false,
        });
    };

    return (
        <div className='filterFormContainer'>
            <Form>
                <p className='normal_text black'>
                    Select from below filters to view {reportType == ReportTypesEnum.Pnl ? 'Profit and loss statement' : 'Rent roll statement'}
                </p>
                <Form.Label>
                    <div className='mb-3 d-flex gap-2 align-items-center mt-4'>
                        <SeparaterLine height={"18px"} background={"#111928"} />
                        <p className='text-uppercase'>Generate report for</p>
                    </div>
                </Form.Label>
                <div className='d-flex gap-4'>
                    {radioOptions.map((option, index) => (
                        <RadioButton
                            key={index}
                            label={option.label}
                            value={option.value}
                            checked={option.checked}
                            onChange={option.onChange}
                            name={option.name}
                        />
                    ))}
                </div>
                {Number(selectedOption) !== FilterEntityEnum.Organization && (
                    <>
                        <Form.Label>
                            <div className='mb-3 d-flex gap-2 align-items-center mt-4'>
                                <SeparaterLine height={"18px"} background={"#111928"} />
                                <p className='text-uppercase'>
                                    {Number(selectedOption) == FilterEntityEnum.Property ? 'Select properties*'
                                        : Number(selectedOption) == FilterEntityEnum.Unit ? 'Select Units*' : null}
                                </p>
                            </div>

                            <MultiselectDropdown
                                options={multiSelectOptions}
                                selectedOption={selectedOption}
                                selectedIds={selectedIds}
                                placeholder={"Select Properties"}
                                onChange={handleCheckboxChange}

                            />
                            {formValidated.isSelectedProperties && (
                                <div className="invalid-feedback d-block">Please select at least one property</div>
                            )}
                        </Form.Label>
                        <div className='d-flex flex-wrap gap-2' style={{ width: "80%" }}>
                            {selectedValues && selectedValues.map((value, i) => (
                                <p key={i} className='selectedValueLabel normal_text'>{value}</p>
                            ))}
                        </div>
                    </>
                )
                }
                <div>
                    <Form.Label>
                        <div className='mb-3 d-flex gap-2 align-items-center mt-4'>
                            <SeparaterLine height={"18px"} background={"#111928"} />
                            <p className='text-uppercase'>Select Duration *</p>
                        </div>
                        <RangeDatePicker
                            range={range}
                            customRanges={customRanges}
                            onChangeHandler={onChangeHandler}
                            relativeDateString={relativeDateString}
                            setRelativeDateString={setRelativeDateString}
                        />
                        {formValidated.isSelectDuration && (
                            <div className="invalid-feedback d-block">Please select any date range</div>
                        )}
                    </Form.Label>
                </div>
            </Form>

            <div className='filterFormButton'>
                <AddTraspButton
                    type="button"
                    bname={"Cancel"}
                    onClick={handleBack}
                    width="fit-content"
                />
                <AddButtonWithArrow
                    buttonname="View Reports"
                    onClick={handleSubmit}
                />
            </div>
        </div>
    )
}

export default FiltersForm