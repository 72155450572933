import { useContext, useEffect, useState } from 'react';
import AddButtonWithArrow from '../firstTimeUserExperience/commonCom/AddButtonWithArrow';
import RadioButton from '../common/RadioButton';
import { GeneratePdfContext } from '../../utils/context/GeneratePdfContext';
import apiHelper from '../../utils/apiHelper';
import { PropertyService, FilterEntityEnum, UnitService } from '@propertelligent/client-api';
import { UserLoginData } from '../common/charts/Enums';
import MultiselectDropdown from '../common/MultiselectDropdown';
import { Form } from 'react-bootstrap';
import AddTraspButton from '../firstTimeUserExperience/commonCom/AddTraspButton';
import Search from '../common/search';

const FilterDropdown = ({ filters = {},
  selectedCategories,
  handleCategoryChange,
  organizationsResponse = () => { },
  propertyResponse = () => { },
  unitResponse = () => { },
  setShow,
  resetFilters = () => { },
  propertyResponseTX = () => { },
  organizationsTXResponse = () => { },
  loading,
  organizationDataTX,
  setLoading,
  setOrganizationDataTX
}) => {
  const { selectedOption, setSelectedOption, selectedValues, setSelectedValues, setSelectedIds, selectedIds, multiSelectOptions, setMultiSelectOptions } = useContext(GeneratePdfContext);
  // const [multiSelectOptions, setMultiSelectOptions] = useState([]);
  const [filterPayer, setFilterPayer] = useState("");
  const [filterPayee, setFilterPayee] = useState("");
  const [formValidated, setFormValidated] = useState({
    isSelectedProperties: false,
    isSelectDuration: false
  });
  const organizationId = parseInt(localStorage.getItem(UserLoginData.organization_id));

  const radioOnChangeHandler = (e) => {
    setSelectedOption(e.target.value);
    setSelectedIds([]);
  };

  useEffect(() => {
    setSelectedOption(FilterEntityEnum.Organization)
  }, [])


  const radioOptions = [
    {
      label: "Organization",
      value: FilterEntityEnum.Organization,
      checked: selectedOption == FilterEntityEnum.Organization,
      onChange: radioOnChangeHandler,
      name: "organization"
    },
    {
      label: "Properties",
      value: FilterEntityEnum.Property,
      checked: selectedOption == FilterEntityEnum.Property,
      onChange: radioOnChangeHandler,
      name: "properties"
    },
    {
      label: "Units",
      value: FilterEntityEnum.Unit,
      checked: selectedOption == FilterEntityEnum.Unit,
      onChange: radioOnChangeHandler,
      name: "units"
    }
  ];

  const getData = (option) => {
    if (option == FilterEntityEnum.Property) {
      apiHelper(PropertyService.getApiPropertyOrganizationlookup, { showNotification: false }, organizationId)
        .then((response) => {
          setMultiSelectOptions(response?.data?.success);
        })
        .catch((Err) => {
          console.error(Err);
        });
    } else if (option == FilterEntityEnum.Unit) {
      setMultiSelectOptions([]);
      apiHelper(UnitService.getApiUnitOrganizationlookup, { showNotification: false }, organizationId)
        .then((response) => {
          setMultiSelectOptions(response?.data?.success);
        })
        .catch((Err) => {
          console.error(Err);
        });
    } else {
      setMultiSelectOptions([]);
    }
  };

  useEffect(() => {
    getData(selectedOption);
  }, [selectedOption]);

  const handleCheckbox = (value, id) => {
    setSelectedValues((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value]
    );

    setSelectedIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((item) => item !== id)
        : [...prevIds, id]
    );
    setFormValidated({
      ...formValidated,
      isSelectedProperties: false,
    });
  };

  const fromItems = filters["From"] || [];
  const toItems = filters["To"] || [];
  const categories = filters["Category"] || [];
  const paymentMethod = filters["PaymentMethod"] || [];

  const handleFilterApply = () => {
    unitResponse()
    propertyResponse()
    organizationsResponse()
    organizationsTXResponse()
    propertyResponseTX()
  }

  return (
    <div className="filter-component">
      <div className="BorderBottom">
        <h4 className="heading px-3">Filter by</h4>
      </div>

      <div className="d-flex "
      // style={{ overflowX: "auto", overflowY: "hidden" }}
      >
        <div className="filterRow">
          <div className="transaction-filter-header BorderRight">
            <h5 className="normal_text fw-500 p-3 sticky-header black">View By</h5>
            <div className="viewByRow pe-3">
              <div className="d-flex flex-column gap-3 ps-3">
                {radioOptions.map((option, index) => (
                  <RadioButton
                    key={index}
                    label={option.label}
                    value={option.value}
                    checked={option.checked}
                    onChange={option.onChange}
                    name={option.name}
                  />
                ))}
              </div>

              {Number(selectedOption) !== FilterEntityEnum.Organization && (
                <>
                  <Form.Label>
                    <MultiselectDropdown
                      options={multiSelectOptions}
                      selectedOption={selectedOption}
                      selectedIds={selectedIds}
                      placeholder={"Select an option"}
                      onChange={handleCheckbox}
                    />
                    {formValidated.isSelectedProperties && (
                      <div className="invalid-feedback d-block">Please select at least one property</div>
                    )}
                  </Form.Label>
                </>
              )}
              <div className='selectedData'>
                {selectedValues && selectedValues.map((value, i) => (
                  <p key={i} className='selectedValueLabel normal_text '>
                    {value}
                  </p >
                ))}
              </div >
            </div>
          </div>
        </div>
        <div className='BorderLeft' style={{ overflow: 'auto', width: '100%', display: 'flex', overflowY: "hidden" }}>
          <div className=" transaction-filter-header">
            <h5 className="normal_text fw-500 sticky-header p-3 black">Categories</h5>
            <div className='filter-column'>
              {categories.map((item, index) => (
                <div key={index} className="filter-row">
                  <RadioButton
                    type="checkbox"
                    value={item}
                    name="TranactionCreditDebitTypeId"
                    checked={selectedCategories["TranactionCreditDebitTypeId"]?.includes(item)}
                    onChange={(e) => handleCategoryChange(e, "TranactionCreditDebitTypeId")}
                    key={index}
                    label={item}
                    checkBoxClass="sub_text  filterCheckbox"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="BorderLeft transaction-filter-header">
            <h5 className="normal_text fw-500 sticky-header p-3 black">Payment method</h5>
            <div className='filter-column'>
              {paymentMethod.map((item, index) => (
                <div key={index} className="filter-row">
                  <RadioButton
                    name="paymentMethod.PaymentMethodTypeId"
                    type="checkbox"
                    value={item}
                    checked={selectedCategories["paymentMethod.PaymentMethodTypeId"]?.includes(item)}
                    onChange={(e) => handleCategoryChange(e, "paymentMethod.PaymentMethodTypeId")}
                    key={index}
                    label={item}
                    checkBoxClass="sub_text filterCheckbox"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="BorderLeft transaction-filter-header" id='payee-column'>
            <h5 className="normal_text fw-500 sticky-header black p-3">Payee</h5>
            <div className='mb-3'>
              <Search placeholderName="Search Payee" searchValue={filterPayee} searchFilterChange={(value) => setFilterPayee(value)} />
            </div>
            <div className='filter-column'>
              {toItems.filter((item) => item.toLowerCase().includes(filterPayee.toLowerCase())).map((item, index) => (
                <>
                  <div key={index} className="filter-row">
                    <RadioButton
                      name="payeeAccount.accountName"
                      type="checkbox"
                      value={item}
                      checked={selectedCategories["payeeAccount.accountName"]?.includes(item)}
                      onChange={(e) => handleCategoryChange(e, "payeeAccount.accountName")}
                      key={index}
                      label={item}
                      checkBoxClass=" sub_text filterCheckbox"
                    />
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="BorderLeft transaction-filter-header" id='payer-column'>
            <h5 className="normal_text fw-500 sticky-header black p-3">Payer</h5>
            <div className='mb-3'>
              <Search placeholderName="Search Payer" searchValue={filterPayer} searchFilterChange={(value) => setFilterPayer(value)} />
            </div>
            <div className='filter-column'>
              {fromItems.filter((item) => item.toLowerCase().includes(filterPayer.toLowerCase())).map((item, index) => (
                <>
                  <div key={index} className="filter-row">
                    <RadioButton
                      name="payeeAccount.accountName"
                      type="checkbox"
                      value={item}
                      checked={selectedCategories["payeeAccount.accountName"]?.includes(item)}
                      onChange={(e) => handleCategoryChange(e, "payeeAccount.accountName")}
                      key={index}
                      label={item}
                      checkBoxClass=" sub_text filterCheckbox"
                    />
                  </div>
                </>
              ))}
              {/* {fromItems.map((item, index) => (
                <div key={index} className="filter-row">
                  <RadioButton
                    name="paymentMethod.accountName"
                    type="checkbox"
                    value={item}
                    checked={selectedCategories["paymentMethod.accountName"]?.includes(item)}
                    onChange={(e) => handleCategoryChange(e, "paymentMethod.accountName")}
                    key={index}
                    label={item}
                    checkBoxClass="sub_text filterCheckbox"
                  />
                </div>
              ))} */}
            </div>
          </div>
        </div>
      </div>
      <div className="BorderTop applyFilterBtn">
        <AddTraspButton
          bname="Clear filter"
          onClick={() => {
            resetFilters()
            setShow(false)
          }}
          width="fit-content"
        />
        <AddButtonWithArrow buttonname="Apply filter"
          onClick={() => {
            setShow(false)
            handleFilterApply()
          }} />
      </div>
    </div>
  );
};

export default FilterDropdown;
