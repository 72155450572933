import React, { useEffect, useState } from 'react';
import { GetImages } from '../../utils/GetImages';
import SeparaterLine from '../common/SeparaterLine';
import apiHelper from '../../utils/apiHelper';
import { OrganizationService, PaymentService, TransactionFlowTypesEnum } from '@propertelligent/client-api';
import { UserLoginData } from '../common/charts/Enums';
import DateHelper from '../../utils/DateHelper';
import SimpleSpinner from '../loader';
import { useGeneratePdfContext } from '../../utils/context/GeneratePdfContext';
import AddTraspButton from '../firstTimeUserExperience/commonCom/AddTraspButton';
import GenericSearchAbleDropdown from '../common/GenericSearchAbleDropdown';
import CustomInputs from '../common/Inputs';
import { convertENUMtoJSONArray } from '../../utils/helper';
import { FORM_CONTROLFIELD_TYPES } from '../../constants/constants';
import EmptyData from '../common/EmptyData';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import UtilHelper from '../../utils/UtilHelper';

const TransactionTable = ({
  organizationDataTX,
  organizationData,
  propertyData,
  unitData,
  organizationsResponse,
  propertyResponse,
  unitResponse,
  setSelectedPropertyId,
  setSelectedUnitId,
  propertyResponseTX,
  propertyDataTX,
  setOrganizationData,
  setPropertyData,
  setUnitData,
  selectedPropertyId,
  selectedUnitId,
  orgLoading,
  unitLoading,
  propertyLoading,
  loading,
  selectedCategories,
  setSelectedCategories,
  organizationsTXResponse
}) => {
  const [expandedOrganisationIndex, setExpandedOrganisationIndex] = useState(null);
  const [expandedPropertyIndex, setExpandedPropertyIndex] = useState(null);
  const [expandedUnitIndex, setExpandedUnitIndex] = useState(null);
  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
  const { selectedValues, selectedOption, setSelectedValues } = useGeneratePdfContext();
  const [editOrganizationRowIndex, setEditOrganizationRowIndex] = useState(null);
  const [editPropertyRowIndex, setEditPropertyRowIndex] = useState(null);
  const [editUnitRowIndex, setEditUnitRowIndex] = useState(null);
  const [paymentMethodsArr, setPaymentMethodsArr] = useState([])
  const [payeeAccountData, setPayeeAccountData] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [currentItem, setCurrentItem] = useState(null);
  const [currentPropertyItem, setCurrentPropertyItem] = useState(null);
  const [currentUnitItem, setCurrentUnitItem] = useState(null);
  const [onHover, setOnHover] = useState(null)

  const PaymentMethodList = () => {
    apiHelper(
      PaymentService.getApiPaymentMethodOrganizationlist,
      { showNotification: false },
      orgId
    )
      .then((res) => {
        if (res?.data?.success) {
          setPaymentMethodsArr(res?.data?.success);
        }
      })
      .catch((ptErr) => { });
  }

  const PayeeList = () => {
    apiHelper(
      PaymentService.getApiPayeeAccountOrganizationlist,
      { showNotification: false },
      orgId
    )
      .then((res) => {
        if (res?.data?.success) {
          setPayeeAccountData(res?.data?.success)
        }
      }).catch((err) => { })
  }

  useEffect(() => {
    PaymentMethodList()
    PayeeList()
  }, [isEdit])

  const handleOrganizationDataChange = (item, field, value, index) => {
    setCurrentItem(item);
    setOrganizationData(prevData => {
      const updatedData = [...prevData];
      updatedData[index] = { ...updatedData[index], [field]: value };
      return updatedData;
    });
  };

  const handlePropertyDataChange = (item, field, value, index) => {
    setCurrentPropertyItem(item);
    setPropertyData(prevData => {
      const updatedData = [...prevData];
      updatedData[index] = { ...updatedData[index], [field]: value };
      return updatedData;
    });
  };

  const handleUnitDataChange = (item, field, value, index) => {
    setCurrentUnitItem(item);
    setUnitData(prevData => {
      const updatedData = [...prevData];
      updatedData[index] = { ...updatedData[index], [field]: value };
      return updatedData;
    });
  };

  const handleOrganizationEdit = () => {
    if (organizationData.length && currentItem) {
      let payload = organizationData.find(row => row?.id === currentItem?.id);
      payload = {
        ...payload,
        transactionId: payload.transactionId || crypto.randomUUID().replaceAll('-', '').toUpperCase()
      }
      apiHelper(OrganizationService.putApiOrganizationTransaction, { successMessage: "Successfully Updated Transaction", showNotification: true }, currentItem?.id, payload)
        .then((response) => {
          if (response) {
            organizationsResponse(orgId)
          }
        })
        .catch((Err) => {
          console.error(Err);
        });
    }
  }

  const handlePropertyEdit = () => {
    if (propertyData.length && currentPropertyItem) {
      let updatedValues = propertyData.find(row => row?.id === currentPropertyItem?.id);
      updatedValues = {
        ...updatedValues,
        transactionId: updatedValues.transactionId || crypto.randomUUID().replaceAll('-', '').toUpperCase()
      }
      apiHelper(OrganizationService.putApiOrganizationTransaction, { successMessage: "Successfully Updated Transaction", showNotification: true }, currentPropertyItem?.id, updatedValues)
        .then((response) => {
          if (response) {
            propertyResponse(selectedPropertyId)
          }
        })
        .catch((Err) => {
          console.error({ Err });
        });
    }
  }

  const handleUnitEdit = () => {
    if (unitData.length && currentUnitItem) {
      const transactionId = currentUnitItem.transactionId || crypto.randomUUID().replaceAll('-', '').toUpperCase();
      let updatedValues = unitData.find(row => row?.id === currentUnitItem?.id);
      updatedValues = {
        ...updatedValues,
        transactionId: updatedValues.transactionId || crypto.randomUUID().replaceAll('-', '').toUpperCase()
      }
      apiHelper(OrganizationService.putApiOrganizationTransaction, { successMessage: "Successfully Updated Transaction", showNotification: true }, currentUnitItem?.id, updatedValues)
        .then((response) => {
          if (response) {
            unitResponse(selectedUnitId)
          }
        })
        .catch((Err) => {
          console.error({ Err });
        });
    }
  }

  const handleEditOrganizationRow = (txIndex) => {
    setEditOrganizationRowIndex(txIndex);
  };

  const handleEditPropertyRow = (txIndex) => {
    setEditPropertyRowIndex(txIndex);
  };

  const handleSaveOrganizationRow = (txIndex) => {
    setEditOrganizationRowIndex(null);
  };

  const handleEditUnitRow = (txIndex) => {
    setEditUnitRowIndex(txIndex);
  };

  const handleSaveUnitRow = (txIndex) => {
    setEditUnitRowIndex(null);
  };

  const handleSavePropertyRow = (txIndex) => {
    setEditPropertyRowIndex(null);
  };
  const categories = selectedCategories.TranactionCreditDebitTypeId
  const payeeAccount = selectedCategories["payeeAccount.accountName"];
  const paymentMethod = selectedCategories["paymentMethod.PaymentMethodTypeId"];
  const payer = selectedCategories["paymentMethod.accountName"];

  return (
    <>
      <div className='d-flex flex-wrap gap-2 mb-3'>
        {selectedValues && selectedValues.map((value, i) => (
          <p key={i} className='selectedValueLabel normal_text '>
            {value}
            <AddTraspButton
              imageValue={GetImages.Close}
              iconPosition="right"
              className="closeBtn"
              onClick={() => {
                setSelectedValues(prevSelected => prevSelected.filter(item => item !== value));
                // organizationsTXResponse()
              }}
            />
          </p >
        ))}

        {categories &&
          categories.map((value, i) => (
            <p key={i} className="selectedValueLabel normal_text">
              {value}
              <AddTraspButton
                imageValue={GetImages.Close}
                iconPosition="right"
                className="closeBtn"
                onClick={() => {
                  setSelectedCategories(prevSelected => ({
                    ...prevSelected,
                    TranactionCreditDebitTypeId: prevSelected.TranactionCreditDebitTypeId.filter(
                      item => item !== value
                    ),
                  }));
                  organizationsTXResponse()
                }}
              />
            </p>
          ))}

        {payeeAccount &&
          payeeAccount.map((value, i) => (
            <p key={i} className="selectedValueLabel normal_text">
              {value}
              <AddTraspButton
                imageValue={GetImages.Close}
                iconPosition="right"
                className="closeBtn"
                onClick={() => {
                  setSelectedCategories(prevSelected => ({
                    ...prevSelected,
                    "payeeAccount.accountName": prevSelected["payeeAccount.accountName"].filter(
                      item => item !== value
                    ),
                  }));
                  organizationsTXResponse()
                }}
              />
            </p>
          ))}

        {paymentMethod &&
          paymentMethod.map((value, i) => (
            <p key={i} className="selectedValueLabel normal_text">
              {value}
              <AddTraspButton
                imageValue={GetImages.Close}
                iconPosition="right"
                className="closeBtn"
                onClick={() => {
                  setSelectedCategories(prevSelected => ({
                    ...prevSelected,
                    "paymentMethod.PaymentMethodTypeId": prevSelected["paymentMethod.PaymentMethodTypeId"].filter(
                      item => item !== value
                    ),
                  }));
                  organizationsTXResponse()
                }}
              />
            </p>
          ))}

        {payer &&
          payer.map((value, i) => (
            <p key={i} className="selectedValueLabel normal_text">
              {value}
              <AddTraspButton
                imageValue={GetImages.Close}
                iconPosition="right"
                className="closeBtn"
                onClick={() => {
                  setSelectedCategories(prevSelected => ({
                    ...prevSelected,
                    "paymentMethod.accountName": prevSelected["paymentMethod.accountName"].filter(
                      item => item !== value
                    ),
                  }));
                  organizationsTXResponse()
                }}
              />
            </p>
          ))}
      </div >

      <div className="table-responsive fixed-table-header" style={{
        height: 'calc(100vh - 150px)'
      }}>
        <table className="collapsible-table" style={{ width: "99%" }}>
          <thead>
            <tr className='transaction-table-header normal_text fw-600 grey_subtext1'>
              <th style={{ width: "18%" }}>
                <span style={{ marginLeft: "12px" }}> Description</span>
              </th>
              <th style={{ width: "18%" }}>
                <span>From</span>
              </th>
              <th style={{ width: "18%" }}>
                <span>To</span>
              </th>
              <th>
                <span>Method</span>
              </th>
              <th>
                <span>Reference</span>
              </th>
              <th>
                <span>Category</span>
              </th>
              <th>
                <span>Amount</span>
              </th>
              <th>
                <span>Date</span>
              </th>
              <th style={{ borderRight: "none", width: "18%" }}>
                <span> Transaction ID</span>
              </th>
              <th style={{ borderLeft: "none" }}></th>
            </tr>
          </thead>
          {!loading ? (
            <tbody className='h-auto'>
              {organizationDataTX.map((org, orgIndex) => {
                return (
                  <React.Fragment key={orgIndex}>
                    <tr className='p-12' onClick={() => {
                      if (expandedOrganisationIndex == null || expandedPropertyIndex == null) {
                      }
                      if (org?.entityId === orgId) {
                        setExpandedOrganisationIndex(expandedOrganisationIndex === orgIndex ? null : orgIndex)
                        organizationsResponse(org?.entityId)
                        setEditOrganizationRowIndex(null)

                      }
                      else {
                        setExpandedPropertyIndex(expandedPropertyIndex === orgIndex ? null : orgIndex);
                        propertyResponseTX(org?.entityId);
                        propertyResponse(org?.entityId);
                        setSelectedPropertyId(org?.entityId)
                        setEditPropertyRowIndex(null)
                      }
                    }}>
                      <td colSpan={5} style={{ width: "48%", borderRight: "0px" }} className="px-2 p-12">
                        {org?.formatted?.OrganizationGroupTransactionsTypeId === "Organization" ?
                          <span className='d-flex align-items-center gap-1'>  {expandedOrganisationIndex === orgIndex ? <div className='toggle-btn'>-</div> : expandedPropertyIndex === orgIndex ? <div className='toggle-btn'>-</div> : <div className='toggle-btn'>+</div>} {" "} <span className=' normal_text fw-500'>{org.description}</span></span>
                          : <>
                            <span className='d-flex align-items-center gap-1'>  {expandedOrganisationIndex === orgIndex ? <div className='toggle-btn'>-</div> : expandedPropertyIndex === orgIndex ? <div className='toggle-btn'>-</div> : <div className='toggle-btn'>+</div>} {" "}  <img src={GetImages.DarkBlueHome} /> <span className=' normal_text fw-500'>{org.description}</span></span>
                          </>}
                      </td>
                      <td colSpan={5} style={{ width: "50%", borderLeft: "0px" }} className="px-3 p-12">
                        <div className="d-flex gap-2 justify-content-end">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip`}>
                                <span>{`$ ${org?.formatted?.IncomeRaw}`}</span></Tooltip>
                            }
                          >
                            <span className="normal_text grey_subtext1 transaction-income">
                              Income: <span className="black normal_text fw-600">{`$ ${org?.formatted?.Income}`}</span>
                            </span>
                          </OverlayTrigger>
                          <SeparaterLine height={"26px"} background={"#e5e7eb"} />
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip`}> <span>{`$ ${org?.formatted?.ExpenseRaw}`}</span></Tooltip>
                            }
                          >
                            <span className="normal_text grey_subtext1 transaction-income">
                              Expenses: <span className="black normal_text fw-600">{`$ ${org?.formatted?.Expense}`}</span>
                            </span>
                          </OverlayTrigger>
                          <SeparaterLine height={"26px"} background={"#e5e7eb"} />
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip`}> <span>{`$ ${org?.formatted?.NetIncomeRaw}`}</span></Tooltip>
                            }
                          >
                            <span className="normal_text grey_subtext1 transaction-income">
                              Net Income: <span className="black normal_text fw-600">{`$ ${org?.formatted?.NetIncome}`}</span>
                            </span>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                    {expandedOrganisationIndex !== null && expandedOrganisationIndex === orgIndex && (
                      <>
                        {orgLoading ? (
                          <tr >
                            <td colSpan={10} style={{ height: "100px" }}>
                              <SimpleSpinner myClassName='Icon40' />
                            </td>
                          </tr>
                        ) : organizationData.length === 0 ? (
                          <>
                            <tr>
                              <td colSpan={10}>
                                <EmptyData mainText="No Data Available" />
                              </td>
                            </tr>
                          </>
                        ) :
                          (
                            organizationData.map((item, txIndex) => {
                              return (
                                <tr key={txIndex} className='tooltip-main-row' style={{ height: "unset" }}>
                                  <td style={{ borderLeft: "none", borderBottom: "none", borderTop: "none" }}>
                                    <div className="normal_text ps-3 h-100" >
                                      <span style={{ borderLeft: "1px solid #E5E7EB" }} className='px-2 d-flex gap-1 align-items-center h-100'
                                      >
                                        {
                                          editOrganizationRowIndex === txIndex ? (
                                            <>
                                              <CustomInputs
                                                value={item?.description}
                                                onChange={(e) => { handleOrganizationDataChange(item, 'description', e.target.value, txIndex) }}
                                                myClassName='border-0'
                                                name={"description"}
                                                type={FORM_CONTROLFIELD_TYPES.TEXT}
                                                isFormControl={true}
                                                placeholder="Enter Description"
                                              />
                                            </>
                                          ) : (
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={<Tooltip id={`tooltip`}>{item?.description}</Tooltip>}
                                            >
                                              <p> {item?.description}</p>
                                            </OverlayTrigger>
                                          )
                                        }


                                      </span>
                                      <hr style={{ margin: "-1px", backgroundColor: "#ddd", opacity: "0.1" }} />
                                    </div>
                                  </td>

                                  <td className="normal_text px-2">
                                    <div>
                                      {
                                        editOrganizationRowIndex === txIndex ? (
                                          <>
                                            <GenericSearchAbleDropdown
                                              key={txIndex}
                                              fieldName={"accountName"}
                                              initialOptions={paymentMethodsArr}
                                              name={"accountName"}
                                              isFormControl={true}
                                              value={Number(item?.paymentMethodId)}
                                              onChange={(e) => handleOrganizationDataChange(item, 'paymentMethodId', Number(e.target.value), txIndex)}
                                              placeholder="Select Payment Method"
                                              myClassName="normal_text border-0"
                                              style={{ height: "46px" }}
                                            />
                                          </>
                                        ) : (
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip`}>{item?.paymentMethodAccountName}</Tooltip>}
                                          >
                                            <p> {item?.paymentMethodAccountName}</p>
                                          </OverlayTrigger>
                                        )
                                      }
                                    </div>
                                  </td>
                                  <td className="normal_text px-2">
                                    {
                                      editOrganizationRowIndex === txIndex ? (
                                        <>
                                          <GenericSearchAbleDropdown
                                            fieldName={"accountName"}
                                            initialOptions={payeeAccountData}
                                            name={"accountName"}
                                            isFormControl={true}
                                            value={Number(item?.payeeAccountId)}
                                            onChange={(e) => { handleOrganizationDataChange(item, 'payeeAccountId', Number(e.target.value), txIndex) }}
                                            placeholder="Select Payee Account"
                                            myClassName="normal_text border-0"
                                            style={{ height: "46px" }}
                                          />
                                        </>
                                      ) : (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip id={`tooltip`}>{item?.payeeAccountName}</Tooltip>}
                                        >
                                          <p> {item?.payeeAccountName}</p>
                                        </OverlayTrigger>
                                      )
                                    }
                                  </td>
                                  <td className="normal_text px-2">
                                    {
                                      editOrganizationRowIndex === txIndex ? (
                                        <>
                                          <GenericSearchAbleDropdown
                                            fieldName={"accountName"}
                                            initialOptions={paymentMethodsArr && paymentMethodsArr}
                                            name={"accountName"}
                                            isFormControl={true}
                                            value={Number(item?.paymentMethodId)}
                                            onChange={(e) => {
                                              handleOrganizationDataChange(item, 'paymentMethodId', Number(e.target.value), txIndex)
                                            }}
                                            placeholder="Select Payment Method"
                                            myClassName="normal_text border-0"
                                            style={{ height: "46px" }}
                                          />
                                        </>
                                      ) : (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip id={`tooltip`}>{item?.formatted?.PaymentMethodTypeId}</Tooltip>}
                                        >
                                          <p style={{ width: "75px" }}> {item?.formatted?.PaymentMethodTypeId}</p>
                                        </OverlayTrigger>
                                      )
                                    }
                                  </td>
                                  <td className="normal_text px-2">
                                    {
                                      editOrganizationRowIndex === txIndex ? (
                                        <>
                                          <CustomInputs
                                            value={item?.reference}
                                            onChange={(e) => { handleOrganizationDataChange(item, 'reference', e.target.value, txIndex) }}
                                            myClassName='border-0'
                                            name={"reference"}
                                            type={FORM_CONTROLFIELD_TYPES.TEXT}
                                            isFormControl={true}
                                            placeholder="Enter Reference No."
                                          />
                                        </>
                                      ) : (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip id={`tooltip`}>{item?.reference}</Tooltip>}
                                        >
                                          <p style={{ width: "75px" }}> {item?.reference}</p>
                                        </OverlayTrigger>)
                                    }
                                  </td>
                                  <td className="normal_text px-2">
                                    {
                                      editOrganizationRowIndex === txIndex ? (
                                        <>
                                          <GenericSearchAbleDropdown
                                            fieldName={"name"}
                                            initialOptions={convertENUMtoJSONArray(TransactionFlowTypesEnum)}
                                            name={"name"}
                                            isFormControl={true}
                                            value={Number(item?.tranactionCreditDebitTypeId)}
                                            onChange={(e) => { handleOrganizationDataChange(item, 'tranactionCreditDebitTypeId', Number(e.target.value), txIndex) }}
                                            placeholder="Select Payment Method"
                                            myClassName="normal_text border-0"
                                            style={{ height: "46px" }}
                                          />
                                        </>
                                      ) : (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip id={`tooltip`}>{item?.formatted?.TranactionCreditDebitTypeId}</Tooltip>}
                                        >
                                          <p style={{ width: "75px" }}>{item?.formatted?.TranactionCreditDebitTypeId}</p>
                                        </OverlayTrigger>
                                      )
                                    }
                                  </td>
                                  <td className=" normal_text px-2 text-end">
                                    {
                                      editOrganizationRowIndex === txIndex ? (
                                        <>
                                          <CustomInputs
                                            value={item?.amount}
                                            onChange={(e) => { handleOrganizationDataChange(item, 'amount', e.target.value, txIndex) }}
                                            myClassName='border-0'
                                            name={"amount"}
                                            type={FORM_CONTROLFIELD_TYPES.TEXT}
                                            isFormControl={true}
                                            placeholder="Enter Amount"
                                          />
                                        </>
                                      ) : (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip id={`tooltip`}>
                                            $ {UtilHelper.formatNegativeValue(item?.amount)}
                                          </Tooltip>}
                                        >
                                          <p style={{ width: "75px" }}>
                                            $ {UtilHelper.formatNegativeValue(item?.amount)}
                                          </p>
                                        </OverlayTrigger>
                                      )
                                    }
                                  </td>
                                  <td className="normal_text px-2 text-end">
                                    {
                                      editOrganizationRowIndex === txIndex ? (
                                        <>
                                          <CustomInputs
                                            value={DateHelper.convertUTCtoDateTime2(item?.paymentDate)}
                                            onChange={(e) => { handleOrganizationDataChange(item, 'paymentDate', new Date(e.target.value), txIndex) }}
                                            myClassName='border-0'
                                            name={"paymentDate"}
                                            type={FORM_CONTROLFIELD_TYPES.DATE}
                                            isFormControl={true}
                                            placeholder="Enter Date"
                                          />
                                        </>
                                      ) : (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip id={`tooltip`}>{item?.formatted?.PaymentDateDateOnly}</Tooltip>}
                                        >
                                          <p style={{ width: "75px" }}>{item?.formatted?.PaymentDateDateOnly}</p>
                                        </OverlayTrigger>
                                      )
                                    }
                                  </td>
                                  <td className="normal_text px-2">
                                    {
                                      editOrganizationRowIndex === txIndex ? (
                                        <>
                                          <CustomInputs
                                            value={item?.transactionId}
                                            onChange={(e) => { handleOrganizationDataChange(item, 'transactionId', e.target.value, txIndex) }}
                                            myClassName='border-0'
                                            name={"transactionId"}
                                            type={FORM_CONTROLFIELD_TYPES.TEXT}
                                            isFormControl={true}
                                            placeholder="Enter Transaction Id"
                                          />
                                        </>
                                      ) : (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip id={`tooltip`}>{item?.transactionId}</Tooltip>}
                                        >
                                          <p>{item?.transactionId}</p>
                                        </OverlayTrigger>)
                                    }
                                    {/* <Tooltip placement="top" className="in tooltip-main sub_text" id="tooltip-bottom">
                                      {item?.transactionId}
                                    </Tooltip> */}
                                  </td>
                                  <td className="px-2 normal_text">
                                    {editOrganizationRowIndex === txIndex ? (
                                      <div className='editViewBtn'>
                                        <AddTraspButton imageValue={GetImages.bluetick} IconClassName="iconstyle14" iconPosition="right" onClick={() => {
                                          handleOrganizationEdit()
                                          handleSaveOrganizationRow(txIndex)
                                          setIsEdit(false)
                                        }} />
                                        <AddTraspButton imageValue={GetImages.greycross} IconClassName="iconstyle12" iconPosition="right" onClick={() => {
                                          handleSaveOrganizationRow(txIndex)
                                          setIsEdit(false)
                                        }} />

                                      </div>

                                    ) : (
                                      <AddTraspButton imageValue={GetImages.BluePencilEdit} iconPosition="right" onClick={() => {
                                        handleEditOrganizationRow(txIndex)
                                        setIsEdit(true)
                                      }} />
                                    )}
                                  </td>
                                </tr>
                              )
                            })
                          )
                        }
                      </>
                    )
                    }

                    {expandedPropertyIndex !== null && expandedPropertyIndex === orgIndex && (
                      <>
                        {propertyLoading ? (
                          <tr>
                            <td colSpan={10} style={{ height: "100px" }}>
                              <SimpleSpinner myClassName='Icon40' />
                            </td>
                          </tr>
                        ) : propertyData.length === 0 ? (
                          <>
                            <tr>
                              <td colSpan={10}>
                                <EmptyData mainText="No Data Available" />
                              </td>
                            </tr>
                          </>
                        ) : (
                          propertyData.map((item, propertytxIndex) => {
                            return (
                              <tr key={propertytxIndex} className='tooltip-main-row' style={{ height: "unset" }}>
                                <td style={{ borderLeft: "none", borderBottom: "none", borderTop: "none" }}>
                                  <div className="normal_text ps-3 h-100" >
                                    <span style={{ borderLeft: "1px solid #E5E7EB" }} className='px-2 d-flex gap-1 align-items-center h-100'>
                                      {
                                        editPropertyRowIndex === propertytxIndex ? (
                                          <>
                                            <CustomInputs
                                              value={item?.description}
                                              onChange={(e) => { handlePropertyDataChange(item, 'description', e.target.value, propertytxIndex) }}
                                              myClassName='border-0'
                                              name={"description"}
                                              type={FORM_CONTROLFIELD_TYPES.TEXT}
                                              isFormControl={true}
                                              placeholder="Enter Description"
                                            />
                                          </>
                                        ) : (
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip`}>{item.description}</Tooltip>}
                                          >
                                            <p>{item.description}</p>
                                          </OverlayTrigger>
                                        )
                                      }

                                    </span>
                                    <hr style={{ margin: "-1px", backgroundColor: "#ddd", opacity: "0.1" }} />
                                  </div>
                                </td>
                                <td className="px-2 normal_text">
                                  {
                                    editPropertyRowIndex === propertytxIndex ? (
                                      <>
                                        <GenericSearchAbleDropdown
                                          key={propertytxIndex}
                                          fieldName={"accountName"}
                                          initialOptions={paymentMethodsArr}
                                          name={"accountName"}
                                          isFormControl={true}
                                          value={Number(item?.paymentMethodId)}
                                          onChange={(e) => handlePropertyDataChange(item, 'paymentMethodId', Number(e.target.value), propertytxIndex)}
                                          placeholder="Select Payment Method"
                                          myClassName="normal_text border-0"
                                          style={{ height: "46px" }}
                                        />
                                      </>
                                    ) : (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip`}>{item.paymentMethodAccountName}</Tooltip>}
                                      >
                                        <p>{item.paymentMethodAccountName}</p>
                                      </OverlayTrigger>
                                    )
                                  }
                                  {/* <Tooltip placement="top" className="in tooltip-main sub_text" id="tooltip-bottom">
                                    {item?.paymentMethodAccountName}
                                  </Tooltip> */}
                                </td>
                                <td className="px-2 normal_text">
                                  {
                                    editPropertyRowIndex === propertytxIndex ? (
                                      <>
                                        <GenericSearchAbleDropdown
                                          fieldName={"accountName"}
                                          initialOptions={payeeAccountData}
                                          name={"accountName"}
                                          isFormControl={true}
                                          value={Number(item?.payeeAccountId)}
                                          onChange={(e) => { handlePropertyDataChange(item, 'payeeAccountId', Number(e.target.value), propertytxIndex) }}
                                          placeholder="Select Payee Account"
                                          myClassName="normal_text border-0"
                                          style={{ height: "46px" }}
                                        />
                                      </>
                                    ) : (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip`}>{item.payeeAccountName}</Tooltip>}
                                      >
                                        <p>{item.payeeAccountName}</p>
                                      </OverlayTrigger>
                                    )
                                  }
                                  {/* <Tooltip placement="top" className="in tooltip-main sub_text" id="tooltip-bottom">
                                    {item?.payeeAccountName}
                                  </Tooltip> */}
                                </td>
                                <td className="px-2 normal_text">
                                  {
                                    editPropertyRowIndex === propertytxIndex ? (
                                      <>
                                        <GenericSearchAbleDropdown
                                          key={propertytxIndex}
                                          fieldName={"accountName"}
                                          initialOptions={paymentMethodsArr}
                                          name={"accountName"}
                                          isFormControl={true}
                                          value={Number(item?.paymentMethodId)}
                                          onChange={(e) => handlePropertyDataChange(item, 'paymentMethodId', Number(e.target.value), propertytxIndex)}
                                          placeholder="Select Payment Method"
                                          myClassName="normal_text border-0"
                                          style={{ height: "46px" }}
                                        />
                                      </>
                                    ) : (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip`}>{item.paymentMethodAccountName}</Tooltip>}
                                      >
                                        <p style={{ width: "75px" }}>{item.paymentMethodAccountName}</p>
                                      </OverlayTrigger>
                                    )
                                  }
                                </td>
                                <td className="px-2 normal_text">
                                  {
                                    editPropertyRowIndex === propertytxIndex ? (
                                      <>
                                        <CustomInputs
                                          value={item?.reference}
                                          onChange={(e) => { handlePropertyDataChange(item, 'reference', e.target.value, propertytxIndex) }}
                                          myClassName='border-0'
                                          name={"reference"}
                                          type={FORM_CONTROLFIELD_TYPES.TEXT}
                                          isFormControl={true}
                                          placeholder="Enter Reference No."
                                        />
                                      </>
                                    ) : (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip`}>{item.reference}</Tooltip>}
                                      >
                                        <p style={{ width: "75px" }}>{item.reference}</p>
                                      </OverlayTrigger>
                                    )
                                  }
                                </td>
                                <td className="px-2 normal_text">
                                  {
                                    editPropertyRowIndex === propertytxIndex ? (
                                      <>
                                        <GenericSearchAbleDropdown
                                          fieldName={"name"}
                                          initialOptions={convertENUMtoJSONArray(TransactionFlowTypesEnum)}
                                          name={"name"}
                                          isFormControl={true}
                                          value={Number(item?.tranactionCreditDebitTypeId)}
                                          onChange={(e) => {
                                            handlePropertyDataChange(item, 'tranactionCreditDebitTypeId', Number(e.target.value), propertytxIndex)
                                          }}
                                          placeholder="Select Category"
                                          myClassName="normal_text border-0"
                                          style={{ height: "46px" }}
                                        />
                                      </>
                                    ) : (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip`}>{item?.formatted?.TranactionCreditDebitTypeId}</Tooltip>}
                                      >
                                        <p style={{ width: "75px" }}>{item?.formatted?.TranactionCreditDebitTypeId}</p>
                                      </OverlayTrigger>
                                    )
                                  }
                                </td>
                                <td className="px-2 normal_text text-end">
                                  {
                                    editPropertyRowIndex === propertytxIndex ? (
                                      <>
                                        <CustomInputs
                                          value={item?.amount}
                                          onChange={(e) => { handlePropertyDataChange(item, 'amount', e.target.value, propertytxIndex) }}
                                          myClassName='border-0'
                                          name={"amount"}
                                          type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                          isFormControl={true}
                                          placeholder="Enter Amount"
                                        />
                                      </>
                                    ) : (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip`}>
                                          $ {UtilHelper.formatNegativeValue(item?.amount)}
                                        </Tooltip>}
                                      >
                                        <p style={{ width: "75px" }}>
                                          $ {UtilHelper.formatNegativeValue(item?.amount)}
                                        </p>
                                      </OverlayTrigger>
                                    )
                                  }
                                </td>
                                <td className="px-2 normal_text text-end">
                                  {
                                    editPropertyRowIndex === propertytxIndex ? (
                                      <>
                                        <CustomInputs
                                          value={DateHelper.convertUTCtoDateTime2(item?.paymentDate)}
                                          onChange={(e) => { handlePropertyDataChange(item, 'paymentDate', new Date(e.target.value), propertytxIndex) }}
                                          myClassName='border-0'
                                          name={"paymentDate"}
                                          type={FORM_CONTROLFIELD_TYPES.DATE}
                                          isFormControl={true}
                                          placeholder="Enter Date"
                                        />
                                      </>
                                    ) : (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip`}>{item?.formatted?.PaymentDateDateOnly}</Tooltip>}
                                      >
                                        <p style={{ width: "75px" }}>{item?.formatted?.PaymentDateDateOnly}</p>
                                      </OverlayTrigger>
                                    )
                                  }
                                </td>
                                <td className="px-2 normal_text">
                                  {
                                    editPropertyRowIndex === propertytxIndex ? (
                                      <>
                                        <CustomInputs
                                          value={item?.transactionId}
                                          onChange={(e) => { handlePropertyDataChange(item, 'transactionId', e.target.value, propertytxIndex) }}
                                          myClassName='border-0'
                                          name={"transactionId"}
                                          type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                          isFormControl={true}
                                          placeholder="Enter Transaction Id"
                                        />
                                      </>
                                    ) : (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip`}>{item?.transactionId}</Tooltip>}
                                      >
                                        <p> {item?.transactionId}</p>
                                      </OverlayTrigger>
                                    )
                                  }

                                </td>
                                <td className="px-2 normal_text">
                                  {editPropertyRowIndex === propertytxIndex ? (
                                    <div className='editViewBtn'>

                                      <AddTraspButton imageValue={GetImages.bluetick} IconClassName="iconstyle14" iconPosition="right" onClick={() => {
                                        handlePropertyEdit()
                                        handleSavePropertyRow(propertytxIndex)
                                        setIsEdit(false)
                                      }} />
                                      <AddTraspButton imageValue={GetImages.greycross} IconClassName="iconstyle12" iconPosition="right" onClick={() => {
                                        handleSavePropertyRow(propertytxIndex)
                                        setIsEdit(false)
                                      }} />
                                    </div>

                                  ) : (
                                    <AddTraspButton imageValue={GetImages.BluePencilEdit} iconPosition="right" onClick={() => {
                                      handleEditPropertyRow(propertytxIndex)
                                      setIsEdit(true)
                                    }} />
                                  )}
                                </td>
                              </tr>
                            )
                          }
                          ))}
                      </>
                    )}

                    {expandedPropertyIndex === orgIndex && propertyDataTX.map((item, index) => {
                      return (
                        <>
                          <tr onClick={() => {
                            setExpandedUnitIndex(expandedUnitIndex === index ? null : index);
                            if (item?.entityId) {
                              unitResponse(item?.entityId);
                              setEditUnitRowIndex(null)
                              setSelectedUnitId(item?.entityId)
                            }
                          }
                          }>
                            <td colSpan={5} style={{ width: "48%", borderRight: "0px", borderLeft: "none", borderBottom: "none", borderTop: "none" }} >
                              <div className="ps-3 h-100">
                                <span style={{ borderLeft: "1px solid #E5E7EB", background: "#F9FAFB" }} className='p-2 d-flex gap-1 h-100 align-items-center'>
                                  {expandedUnitIndex === index ? <div className='toggle-btn'>-</div> : <div className='toggle-btn'>+</div>}
                                  <img src={GetImages.UnitIconBlue} className='iconstyle16' />
                                  <span className='normal_text fw-500 d-flex align-items-center'> {item.description}</span>
                                </span>
                                <hr style={{ margin: "0px", backgroundColor: "#ddd", opacity: "0.1" }} />
                              </div>
                            </td>
                            <td colSpan={5} style={{ width: "50%", borderLeft: "0px", background: "#F9FAFB" }} className="px-3">
                              <div className="d-flex gap-2 justify-content-end">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip`}> <span>{`$ ${item?.formatted?.IncomeRaw}`}</span></Tooltip>
                                  }
                                >
                                  <span className="normal_text grey_subtext1 transaction-income">
                                    Income: <span className="black normal_text fw-600">{`$ ${item?.formatted?.Income}`}</span>
                                  </span>
                                </OverlayTrigger>
                                <SeparaterLine height={"26px"} background={"#e5e7eb"} />
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip`}> <span>{`$ ${item?.formatted?.ExpenseRaw}`}</span></Tooltip>
                                  }
                                >
                                  <span className="normal_text grey_subtext1 transaction-income">
                                    Expenses: <span className="black normal_text fw-600">{`$ ${item?.formatted?.Expense}`}</span>
                                  </span>
                                </OverlayTrigger>
                                <SeparaterLine height={"26px"} background={"#e5e7eb"} />
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip`}> <span>{`$ ${item?.formatted?.NetIncomeRaw}`}</span></Tooltip>
                                  }
                                >
                                  <span className="normal_text grey_subtext1 transaction-income">
                                    Net Income: <span className="black normal_text fw-600">{`$ ${item?.formatted?.NetIncome}`}</span>
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </td>
                          </tr>
                          {expandedUnitIndex !== null && expandedUnitIndex === index && (
                            <>
                              {unitLoading ? (
                                <tr>
                                  <td colSpan={10} style={{ height: "100px" }}>
                                    <SimpleSpinner myClassName='Icon40' />
                                  </td>
                                </tr>
                              ) : unitData.length === 0 ? (
                                <>
                                  <tr>
                                    <td colSpan={9}>
                                      <EmptyData mainText="No Data Available" />
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                unitData.map((item, unitTxIndex) => {
                                  return (
                                    <tr key={unitTxIndex} style={{ height: "unset" }}>
                                      <td style={{ borderLeft: "none", borderBottom: "none", borderTop: "none" }}>
                                        <div className="normal_text ps-3 h-100" >
                                          <span style={{ borderLeft: "1px solid #E5E7EB" }} className='px-2 d-flex gap-1 align-items-center h-100'>
                                            {
                                              editUnitRowIndex === unitTxIndex ? (
                                                <>
                                                  <CustomInputs
                                                    value={item?.description}
                                                    onChange={(e) => { handleUnitDataChange(item, 'description', e.target.value, unitTxIndex) }}
                                                    myClassName='border-0'
                                                    name={"description"}
                                                    type={FORM_CONTROLFIELD_TYPES.TEXT}
                                                    isFormControl={true}
                                                    placeholder="Enter Description"
                                                  />
                                                </>
                                              ) : (
                                                <OverlayTrigger
                                                  placement="top"
                                                  overlay={<Tooltip id={`tooltip`}>{item?.description}</Tooltip>}
                                                >
                                                  <p> {item?.description}</p>
                                                </OverlayTrigger>
                                              )
                                            }
                                          </span>
                                          <hr style={{ margin: "-1px", backgroundColor: "#ddd", opacity: "0.1" }} />
                                        </div>

                                      </td>
                                      <td className="px-2 normal_text">
                                        {
                                          editUnitRowIndex === unitTxIndex ? (
                                            <>
                                              <GenericSearchAbleDropdown
                                                key={unitTxIndex}
                                                fieldName={"accountName"}
                                                initialOptions={paymentMethodsArr}
                                                name={"accountName"}
                                                isFormControl={true}
                                                value={Number(item?.paymentMethodId)}
                                                onChange={(e) => handleUnitDataChange(item, 'paymentMethodId', Number(e.target.value), unitTxIndex)}
                                                placeholder="Select Payment Method"
                                                myClassName="normal_text border-0"
                                                style={{ height: "46px" }}
                                              />
                                            </>
                                          ) : (
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={<Tooltip id={`tooltip`}>{item?.paymentMethodAccountName}</Tooltip>}
                                            >
                                              <p> {item?.paymentMethodAccountName}</p>
                                            </OverlayTrigger>
                                          )
                                        }
                                      </td>
                                      <td className="px-2 normal_text">
                                        {
                                          editUnitRowIndex === unitTxIndex ? (
                                            <>
                                              <GenericSearchAbleDropdown
                                                fieldName={"accountName"}
                                                initialOptions={payeeAccountData}
                                                name={"accountName"}
                                                isFormControl={true}
                                                value={Number(item?.payeeAccountId)}
                                                onChange={(e) => { handleUnitDataChange(item, 'payeeAccountId', Number(e.target.value), unitTxIndex) }}
                                                placeholder="Select Payee Account"
                                                myClassName="normal_text border-0"
                                                style={{ height: "46px" }}
                                              />
                                            </>
                                          ) : (
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={<Tooltip id={`tooltip`}>{item?.payeeAccountName}</Tooltip>}
                                            >
                                              <p> {item?.payeeAccountName}</p>
                                            </OverlayTrigger>
                                          )
                                        }

                                      </td>
                                      <td className="px-2 normal_text">
                                        {
                                          editUnitRowIndex === unitTxIndex ? (
                                            <>
                                              <GenericSearchAbleDropdown
                                                fieldName={"accountName"}
                                                initialOptions={paymentMethodsArr && paymentMethodsArr}
                                                name={"accountName"}
                                                isFormControl={true}
                                                value={Number(item?.paymentMethodId)}
                                                onChange={(e) => {
                                                  handleUnitDataChange(item, 'paymentMethodId', Number(e.target.value), unitTxIndex)
                                                }}
                                                placeholder="Select Payment Method"
                                                myClassName="normal_text border-0"
                                                style={{ height: "46px" }}
                                              />
                                            </>
                                          ) : (
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={<Tooltip id={`tooltip`}>{item?.formatted?.PaymentMethodTypeId}</Tooltip>}
                                            >
                                              <p style={{ width: "75px" }}>{item?.formatted?.PaymentMethodTypeId}</p>
                                            </OverlayTrigger>
                                          )
                                        }
                                      </td>

                                      <td className="px-2 normal_text">
                                        {editUnitRowIndex === unitTxIndex ? (
                                          <>
                                            <CustomInputs
                                              value={item?.reference}
                                              onChange={(e) => { handleUnitDataChange(item, 'reference', e.target.value, unitTxIndex) }}
                                              myClassName='border-0'
                                              name={"reference"}
                                              type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                              isFormControl={true}
                                              placeholder="Enter Reference No."
                                            />
                                          </>
                                        ) : (
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip`}>{item?.reference}</Tooltip>}
                                          >
                                            <p style={{ width: "75px" }}>{item?.reference}</p>
                                          </OverlayTrigger>
                                        )
                                        }
                                      </td>

                                      <td className="px-2 normal_text">
                                        {editUnitRowIndex === unitTxIndex ? (
                                          <>
                                            <GenericSearchAbleDropdown
                                              fieldName={"name"}
                                              initialOptions={convertENUMtoJSONArray(TransactionFlowTypesEnum)}
                                              name={"name"}
                                              isFormControl={true}
                                              value={Number(item?.tranactionCreditDebitTypeId)}
                                              onChange={(e) => { handleUnitDataChange(item, 'tranactionCreditDebitTypeId', Number(e.target.value), unitTxIndex) }}
                                              placeholder="Select Category"
                                              myClassName="normal_text border-0"
                                              style={{ height: "46px" }}
                                            />
                                          </>
                                        ) : (
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip`}>{item?.formatted?.TranactionCreditDebitTypeId}</Tooltip>}
                                          >
                                            <p style={{ width: "75px" }}>{item?.formatted?.TranactionCreditDebitTypeId}</p>
                                          </OverlayTrigger>
                                        )
                                        }
                                      </td>
                                      <td className="px-2 normal_text text-end">
                                        {
                                          editUnitRowIndex === unitTxIndex ? (
                                            <>
                                              <CustomInputs
                                                value={item?.amount}
                                                onChange={(e) => { handleUnitDataChange(item, 'amount', e.target.value, unitTxIndex) }}
                                                myClassName='border-0'
                                                name={"amount"}
                                                type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                                isFormControl={true}
                                                placeholder="Enter Amount"
                                              />
                                            </>
                                          ) : (
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={<Tooltip id={`tooltip`}>
                                                $ {UtilHelper.formatNegativeValue(item?.amount)}
                                              </Tooltip>}
                                            >
                                              <p style={{ width: "75px" }}>
                                                $ {UtilHelper.formatNegativeValue(item?.amount)}
                                              </p>
                                            </OverlayTrigger>
                                          )
                                        }
                                      </td>
                                      <td className="px-2 normal_text text-end">
                                        {
                                          editUnitRowIndex === unitTxIndex ? (
                                            <>
                                              <CustomInputs
                                                value={DateHelper.convertUTCtoDateTime2(item?.paymentDate)}
                                                onChange={(e) => { handleUnitDataChange(item, 'paymentDate', new Date(e.target.value), unitTxIndex) }}
                                                myClassName='border-0'
                                                name={"paymentDate"}
                                                type={FORM_CONTROLFIELD_TYPES.DATE}
                                                isFormControl={true}
                                                placeholder="Enter Date"
                                              />
                                            </>
                                          ) : (
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={<Tooltip id={`tooltip`}>{item?.formatted?.PaymentDateDateOnly}</Tooltip>}
                                            >
                                              <p style={{ width: "75px" }}>{item?.formatted?.PaymentDateDateOnly}</p>
                                            </OverlayTrigger>
                                          )
                                        }
                                      </td>
                                      <td className="px-2 normal_text">
                                        {
                                          editUnitRowIndex === unitTxIndex ? (
                                            <>
                                              <CustomInputs
                                                value={item?.transactionId}
                                                onChange={(e) => { handleUnitDataChange(item, 'transactionId', e.target.value, unitTxIndex) }}
                                                myClassName='border-0'
                                                name={"transactionId"}
                                                type={FORM_CONTROLFIELD_TYPES.TEXT}
                                                isFormControl={true}
                                                placeholder="Enter Transaction Id"
                                              />
                                            </>
                                          ) : (
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={<Tooltip id={`tooltip`}> {item?.transactionId}</Tooltip>}
                                            >
                                              <p> {item?.transactionId}</p>
                                            </OverlayTrigger>
                                          )
                                        }
                                      </td>
                                      <td className="px-2 normal_text">
                                        {editUnitRowIndex === unitTxIndex ? (
                                          <div className='editViewBtn'>
                                            <AddTraspButton imageValue={GetImages.bluetick} IconClassName="iconstyle14" iconPosition="right" onClick={() => {
                                              handleUnitEdit()
                                              handleSaveUnitRow(unitTxIndex)
                                              setIsEdit(false)
                                            }} />
                                            <AddTraspButton imageValue={GetImages.greycross} IconClassName="iconstyle12" iconPosition="right" onClick={() => {
                                              handleSaveUnitRow(unitTxIndex)
                                              setIsEdit(false)
                                            }} />
                                          </div>

                                        ) : (
                                          <AddTraspButton imageValue={GetImages.BluePencilEdit} iconPosition="right" onClick={() => {
                                            handleEditUnitRow(unitTxIndex)
                                            setIsEdit(true)
                                          }} />
                                        )}
                                      </td>
                                    </tr>
                                  )
                                }
                                )
                              )}
                            </>
                          )}
                        </>
                      )
                    })}
                  </React.Fragment>
                );
              })}
            </tbody>
          ) : (
            loading &&
            (
              <tbody >
                <tr>
                  <td colSpan={10}>
                    <SimpleSpinner />
                  </td>
                </tr>
              </tbody>
            )
          )}
        </table>
      </div >
    </>
  );
};

export default TransactionTable;
