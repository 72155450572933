import { ChangeEvent, useEffect, useState } from "react";
import { GetImages } from "../../../utils/GetImages";
import EditCustomComponent from "../../../components/tenants/EditCustomComponent";
import { useNavigate } from "react-router";
import "./profile.css";
import { TenantService } from "@propertelligent/client-api";
import { UserLoginData } from "../../../components/common/charts/Enums";
import apiHelper from "../../../utils/apiHelper";
import { Form } from "react-bootstrap";
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validateNumber,
} from "../../../utils/validateHelper";
import { useError } from "../../../utils/context/ErrorContext";
import AddButtonWithArrow from "../../../components/firstTimeUserExperience/commonCom/AddButtonWithArrow";

const MyProfileView = () => {
  const { errors, errorMessages, setErrors, setErrorMessages, clearErrors } = useError();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    switch (name) {
      case "email":
        setErrors({ ...errors, email: false });
        setErrorMessages({ ...errorMessages, email: "Email is required" });
        break;
      case "firstName":
        setErrors({ ...errors, firstName: false });
        setErrorMessages({
          ...errorMessages,
          firstName: "First Name is required",
        });
        break;
      case "lastName":
        setErrors({ ...errors, lastName: false });
        setErrorMessages({
          ...errorMessages,
          lastName: "Last Name is required",
        });
        break;
      case "phoneNumber":
        setErrors({ ...errors, phoneNumber: false });
        setErrorMessages({
          ...errorMessages,
          phoneNumber: "Phone Number is required",
        });
        break;
    }

    setValues({ ...values, [name]: value });
  };

  const formDetails = [
    {
      label: "First name",
      placeholder: "Enter first name",
      value: values.firstName,
      name: "firstName",
      blurFunction: () => validateFirstName(values.firstName, setErrors),
      errorMessages: errorMessages.firstName,
      isError: errors.firstName,
    },
    {
      label: "Last name",
      placeholder: "Enter last name",
      value: values.lastName,
      name: "lastName",
      blurFunction: () => validateLastName(values.lastName, setErrors),
      errorMessages: errorMessages.lastName,
      isError: errors.lastName,
    },
    {
      label: "Email address",
      placeholder: "Enter email",
      value: values.email,
      name: "email",
      blurFunction: () =>
        validateEmail(values.email, setErrors, setErrorMessages),
      errorMessages: errorMessages.email,
      isError: errors.email,
    },
    {
      label: "Phone number",
      placeholder: "Enter phone no.",
      value: values.phoneNumber,
      name: "number",
      blurFunction: () => validateNumber(values.phoneNumber, setErrors),
      errorMessages: errorMessages.phoneNumber,
      isError: errors.phoneNumber,
      maxLength: 10
    },
  ];

  const checkEmptyFields = (): void => {
    clearErrors();
    if (values.email.trim() === "") {
      setErrors({ ...errors, email: true });
    }

    if (values.firstName.trim() === "") {
      setErrors({ ...errors, firstName: true });
    }

    if (values.lastName.trim() === "") {
      setErrors({ ...errors, lastName: true });
    }

    if (values.phoneNumber.trim() === "") {
      setErrors({ ...errors, phoneNumber: true });
    }
  };

  const submitTimeError = () => {
    return (
      errors.firstName ||
      errors.lastName ||
      errors.email ||
      errors.phoneNumber ||
      values.firstName.trim() === "" ||
      values.lastName.trim() === "" ||
      values.phoneNumber.trim() === "" ||
      values.email.trim() === ""
    );
  };

  const getProfileData = () => {
    const tanantId = Number(localStorage.getItem(UserLoginData.tenant_id));
    apiHelper(
      TenantService.getApiTenant1,
      { showNotification: false },
      tanantId
    )
      .then((res) => {
        setValues({
          ...values,
          firstName: res?.data?.success?.firstName,
          lastName: res?.data?.success?.lastName,
          email: res?.data?.success?.email,
          phoneNumber: res?.data?.success?.phoneNumber,
        });
      })
      .catch((error) => { });
  };

  useEffect(() => {
    getProfileData();
    clearErrors();
  }, []);

  const handleEditDetails = (e) => {
    e.preventDefault();
    checkEmptyFields();
    if (submitTimeError()) {
    } else {
      const newValues = {
        ...values,
        id: Number(localStorage.getItem(UserLoginData.tenant_id)),
      };

      apiHelper(
        TenantService.putApiTenant,
        {
          successMessage: "Your Profile Details Has been updated ",
          failureMessage: "Unable to Update Profile Details",
          showNotification: true,
        },
        newValues
      ).then(({ data }) => {
        setValues({
          ...values,
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
        });
        setIsEditing(false);
        getProfileData();
      });
    }
  };

  return (
    <>
      <div style={{ background: "white" }}>
        <div className="singleTenantTopbar">
          <div
            onClick={() => {
              navigate("/");
            }}
            className="clickable"
          >
            <p className="normal_text fw-500 black">Dashboard</p>
          </div>
          <img src={GetImages.BlackChevronRight} />
          <p className="normal_texzt fw-500 grey">My Profile</p>
        </div>

        <Form onSubmit={handleEditDetails}>
          <div className="organizationHeading top_header d-flex justify-content-between flex-wrap">
            <p>Profile</p>

            {!isEditing && (
              <AddButtonWithArrow
                buttonname="Edit Detail"
                onClick={() => setIsEditing(true)}
                iconDirection="left"
                Icon={GetImages.WhiteForwardArrow}
              />
            )}
          </div>

          <div className="profileForm">
            <div className="d-flex addContractorInputContainer">
              {formDetails.map((item) => {
                return (
                  <EditCustomComponent
                    key={item.label}
                    label={item.label}
                    placeholder={item.placeholder}
                    value={item.value}
                    name={item.name}
                    onChangeFunction={handleChange}
                    onBlurFunction={item.blurFunction}
                    errorMessages={item.errorMessages}
                    isError={item.isError}
                    width="290px"
                    disabled={!isEditing}
                    maxLength={item.maxLength}
                  />
                );
              })}
            </div>
            <div>
              {isEditing && (
                <div>
                  <AddButtonWithArrow
                    buttonname="Save Changes"
                    iconDirection="left"
                    Icon={GetImages.WhiteForwardArrow}
                    type="submit"
                  />
                </div>
              )}
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default MyProfileView;
