import Form from "react-bootstrap/Form";
import "./tenantStyles.css";

function NewSelectAuto({
  data,
  disabled,
  setDisabled,
  selected,
  setSelected,
  title,
  type = "Option",
  setResetDependencies = null,
  error,
  setError,
  fromContractor = false,
  label = "",
  width = "298px",
  setSelectedOption = null,
  selectedOption = null,
  errorMessage = undefined
}) {
  const handleChange = (e) => {
    setError(false);
    if (setResetDependencies) {
      setResetDependencies([]);
    }
    setSelected && setSelected(Number(e.target.value));
    if (setSelectedOption) {
      setSelectedOption(e.target.options[e.target.selectedIndex].text);
    }
  };

  return (
    <div
      style={{
        width: width,
      }}
      title={disabled ? title : ""}
      className={
        disabled
          ? " customEditTenantInputContainerStyles disabled"
          : "customEditTenantInputContainerStyles"
      }
    >
      {fromContractor && (
        <div>
          <p className={`${label ? "normal_text black mb-2"  :""} `}>{label}</p>
        </div>
      )}

      <Form.Select
        className={disabled ? "disabled FormSelectCustom" : "FormSelectCustom"}
        onChange={handleChange}
        aria-label="Default select example"
        style={{ color: selected === null ? "#9ca3af" : "#111928", border: error ? "1px solid #F05252" : "1px solid #e5e7eb" }}
        value={selected || ""}
      >
        <option value="" disabled>{`Select ${type}`}</option>

        {data && data.length > 0 ? (
          data.map((item) => (
            <option key={item?.id} value={item?.id}>
              {type === "property"
                ? item?.name
                : type === "unit"
                  ? item?.unitNumber
                  : item?.name}
            </option>
          ))
        ) : (
          <option disabled>No {type} listed</option>
        )}
      </Form.Select>
      {error && <p className="errorMessage">{errorMessage}</p>}
    </div>
  );
}

export default NewSelectAuto;
