import { useState } from "react";
import { users } from "../../assets/index";
import DateHelper from "../../utils/DateHelper";
import { GetImages } from "../../utils/GetImages";
import SeparaterLine from "../common/SeparaterLine";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const ServiceRequestInfo = ({ serviceReqInfo }) => {
  return (
    <>
      <div
        style={{ marginTop: "20px" }}
        className="singleTenantNameDetailsLeftSide"
      >
        <div className="serviceRequestData">
          <div>
            <div className="singleTenantUnitOr flex-wrap">
              <span className="sub_text black d-flex gap-1 align-items-center">
                <img
                  height={18}
                  width={18}
                  src={GetImages.OrangeUnit}
                />
                <p> {serviceReqInfo?.unitNumber} - {serviceReqInfo?.streetAddress}</p>
              </span>
              <SeparaterLine height={"25px"} background={undefined} />
              <span className="sub_text black ms-0 d-flex gap-1 align-items-center">
                <img
                  height={16}
                  width={16}
                  src={GetImages.OrangeCalendar}
                />
                <p className="grey_subtext1">  Raised on : {DateHelper.convertUTCtoDateTimeSimple(serviceReqInfo?.formatted?.RequestDateDateOnly)}</p>
              </span>
              <SeparaterLine height={"25px"} background={undefined} />
              <span className="sub_text black ms-0 d-flex gap-1 align-items-center">
                <img
                  height={18}
                  width={18}
                  src={users}
                />
                <p> Created by: {serviceReqInfo?.createdBy}</p>
              </span>
              <SeparaterLine height={"25px"} background={undefined} />
              <span className="sub_text black ms-0 d-flex gap-1 align-items-center">
                <img
                  height={18}
                  width={18}
                  src={users}
                />
                <p>Tenant: {serviceReqInfo?.tenantInfo?.firstName} {" "}{serviceReqInfo?.tenantInfo?.lastName}</p>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      <div className="tenant-info-tooltip">
                        <div className="d-flex justify-content-between align-items-center mb-2 gap-4">
                          <p className="sub_text">Email</p>
                          <p className="sub_text fw-500">{serviceReqInfo?.tenantInfo?.email}</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center gap-4">
                          <p className="sub_text">Phone No.</p>
                          <p className="sub_text fw-500">{serviceReqInfo?.tenantInfo?.phoneNumber}</p>
                        </div>
                      </div>
                    </Tooltip>
                  }
                >
                  <img className="clickable" aria-describedby="tooltip" width={16} height={16} src={GetImages.InfoIcon} />
                </OverlayTrigger>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
