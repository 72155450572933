import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker, Range, StaticRange } from 'react-date-range';
import { enUS } from 'date-fns/locale';
import { GetImages } from '../../../../utils/GetImages';
import DateHelper from '../../../../utils/DateHelper';
import AddButtonWithArrow from '../../../firstTimeUserExperience/commonCom/AddButtonWithArrow';

interface RangeDatePickerProps {
    range: Range[];
    customRanges: StaticRange[];
    onChangeHandler: (ranges: Range[]) => void;
    relativeDateString: string;
    setRelativeDateString: (arg?: any) => void;
    fetchData?: () => void;
    applyButton?: boolean;
}

const RangeDatePicker: React.FC<RangeDatePickerProps> = ({ range, onChangeHandler, setRelativeDateString, relativeDateString, customRanges, fetchData, applyButton }) => {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [isDateSelected, setIsDateSelected] = useState(false);
    const [selectedRangeLabel, setSelectedRangeLabel] = useState<string | null>(null);
    const datePickerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (relativeDateString) {
            const matchedRange = customRanges.find(range => range.label === relativeDateString)
            if (matchedRange) {
                setIsDateSelected(true)
            }
        }
    }, [relativeDateString])

    const handleSelect = (item: any) => {
        setIsDateSelected(true)
        const selectedRange = customRanges.find(
            range => range.range().startDate.getTime() === item.selection.startDate.getTime() &&
                range.range().endDate.getTime() === item.selection.endDate.getTime()
        );
        const label = selectedRange ? selectedRange.label : 'Custom';
        setRelativeDateString(label);
        setSelectedRangeLabel(label)
        onChangeHandler([item.selection])
    };

    const handleClose = (event: MouseEvent) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
            setShowDatePicker(false);
        }
    };

    useEffect(() => {
        if (showDatePicker) {
            document.addEventListener('mousedown', handleClose);
        } else {
            document.removeEventListener('mousedown', handleClose);
        }

        return () => {
            document.removeEventListener('mousedown', handleClose);
        };
    }, [showDatePicker]);

    const handleApply = () => {
        setShowDatePicker(false);
        fetchData()
    };

    return (
        <div ref={datePickerRef}>
            <button type='button' className='durationButton' onClick={() => setShowDatePicker(!showDatePicker)}>
                <img src={GetImages.BlueCalendar} alt='' />
                {isDateSelected ? `${DateHelper.formatDateonly(String(range[0].startDate))} - ${DateHelper.formatDateonly(String(range[0].endDate))}` : 'Duration'}
            </button>

            {showDatePicker && (

                <DateRangePicker
                    onChange={handleSelect}
                    showPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={range}
                    staticRanges={customRanges}
                    inputRanges={[]}
                    direction="horizontal"
                    locale={enUS}
                    footerContent={applyButton ? (
                        <AddButtonWithArrow
                            onClick={handleApply}
                            buttonname={"Apply"}
                        />
                    ) : null}
                />

            )}
        </div>
    );
};

export default RangeDatePicker;
