import ServiceIcon from "../common/serviceTypeImage";
import DateHelper from "../../utils/DateHelper";
import StatusTag from "../common/StatusTag";
import { GetImages } from "../../utils/GetImages";
import { ServiceRequestInfo } from "./serviceRequestInfo";
import { ServiceNotes } from "./serviceNotes";
import SeparaterLine from "../common/SeparaterLine";

const EditServiceRequestHeader = ({ serviceReqInfo }) => {
  const getStatusColorStyle = (status) => {
    switch (status) {
      case "Completed":
        return { backgroundColor: "#DEF7EC", color: "#057A55", label: "green" };
      case "Payment Pending":
        return {
          backgroundColor: "#FDE8E8",
          color: "#E02424",
          label: "red",
        };
      case "In Progress":
        return {
          backgroundColor: "#FDF6B2",
          color: "#C58C07",
          label: "yellow",
        };
      case "New Request":
        return {
          backgroundColor: "#E5EDFF",
          color: "#5850EC",
          label: "lightBlue",
        };
      case "Open":
        return {
          backgroundColor: "#FDF6B2",
          color: "#C58C07",
          label: "yellow",
        };
      case "Estimating":
        return {
          backgroundColor: "#E5EDFF",
          color: "#5850EC",
          label: "lightBlue",
        };
      case "Verification Pending":
        return { backgroundColor: "#E1EFFE", color: "#C58C07", label: "blue" };
    }
  };

  return (
    <>
      <div className="serviceCardConatiner border-0" id="seviceDetail">
        <div className="propertCardLeft" style={{ maxWidth: "100%" }}>
          <div
            className="imgcommonstyle"
            style={{ borderRadius: "50%", overflow: "hidden" }}
          >
            <ServiceIcon serviceType={serviceReqInfo?.formatted?.ServiceType} />
          </div>
          <div className="w-100 d-flex justify-content-between flex-column gap-3">

            <div className="d-flex justify-content-between flex-wrap">
              <div className="propertyCardDisc">
                <div className="propertyCardDetails ">
                  <div className="d-flex align-items-center ">
                    <span className="sub_text  grey_subtext1">
                      {serviceReqInfo?.formatted?.ServiceSubType}
                    </span>
                  </div>

                  <div>
                    <span className="normal_text fw-bold">
                      {serviceReqInfo?.formatted?.ServiceType}
                    </span>
                  </div>
                </div>
                <div className="propertyCardQuantity">
                  <div className="d-flex align-items-center ">
                    {/* {serviceReqInfo?.unitNumber != "" && ( */}
                    <ServiceRequestInfo serviceReqInfo={serviceReqInfo} />
                    {/* )} */}
                  </div>
                </div>
              </div>

              <div >
                <div className="view-attachment">
                  <div className="justify-content-end">
                    <div className="propertCardRight w-100">
                      <StatusTag
                        color={
                          getStatusColorStyle(
                            serviceReqInfo?.formatted?.ServiceRequestStatus
                          )?.label
                        }
                        statusText={serviceReqInfo?.formatted?.ServiceRequestStatus}
                        isLong
                      />
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-end"
                    style={{ marginTop: "12px" }}
                  >
                    <img
                      src={GetImages.attach_file_skyblue}
                      className="iconstyle "
                      style={{ marginRight: "4px" }}
                    />
                    <span
                      className="sub_text fw-600 blue ml-1 clickable"
                    >
                      View attachements
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <ServiceNotes serviceReqInfo={serviceReqInfo?.description} />
          </div>
        </div>

      </div>
    </>
  );
};

export default EditServiceRequestHeader;

