import { useState } from "react";
import { GetImages } from "../../utils/GetImages";
import NameAvatarComponent from "./NameAvatarComponent";

const SingleNoteComponent = ({ notesData, date, name, showNotes = false, handleEdit = undefined, handleDelete = undefined }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedNote, setEditedNote] = useState("");

  const nameParts = name.split(/\s+/).filter(Boolean);
  const firstName = nameParts[0] || "";
  const lastName = nameParts[1] || "";

  const handleNoteEdit = () => {
    setIsEditing(true);
    setEditedNote(notesData?.noteData)
  };

  const handleSaveNote = () => {
    setIsEditing(false);
    if (handleEdit) {
      handleEdit(Number(notesData.id), editedNote);
    }
  };

  return (
    <div className="singleMessage d-flex align-items-start gap-2 mb-2">
      {!showNotes ?
        <NameAvatarComponent
          heightWidth={32}
          firstName={firstName}
          lastName={lastName}
        /> : null}
      <div className={`${showNotes && "w-100"} d-flex flex-column gap-2 mb-2`}>
        <div className={showNotes ? "notesTextStyle w-100" : "messageTextStyle sub_text"}>
          <div className={showNotes ? "d-flex justify-content-between" : ""}>
            {isEditing ? (
              <textarea
                value={editedNote}
                onChange={(e) => setEditedNote(e.target.value)}
                className="editNoteTextarea"
                style={{
                  width: "100%",
                  height: "100px",
                  border: "none",
                  resize: "none",
                  outline: "none",
                  background: "transparent"
                }}
              />
            ) : (
              <p className="normal_text">{notesData && notesData.noteData}</p>
            )}

            {showNotes && !isEditing && (
              <div className="d-flex gap-2 justify-content-end">
                <img
                  src={GetImages.BlueEdit}
                  alt=""
                  className="clickable"
                  style={{ height: "24px" }}
                  onClick={handleNoteEdit}
                />
                <img
                  src={GetImages.BlueDelete}
                  alt=""
                  className="clickable"
                  style={{ height: "24px" }}
                  onClick={handleDelete}
                />
              </div>
            )}
          </div>


          {/* Save and Cancel buttons during editing */}
          {isEditing && (
            <div className="d-flex justify-content-end gap-2 mt-2">
              <button
                className="btn btn-secondary clickable notesCancelbtn sub_text fw-600 grey_subtext1"
                onClick={() => setIsEditing(false)} // Cancel editing
              >
                Cancel
              </button>
              <button
                className="btn btn-primary clickable notesSavebtn sub_text fw-600 "
                onClick={handleSaveNote} // Save edited note
              >
                Save
              </button>
            </div>
          )}
        </div>

        {showNotes ?
          <div className="d-flex align-items-center gap-2">
            <NameAvatarComponent
              heightWidth={18}
              firstName={firstName}
              lastName={lastName}
              fontSize={"8px"}
            />
            <p className="small_text grey">{name} {date}</p>
          </div>
          : <p className="small_text grey">{date}</p>
        }
      </div>
    </div>
  );
};

export default SingleNoteComponent;
