import EditCustomComponent from '../../../tenants/EditCustomComponent';
import { Col, Form, Image, Row } from "react-bootstrap";
import Uploader from '../../../firstTimeUserExperience/commonCom/Uploader';
import { useError } from '../../../../utils/context/ErrorContext';
import { GetImages } from '../../../../utils/GetImages';
import AddButtonWithArrow from '../../../firstTimeUserExperience/commonCom/AddButtonWithArrow';
import AddTraspButton from '../../../firstTimeUserExperience/commonCom/AddTraspButton';
import { useEffect } from 'react';
import DisplayNamewithLeftBorder from '../../../common/DisplayNamewithLeftBorder';
import AddressAutocomplete from '../../../firstTimeUserExperience/commonCom/AddressAutocomplete';

const BasicDetailsForm = ({
    handleChangeMainImage, handleDelete, mainImage, setMainImage,
    formDetails, handleChange, isUploader = false, submitHandler = undefined,
    setShowModal, propertyValue, setPropertyvalue, values, setValues
}) => {
    const { errors, errorMessages, setErrors } = useError()

    useEffect(() => {
        if (propertyValue?.address) {
            setValues({
                ...values,
                streetAddress: propertyValue?.address?.streetAddress,
                city: propertyValue?.address?.city,
                state: propertyValue?.address?.state,
                zip: propertyValue?.address?.zip
            })
            setErrors(({ ...errors, streetAddress: false, state: false, city: false, billingZipCode: false }));
        }
    }, [propertyValue])

    return (
        <>
            <Form onSubmit={submitHandler} className='edit_basic_details'>
                <div className='basic_details_container'>
                    {isUploader &&
                        <Row className="paymentinputrow">
                            <Col>
                                <div className="uploader-add-tenant mb-2 ps-0">
                                    <div className="addressUploader">
                                        <div className="uploader clickable" style={{ flexDirection: "row" }}>
                                            <Uploader
                                                handleChange={handleChangeMainImage}
                                                mainImage={mainImage}
                                                name="Add Logo"
                                                Size={{ size: "Icon100", iconSize: "Icon32" }}
                                            />
                                            {mainImage && (
                                                <div>
                                                    <div className="deletereplacemain">
                                                        <div
                                                            onClick={() => setMainImage(null)}
                                                            className="deletereplace"
                                                        >
                                                            <Image src={GetImages.BlueReplace} className="iconstyle" />
                                                            <p className="sub_text blue fw-500">Replace</p>
                                                        </div>
                                                        <div className="divider"></div>
                                                        <div onClick={handleDelete} className="deletereplace">
                                                            <Image src={GetImages.deleteIconRed} className="iconstyle" />
                                                            <p className="sub_text red fw-500">Delete</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {(errors.ImageType || errors.ImageSize) && (
                                                <div style={{ margin: "8px 0px" }}>
                                                    {errors.ImageType && (
                                                        <p className="errorMessageStyles">{errorMessages.ImageType}</p>
                                                    )}
                                                    {errors.ImageSize && (
                                                        <p className="errorMessageStyles">{errorMessages.ImageSize}</p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {!mainImage && <p className='small_text grey_subtext1'>
                                    Image format jpeg and png. Max image size 2 MB
                                </p>}
                            </Col>
                        </Row>
                    }
                    <div className="d-flex addContractorInputContainer1">
                        {formDetails?.basicDetails && formDetails?.basicDetails.map((item, index) => {
                            if (item.name === "phoneNumber" && formDetails?.basicDetails[index + 1]?.name === "email") {
                                return (
                                    <div className="form-row">
                                        <EditCustomComponent
                                            label={item.label}
                                            placeholder={item.placeholder}
                                            value={item.value}
                                            name={item.name}
                                            onChangeFunction={handleChange}
                                            onBlurFunction={item.blurFunction}
                                            errorMessages={item.errorMessages}
                                            isError={item.isError}
                                            linkWebsite={item.linkWebsite}
                                            width={item.width}
                                            borderRadius="4px"
                                            type={item.type}
                                            required={item.required}
                                            maxLength={item.maxLength}
                                        />

                                        <EditCustomComponent
                                            label={formDetails?.basicDetails[index + 1].label}
                                            placeholder={formDetails?.basicDetails[index + 1].placeholder}
                                            value={formDetails?.basicDetails[index + 1].value}
                                            name={formDetails?.basicDetails[index + 1].name}
                                            onChangeFunction={handleChange}
                                            onBlurFunction={formDetails?.basicDetails[index + 1].blurFunction}
                                            errorMessages={formDetails?.basicDetails[index + 1].errorMessages}
                                            isError={formDetails?.basicDetails[index + 1].isError}
                                            linkWebsite={formDetails?.basicDetails[index + 1].linkWebsite}
                                            width={formDetails?.basicDetails[index + 1].width}
                                            borderRadius="4px"
                                            type={formDetails?.basicDetails[index + 1].type}
                                            required={formDetails?.basicDetails[index + 1].required}
                                            maxLength={formDetails?.basicDetails[index + 1].maxLength}
                                        />
                                    </div>
                                );
                            }

                            if (item.name === "email" && formDetails?.basicDetails[index - 1]?.name === "phoneNumber") {
                                return null;
                            }

                            return (
                                <EditCustomComponent
                                    key={item.label}
                                    label={item.label}
                                    placeholder={item.placeholder}
                                    value={item.value}
                                    name={item.name}
                                    onChangeFunction={handleChange}
                                    onBlurFunction={item.blurFunction}
                                    errorMessages={item.errorMessages}
                                    isError={item.isError}
                                    linkWebsite={item.linkWebsite}
                                    width={item.width}
                                    borderRadius="4px"
                                    type={item.type}
                                    required={item.required}
                                    maxLength={item.maxLength}
                                />
                            );
                        })}
                    </div>
                    <div className='mb-3 mt-3'>
                        <DisplayNamewithLeftBorder HeaderName="Address Details" />
                        <div className="mt-3">
                            <AddressAutocomplete
                                propertyValue={propertyValue}
                                setPropertyvalue={setPropertyvalue}
                                required={false}
                                showSelectedValue={false}
                            />

                            <div className="basicDetailsForm mt-3">
                                <div className="office-address-form">
                                    <div className="d-flex addContractorInputContainer1">
                                        {formDetails?.address?.map((item: any) => (
                                            <EditCustomComponent
                                                key={item.label}
                                                label={item.label}
                                                placeholder={item.placeholder}
                                                value={item.value}
                                                name={item.name}
                                                onChangeFunction={handleChange}
                                                onBlurFunction={item.blurFunction}
                                                errorMessages={item.errorMessages}
                                                isError={item.isError}
                                                linkWebsite={item.linkWebsite}
                                                width={item.width}
                                                borderRadius="4px"
                                                type={item.type}
                                                required={item.required}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="editFormFooterContractor">
                    <AddTraspButton
                        type="button"
                        bname="Cancel"
                        onClick={() => setShowModal(false)}
                        width="fit-content"
                    />
                    <AddButtonWithArrow
                        type="submit"
                        buttonname="Save Changes"
                    />
                </div>
            </Form>
        </>
    )
}

export default BasicDetailsForm