import { useCallback, useState } from "react";
import Delete from "../../../assets/deleteIconRed.svg";
import { QuestionTypeEnum } from "@propertelligent/client-api";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import AddButtonWithArrow from "../../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import { GetImages } from "../../../utils/GetImages";
import CustomSwitchToggle from "../../common/Switch";
import CustomInputs from "../../common/Inputs";
import RadioButton from "../../common/RadioButton";
import NewSelectAuto from "../../tenants/NewSelectAuto";
import { convertENUMtoJSONArray } from "../../../utils/helper";
import EmptyData from "../../common/EmptyData";


const QuestionHistoryEdit = ({ postData, setPostData }) => {
  const [showFollowUpInput, setShowFollowUpInputs] = useState({});
  const handleAddQuestion = () => {
    const updatedQuestions = [
      ...postData?.leaseApplicationTemplateSections?.Questionnaires?.questions,
    ];
    const newQuestion = {
      id: updatedQuestions?.length + 1,
      questionText: "",
      isRequired: false,
      questionTypeId: QuestionTypeEnum.SingleChoice,
      choices: [
        { id: 1, optionText: "Yes" },
        { id: 2, optionText: "No" },
      ],
      followUpQuestionText: "",
      followUpQuestionChoiceId: 1,
    };
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData?.leaseApplicationTemplateSections,
        Questionnaires: {
          ...prevData?.leaseApplicationTemplateSections?.Questionnaires,
          questions: [
            ...(prevData?.leaseApplicationTemplateSections?.Questionnaires?.questions || []), // Add fallback to ensure questions is an array
            newQuestion,
          ],
        },
      },
    }));
  };

  const handleQuestionChange = (index, newText, newType) => {

    const updatedQuestions = [
      ...postData.leaseApplicationTemplateSections.Questionnaires.questions,
    ];
    updatedQuestions[index].questionText = newText;
    updatedQuestions[index].questionTypeId = Number(newType);
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData.leaseApplicationTemplateSections,
        Questionnaires: {
          ...prevData.leaseApplicationTemplateSections.Questionnaires,
          questions: updatedQuestions,
        },
      },
    }));
  };

  const handleAddOption = (index) => {
    const updatedQuestions = [
      ...postData?.leaseApplicationTemplateSections?.Questionnaires?.questions,
    ];
    const newOption = {
      id: updatedQuestions[index]?.choices?.length + 1,
      optionText: `Option ${updatedQuestions[index]?.choices?.length + 1}`,
    };
    updatedQuestions[index]?.choices?.push(newOption);
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData?.leaseApplicationTemplateSections,
        Questionnaires: {
          ...prevData?.leaseApplicationTemplateSections?.Questionnaires,
          questions: updatedQuestions,
        },
      },
    }));
  };

  const handleRemoveOption = (index) => {
    const updatedQuestions = [
      ...postData?.leaseApplicationTemplateSections?.Questionnaires?.questions,
    ];
    updatedQuestions[index].choices.pop();
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData.leaseApplicationTemplateSections,
        Questionnaires: {
          ...prevData?.leaseApplicationTemplateSections?.Questionnaires,
          questions: updatedQuestions,
        },
      },
    }));
  };

  const handleOnClick = (qIndex, optIndex, onFocus) => {
    const updatedQuestions = [
      ...postData?.leaseApplicationTemplateSections?.Questionnaires?.questions,
    ];
    updatedQuestions[qIndex].choices.forEach((choice, index) => {
      updatedQuestions[qIndex].choices[index].isOnHover = false;
    });
    updatedQuestions[qIndex].choices[optIndex].isOnHover = onFocus;
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData.leaseApplicationTemplateSections,
        Questionnaires: {
          ...prevData.leaseApplicationTemplateSections.Questionnaires,
          questions: updatedQuestions,
        },
      },
    }));
  };

  const handleOnClickElement = (qIndex, optIndex, questionTypeId: any) => {
    const updatedQuestions = [
      ...postData?.leaseApplicationTemplateSections?.Questionnaires?.questions,
    ];
    updatedQuestions[qIndex].choices[optIndex].isRadio =
      !updatedQuestions[qIndex].choices[optIndex].isRadio;
    updatedQuestions[qIndex].followUpQuestionChoiceId = optIndex + 1;
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData.leaseApplicationTemplateSections,
        Questionnaires: {
          ...prevData.leaseApplicationTemplateSections.Questionnaires,
          questions: updatedQuestions,
        },
      },
    }));
  };

  const handleChangeFollowup = (index, e) => {
    const updatedQuestions = [
      ...postData.leaseApplicationTemplateSections.Questionnaires.questions,
    ];
    updatedQuestions[index].followUpQuestionText = e.target.value;
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData.leaseApplicationTemplateSections,
        Questionnaires: {
          ...prevData.leaseApplicationTemplateSections.Questionnaires,
          questions: updatedQuestions,
        },
      },
    }));
  };

  const handleChangeQuestionText = (index, e) => {
    const updatedQuestions = [
      ...postData.leaseApplicationTemplateSections.Questionnaires.questions,
    ];
    updatedQuestions[index].questionText = e.target.value;
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData.leaseApplicationTemplateSections,
        Questionnaires: {
          ...prevData.leaseApplicationTemplateSections.Questionnaires,
          questions: updatedQuestions,
        },
      },
    }));
  };

  const handleChangeText = (e, qIndex, optIndex) => {
    const updatedQuestions = [
      ...postData.leaseApplicationTemplateSections.Questionnaires.questions,
    ];
    updatedQuestions[qIndex].choices[optIndex].optionText = e.target.value;
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData.leaseApplicationTemplateSections,
        Questionnaires: {
          ...prevData.leaseApplicationTemplateSections.Questionnaires,
          questions: updatedQuestions,
        },
      },
    }));
  };

  const handleShowFollowUpInput = (index) => {
    setShowFollowUpInputs((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const deleteQuestion = useCallback(
    (index) => {
      const updatedQuestions =
        postData?.leaseApplicationTemplateSections?.Questionnaires?.questions.filter(
          (_, i) => i !== index
        );
      setPostData((prevData) => ({
        ...prevData,
        leaseApplicationTemplateSections: {
          ...prevData?.leaseApplicationTemplateSections,
          Questionnaires: {
            ...prevData?.leaseApplicationTemplateSections?.Questionnaires,
            questions: updatedQuestions,
          },
        },
      }));
    },
    [postData, setPostData]
  );
  const copyQuestion = useCallback(
    (index) => {
      const questionToCopy = {
        ...postData?.leaseApplicationTemplateSections?.Questionnaires?.questions[index],
      };
      const updatedQuestions = [
        ...postData?.leaseApplicationTemplateSections?.Questionnaires?.questions,
        questionToCopy,
      ];

      setPostData((prevData) => ({
        ...prevData,
        leaseApplicationTemplateSections: {
          ...prevData?.leaseApplicationTemplateSections,
          Questionnaires: {
            ...prevData?.leaseApplicationTemplateSections?.Questionnaires,
            questions: updatedQuestions,
          },
        },
      }));
    },
    [postData, setPostData]
  );

  const handleToggleChange = (index) => {
    const updatedQuestions = [
      ...postData?.leaseApplicationTemplateSections?.Questionnaires?.questions,
    ];
    updatedQuestions[index].isRequired = !updatedQuestions[index].isRequired;
    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData?.leaseApplicationTemplateSections,
        Questionnaires: {
          ...prevData?.leaseApplicationTemplateSections?.Questionnaires,
          questions: updatedQuestions,
        },
      },
    }));
  };


  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("draggedIndex", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow dropping
  };

  const handleDrop = (e, dropIndex) => {
    const draggedIndex = e.dataTransfer.getData("draggedIndex");

    const updatedQuestions = [
      ...postData?.leaseApplicationTemplateSections?.Questionnaires?.questions,
    ];
    const [draggedItem] = updatedQuestions.splice(draggedIndex, 1);
    updatedQuestions.splice(dropIndex, 0, draggedItem);

    setPostData((prevData) => ({
      ...prevData,
      leaseApplicationTemplateSections: {
        ...prevData.leaseApplicationTemplateSections,
        Questionnaires: {
          ...prevData.leaseApplicationTemplateSections.Questionnaires,
          questions: updatedQuestions,
        },
      },
    }));
  };

  return (
    <>
      <section className="question-history">
        <p className="sub_heading greyHeading">Questionnaires</p>
        {postData?.leaseApplicationTemplateSections?.Questionnaires?.questions?.length == 0 ?
          <EmptyData
            mainText={"No Section Available"}
            // ImageSrc={GetImages.data}
            button={
              <AddTraspButton
                bname={"Add Question"}
                iconPosition={"right"}
                imageValue={GetImages.BlueAdd}
                onClick={handleAddQuestion}
                border={true} />} />
          :
          <div className="quitionnaire_list">
            {postData?.leaseApplicationTemplateSections?.Questionnaires?.questions?.map(
              (question, index) => (
                <div className="questionnaire_edit question-item" key={index}
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, index)}
                >
                  <div className="questionnaire_edit_option">
                    <div className="questionnaire_edit_option_left ">
                      <img src={GetImages.GripDots} alt={GetImages.GripDots} />

                      <CustomInputs
                        type="text"
                        value={question?.questionText}
                        isError={false}
                        placeholder="Add Question text here"
                        myClassName={"myClassName"}
                        width={"100%"}
                        onChange={(e) => handleChangeQuestionText(index, e)}
                      />

                    </div>

                    <div className="questionnaire_edit_option_right">
                      <NewSelectAuto
                        disabled={undefined}
                        setDisabled={undefined}
                        selected={question?.questionTypeId}
                        setSelected={(value) => handleQuestionChange(index, question?.questionText, value)}
                        setSelectedOption={undefined}
                        selectedOption={undefined}
                        data={convertENUMtoJSONArray(QuestionTypeEnum)}
                        title={"Wait till the data fetches"}
                        type="Select  Type"
                        error={false}
                        setError={() => { }}
                        fromContractor={true}
                        label={undefined}
                        width={"290px"}
                      />
                    </div>
                  </div>

                  {(question?.questionTypeId === QuestionTypeEnum.SingleChoice || question?.questionTypeId === QuestionTypeEnum.MultipleChoice) && (
                    <div className="radio-buttons">
                      {question?.choices?.map((option, optionIndex) => (
                        <div key={optionIndex} className="custom-radio-wrapper">
                          <RadioButton
                            type={question?.questionTypeId === QuestionTypeEnum.SingleChoice ? "radio" : "checkbox"}
                            id={`flexRadioDefault${index}-${optionIndex}`}
                            label={
                              option?.isOnHover ? (
                                <CustomInputs
                                  type="text"
                                  value={option?.optionText}
                                  placeholder="Option text"
                                  onChange={(e) => handleChangeText(e, index, optionIndex)}
                                  onBlur={() => handleOnClick(index, optionIndex, false)}
                                  onFocus={() => handleOnClick(index, optionIndex, true)}
                                  isError={false}
                                  myClassName="editable-label"
                                  width={'100px'}
                                />
                              ) : (
                                <span
                                  onClick={() => handleOnClick(index, optionIndex, true)}
                                  className="label-text" >
                                  {option?.optionText}
                                </span>
                              )}
                            value={option?.id}
                            name={`flexRadioDefault${index}`}
                            checked={postData?.leaseApplicationTemplateSections?.Questionnaires?.questions[
                              index
                            ]?.followUpQuestionChoiceId === option?.id}
                            onChange={() => handleOnClickElement(index, optionIndex, question?.questionTypeId)}
                            checkBoxClass="custom-radio-checkbox"
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="optionsection">
                    <div className="options_addRemove">
                      {question?.questionTypeId !== QuestionTypeEnum.ShortAnswer &&
                        question?.questionTypeId !== QuestionTypeEnum.Paragraph && (
                          <>
                            <AddTraspButton
                              width={0}
                              textclass={"sub_text fw-600 blue p-0"}
                              bname={"+ Add Option"}
                              iconPosition={"right"}
                              onClick={() => handleAddOption(index)}
                              border={false}
                              imageValue={undefined}
                            />
                            <AddTraspButton
                              width={0}
                              textclass={"sub_text fw-600 grey p-0"}
                              bname={"- Remove Option"}
                              iconPosition={"right"}
                              onClick={() => handleRemoveOption(index)}
                              border={false}
                              disabled={question?.choices?.length <= 1}
                              imageValue={undefined}
                            />
                          </>
                        )}
                    </div>
                    <div className="options_buttons">
                      <div className="d-flex align-items-center gap-1">
                        <p className="sub_text  fw-600 black">Required</p>
                        <CustomSwitchToggle
                          key={index}
                          onClick={() => handleToggleChange(index)}
                          checked={question.isRequired}
                        />
                      </div>
                      <AddTraspButton
                        width={0}
                        bname={undefined}
                        imageValue={GetImages.CopyBlue}
                        iconPosition={"right"}
                        onClick={() => copyQuestion(index)}
                        border={false}
                      />
                      <AddTraspButton
                        width={0}
                        bname={undefined}
                        imageValue={Delete}
                        iconPosition={"right"}
                        onClick={() => deleteQuestion(index)}
                        border={false}
                      />
                    </div>
                  </div>

                  {question?.questionTypeId == QuestionTypeEnum.SingleChoice && (
                    <div className="">
                      <AddButtonWithArrow
                        buttonname={"Ask follow up question"}
                        onClick={() => handleShowFollowUpInput(index)}
                        disabled={undefined}
                        iconDirection={"right"}
                        Icon={GetImages.WhiteAdd}
                      />
                    </div>
                  )}
                  {showFollowUpInput[index] && (
                    <>
                      {postData?.leaseApplicationTemplateSections?.Questionnaires?.questions[index]
                        ?.questionTypeId == QuestionTypeEnum.SingleChoice && (
                          <>
                            <div className="followup_quetion">
                              <p className="sub_text  fw-600 grey_subtext1">
                                Ask follow up question when user selects
                              </p>
                              <div className="question-option">
                                {postData?.leaseApplicationTemplateSections?.Questionnaires?.questions[
                                  index
                                ]?.choices?.map((option, optionIndex) => (
                                  <RadioButton
                                    type="radio"
                                    id={`flexRadioDefault${optionIndex}`}
                                    label={option.optionText}
                                    value={Number(option.id)}
                                    name={`question-${index}`}
                                    // checked={item.checked}
                                    checked={postData?.leaseApplicationTemplateSections?.Questionnaires?.questions[
                                      index
                                    ]?.followUpQuestionChoiceId == option?.id
                                    }
                                    onChange={undefined} />
                                ))}
                              </div>
                            </div>
                            <CustomInputs
                              type="text"
                              placeholder="Follow-Up Question"
                              width={"100%"}
                              value={question?.followUpQuestionText}
                              onChange={(e) => {
                                handleChangeFollowup(index, e);
                              }}
                            />
                          </>
                        )}
                    </>
                  )}
                </div>
              )
            )}
            <div className="addRemove">
              <AddTraspButton
                width={0}
                bname={"Add another Question"}
                textclass={"sub_text fw-600 blue p-0"}
                iconPosition={undefined}
                onClick={handleAddQuestion}
                border={false}
              />
            </div>
          </div>
        }
      </section>
    </>
  );
};

export default QuestionHistoryEdit;



