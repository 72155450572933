// validationHelpers.js
import html2pdf from 'html2pdf.js';

// Required field validation
export const validateRequired = (value, fieldName, setErrors) => {
  if (value.trim() === "") {
    setErrors((prev) => ({ ...prev, [fieldName]: true }));
    return "This field is required";
  }
  setErrors((prev) => ({ ...prev, [fieldName]: false }));
  return "";
};



// validate unit number
export const validateUnitNumber = (value, setErrors, setErrorMessages) => {
  if (!value) {
    // Check for empty or whitespace-only
    setErrors((prev) => ({ ...prev, unitNumber: true }));
    setErrorMessages((prev) => ({
      ...prev,
      unitNumber: "Unit Number is required",
    }));
    return "Unit Number is required";
  }

  // Clear any existing errors
  setErrors((prev) => ({ ...prev, unitNumber: false }));
  setErrorMessages((prev) => ({ ...prev, unitNumber: "" }));
  return "";
};



// validate unit size
export const validateUnitNumberSize = (value, setErrors, setErrorMessages) => {
  if (!value) {
    setErrors((prev) => ({ ...prev, unitNumberSize: true }));
    setErrorMessages((prev) => ({
      ...prev,
      unitNumberSize: "Unit Size is required",
    }));
    return "Unit Size is required";
  }

  // If the field is not empty, clear any existing errors
  setErrors((prev) => ({ ...prev, unitNumberSize: false }));
  setErrorMessages((prev) => ({ ...prev, unitNumberSize: "" })); // Clear message if valid
  return "";
};



//validate account name
export const validateAccountName = (value, setErrors, setErrorMessages) => {
  if (!value) {
    setErrors((prev) => ({ ...prev, accountingName: true }));
    setErrorMessages((prev) => ({
      ...prev,
      accountingName: "Account Name is required",
    }));
    return "Account Name is required";
  }

  // Optionally check for length (e.g., minimum 2 characters)
  if (value.length < 2) {
    setErrors((prev) => ({ ...prev, accountingName: true }));
    setErrorMessages((prev) => ({
      ...prev,
      accountingName: "Account Name must be at least 2 characters",
    }));
    return "Account Name must be at least 2 characters";
  }

  setErrors((prev) => ({ ...prev, accountingName: false }));
  setErrorMessages((prev) => ({ ...prev, accountingName: "" })); // Clear message if valid
  return "";
};



// validate expiry date
export const validateExpiryDate = (value, setErrors, setErrorMessages) => {
  // Check if the field is empty
  if (!value) {
    setErrors((prev) => ({ ...prev, expMonth: true }));
    setErrorMessages((prev) => ({
      ...prev,
      expMonth: "Expiry date is required",
    }));
    return "Expiry date is required";
  }

  // Check if the input length is exactly 5
  if (value.length !== 5) {
    setErrors((prev) => ({ ...prev, expMonth: true }));
    setErrorMessages((prev) => ({
      ...prev,
      expMonth: "Expiry date must be in MM/YY format",
    }));
    return "Expiry date must be in MM/YY format";
  }

  const [monthStr, yearStr] = value.split("/");
  const month = parseInt(monthStr, 10);
  const year = parseInt(yearStr, 10) + 2000; // Assuming 2-digit year

  // Validate month and year
  if (isNaN(month) || isNaN(year) || month < 1 || month > 12) {
    setErrors((prev) => ({ ...prev, expMonth: true }));
    setErrorMessages((prev) => ({ ...prev, expMonth: "Invalid expiry date" }));
    return "Invalid expiry date";
  }

  const currentDate = new Date();
  const inputDate = new Date(year, month - 1);

  if (inputDate < currentDate) {
    setErrors((prev) => ({ ...prev, expMonth: true }));
    setErrorMessages((prev) => ({ ...prev, expMonth: "Card Expired" }));
    return "Card Expired";
  }

  // No errors
  setErrors((prev) => ({ ...prev, expMonth: false }));
  setErrorMessages((prev) => ({ ...prev, expMonth: "" })); // Clear any previous error message
  return "";
};



// validate zip code
export const validateZipCode = (value, setErrors, setErrorMessages) => {
  if (!value) {
    setErrors((prev) => ({ ...prev, billingZipCode: true }));
    setErrorMessages((prev) => ({
      ...prev,
      billingZipCode: "Zip code is required",
    }));
    return "Zip code is required";
  }

  if (value.length !== 5) {
    setErrors((prev) => ({ ...prev, billingZipCode: true }));
    setErrorMessages((prev) => ({
      ...prev,
      billingZipCode: "Zip code must be 5 digits",
    }));
    return "Zip code must be 5 digits";
  }
  setErrors((prev) => ({ ...prev, billingZipCode: false }));
  setErrorMessages((prev) => ({ ...prev, billingZipCode: "" })); // Clear any previous error message
  return "";
};

// validate routing number
export const validateRoutingNumber = (value, setErrors, setErrorMessages) => {
  if (!value) {
    setErrors((prev) => ({ ...prev, routingNumber: true }));
    setErrorMessages((prev) => ({
      ...prev,
      routingNumber: "Routing Number is required",
    }));
    return "Routing Number is required";
  }

  const isValid = /^\d{9}$/.test(value);
  if (!isValid) {
    setErrors((prev) => ({ ...prev, routingNumber: true }));
    setErrorMessages((prev) => ({
      ...prev,
      routingNumber: "Routing Number must be 9 digits",
    }));
    return "Routing Number must be 9 digits";
  }
  setErrors((prev) => ({ ...prev, routingNumber: false }));
  return "";
};




// validate email
export const validateEmail = (value, setErrors, setErrorMessages) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  let email;

  // Check if the value is a string or an object
  if (typeof value === "string") {
    email = value.trim().toLowerCase();
  } else if (typeof value === "object" && value !== null && "email" in value) {
    email = value.email.trim().toLowerCase();
  } else {
    // Handle unexpected value types
    setErrors((prev) => ({ ...prev, email: true }));
    setErrorMessages((prev) => ({ ...prev, email: "Invalid email format" }));
    return "Invalid email format";
  }

  // Check if the email field is empty
  if (!email) {
    setErrors((prev) => ({ ...prev, email: true }));
    setErrorMessages((prev) => ({ ...prev, email: "Email is required" }));
    return "Email is required";
  }
  // Check if the email format is valid
  if (!emailRegex.test(email)) {
    setErrors((prev) => ({ ...prev, email: true }));
    setErrorMessages((prev) => ({
      ...prev,
      email: "Please enter a valid email address",
    }));
    return "Please enter a valid email address";
  }
  // If both checks pass, set the error state to false
  setErrors((prev) => ({ ...prev, email: false }));
  return "";
};



// validate password
export const validatePassword = (value, setErrors, setErrorMessages) => {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;

  const trimmedValue = value.trim(); // Trim whitespace

  // Check if password is empty
  if (trimmedValue.length === 0) {
    setErrors((prev) => ({ ...prev, password: true }));
    setErrorMessages((prev) => ({ ...prev, password: "Password is required" }));
    return "Password is required";
  }

  // Check for spaces
  if (trimmedValue.length !== value.length) {
    setErrors((prev) => ({ ...prev, password: true }));
    setErrorMessages((prev) => ({
      ...prev,
      password: "Spaces are not allowed",
    }));
    return "Spaces are not allowed";
  }
  // Check regex validation
  if (!passwordRegex.test(trimmedValue)) {
    setErrors((prev) => ({ ...prev, password: true }));
    setErrorMessages((prev) => ({
      ...prev,
      password:
        "Password must be at least 8 characters, contain one number, one uppercase letter, and one special character",
    }));
    return "Password must be at least 8 characters, contain one number, one uppercase letter, and one special character";
  }
  setErrors((prev) => ({ ...prev, password: false }));
  return "";
};



// validate account number
export const validateAccountNumber = (value, setErrors, setErrorMessages) => {
  if (!value) {
    setErrors((prev) => ({ ...prev, accountingNumber: true }));
    setErrorMessages((prev) => ({
      ...prev,
      accountingNumber: "Account number is required",
    }));
    return "Account number is required";
  }

  const isValid = /^[0-9]{7,15}$/.test(value);
  if (!isValid) {
    setErrors((prev) => ({ ...prev, accountingNumber: true }));
    setErrorMessages((prev) => ({
      ...prev,
      accountingNumber: "Please enter a valid account number (7 to 15 digits)",
    }));
    return "Please enter a valid account number (7 to 15 digits)";
  }
  setErrors((prev) => ({ ...prev, accountingNumber: false }));
  setErrorMessages((prev) => ({ ...prev, accountingNumber: "" }));
  return "";
};



// validate creditcard
export const validateCreditCard = (value, setErrors, setErrorMessages) => {
  const formattedValue = value.replace(/\D/g, "");
  // Check if the field is empty
  if (formattedValue.length === 0) {
    setErrors((prev) => ({ ...prev, cardNumber: true }));
    setErrorMessages((prev) => ({
      ...prev,
      cardNumber: "Credit card number is required",
    }));
    return;
  }
  // Check if the length is not equal to 16
  if (formattedValue.length !== 16) {
    setErrors((prev) => ({ ...prev, cardNumber: true }));
    setErrorMessages((prev) => ({
      ...prev,
      cardNumber: "Credit card should have 16 digits",
    }));
    return;
  }
  // If valid
  setErrors((prev) => ({ ...prev, cardNumber: false }));
  setErrorMessages((prev) => ({ ...prev, cardNumber: "" }));
};


// validate cvv
export const validateCVV = (value, setErrors, setErrorMessages) => {
  if (!value) {
    setErrors((prev) => ({ ...prev, cvvNumber: true }));
    setErrorMessages((prev) => ({ ...prev, cvvNumber: "CVV is required" }));
    return "CVV is required";
  }
  if (value.length !== 3) {
    setErrors((prev) => ({ ...prev, cvvNumber: true }));
    setErrorMessages((prev) => ({
      ...prev,
      cvvNumber: "CVV must be 3 digits",
    }));
    return "CVV must be 3 digits";
  }
  setErrors((prev) => ({ ...prev, cvvNumber: false }));
  setErrorMessages((prev) => ({ ...prev, cvvNumber: "" }));
  return "";
};


// validate first name
export const validateFirstName = (value, setErrors) => {
  const nameRegex = /^[a-zA-Z ]{2,40}$/;
  let firstName;

  if (typeof value === "object" && value !== null && "firstName" in value) {
    firstName = value.firstName.trim();
  } else if (typeof value === "string") {
    firstName = value.trim();
  } else {
    setErrors((prev) => ({ ...prev, firstName: true }));
    return "Invalid input format";
  }

  if (firstName.length === 0) {
    setErrors((prev) => ({ ...prev, firstName: true }));
    return "First name is required";
  }
  if (!nameRegex.test(firstName)) {
    setErrors((prev) => ({ ...prev, firstName: true }));
    return "First name should only contain letters and spaces";
  }
  if (firstName.length < 2 || firstName.length > 40) {
    setErrors((prev) => ({ ...prev, firstName: true }));
    return "First name should have 2-40 characters";
  }

  setErrors((prev) => ({ ...prev, firstName: false }));
  return "";
};



// validate last name 
export const validateLastName = (value, setErrors) => {
  const nameRegex = /^[a-zA-Z ]{2,40}$/;
  let lastName;

  // Check if the value is an object and has lastName, or is a string
  if (typeof value === "object" && value !== null && "lastName" in value) {
    lastName = value.lastName.trim();
  } else if (typeof value === "string") {
    lastName = value.trim();
  } else {
    // Handle unexpected value types
    setErrors((prev) => ({ ...prev, lastName: true }));
    return "Invalid last name format";
  }

  // Check if the last name is empty
  if (lastName.length === 0) {
    setErrors((prev) => ({ ...prev, lastName: true }));
    return "Last name is required";
  }

  // Check if the last name matches the regex
  if (!nameRegex.test(lastName)) {
    setErrors((prev) => ({ ...prev, lastName: true }));
    return "Last name should only contain letters";
  }

  // Check length constraints
  if (lastName.length < 2 || lastName.length > 40) {
    setErrors((prev) => ({ ...prev, lastName: true }));
    return "Last name should have 2-40 characters";
  }

  // Clear any previous errors
  setErrors((prev) => ({ ...prev, lastName: false }));
  return "";
};



// validate name
export const validateName = (value, setErrors) => {
  const nameRegex = /^[a-zA-Z ]+$/; // Allows only letters and spaces
  let name;

  // Check if the value is a string or an object
  if (typeof value === "string") {
    name = value.trim();
  } else if (typeof value === "object" && value !== null && "name" in value) {
    name = value.name.trim();
  } else {
    // Handle unexpected value types
    setErrors((prev) => ({ ...prev, name: true }));
    return "Invalid name format";
  }

  // Check if the name is empty
  if (name.length === 0) {
    setErrors((prev) => ({ ...prev, name: true }));
    return "Name is required";
  }

  // Check if the name matches the regex
  if (!nameRegex.test(name)) {
    setErrors((prev) => ({ ...prev, name: true }));
    return "Please enter letters only";
  }

  // Check length constraints
  if (name.length < 2 || name.length > 40) {
    setErrors((prev) => ({ ...prev, name: true }));
    return "Name should have 2-40 characters";
  }

  // Clear any previous errors
  setErrors((prev) => ({ ...prev, name: false }));
  return "";
};



// Phone number validation
export const validateNumber = (value, setErrors) => {
  const mobileNumberRegex = /^[0-9]{8,13}$/;
  let phoneNumber;

  if (typeof value === "object" && value !== null && "phoneNumber" in value) {
    phoneNumber = value.phoneNumber.trim();
  }
  else if (typeof value === "string") {
    phoneNumber = value.trim();
  }
  else {
    setErrors((prev) => ({ ...prev, phoneNumber: true }));
    return "Invalid input format";
  }
  if (phoneNumber.length === 0) {
    setErrors((prev) => ({ ...prev, phoneNumber: true }));
    return "Phone number is required";
  }
  if (!mobileNumberRegex.test(phoneNumber)) {
    setErrors((prev) => ({ ...prev, phoneNumber: true }));
    return "Please enter a valid phone number (8 to 13 digits)";
  }

  setErrors((prev) => ({ ...prev, phoneNumber: false }));
  return "";
};



// validate checkEmptyFeilds
export const checkEmptyFields = (fields, setErrors) => {
  const errors = {};
  Object.keys(fields).forEach((field) => {
    if (fields[field].trim() === "") {
      errors[field] = `[field] is required`;
      setErrors((prev) => ({ ...prev, [field]: true }));
    } else {
      setErrors((prev) => ({ ...prev, [field]: false }));
    }
  });
  return errors;
};



// Positive number validation
export const validatePositiveNumber = (value, setErrors) => {
  const numberValue = parseFloat(value);
  if (numberValue <= 0) {
    setErrors((prev) => ({ ...prev, positiveNumber: true }));
    return "Must be a positive number";
  }
  setErrors((prev) => ({ ...prev, positiveNumber: false }));
  return "";
};



// Future date validation
export const validateDate = (dateString, setErrors) => {
  const date = new Date(dateString);
  const now = new Date();
  if (date <= now) {
    setErrors((prev) => ({ ...prev, futureDate: true }));
    return "Due date must be in the future";
  }
  setErrors((prev) => ({ ...prev, futureDate: false }));
  return "";
};



// Grace period validation
export const validateGracePeriod = (value, setErrors) => {
  const numberValue = parseInt(value, 10);
  if (!Number.isInteger(numberValue) || numberValue < 0) {
    setErrors((prev) => ({ ...prev, gracePeriod: true }));
    return "Grace period must be a non-negative integer";
  }
  setErrors((prev) => ({ ...prev, gracePeriod: false }));
  return "";
};



// Amount validation
export const validateAmount = (value, setErrors) => {
  const amountValue = parseFloat(value);
  if (amountValue <= 0) {
    setErrors((prev) => ({ ...prev, amount: true }));
    return "Amount must be greater than zero";
  }
  setErrors((prev) => ({ ...prev, amount: false }));
  return "";
};

export  const checkEmptyFieldsFunc = (data: any ,clearErrors:any ,errors:any,errorMessages:any ,setErrors:any ,setErrorMessages:any ,skipField:any): boolean => {
 
  clearErrors();
  const newErrors = { ...errors };
  const newErrorMessages = { ...errorMessages };
  let hasErrors = false;

  const checkFields = (item: any, parentKey: string = '') => {
    if (Array.isArray(item)) {
      item.forEach((item1, index) => {
        Object.keys(item1).forEach((key) => {
          const value = item1[key];
          const errorKey = `${key}[${index}]`;
          if ((typeof value === 'string' && !value.trim()) || value === null || value === undefined) {
            newErrors[errorKey] = true;
            newErrorMessages[errorKey] = `${key} at index ${index} is required`;
            hasErrors = true;
          } else {
            newErrors[errorKey] = false;
          }
        });
      });
    } else if (typeof item === 'object' && item !== null) {
      Object.keys(item).forEach((key) => {
        const value = item[key];
        const errorKey = parentKey ? `${parentKey}.${key}` : key;
        checkFields(value, errorKey);
      });
    } else {
      const errorKey = parentKey;
      if ((typeof item === 'string' && !item.trim()) || item === null || item === undefined) {
        newErrors[errorKey] = true;
        newErrorMessages[errorKey] = `${errorKey} is required`;
        hasErrors = true;
      } else {
        newErrors[errorKey] = false;
      }
    }
  };
  checkFields(data);
  setErrors(newErrors);
  setErrorMessages(newErrorMessages);
  if (skipField == "Questionnaires") {
    hasErrors = false;
  }
  return hasErrors;
};


export  const handleDownloadPdf = (setISPrint ,contentRef) => {
  setISPrint(true)
  const element = contentRef.current; // Get the content to download

  const options = {
    margin: 0.25,
    filename: 'Review Application.pdf',
    image: { type: 'pdf', quality: 0.50 },
    html2canvas: { scale: 1.10 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
  };

  if (element) {
    html2pdf()
      .from(element)
      .set(options)
      .save()
  }
  setTimeout(() => {
    setISPrint(false)
  }, 200)
};

