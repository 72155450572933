import React, { memo, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { AnalyticsService, OrganizationService } from "@propertelligent/client-api";
import PieChart from "./expenseDistribution";
import Transactions from "./transactions";
import { useAnalyticsHook } from "../../../hooks/useAnalyticsHook";
import { usePaginationHook } from "../../../hooks/usePaginationHook";
import { GetImages } from "../../../utils/GetImages";
import UtilHelper from "../../../utils/UtilHelper";

const Overview = ({
  paymentMethodsArr,
  payeeAccountsArr,
  entityTypeId,
  loadingStates,
  setLoadingStates,
}) => {
  const { id } = useParams();
  const [rev_monthValue, setRev_MonthValue] = useState(-12);
  const [fin_monthValue, setFin_MonthValue] = useState(-12);
  const [isTooltip, setIsTooltip] = useState(false);

  const { singleProperty } = useSelector((state: any) => state.singleProperty);
  const customMonthField = "monthYear";

  const {
    cashFlow,
    expensebycategoryArr,
    fetchAndFormatExpensebycategory
  } = useAnalyticsHook({
    httpExpensebycategoryFn: AnalyticsService.getApiAnalyticsPropertyExpensebycategory,
    httpCashflowFn: AnalyticsService.getApiAnalyticsPropertyCashflow,
    customMonthField,
    parentEntityId: Number(id),
    fullyQualifiedFilters: {
      rev: `Created>DateTime.UtcNow.AddMonths(${rev_monthValue})`,
      fin: `Created>DateTime.UtcNow.AddMonths(${fin_monthValue})`,
    },
  });

  const {
    loading,
    rows,
    fetchData,
  } = usePaginationHook({
    httpFetchDataFn: OrganizationService.getApiOrganizationTransactionPropertylistInclUnits,
    paginationDataMapper: null,
    parentEntityId: Number(id),
    isDependentEntity: true,
    FullyQualifiedFilters: null,
    FullyQualifiedSorts: `PaymentDate desc`,
    PageSize: 5
  });

  useMemo(() => { }, [
    fin_monthValue,
    rev_monthValue,
    expensebycategoryArr?.length,
  ]);

  useEffect(() => { }, [rows?.length]);

  useEffect(() => {
    if (!loading && cashFlow && expensebycategoryArr) {
      setLoadingStates({
        ...loadingStates,
        overview: false,
      });
    }
  }, [loading, expensebycategoryArr, expensebycategoryArr]);

  const { totalExpense, totalRevenue, netProfit } = cashFlow.reduce(
    (accumulator, current) => {
      accumulator.totalExpense += current.expense || 0;
      accumulator.totalRevenue += current.income || 0;
      return accumulator;
    },
    { totalExpense: 0, totalRevenue: 0, netProfit: 0 }
  );

  const expensedata = [
    {
      id: 1,
      name: "Net Profit",
      value: totalRevenue - totalExpense,
      boxclass: "blueBox",
    },
    {
      id: 2,
      name: "Expense",
      value: totalExpense,
      boxclass: "orangeBox"
    },
  ];

  return (
    <>
      <div className="overview-details-container">
        <div className="overviewfirst">
          <div className="overviewfirstright">
            <div className="overviewfirstrightfirst">
              <div>
                <div className="CommonRoundBackground BackgroundlightOrange">
                  <img src={GetImages.OrangeDoller} className="Icon20" />
                </div>
              </div>
              <div className="incomeExpense ">
                <div className="commonbox">
                  <p className="heading black">
                    $ {singleProperty?.formatted?.IncomeDue}
                  </p>
                  <p className="sub_text grey_subtext1">Income due</p>
                </div>
                <div className="dividerdiv"></div>
                <div className="commonbox">
                  <p className="heading black">
                    $ {singleProperty?.formatted?.ExpensesDue}
                  </p>
                  <p className="sub_text grey_subtext1">Expense due</p>
                </div>
              </div>
            </div>
            <div className="overviewfirstrightsecond">
              <div className="firstbox">
                <div className="CommonRoundBackground BackgroundlightOrange">
                  <img src={GetImages.OrangeUnit} className="Icon20" />
                </div>

                <div className="commonbox">
                  <p className="heading black">
                    {singleProperty?.vacantUnits}
                  </p>
                  <p className="sub_text  grey_subtext1">Vacant Units</p>
                </div>
              </div>
            </div>
            <div className="overviewfirstrighthird">
              <div className="firstbox">
                <div className="CommonRoundBackground BackgroundlightOrange">
                  <img src={GetImages.OrangeList} className="Icon20" />
                </div>
                <div className="commonbox">
                  <p className="heading black">
                    {singleProperty?.openServiceRequests}
                  </p>
                  <p className="sub_text  grey_subtext1">Open service requests</p>
                </div>
              </div>
            </div>
          </div>
          <div className="overviewfirstleft">
            <div className="overviewfirstleftfirst">
              <p className="heading black fw-600">Revenue Overview</p>

              <div className="monthhead">
                <button
                  onClick={() => setRev_MonthValue(-1)}
                  className={
                    rev_monthValue === -1
                      ? "overviewSelectedButton sub_text fw-500 blue"
                      : "overviewUnselectedButton sub_text fw-500 grey_subtext1"
                  }
                >
                  Last Month
                </button>
                <button
                  onClick={() => setRev_MonthValue(-6)}
                  className={
                    rev_monthValue === -6
                      ? "overviewSelectedButton sub_text fw-500 blue"
                      : "overviewUnselectedButton sub_text fw-500 grey_subtext1"
                  }
                >
                  Last 6 Months
                </button>
                <button
                  onClick={() => setRev_MonthValue(-12)}
                  className={
                    rev_monthValue === -12
                      ? "overviewSelectedButton sub_text fw-500 blue"
                      : "overviewUnselectedButton sub_text fw-500 grey_subtext1"
                  }
                >
                  Last 12 Months
                </button>
              </div>
            </div>

            <div className="overviewfirstleftsecond">
              <div className="revenueleft">
                {expensedata?.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <div className="netprofit-expesnse">
                      <div className="netprofit-expesnse-first ">
                        <div className="propertyCardQuantityDesc ">
                          <div className={item.boxclass}></div>
                          <p className="normal_text grey_subtext1">{item.name}</p>
                        </div>
                        <p className="heading black">{UtilHelper.CurrencyFormat(item.value)}</p>
                      </div>
                    </div>
                    {index !== expensedata.length - 1 && (
                      <div className="dividerdiv"></div>
                    )}
                  </React.Fragment>
                ))}
              </div>
              <div className="revenueright">
                <div
                  style={
                    {
                      "--percentage":
                        ((totalRevenue - totalExpense) / totalRevenue) * 100,
                    } as React.CSSProperties
                  }
                  className="semi-donut margin position-relative"
                  onMouseMove={() => setIsTooltip(true)}
                  onMouseLeave={() => setIsTooltip(false)}
                >
                  <div className="RevenueOverviewHalfDoughnut">
                    <p className="heading black">{UtilHelper.CurrencyFormat(totalRevenue)}</p>
                    <p className="sub_text grey_subtext1">Total Revenue</p>
                  </div>
                  {isTooltip && (
                    <div className="normal_text grey_subtext1 tooltipStyle">
                      <div className="expenseItem flex-wrap">
                        <div className="propertyCardQuantityDesc gap-1">
                          <p className="normal_text grey_subtext1 fw-bold">
                            {UtilHelper.CurrencyFormat(totalRevenue - totalExpense)}
                          </p>
                        </div>
                      </div>
                      <div className="caretStyle"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="financeHeading">
          <p className="heading black">Finance Overview</p>
        </div> */}
        <div className="financeOverview">
          <PieChart
            fin_monthValue={fin_monthValue}
            setFin_MonthValue={setFin_MonthValue}
            data={expensebycategoryArr}
          />
          <div className="transactions">
            <div className="transactionheader" style={{ border: "none" }}>
              <p className="heading black fw-600">Last 5 Transactions</p>
            </div>
            <Transactions
              rows={rows}
              sorting="last-five"
              paymentMethodsArr={paymentMethodsArr}
              fetchData={fetchData}
              fetchAndFormatExpensebycategory={fetchAndFormatExpensebycategory}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Overview);
