import { useCallback, useEffect, useMemo, useState } from "react";
import QuestionHeader from "../QuestionHeader";
import { LeaseApplicationTemplateSectionVM, QuestionTypeEnum, TemplateEntityTypesEnum, TemplateService, TemplateTypeEnum } from "@propertelligent/client-api";
import { useNavigate, useParams } from "react-router";
import "./AddTemplate.css"
import RequestBreadcrumb from "../../../common/RequestBreadcrumb";
import AddTraspButton from "../../../firstTimeUserExperience/commonCom/AddTraspButton";
import AddButtonWithArrow from "../../../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import { GetImages } from "../../../../utils/GetImages";
import QuestionOptional from "../QuestionOptional";
import EmploymentHistoryEdit from "../EmploymentHistoryEdit";
import Reference from "../RefrenceEdit";
import QuestionHistoryEdit from "../QuestionEditTemplete";
import FeeTemplete from "../FeeTemplete";
import ConfirmSignature from "../ConfirmSignature";
import BoardingReview from "../../../TenantOnboard/RenderSteps/BoardingReview";
import apiHelper from "../../../../utils/apiHelper";
import { UserLoginData } from "../../../common/charts/Enums";

const AddTemplate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeSection, setActiveSection] = useState("");
  const [previewApplication, setPreviewApplication] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const [postData, setPostData] = useState<any | undefined>(
    {
      name: "Tenant Application 2",
      description: "Application for prospective tenants",
      templateTypeId: TemplateTypeEnum.LeaseApplication,
      entityTypeId: TemplateEntityTypesEnum.Organization,
      entityId: parseInt(localStorage.getItem(UserLoginData.organization_id)),
      isInUse: false,
      leaseApplicationTemplateSections: {
        CreditCheck: { requiredRange: 2 },
        CurrentAddress: { requiredRange: 2 },
        RentIncomeCheck: { requiredRange: 2 },
        EmploymentHistory: { requiredRange: 2 },
        References: { requiredRange: 2 },
        Questionnaires: {
          questions: [{
            id: 1, questionTypeId: QuestionTypeEnum.SingleChoice,
            questionText: "Do you smoke?", isRequired: false, choices: [{ id: 1, optionText: "Yes" }, { id: 2, optionText: "No" }],
            followUpQuestionChoiceId: 1, followUpQuestionText: "Please provide details?"
          }]
        },
        Signature: { clause: "Are You agree for everyting" },
        ApplicationFees: { clause: " Fee is non refundable", fee: 50 }
      } as LeaseApplicationTemplateSectionVM,
    })

  useEffect(() => { if (id) { getTempleteData(); } }, [id]);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      const apiCall = id
        ? apiHelper(TemplateService.putApiTemplate, {
          successMessage: "Template Updated",
          failureMessage: "Unable to Add Template",
          showNotification: true,
        }, Number(id), postData)
        : apiHelper(TemplateService.postApiTemplate, {
          successMessage: "Template Created",
          failureMessage: "Unable to updated Template",
          showNotification: true,
        }, postData)
      const res = await apiCall;
      if (res?.data?.success) {
        // setPreviewApplication(true)
        setActiveSection("")
      }
    } catch (error) {
      console.error("Error occurred while submitting data:", error);
    } finally { setIsLoading(false); }
  }, [id, postData, navigate]);

  const getTempleteData = () => {
    apiHelper(TemplateService.getApiTemplate, { showNotification: false, }, Number(id))
      .then((res) => { if (res?.data?.success) { setPostData(res?.data?.success); } })
      .catch((err) => { });
  };

  const duplicateTemplete = () => {
    if (id) {
      apiHelper(TemplateService.postApiTemplateDuplicate, {
        successMessage: "Template Duplicated",
        failureMessage: "Unable to Duplicate Template",
        showNotification: true,
      }, Number(id)).then((res) => {
        if (res?.data?.success) { navigate(`/organization/templates/edit/${res?.data?.success.id}`); }
      }).catch((err) => { });
    }
  };

  const deleteTemplete = () => {
    if (id) {
      apiHelper(TemplateService.deleteApiTemplate, {
        successMessage: "Template Deleted",
        failureMessage: "Unable to Delete Template",
        showNotification: true,
      }, Number(id)).then((res) => {
        navigate("/organization/templates");
      }).catch((err) => { });
    }
  };

  const HandleEditFeature = (sectionName: string) => {
    if (sectionName) {
      setActiveSection(sectionName);
    } else { setActiveSection("") }
  };

  const handleSectionToggle = useCallback((sectionName: string) => {
    setPostData((prevData) => {
      const newData = { ...prevData };
      newData.leaseApplicationTemplateSections = newData.leaseApplicationTemplateSections || {};
      if (newData.leaseApplicationTemplateSections[sectionName]) {
        delete newData.leaseApplicationTemplateSections[sectionName];
      } else {
        newData.leaseApplicationTemplateSections[sectionName] = getDefaultSectionDetails(sectionName);
      }
      return newData;
    });
  }, [setPostData]);

  const getDefaultSectionDetails = (sectionName: string) => {
    const updatedQuestions = [
      ...(postData?.leaseApplicationTemplateSections?.Questionnaires?.questions || []),
    ];
    switch (sectionName) {
      case "RentIncomeCheck":
      case "CreditCheck":
      case "CurrentAddress":
      case "EmploymentHistory":
      case "References":
        return { requiredRange: 2 };
      case "Questionnaires":
        return {
          questions: [
            {
              id: updatedQuestions?.length + 1,
              questionTypeId: 1,
              questionText: "Do you smoke?",
              choices: [
                { id: 1, optionText: "Yes" },
                { id: 2, optionText: "No" },
              ],
              followUpQuestionChoiceId: 1,
              followUpQuestionText: "Please provide details?",
            },
          ],
          requiredRange: 8,
        };
      case "ApplicationFees":
        return { clause: "Fee is non refundable", fee: 50 };
      case "Signature":
        return { clause: "Are You agree for everyting" };
      default:
        return {};
    }
  };

  const sectionConfig = [
    { id: 1, name: "Current Address", editable: false },
    { id: 2, name: "Credit Check", editable: false },
    { id: 3, name: "Rent Income Check", editable: false },
    { id: 4, name: "Employment History", editable: true },
    { id: 5, name: "References", editable: true },
    { id: 6, name: "Questionnaires", editable: true },
    { id: 7, name: "Signature", editable: true },
    { id: 8, name: "Application Fees", editable: true },
  ];

  const data = useMemo(
    () =>
      sectionConfig.map((section) => ({
        ...section,
        disbale: postData?.leaseApplicationTemplateSections?.[section.name.replace(/\s/g, "")] || false,
        onClick: () => handleSectionToggle(section.name.replace(/\s/g, "")),
        editClick: section.editable
          ? () => HandleEditFeature(section.name.replace(/\s/g, ""))
          : undefined,
      })),
    [postData, handleSectionToggle]
  );

  return (
    <>
      <div className="application-section">
        <RequestBreadcrumb
          firstName={"Template"}
          link={"/organization/templates"}
          currentStep={"Questionnaire template"}
        />
        <QuestionHeader
          postData={postData}
          setPostData={setPostData}
          duplicateTemplete={duplicateTemplete}
          deleteTemplete={deleteTemplete}
          previewApplication={previewApplication}
        />
        <div className="application-section-main">
          <section className="application_review">
            <div className="application_review_left">
              <QuestionOptional
                data={data}
                postData={postData}
                activeSection={activeSection}
              />
            </div>
            <div className="application_review_right">
              {activeSection === "EmploymentHistory" && (<EmploymentHistoryEdit postData={postData} setPostData={setPostData} />)}
              {activeSection === "References" && (<Reference postData={postData} setPostData={setPostData} />)}
              {activeSection === "Questionnaires" && (<QuestionHistoryEdit postData={postData} setPostData={setPostData} />)}
              {activeSection === "ApplicationFees" && (<FeeTemplete postData={postData} setPostData={setPostData} />)}
              {activeSection === "Signature" && (<ConfirmSignature postData={postData} setPostData={setPostData} />)}

              {!activeSection && (
                <>
                  <div className="application-preview-header">
                    <p className="sub_heading black"> Application Preview: Not Actual Data</p>
                    <p className="normal_text black"> <i>
                      Please note, this preview is for visualization purposes only and
                      does not contain real data.
                    </i></p>
                  </div>
                  <BoardingReview
                    postData={postData}
                    setPostData={setPostData}
                    editable={false}
                    isEditClick={undefined}
                    formData={undefined}
                    setFormData={undefined}
                    steps={undefined} />
                </>
              )}
            </div>
          </section>
        </div>
        <div className="application-section-bttom-view1">
          <AddTraspButton
            type="button"
            bname="Cancel"
            onClick={() => setActiveSection("")}
            border={true}
          />
          <AddButtonWithArrow
            className={previewApplication ? "bg-secondary" : ""}
            buttonname={isLoading ? "Saving..." : "Save"}
            onClick={handleSubmit}
            disabled={previewApplication}
            iconDirection={"left"}
            Icon={GetImages.WhiteForwardArrow}
          />
        </div>
      </div>
    </>
  );
};

export default AddTemplate;
