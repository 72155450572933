

import { memo, useEffect, useState } from "react";
import Overview from "./overview";
import UnitList from "./unitList";
import ServiceRequestList from "../../common/ServiceRequestList";
import { EntityType, PaymentService, ServiceRequestService, } from "@propertelligent/client-api";
import { UserLoginData } from "../../common/charts/Enums";
import apiHelper from "../../../utils/apiHelper";

const Tabs = ({
  id,
  loadingStates,
  setLoadingStates,
  checkActive
}) => {
  const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
  const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);

  useEffect(() => {
    const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
    const propertyId = parseInt(id);

    if (propertyId) {
      apiHelper(PaymentService.getApiPaymentMethodPropertylist, { showNotification: false }, propertyId)
        .then((res) => {
          if (res?.data?.success) {
            setPaymentMethodsArr(res?.data?.success);
          }
        });
    }

    apiHelper(PaymentService.getApiPayeeAccountOrganizationlist, { showNotification: false }, orgId)
      .then((ptRes) => {
        if (ptRes?.data?.success) {
          setPayeeAccountsArr(ptRes?.data?.success);
        }
      })
      .catch((ptErr) => { });
  }, [id]);

  return (
    <>
      <div className={`panel ${checkActive(1, "active")}`}>
        <Overview
          loadingStates={loadingStates}
          setLoadingStates={setLoadingStates}
          paymentMethodsArr={paymentMethodsArr}
          payeeAccountsArr={payeeAccountsArr}
          entityTypeId={EntityType.Property}
        />
      </div>
      <div className={`panel ${checkActive(2, "active")}`}>
        <UnitList
          loadingStates={loadingStates}
          setLoadingStates={setLoadingStates}
        />
      </div>
      <div className={`panel ${checkActive(3, "active")}`}>
        <ServiceRequestList
          setTotalServiceRequest={null}
          id={id}
          httpFetchDataFn={
            ServiceRequestService.getApiServiceRequestPropertylist
          }
          loadingStates={loadingStates}
          setLoadingStates={setLoadingStates}
          isAddress={false}
        />
      </div>
    </>
  );
};

export default memo(Tabs);
