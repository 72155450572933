import { useState } from "react";
import "./Screens.css";
import { LeaseApplicationService, LeaseApplicationStatusTypesEnum } from "@propertelligent/client-api";
import AddTraspButton from "../firstTimeUserExperience/commonCom/AddTraspButton";
import { GetImages } from "../../utils/GetImages";
import AddButtonWithArrow from "../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import apiHelper from "../../utils/apiHelper";
import { CustomInputTextArea } from "../common/formControls";

const RejectedScreen = ({ leaseApplicationId, setShowModel }) => {
  const [interalTenant, setInteralTenant] = useState();
  const [noteTenant, setNoteTenant] = useState();

  const handleChange = (e) => {
    setInteralTenant(e.target.value);
  };

  const handleTenantChange = (e) => {
    setNoteTenant(e.target.value);
  };

  const handleSubmit = () => {
    const dataToSend = {
      leaseApplicationStatusTypeId: LeaseApplicationStatusTypesEnum.Rejected,
      internalNotes: interalTenant,
      tenantNotes: noteTenant
    };

    apiHelper(LeaseApplicationService.putApiLeaseApplicationReivew, { showNotification: false }, leaseApplicationId, dataToSend)
      .then((response) => {
        if (response?.data?.success) {
          setShowModel(false)
        }
      })
      .catch((error) => {
      });

  };
  return (
    <>
      <div className="approved-app-form">
        <div className="InnerBody">
          <p className="innerBodyTitle">INTERAL NOTES</p>
          <p className="normal_text mt-3">
            This is internal notes and would not be sent to Tenat
          </p>
          <br />
          <CustomInputTextArea
            name={undefined}
            placeholder="Add note here..."
            required
            isFormControl
            value={interalTenant}
            onChange={handleChange}
            isError={false}
            errorMessages="Add required "
            myClassName="normal_text black w-100 mb-3 p-3"
            rows={3}
          />
          <p className="innerBodyTitle"> NOTES FOR TENANT</p>
          <p className="normal_text mt-3">This note will be sent to Tenant</p>
          <br />
          <CustomInputTextArea
            name={undefined}
            placeholder="Add note here..."
            required
            isFormControl
            value={noteTenant}
            onChange={handleTenantChange}
            isError={false}
            errorMessages="Add required "
            myClassName="normal_text black w-100 mb-3 p-3"
            rows={3}
          />
        </div>
        <div className="d-flex align-items-center gap-1 px-4 float-end">
          <AddTraspButton
            type="button"
            border={true}
            onClick={undefined}
            bname="cancel"
          />
          <AddButtonWithArrow
            className="px-5 bg-danger border-0"
            Icon={GetImages.WhiteForwardArrow}
            iconDirection={"left"}
            buttonname="Reject Application"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default RejectedScreen;
