import { Row, Col } from "react-bootstrap"
import InProgressForm from "./inProgressForm"
import { GetImages } from "../../utils/GetImages"
import AddButtonWithArrow from "../firstTimeUserExperience/commonCom/AddButtonWithArrow"
import SeparaterLine from "../common/SeparaterLine"
import AddTraspButton from "../firstTimeUserExperience/commonCom/AddTraspButton"
import { useNavigate } from "react-router"
import { DocumentEntityTypesEnum, DocumentService, DocumentTypesEnum } from "@propertelligent/client-api"
import apiHelper from "../../utils/apiHelper"
import { useEffect, useState } from "react"
import DateHelper from "../../utils/DateHelper"

const InProgressLoader = ({
	setInProgress,
	setShowVerifyNPay,
	setReassign,
	docsArr,
	currentRecord,
	setCurrentStep, steps, setMarkCompleted, id,
	calculateCurrentDate, latestContractor,
	state, setState, handleMarkCompleteNShowVerify, showBtn, setShowBtn, showVerifyNPay,
	errors,
	errorMessages,
	setErrors,
	handleUpdateServiceRequest }) => {

	return (
		<>
			<div className="singleServiceNameDetailsLeftSide">
				<div className="d-flex flex-column" style={{ gap: "12px" }}>
					<p className="normal_text fw-600 black">Contractor Details</p>
					<div className="contractor-card">
						<div className="contractor-detail">
							<span className="BackgroundlightOrange contractor-card-img">
								<img src={GetImages.orangeContractor} />
							</span>
							<span className="d-flex flex-column gap-1">
								<p className="sub_text black">Contractor Name</p>
								<p className="sub_text black fw-600">{latestContractor}</p>
							</span>
						</div>
						<SeparaterLine height={"38px"} background={undefined} />
						<div className="contractor-detail">
							<span className="BackgroundlightOrange contractor-card-img">
								<img src={GetImages.OrangeCalendar} />
							</span>
							<span className="d-flex flex-column gap-1">
								<p className="sub_text black">Assigned on</p>
								<p className="sub_text black fw-600">{calculateCurrentDate}</p>
							</span>
						</div>
						<SeparaterLine height={"38px"} background={undefined} />
						<div className="contractor-detail">
							<span className="BackgroundlightOrange contractor-card-img">
								<img src={GetImages.OrangeDoller} />
							</span>
							<span className="d-flex flex-column gap-1">
								<p className="sub_text black">Estimate</p>
								<p className="sub_text black fw-600">$ {currentRecord?.estimatedCost}</p>
							</span>
						</div>
					</div>
				</div>
				{
					showBtn && (
						<div>
							<AddTraspButton border={true} bname="Edit/Reassign" onClick={() => {
								setCurrentStep("assignKey")
								setInProgress(false);
								setShowVerifyNPay(false);
								setReassign(true);
							}} />
						</div>
					)
				}
			</div>

			{
				showVerifyNPay && (
					<div className="singleServiceNameDetailsLeftSide" id="fix-detail-card">
						<div className="d-flex justify-content-between p-12 BorderBottom align-items-center" >
							<p className="normal_text fw-600 black">Fix Details</p>
							{
								showVerifyNPay && (
									<div>
										<AddTraspButton border={true} bname="Edit"
											width="fit-content"
											onClick={() => {
												setCurrentStep("progressKey")
												setInProgress(true);
												setShowVerifyNPay(false);
												setReassign(false);
												setShowBtn(true)
											}} />
									</div>
								)
							}
						</div>
						<div className="contractor-card">
							<div className="contractor-detail">
								<span className="contractor-card-img">
									<img src={GetImages.OrangeCalendar} className="iconstyle16" />
								</span>
								<span className="d-flex gap-1">
									<p className="sub_text black fw-500">Fix Date :</p>
									<p className="sub_text black ">{DateHelper.convertUTCtoDateTimeSimple(currentRecord?.completeDate)}</p>
								</span>
							</div>
							<div className="contractor-detail">
								<span className="contractor-card-img">
									<img src={GetImages.OrangeList} className="iconstyle16" />
								</span>
								<span className="d-flex flex-column gap-1">
									<p className="sub_text black fw-500">Fix Note :</p>
									<p className="sub_text black">{currentRecord?.fixNotes}</p>
								</span>
							</div>
							<div className="contractor-detail">
								<span className="contractor-card-img">
									<img src={GetImages.OrangeList} className="iconstyle16" />
								</span>
								<span className="d-flex flex-column" style={{ gap: "12px" }}>
									<p className="sub_text black fw-500">Review work images</p>
									<div>
										<div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
											{docsArr.length > 0 &&
												docsArr.map((doc, index) => (
													<div
														style={{
															width: "80px",
															height: "80px",
															boxShadow: "0px 1px 3px #aaaaaa",
															borderRadius: "8px",
															position: "relative",
															overflow: "hidden",
														}}
														key={doc.id}
													>
														<img
															src={doc?._rawFile}
															alt={`Document ${index + 1}`}
															style={{
																width: "100%",
																height: "100%",
																borderRadius: "8px",
																objectFit: "cover",
																transition: "filter 0.3s ease",
															}}
														/>
													</div>

												))}
										</div>
									</div>
								</span>
							</div>
						</div>
					</div>
				)
			}
			<InProgressForm id={id} calculateCurrentDate={calculateCurrentDate} latestContractor={latestContractor} state={state} setState={setState} handleUpdateServiceRequest={handleUpdateServiceRequest} showBtn={showBtn} setShowBtn={setShowBtn} errors={errors}
				errorMessages={errorMessages} setErrors={setErrors} />

			<div>
				{
					!showVerifyNPay && (
						<AddButtonWithArrow buttonname={"Mark as Fixed"} onClick={handleUpdateServiceRequest} />
					)
				}
			</div>
		</>
	)
}

export default InProgressLoader
