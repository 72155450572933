import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";
import CustomInputs from "../../common/Inputs";
import NewSelectAuto from "../../tenants/NewSelectAuto";

const EmploymentHistoryEdit = ({
  postData,
  setPostData,
}) => {
  const data = [
    {
      id: 1,
      name: "1"
    },
    {
      id: 2,
      name: "2"
    },
    {
      id: 3,
      name: "3"
    },

  ]

  return (
    <>
      <div className="edit-card">
        <p className="sub_heading">Employee History</p>
        <p className="normal_text black">
          <i>
            Please specify the quantity of employee histories you wish to query
            within the app by entering the corresponding number.
          </i>
        </p>
        <p className="normal_text black">How many Employee History you would like to ask?</p>
        <div>

          <NewSelectAuto
            disabled={undefined}
            setDisabled={undefined}
            selected={postData?.leaseApplicationTemplateSections?.EmploymentHistory?.requiredRange}
            setSelected={(value) => setPostData({
              ...postData,
              leaseApplicationTemplateSections: {
                ...postData.leaseApplicationTemplateSections,
                EmploymentHistory: {
                  ...postData.leaseApplicationTemplateSections.EmploymentHistory,
                  requiredRange: Number(value),
                },
              },
            })}
            setSelectedOption={undefined}
            selectedOption={undefined}
            data={data}
            title={"Wait till the data fetches"}
            type="Select  Type"
            error={false}
            setError={() => { }}
            fromContractor={true}
            label={undefined}
            width={"290px"}
          />

        </div>
      </div>
    </>
  );
};

export default EmploymentHistoryEdit;
