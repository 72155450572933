import { useEffect, useState } from 'react'
import OrganizationNameHeader from '../common/OrganizationNameHeader/OrganizationNameHeader';
import { useGeneratePdfContext } from '../../utils/context/GeneratePdfContext';

const PrintLayout = ({ children, isPrint }) => {
    const [topValue, setTopValue] = useState<number | undefined>(undefined);
    const [height, setHeight] = useState<string>('');  // Use a string to store the height calculation
    const { orgDetails, statementData, subHeaderName, } = useGeneratePdfContext();

    useEffect(() => {
        if (statementData) {
            const header = document.querySelector('.PropertyList_View_Header') as HTMLElement;

            if (header) {
                const headerHeight = header.offsetHeight;
                setTopValue(250 + headerHeight + 10);
                setHeight(`calc(100vh - 322px - ${headerHeight}px)`);
            } else {
                setTopValue(250);
                setHeight(`calc(100vh - 322px)`);
            }
        }
    }, [statementData]);

    if (!statementData) {
        setTopValue(250);
        setHeight(`calc(100vh - 322px)`);
    }

    return (
        <>
            <div className='generatePdfDoc'
                style={{
                    top: isPrint ? "unset" : `${topValue}px`,
                    height: isPrint ? "unset" : height,
                    overflow: isPrint ? "unset" : "scroll",
                    position: isPrint ? "unset" : "absolute",
                    width: isPrint ? "99%" : "calc(100vw - 327px)"
                }}
            >
                <table className="print-component"
                    style={{
                        overflow: isPrint ? "unset" : "scroll",
                        position: isPrint ? "unset" : "absolute",
                        width: isPrint ? "99%" : "calc(100vw - 340px)"
                    }}
                >
                    {
                        isPrint && isPrint ?
                            <>
                                <thead>
                                    <tr>
                                        <th>
                                            <OrganizationNameHeader SubHeaderName={subHeaderName} element={undefined} OrgName={orgDetails?.name} OrgImg={`data:image/*;base64,${orgDetails?.logoImageBase64}`} />
                                        </th>
                                    </tr>
                                </thead>
                            </>
                            : ""
                    }
                    <tbody>
                        <tr>
                            <td>
                                {children}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default PrintLayout