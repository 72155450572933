import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { GetImages } from '../../../utils/GetImages';
import apiHelper from '../../../utils/apiHelper';
import { DateFilterTypesEnum, PaymentService } from '@propertelligent/client-api';
import { useNavigate } from 'react-router';
import AddTraspButton from '../../firstTimeUserExperience/commonCom/AddTraspButton';
import AddButtonWithArrow from '../../firstTimeUserExperience/commonCom/AddButtonWithArrow';
import EmptyData from '../../common/EmptyData';
import { PaymentContext } from '../../../utils/context/PaymentContext';
import CommonConfirmation from '../../common/CommonConfirmation';
import RadioButton from '../../common/RadioButton';
import DateHelper from '../../../utils/DateHelper';
import SimpleSpinner from '../../loader';
import UtilHelper from '../../../utils/UtilHelper';
import CustomInputs from '../../common/Inputs';
import { FORM_CONTROLFIELD_TYPES } from '../../../constants/constants';

const PaymentDetail = ({
    onClose,
    heading,
    show,
    paymentMode,
    subheading1 = undefined,
    subheading2 = undefined,
    payeAccountId,
    handleClick,
    isEdit = false,
    tenantId = undefined,
}) => {
    const [rev_monthValue, setRev_MonthValue] = useState(4);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [edit, setEdit] = useState(isEdit);
    const [selectAll, setSelectAll] = useState(false);
    const { payeeAccountsArr, setPayeeAccountsArr, selectedItems, setSelectedItems, selectedOption, setSelectedOption, selectEntityId, totalOutStanding, setTotalOutStanding } = useContext(PaymentContext);
    const [monthFilter, setMonthFilter] = useState(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const getPaymentReceivedTotal = () => {
        return payeeAccountsArr.reduce((total, item) => {
            if (selectedOption[item.id] === "Payment Received") {
                return Number(total) + Number(item.amount);
            }
            const totalAmount = UtilHelper.formatNegativeValue(total)
            return totalAmount;
        }, 0);
    };

    const getWaivedTotal = () => {
        return payeeAccountsArr.reduce((total, item) => {
            if (selectedOption[item.id] === "Waived") {
                return Number(total) + Number(item.amount);
            }

            const totalAmount = UtilHelper.formatNegativeValue(total)
            return totalAmount;
        }, 0);
    };

    const month = [
        { value: 4, label: "All" },
        { value: 30, label: "30 Days" },
        { value: 3, label: "3 months" },
        { value: 6, label: "6 months" }
    ]

    const getOutstandingTotal = () => {
        const totalReceived = getPaymentReceivedTotal();
        const totalWaived = getWaivedTotal();
        const totalOutstandingAmount = totalOutStanding - totalReceived - totalWaived;
        return UtilHelper.formatNegativeValue(totalOutstandingAmount)
    };

    const setMonthFilters = (month: any) => {
        setRev_MonthValue(month);
        const filters =
            month == 30
                ? DateFilterTypesEnum.LastMonth
                : month == 3
                    ? DateFilterTypesEnum.Last3Months
                    : month == 6
                        ? DateFilterTypesEnum.Last6Months
                        : null;
        setMonthFilter(filters);
    };

    const getPayablesPayeeList = () => {
        setLoading(true)
        let clientDate = DateHelper.getClientDate();
        if (monthFilter !== null) {
            apiHelper(
                PaymentService.getApiPaymentDueAccountPayeelist,
                { showNotification: false },
                payeAccountId,
                undefined, undefined, undefined, undefined, undefined, undefined, undefined,
                monthFilter, clientDate)
                .then((res) => {
                    if (res?.data?.success) {
                        setPayeeAccountsArr(res?.data?.success);
                        setTotalOutStanding(res?.data?.success.reduce((total, item) => Number(total) + Number(item.amount), 0))
                        setLoading(false)
                    }
                })
                .catch((ptErr) => { });
        } else {
            apiHelper(PaymentService.getApiPaymentDueAccountPayeelist, { showNotification: false }, payeAccountId)
                .then((res) => {
                    if (res?.data?.success) {
                        setPayeeAccountsArr(res?.data?.success);
                        setTotalOutStanding(res?.data?.success.reduce((total, item) => Number(total) + Number(item.amount), 0))
                        setLoading(false)
                    }
                })
                .catch((ptErr) => { });
        }
    }

    const getReceivablesPayeeList = () => {
        setLoading(true)
        let clientDate = DateHelper.getClientDate();
        if (monthFilter !== null) {
            apiHelper(
                PaymentService.getApiPaymentDueAccountTenantlist,
                { showNotification: false },
                selectEntityId,
                undefined, undefined, undefined, undefined, undefined, undefined, undefined,
                monthFilter,
                clientDate)
                .then((res) => {
                    if (res?.data?.success) {
                        setPayeeAccountsArr(res?.data?.success);
                        setTotalOutStanding(res?.data?.success.reduce((total, item) => Number(total) + Number(item.amount), 0))
                        setLoading(false)
                    }
                })
                .catch((ptErr) => { });
        } else {
            apiHelper(PaymentService.getApiPaymentDueAccountTenantlist, { showNotification: false }, selectEntityId)
                .then((res) => {
                    if (res?.data?.success) {
                        setPayeeAccountsArr(res?.data?.success);
                        setTotalOutStanding(res?.data?.success.reduce((total, item) => Number(total) + Number(item.amount), 0))
                        setLoading(false)
                    }
                })
                .catch((ptErr) => { });
        }
    }

    useEffect(() => {
        if (paymentMode === "Payables") {
            getPayablesPayeeList()
        } else {
            getReceivablesPayeeList()
        }
    }, [payeAccountId, monthFilter])

    useEffect(() => {
        setSelectAll(payeeAccountsArr.length > 0 && payeeAccountsArr.every(item => selectedItems.some(selectedItem => selectedItem.id === item.id)));
    }, [selectedItems, payeeAccountsArr]);

    const handleCheckboxChange = (item) => {
        const isSelected = selectedItems.some(i => i.id === item.id);
        const updatedSelection = isSelected
            ? selectedItems.filter(i => i.id !== item.id)
            : [...selectedItems, item];

        setSelectedItems(updatedSelection);
    };

    const handleRadioChange = (itemId, optionValue) => {
        setSelectedOption(prevOptions => ({
            ...prevOptions,
            [itemId]: optionValue,
        }));
    };

    const handleSelectAllChange = () => {
        setSelectAll(!selectAll);
        setSelectedItems(selectAll ? [] : [...payeeAccountsArr]);
    };

    const getTotalAmount = () => {
        const totalAmount = selectedItems.reduce((total, item) => total + Number(item.amount), 0);
        return totalAmount.toFixed(2);
    };

    const getReceivableTotalAmount = () => {
        const totalReceived = getPaymentReceivedTotal();
        const totalWaived = getWaivedTotal();
        const totalAmount = totalReceived + totalWaived;
        return totalAmount.toFixed(2);
    };

    const radioOptions = [
        { id: 1, label: "Payment Received", value: "Payment Received" },
        { id: 2, label: "Waived", value: "Waived" },
    ];

    const handleConfirmPayment = () => {
        // setShowConfirmation(false);
        onClose();
    };

    const handleNavigate = () => {
        if (edit) {
            setShowConfirmation(true);
            setEdit(false);
            return;
        }
        if (paymentMode === "Payables") {
            if (selectedItems.length === 0) {
                return;
            }
        } else {
            if (Object.keys(selectedOption).length === 0) {
                return;
            }
        }
        handleClick(payeAccountId);
    };

    const hasPaymentReceived = Object.values(selectedOption).includes("Payment Received");
    const hasWaived = Object.values(selectedOption).includes("Waived");

    return (
        <div className="p-3 payment-detail">
            <Modal
                className="commonConfirmationModal d-flex align-items-center justify-content-center"
                show={show}
                onHide={onClose}
            >
                <div className="d-flex flex-column justify-content-betweenh-100" style={{ gap: "24px", padding: "20px" }}>
                    <div className="d-flex flex-column">
                        <div className='d-flex align-items-center justify-content-between'>
                            <p className="heading black">{heading}</p>
                            <img
                                className="clickable"
                                onClick={onClose}
                                src={GetImages.closeIconBlack}
                                alt="close"
                            />
                        </div>
                        <div className='d-flex'>
                            <p className="sub_text black">{subheading1}</p>
                            <p className="normal_text fw-500 text-nowrap grey_subtext1 mx-1">•</p>
                            <p className="sub_text black">{subheading2}</p>
                        </div>
                    </div>

                    <div className="d-flex flex-column" style={{ gap: "24px" }}>
                        <div className="monthhead">
                            {month.map(button => (
                                <button
                                    key={button.value}
                                    onClick={() => setMonthFilters(button.value)}
                                    className={rev_monthValue === button.value ? "monthSelectedButton sub_text fw-500 blue" : "monthButton sub_text fw-500 grey_subtext1"}
                                >
                                    {button.label}
                                </button>
                            ))}
                        </div>

                        <div className={`${paymentMode === "Payables" ? " borderWithRadius payments-card" : " payments-card"}`}>
                            {loading ? (
                                <div className='loading-card'>
                                    <SimpleSpinner myClassName='Icon40' />
                                </div>
                            ) : payeeAccountsArr.length > 0 && paymentMode === "Payables" ? (
                                <>
                                    <div className="BorderBottom selectAllBox">
                                        <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />
                                        <p className="normal_text fw-500 black">Select All</p>
                                    </div>
                                    {payeeAccountsArr.map((item, index) => (
                                        <div key={index} className="Last_transaction gap-5">
                                            <div className="d-flex align-items-start gap-2">
                                                <input type="checkbox" checked={selectedItems.some(i => i.id === item.id)} onChange={() => handleCheckboxChange(item)} />
                                                <div className="d-flex flex-column">
                                                    <p className="normal_text fw-500 black">{item.payerPayeeName}</p>
                                                    <p className="sub_text grey_subtext1">{item.streetAddress}</p>
                                                    <p className="sub_text grey_subtext1">{item.description}</p>
                                                </div>
                                            </div>

                                            {/* <div
                                                key={item.id}
                                                className="onHoverEdit"
                                                onMouseEnter={() => setOnHover(item.id)}
                                                onMouseLeave={() => setOnHover(null)}
                                            >
                                                {
                                                    onHover === item.id ?
                                                        <AddTraspButton
                                                            width={0}
                                                            textclass={"sub_text fw-600 blue"}
                                                            bname={undefined}
                                                            iconPosition={"right"}
                                                            onClick={() => { navigate(`/payment/edit/${item.id}`) }}
                                                            border={true}
                                                            className="onHoverEditBtn"
                                                            imageValue={GetImages.editVector}
                                                        />
                                                        :
                                                        <p className="normal_text fw-500 black">{`$ ${UtilHelper.formatNegativeValue(item.amount)}`}</p>
                                                }
                                            </div> */}
                                            <div
                                                key={item.id}
                                                className="onHoverEdit d-flex flex-column align-items-end gap-1"
                                            >
                                                <AddTraspButton
                                                    type="button"
                                                    bname={"Edit"}
                                                    className="p-0 paymentDetial-edit-btn"
                                                    onClick={() => {
                                                        navigate(`/payment/edit/${item.id}`)
                                                    }} />
                                                <CustomInputs
                                                    isFormControl={true}
                                                    myClassName="containerTextField normal_text black fw-500"
                                                    currencySymbol="$"
                                                    type={FORM_CONTROLFIELD_TYPES.NUMBER}

                                                    onChange={(e) => {
                                                        let newArray = [...payeeAccountsArr];
                                                        newArray[index].amount = e.target.value;
                                                        setPayeeAccountsArr(newArray)
                                                    }}
                                                    value={item?.amount} />
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : payeeAccountsArr.length > 0 && paymentMode === "Receivables" ? (
                                <div className="receivablePaymentDetail gap-2">
                                    <p className="normal_text fw-500 black">{payeeAccountsArr.length} dues</p>
                                    {payeeAccountsArr.map((item, index) => (
                                        <div key={index} className="paymentReceived borderWithRadius">
                                            <div className="d-flex gap-4 BorderBottom p-12 justify-content-between">
                                                <div className='payment-type-btn'>
                                                    {radioOptions.map((option) => {
                                                        return (
                                                            <div key={option.value} className="d-flex align-items-center gap-1">
                                                                <RadioButton
                                                                    id={`radioOptions-${item.id}-${option.value}`}
                                                                    label={option.label}
                                                                    value={option.value}

                                                                    checked={selectedOption[item.id] === option.value}
                                                                    onChange={() => handleRadioChange(item.id, option.value)}
                                                                    name={`paymentOption-${item.id}`}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    )}
                                                </div>
                                                <AddTraspButton
                                                    type="button"
                                                    bname={"Edit"}
                                                    onClick={() => {
                                                        navigate(`/payment/edit/${item.id}`)
                                                    }} />
                                            </div>

                                            <div className="waived-card">
                                                <div>
                                                    <p className="normal_text grey_subtext1">{item?.formatted?.PaymentTypeId}</p>
                                                    <p className="sub_text grey_subtext1">Due on: {DateHelper.formatDateonly(
                                                        item?.dueDate
                                                    )}</p>
                                                </div>
                                                <div
                                                    key={item.id}
                                                    className="onHoverEdit"
                                                >
                                                    <CustomInputs
                                                        isFormControl={true}
                                                        myClassName="containerTextField normal_text black fw-500"
                                                        currencySymbol="$"
                                                        type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                                        onChange={(e) => {
                                                            let newArray = [...payeeAccountsArr];
                                                            newArray[index].amount = e.target.value;
                                                            setPayeeAccountsArr(newArray)
                                                        }}
                                                        value={item?.amount}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : payeeAccountsArr.length === 0 && (
                                <EmptyData mainText="No Due Payments" ImageSrc={GetImages.DuePayments} button={undefined} />
                            )}
                        </div>
                    </div>

                    {paymentMode === "Payables" ? (
                        <div className="PerformanceOverview_Tag">
                            <p className="normal_text">You're Paying</p>
                            <p className="normal_text fw-600">${`${selectedItems ? getTotalAmount() : getReceivableTotalAmount()}`}</p>
                        </div>
                    ) : (
                        <div className="PerformanceOverview_Tag">
                            {hasPaymentReceived && (
                                <div className='d-flex flex-column'>
                                    <p className="normal_text">Tenant paid: </p>
                                    <p className="normal_text">${getPaymentReceivedTotal()}</p>
                                </div>
                            )}
                            {hasWaived && (
                                <div className='d-flex flex-column'>
                                    <p className="normal_text">Waived:</p>
                                    <p className="normal_text">${getWaivedTotal()}</p>
                                </div>
                            )}
                            <div className='d-flex flex-column'>
                                <p className="normal_text">Outstanding: </p>
                                <p className="normal_text">${getOutstandingTotal()}</p>
                            </div>
                        </div>
                    )}

                    <div className="paymentinputrow d-flex justify-content-end gap-2 p-0">
                        <AddTraspButton
                            bname={paymentMode === "Payables" ? "Cancel" : "Send Reminder"}
                            border={paymentMode === "Payables" ? false : true}
                            onClick={() => {
                                onClose()
                                setSelectedItems([])
                                setSelectedOption([])
                            }}
                            className="pe-2"
                        />
                        <AddButtonWithArrow
                            type="button"
                            buttonname={edit ? "Confirm" : "Settle Payment"}
                            onClick={() => {
                                handleNavigate();
                                onClose()
                            }}
                            className="settlePaymentBtn"
                        />
                        {showConfirmation && (
                            <CommonConfirmation
                                show={true}
                                onConfirm={handleConfirmPayment}
                                onClose={onClose}
                                heading="Confirm payment"
                                subHeading="Do you want to confirm payment?"
                                confirmLabel="Confirm"
                            />
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default PaymentDetail;
