import { useEffect, useRef, useState } from "react";
import { Form, Image, ProgressBar } from "react-bootstrap";
import { CommunicationService } from "@propertelligent/client-api";
import { GetImages } from "../../../../utils/GetImages";
import CustomTooltip from "../../../common/CustomTooltip";
import apiHelper from "../../../../utils/apiHelper";
import CustomInputs from "../../../common/Inputs";
import EditorToolBar from "../../../common/EditorToolBar";
import AddButtonWithArrow from "../../../firstTimeUserExperience/commonCom/AddButtonWithArrow";

interface FormDataObject {
  Attachments?: Blob[];
}

const Compose = ({
  emailId,
  replyId,
  setReplyId,
  getEmailMessages,
  replytype,
}) => {
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const bodyRef = useRef(null);
  const [filesWithProgress, setFilesWithProgress] = useState([]);
  const [suggetions, setSuggetions] = useState([]);
  const [emailDetails, setEmailDetails] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [bold, setBold] = useState<Boolean>(true);
  const [italic, setItalic] = useState<Boolean>(true);
  const [showSuggestions, setShowSuggestions] = useState(false); // Added state variable to track whether suggestions should be shown
  const searchInputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target)
      ) {
        setSuggetions([]);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    apiHelper(
      CommunicationService.getApiGmailSearchContacts,
      { showNotification: false },
      to
    )
      .then((res) => {
        const allresults: any = res.data.success;
        setSuggetions(allresults?.results);
      })
      .catch((error) => { });
  }, [to, replyId]);

  useEffect(() => {
    if (replyId) {
      apiHelper(
        CommunicationService.getApiGmailEmailMessage,
        { showNotification: false },
        replyId
      )
        .then((res) => {
          setTo(res?.data?.success?.fromName);
          setEmailDetails(res?.data?.success?.fromEmail);
        })
        .catch((error) => { });
    }
  }, [replyId]);

  const handleFileChange = async (e) => {
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      setFilesWithProgress((prevFiles) => [
        ...prevFiles,
        { file, progress: 0 },
      ]);
    }
    const interval = setInterval(() => {
      setFilesWithProgress((prevFiles) =>
        prevFiles.map((file) => ({
          ...file,
          progress: Math.min(file.progress + 10, 100),
        }))
      );
    }, 500);

    setTimeout(() => {
      clearInterval(interval);
      setFilesWithProgress((prevFiles) =>
        prevFiles.map((file) => ({
          ...file,
          progress: 100,
        }))
      );
    }, 5000);
  };

  const handleDelete = (index) => {
    setFilesWithProgress((prevFiles) =>
      prevFiles.filter((file, i) => i !== index)
    );
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (filesWithProgress.length > 0) {
      filesWithProgress.forEach(({ file }, index) => {
        const blob = new Blob([file], { type: file.type });
        formData.append(`Attachments[${index}]`, blob, file.name);
      });
    }

    const attachments: Blob[] = Array.from(formData.values()).map((value) => {
      if (value instanceof Blob) {
        return value;
      } else if (typeof value === "string") {
        return new Blob([value], { type: "text/plain" });
      }
    });
    if (!emailId) {
      apiHelper(
        CommunicationService.postApiGmailSendEmailMessage,
        {
          successMessage: "Email sent Successfully",
          failureMessage: "Unable to send email",
          showNotification: true,
        },
        to,
        subject,
        bodyRef.current.innerHTML,
        undefined,
        undefined,
        { Attachments: attachments }
      )
        .then((res) => {
          if (res?.data?.success) {
          }
        })
        .catch((error) => { });
    } else {
      if (replytype == "reply") {
        apiHelper(
          CommunicationService.postApiGmailReplyEmailThread,
          {
            successMessage: "Email sent Successfully",
            failureMessage: "Unable to send email",
            showNotification: true,
          },
          emailDetails,
          subject,
          bodyRef.current.innerHTML,
          undefined,
          emailId,
          { Attachments: attachments }
        )
          .then((res) => {
            setReplyId(null);
            if (res?.data?.success) {
              getEmailMessages();
            }
          })
          .catch((error) => { });
      } else {
        apiHelper(
          CommunicationService.postApiGmailForwardEmailThread,
          {
            successMessage: "Email sent Successfully",
            failureMessage: "Unable to send email",
            showNotification: true,
          },

          emailDetails,
          subject,
          bodyRef.current.innerHTML,
          undefined,
          emailId,
          { Attachments: attachments }
        )
          .then((res) => {
            setReplyId(null);
            if (res?.data?.success) {
              getEmailMessages();
            }
          })
          .catch((error) => { });
      }
    }
    setSelectedUser(null);
    setTo("");
    setSubject("");

    bodyRef.current.textContent = null;
    setFilesWithProgress([]);
  };

  const handleUserSelect = (user) => {
    setTo(user.email);
    setEmailDetails(user.email);
    setSelectedUser(user);
    setSuggetions([]);
  };

  const handleInputChange = (e) => {
    setTo(e.target.value);
    setShowSuggestions(true); // Show suggestions when input is clicked
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className="composeForm">
        <div className="emailInputSection">
          <div className="d-flex flex-column w-100 position-relative">
            <div className="ToSection">
              <p className="sub_heading fw-normal">To</p>
              <CustomInputs
                ref={searchInputRef}
                myClassName="w-100 sub_heading fw-normal black "
                type="text"
                value={
                  selectedUser && typeof selectedUser === "object"
                    ? selectedUser.name
                    : to
                }
                onChange={handleInputChange}
                required
                id="customInputBorder"
              />
              {!to && !selectedUser ? (
                ""
              ) : (
                <img
                  src={GetImages.Blackcross}
                  alt={GetImages.Blackcross}
                  onClick={() => {
                    setSelectedUser(null);
                    setTo("");
                  }}
                />
              )}
              {to.trim() !== "" && suggetions?.length > 0 && !selectedUser && (
                <ul
                  className={`selectedChat z-index-1 top-100 position-absolute`}
                >
                  {suggetions?.map((user, index) => (
                    <li
                      key={index}
                      className={`lists showMe justify-content-between`}
                      onClick={() => handleUserSelect(user)}
                    >
                      <p>{user.name}</p>
                      <p>{user.email}</p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="ToSection">
            <p className="sub_heading fw-normal">Subject</p>
            <CustomInputs
              myClassName="w-100 sub_heading fw-normal black"
              type="text"
              value={subject}
              onChange={handleSubjectChange}
              required
              id="customInputBorder"
            />
          </div>
          <div className="w-100 h-100 border-bottom-0">
            <div contentEditable={true} className="emailBody" ref={bodyRef} />
          </div>
          <div className="w-100 d-flex flex-column justify-content-center align-items-center">
            {filesWithProgress.map(({ file, progress }, index) => (
              <div
                key={index}
                className="file-upload-item d-flex flex-row w-100 justify-content-between p-2"
              >
                <div className="d-flex flex-start w-100 gap-2 align-items-center">
                  {file.type.startsWith("image/") ? (
                    <Image
                      style={{ height: "20px", width: "20px" }}
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      thumbnail
                    />
                  ) : file.type === "application/pdf" ? (
                    <div style={{ height: "20px", width: "20px" }}>
                      <img
                        style={{ height: "100%", width: "100%" }}
                        src={GetImages.pdfImage}
                        alt="PDF File"
                      />
                    </div>
                  ) : file.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                    // Display a Word document icon or placeholder
                    <div style={{ height: "20px", width: "20px" }}>
                      <a
                        href={URL.createObjectURL(file)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          style={{ height: "100%", width: "100%" }}
                          src={GetImages.DocIcon}
                          alt="Word Document"
                        />
                      </a>
                    </div>
                  ) : (
                    // Handle other file types or display a generic placeholder
                    <div style={{ height: "20px", width: "20px", background: "#ccc" }}>
                      <img
                        style={{ height: "100%", width: "100%" }}
                        src={GetImages.GrayFile}
                        alt="placeholder"
                      />
                    </div>
                  )}
                  <p className="sub_heading black fw-normal">{file.name}</p>
                </div>
                <div className="d-flex flex-end w-100 align-items-center gap-2 justify-copntent-center">
                  {progress > 0 && progress < 100 ? (
                    <div className="w-100">
                      <ProgressBar
                        now={progress}
                        label={`${progress}%`}
                        visuallyHidden
                      />
                    </div>
                  ) : (
                    <div className="w-100"></div>
                  )}
                  <img
                    onClick={() => handleDelete(index)}
                    className="iconstyle10"
                    src={GetImages.Blackcross}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="emailBottomSection">
          <div className="textVariation">
            <EditorToolBar editorRef={bodyRef} handleFileChange={handleFileChange} />
          </div>
          <div className="d-flex align-items-center gap-2">
            {replyId ? (
              <CustomTooltip
                id="tooltip-disabled"
                placement="Discard"
                tooltip="custom-tooltip"
                Element={
                  <img
                    onClick={() => {
                      setReplyId(null);
                    }}
                    src={GetImages.BlackDelete}
                    alt={GetImages.BlackDelete}
                    className="iconstyle flex-end"
                  />
                }
              />
            ) : (
              ""
            )}
            <AddButtonWithArrow
              buttonname="Send"
              onClick={undefined}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default Compose;
