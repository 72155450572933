import React from "react";
import CustomSwitchToggle from "../../common/Switch";
import { GetImages } from "../../../utils/GetImages";

const QuestionOptional: React.FC<any> = ({
  data,
  postData,
  activeSection
}) => {

  return (
    <>
      <section className="application-sidebar">
        <div className="application-sidebar-head">
          <p className="main_heading black">Application Sections</p>
          <p className="normal_text black"><i>
            "Customize your experience with toggle buttons to enable or disable
            sections in our app."</i>
          </p>
        </div>
        <div className="application-sidebar-menu">
        {data?.map((item, i) => (
          <div key={i} className={`application-sidebar-item`}>
            <p className={`normal_text fw-600 ${!postData?.leaseApplicationTemplateSections?.[item.name.replace(/\s+/g, "")] ? "grey" : "greyHeading"} `}>{item.name}</p>
            <div className="application-sidebar-item-action">
              {item?.editable && (!postData?.leaseApplicationTemplateSections?.[item.name.replace(/\s+/g, "")] ?
                <img onClick={undefined} src={GetImages.GreyPencilEdit} alt="pencil-outline" className="Icon20" />
                :
                <img onClick={item.editClick} src={GetImages.BluePencilEdit} alt="pencil-outline" className="Icon20" />
              )}
              <CustomSwitchToggle checked={!postData?.leaseApplicationTemplateSections?.[item.name.replace(/\s+/g, "")] ? false : true} onClick={item.onClick}
              //  disabled={!postData?.leaseApplicationTemplateSections[item.name.replace(/\s+/g, "")] ? true :false}
              />
              <img src={GetImages.InfoIcon} className="Icon30" alt="info" />{" "}
            </div>
          </div>
        ))}
        </div>
      </section>
    </>
  );
};

export default QuestionOptional;
