import React, { createContext, useContext, useState } from 'react';

const PaymentContext = createContext(null);

interface PayeeAccount {
    id: number;
    formatted: {
        PaymentTypeId: string;
        Amount: string;
        DueDateDateOnly: string;
    };
}

export const PaymentProvider = ({ children }) => {
    const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [tenantId, setTenantId] = useState()
    const [entityId, setEntityId] = useState()
    const [receivablePayeeAccId, setReceivablePayeeAccId] = useState()
    const [paymentData, setPaymentData] = useState([])
    const [selectEntityId, setSelectEntityId] = useState<any>();
    const [payeeAccountsArr2, setPayeeAccountsArr2] = useState<PayeeAccount[]>([]);
    const [totalOutStanding, setTotalOutStanding] = useState(0)

    const [formData, setFormData] = useState<any>([
        {
            id: 1,
            dueDate: "",
            amount: 0,
            gracePeriods: 0,
            description: "",
        },
    ]);

    const values = {
        payeeAccountsArr,
        setPayeeAccountsArr,
        selectedItems,
        setSelectedItems,
        selectedOption,
        setSelectedOption,
        tenantId,
        setTenantId,
        formData,
        setFormData,
        paymentData,
        setPaymentData,
        receivablePayeeAccId,
        setReceivablePayeeAccId,
        entityId,
        setEntityId,
        selectEntityId,
        setSelectEntityId,
        payeeAccountsArr2,
        setPayeeAccountsArr2,
        totalOutStanding,
        setTotalOutStanding
    }

    return (
        <PaymentContext.Provider value={values}>
            {children}
        </PaymentContext.Provider>
    );
};

export const usePaymentContext = () => {
    return useContext(PaymentContext);
};

export { PaymentContext };
