import { useState } from "react";
import "./Screens.css";
import { LeaseApplicationService, LeaseApplicationStatusTypesEnum } from "@propertelligent/client-api";
import CustomDropDownMenu from "../common/CustomDropDownMenu";
import { GetImages } from "../../utils/GetImages";
import AddTraspButton from "../firstTimeUserExperience/commonCom/AddTraspButton";
import AddButtonWithArrow from "../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import apiHelper from "../../utils/apiHelper";
import { getKeyByValue } from "../properties/components/helper";
import { useNavigate } from "react-router";
import { CustomInputTextArea } from "../common/formControls";

const ApprovedScreen = ({ leaseApplicationId, status, setShowModel, setInitiateLeaseAgreement }) => {
  const navigate = useNavigate()
  const [interalTenant, setInteralTenant] = useState();
  const [noteTenant, setNoteTenant] = useState();
  const handleChange = (e) => { setInteralTenant(e.target.value) };
  const handleTenantChange = (e) => { setNoteTenant(e.target.value) };

  const handleSubmit = (approve) => {
    const dataToSend = {
      leaseApplicationStatusTypeId: status,
      internalNotes: interalTenant,
      tenantNotes: noteTenant
    };

    apiHelper(LeaseApplicationService.putApiLeaseApplicationReivew, {
      successMessage: `Applicaton ${getKeyByValue(Number(status))} successfully`,
      failureMessage: `Applicaton ${getKeyByValue(Number(status))} unable`,
      showNotification: true,
    }, leaseApplicationId, dataToSend)
      .then((response) => {
        if (response?.data?.success) {
          setShowModel(false)
          if (approve == "approve & initiate") {
            setInitiateLeaseAgreement(true)
          }
        }
      })
      .catch((error) => {
        console.error("Error toggling section:", error);
      });
  };
  const actions = [
    {
      id: 1,
      img: undefined,
      name: "Approve Only",
      onClick: () => handleSubmit("approve"),
      linkpath: undefined,
      textClass: 'sub_text fw-600 blue'
    },
    {
      id: 2,
      img: undefined,
      name: "Approve & Initiate lease agreement",
      onClick: () => { handleSubmit("approve & initiate"); },
      linkpath: undefined,
      textClass: 'sub_text fw-600 blue'
    },]

  return (
    <>
      <div className="approved-app-form">
        <div className="InnerBody">
          <p className="innerBodyTitle">INTERAL NOTES</p>
          <p className="normal_text mt-3 mb-2">This is internal notes and would not be sent to Tenant </p>
          <CustomInputTextArea
            name={undefined}
            placeholder="Add note here..."
            required
            isFormControl
            value={interalTenant}
            onChange={handleChange}
            isError={false}
            errorMessages="Add required "
            myClassName="normal_text black w-100 mb-3"
            rows={3}
          />

          <p className="innerBodyTitle">NOTES FOR TENANT</p>
          <p className="normal_text mt-3">This note will be sent to Tenant</p>
          <br />
          <CustomInputTextArea
            name={undefined}
            placeholder="Add note here..."
            required
            isFormControl
            value={noteTenant}
            onChange={handleTenantChange}
            isError={false}
            errorMessages="Add required "
            myClassName="normal_text black w-100 p-3"
            rows={3}
          />

        </div>
        <div className=" d-flex align-items-center px-4 gap-1 float-end">
          <AddTraspButton
            type="button"
            border={true}
            onClick={undefined}
            bname="cancel"
          />
          {status == LeaseApplicationStatusTypesEnum.Approved ? (
            <div className="actionsDropdownContainer clickable">
              <CustomDropDownMenu Action={true} Element={<img src={GetImages.BlueChevronDown} alt="BlueChevronDown" />} data={actions} />
            </div>
          ) :
            <AddButtonWithArrow className={"px-5 bg-danger border-0"} Icon={GetImages.WhiteForwardArrow} iconDirection={"left"} buttonname={"Close Application"} onClick={handleSubmit} />
          }
        </div>
      </div>
    </>
  );
};

export default ApprovedScreen;
