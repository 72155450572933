import { GetImages } from '../../../utils/GetImages'
import AddButtonWithArrow from '../../firstTimeUserExperience/commonCom/AddButtonWithArrow'

const SelectType = ({
    title = undefined,
    firstOption = undefined,
    secondOption = undefined,
    icon1 = undefined,
    icon2 = undefined,
    selectBasic,
    setSelectBasic,
    handleNext,
    showNextBtn = true
}) => {
    return (
        <div className="newTenantSelectType" id="tenantFormContainer">
            <div className="newTenantFormTopBar">
                <p className="main_heading black">
                    {title ? ` Select ${title} Type` : 'Select Tenant Type'}
                </p>
            </div>
            <div className="d-flex justify-content-center gap-5">
                <div
                    onClick={() => setSelectBasic("1")}
                    className="tenantTypeCards clickable"
                    style={{
                        outline: selectBasic === "1" ? "1px solid #1A56DB" : "none",
                    }}
                >
                    <div className="d-flex align-items-center gap-3">
                        <img src={icon1 ? icon1 : GetImages.OnboardNewTenant} alt="Home" />
                        <div>
                            <p className="normal_text black fw-500">
                                {firstOption ? firstOption : "Onboard New Tenant"}
                            </p>
                            <p className='sub_text grey_subtext1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut iaculis arcu</p>
                        </div>
                    </div>
                </div>

                <div
                    onClick={() => setSelectBasic("2")}
                    className="tenantTypeCards clickable"
                    style={{
                        outline: selectBasic === "2" ? "1px solid #1A56DB" : "none",
                    }}
                >
                    <div className="d-flex align-items-center gap-3">
                        <img src={icon2 ? icon2 : GetImages.ExistingTenant} alt="Home" />
                        <div>
                            <p className="normal_text black fw-500">
                                {secondOption ? secondOption : "Existing Tenant"}
                            </p>
                            <p className='sub_text grey_subtext1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut iaculis arcu</p>
                        </div>
                    </div>
                </div>
            </div>
            {showNextBtn &&
                <div className="newTanentBottomBar">
                    <AddButtonWithArrow
                        buttonname="Next"
                        onClick={handleNext}
                        disabled={undefined}
                    />
                </div>
            }
        </div>
    )
}

export default SelectType