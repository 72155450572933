import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import CustomInputs from '../../common/Inputs'
import { FORM_CONTROLFIELD_TYPES } from '../../../constants/constants'
import { useError } from '../../../utils/context/ErrorContext'
import NewSelectAuto from '../../tenants/NewSelectAuto'
import apiHelper from '../../../utils/apiHelper'
import { PaymentService } from '@propertelligent/client-api'
import { UserLoginData } from '../../common/charts/Enums'
import AddButtonWithArrow from '../../firstTimeUserExperience/commonCom/AddButtonWithArrow'
import AddTraspButton from '../../firstTimeUserExperience/commonCom/AddTraspButton'

const AddEditPayeeAccount = ({ setShowModal, isEdit = false, id = undefined, fetchData = undefined, setPayeeAccountId = undefined }) => {
    const [PayeeName, setPayeeName] = useState("")
    const [AccountType, setAccountType] = useState<any>()
    const [AccountTypeError, setAccountTypeError] = useState(false)
    const [options, setOptions] = useState([])
    const { errors, errorMessages, setErrors, setErrorMessages, clearErrors } = useError();
    const orgId = localStorage.getItem(UserLoginData.organization_id)

    const onChangeHandler = (e) => {
        setPayeeName(e.target.value)
        setErrors({ ...errors, payeeName: false })
    }

    const checkEmptyFields = (): boolean => {
        let hasError = false;
        clearErrors();
        const newErrors = { ...errors };
        const newErrorMessages = { ...errorMessages };

        if (AccountType === "" || AccountType === undefined) {
            setAccountTypeError(true)
            hasError = true;
        } else {
            setAccountTypeError(false)
        }

        if (PayeeName.trim() === "") {
            newErrors.payeeName = true;
            hasError = true;
        } else {
            newErrors.payeeName = false;
        }

        setErrors(newErrors);
        setErrorMessages(newErrorMessages);

        return hasError;
    };

    useEffect(() => {
        clearErrors()
        setAccountTypeError(false)
    }, [])

    const getPayeeAccountDetails = () => {
        apiHelper(PaymentService.getApiPayeeAccount, { showNotification: false, }, id).then((res) => {
            if (res?.data?.success) {
                setPayeeName(res?.data?.success?.accountName)
                setAccountType(res?.data?.success?.payeeAccountTypeId)
            }
        }).catch((err) => { })
    }

    useEffect(() => {
        if (isEdit) {
            getPayeeAccountDetails()
        }
    }, [])

    const getPayeeTypesList = () => {
        apiHelper(PaymentService.getApiPayeeAccountPayeeTypes, { showNotification: false, }).then((res) => {
            if (res?.data?.success) {
                setOptions(res?.data?.success)
            }
        }).catch((err) => { })
    }

    useEffect(() => {
        getPayeeTypesList()
    }, [])

    const submitHandler = (e) => {
        e.preventDefault();
        if (checkEmptyFields()) {
        } else {
            if (isEdit) {
                const obj = {
                    id: id,
                    accountName: PayeeName,
                    organizationId: orgId,
                    payeeAccountTypeId: AccountType
                }
                apiHelper(PaymentService.putApiPayeeAccount, {
                    showNotification: true,
                    successMessage: "Payee Account Updated Successfully",
                    failureMessage: "Unable to Update Payee Account",
                }, obj).then((res) => {
                    if (res?.data?.success) {
                        setPayeeName("")
                        setAccountType("")
                        setShowModal(false)
                        fetchData(1)
                    }
                }).catch((err) => { })
            }
            else {
                const obj = {
                    accountName: PayeeName,
                    organizationId: orgId,
                    payeeAccountTypeId: AccountType
                }
                apiHelper(PaymentService.postApiPayeeAccount, {
                    showNotification: true,
                    successMessage: "Payee Account Added Successfully",
                    failureMessage: "Unable to Add Payee Account",
                }, obj).then((res) => {
                    if (res?.data?.success) {
                        setPayeeName("")
                        setAccountType("")
                        setShowModal(false)
                        fetchData()
                        setPayeeAccountId && setPayeeAccountId(res?.data?.success?.id)
                    }
                }).catch((err) => { })
            }
        }
    }

    return (
        <Form onSubmit={submitHandler}>
            <div className='addEditPayeeAccountForm'>
                <CustomInputs
                    type={FORM_CONTROLFIELD_TYPES.TEXT}
                    labelText={"Payee Name"}
                    value={PayeeName}
                    placeholder={"Enter Name"}
                    onBlur={() => {
                        if (PayeeName == "") {
                            setErrors({ ...errors, payeeName: true })
                        } else {
                            setErrors({ ...errors, payeeName: false })
                        }
                    }}
                    onChange={onChangeHandler}
                    isFormControl={true}
                    isError={errors.payeeName}
                    errorMessages={errorMessages.payeeName}
                    required
                />
                <div className='mt-2'>
                    <NewSelectAuto
                        disabled={false}
                        setDisabled={undefined}
                        selected={AccountType}
                        setSelected={setAccountType}
                        data={options}
                        title={undefined}
                        error={AccountTypeError}
                        setError={setAccountTypeError}
                        fromContractor={true}
                        label={"Account Type *"}
                        width='100%'
                        errorMessage={errorMessages.accountType}
                    />
                </div>
            </div>
            <div className="editFormFooterContractor">
                <AddTraspButton
                    type="button"
                    bname="Cancel"
                    onClick={() => setShowModal(false)}
                    width="fit-content"
                />
                <AddButtonWithArrow
                    type="submit"
                    buttonname="Save Changes"
                />
            </div>
        </Form>
    )
}

export default AddEditPayeeAccount