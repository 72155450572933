import { useState } from "react";
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import showPasswordIcon from "../../assets/ShowPasswordIcon.svg";
import hidePasswordIcon from "../../assets/hidePassword.svg";
import "./style.css";

const CustomInputs = ({
  labelText = "",
  label_img = undefined,
  value = undefined,
  onChange,
  onBlur = (value: any): any => { },
  onFocus = () => { },
  errorMessages = "",
  id = undefined,
  name = undefined,
  type = "text",
  placeholder = "",
  isPassword = false,
  focus = false,
  disabled = false,
  required = false,
  maxLength = undefined,
  myClassName = "",
  isFormControl = false,
  isError = false,
  min = undefined,
  max = undefined,
  onImageUpload = undefined,
  currencySymbol = undefined,
  isRadio = false,
  checked = false,
  height = undefined,
  multiple = false,
  rows = undefined,
  width = undefined,
  currencyHeight = undefined,
  ref = undefined,
  InputsWrapperClass = undefined,
  containerClassName = "singleInputsWrapper",
  currencySymbolDirection = "left"
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleChange = (event) => {
    if (type === "tel") {
      const numericValue = event.target.value.replace(/[^0-9]/g, "");
      onChange({ target: { name: event.target.name, value: numericValue } });
    } else if (type === "file" && onImageUpload) {
      onImageUpload(event);
    } else {
      onChange(event);
    }
  };

  const toggleShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  let injectErrorClasses = "";
  if (typeof isError === "boolean") {
    injectErrorClasses = isError
      ? "errorBorder errorOutline"
      : "normalBorder normalOutline";
  }

  return (
    <div
      className={`${InputsWrapperClass} ${containerClassName}`}
      style={{
        display: type === "file" ? "none" : "block",
        width: width,
        marginBottom: isError ? "8px" : "",
      }}
    >

      {isRadio ? (
        <div className="custom-radio-wrapper d-flex align-item-center justify-content-center gap-3">
          <input
            type="radio"
            id={id}
            onChange={onChange}
            name="achAccount"
            className="ms-auto"
            checked={checked}
          />
          <div>
            {labelText &&
              <div className="d-flex justify-content-between">
                <p className="normal_text black ">{required ? `${labelText} *` : labelText}</p>
                {label_img && <img src={label_img} height={16} />}
              </div>
            }
          </div>

        </div>
      ) : (<>
        <div>
          {labelText &&
            <div className="d-flex justify-content-between">
              <p className="normal_text black pb-2">{required ? `${labelText} *` : labelText}</p>
              {label_img && <img src={label_img} height={16} />}
            </div>
          }
        </div>
        <div className="passwordInputBox">
          {isFormControl ? (
            <div className="input-group d-flex flex-nowrap">
              {currencySymbol && (
                currencySymbolDirection === "left" &&
                <div className="input-group-prepend ">
                  <span
                    className={`${injectErrorClasses} ${myClassName} input-group-text removeRightBorder`}
                    style={{ height: currencyHeight }}
                  >
                    {currencySymbol}
                  </span>
                </div>
              )}
              <Form.Control
                id={id}
                className={`${injectErrorClasses} ${myClassName} inputClass normal_text`}
                type={isPassword ? (showPassword ? "text" : "password") : type}
                name={name}
                value={type === "file" ? undefined : value}
                placeholder={placeholder}
                onBlur={onBlur}
                onChange={handleChange}
                disabled={disabled}
                autoFocus={focus}
                onFocus={onFocus}
                maxLength={maxLength}
                multiple={multiple}
                min={min}
                max={max}
                ref={ref}
                aria-rowcount={rows}
                style={{
                  border: isError ? "2px solid #F05252" : "1px solid #E5E7EB",
                  marginBottom: isError ? "0px" : "8px",
                  outlineColor: isError ? "#E02424" : "#1A56DB",
                  height: height,
                }}
              />
              {currencySymbol && (
                currencySymbolDirection === "right" &&
                <div className="input-group-prepend ">
                  <span
                    className={`${injectErrorClasses} ${myClassName} input-group-text removeLeftBorder`}
                    style={{ height: currencyHeight }}
                  >
                    {currencySymbol}
                  </span>
                </div>
              )}
            </div>
          ) : (
            <input
              id={id}
              className={`${injectErrorClasses} ${myClassName} inputClass normal_text`}
              type={isPassword ? (showPassword ? "text" : "password") : type}
              name={name}
              value={type === "file" ? undefined : value}
              placeholder={placeholder}
              onBlur={onBlur}
              onChange={handleChange}
              disabled={disabled}
              autoFocus={focus}
              maxLength={maxLength}
              onFocus={onFocus}
              ref={ref}
              style={{
                border: isError ? "2px solid #F05252" : "1px solid #E5E7EB",
                marginBottom: isError ? "0px" : "8px",
                outlineColor: isError ? "#E02424" : "#1A56DB",
                height: height,
              }}
            />
          )}

          {isPassword && (
            <span
              onClick={toggleShowPassword}
              className="showPasswordIcon"
              style={{
                backgroundImage: `url(${showPassword ? hidePasswordIcon : showPasswordIcon
                  })`,
                transform: isError ? "translateY(-40%)" : "translateY(-60%)",
                top: "45%",
              }}
            />
          )}
        </div>
      </>
      )}

      {isError && (
        <div style={{ marginTop: "8px" }}>
          <p className="errorMessageStyles">{errorMessages}</p>
        </div>
      )}
    </div>
  );
};

export default CustomInputs;
