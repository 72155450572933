import { FilterEntityEnum } from '@propertelligent/client-api';
import React, { createContext, useContext, useState } from 'react';

const GeneratePdfContext = createContext(null);

export const GeneratePdfProvider = ({ children }) => {
    const [statementData, setStatementData] = useState({});
    const [orgDetails, setOrgDetails] = useState({});
    const [subHeaderName, setSubHeaderName] = useState();
    const [reportStatementId, setReportStatementId] = useState(null);
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null)
    const [selectedIds, setSelectedIds] = useState([]);
    const [multiSelectOptions, setMultiSelectOptions] = useState([]);

    const values = {
        statementData,
        setStatementData,
        reportStatementId,
        setReportStatementId,
        orgDetails,
        setOrgDetails,
        subHeaderName,
        setSubHeaderName,
        selectedValues,
        setSelectedValues,
        selectedOption,
        setSelectedOption,
        selectedIds,
        setSelectedIds,
        multiSelectOptions,
        setMultiSelectOptions
    }


    return (
        <GeneratePdfContext.Provider value={values}>
            {children}
        </GeneratePdfContext.Provider>
    );
};

export const useGeneratePdfContext = () => {
    return useContext(GeneratePdfContext);
};

export { GeneratePdfContext };