import "../RenderSteps/tenantonboard.css";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import { OnboardingStepsEnums } from "../../../constants/onboarding";
import AddButtonWithArrow from "../../firstTimeUserExperience/commonCom/AddButtonWithArrow";

const Footer = ({
  currentStepIndex,
  stepsCount,
  stepFivePart2,
  goPrevStep,
  handleSubmit,
  steps
}) => {
  let buttonname = steps[currentStepIndex] == OnboardingStepsEnums.Signature ? "Review" : "Pay Fee";
  return (
    <>
      <div className="footerSection">
        <div className="flexRow gap-2">
          <AddTraspButton
            type="button"
            bname={"Cancel"}
            iconPosition={undefined}
            onClick={undefined}
            border={true}
          />
          {currentStepIndex > 1 && (
            <AddButtonWithArrow
              buttonname="Previous"
              onClick={goPrevStep}
            />
          )}
          <AddButtonWithArrow
            buttonname={currentStepIndex != stepsCount ? "Next" : buttonname}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};
export default Footer;
