import React from 'react'
import { GetImages } from '../../../utils/GetImages'
import DateHelper from '../../../utils/DateHelper'
import EmptyData from '../../common/EmptyData'

const CreditCheckInfo = ({CreditCheck}) => {
  return (
    <>{
        CreditCheck ? (
            <div className="creditcheck">
              <div className="creditcheck_left">
                <div className="CommonRoundBackground BackgroundlightOrange Icon40">
                  <img src={GetImages.SSN_ORANGE} alt="SSN_ORANGE" />
                </div>
                <div>
                  <p className="normal_text fw-bold black">Social Security Number</p>
                  <p className="normal_text black">
                    {CreditCheck?.taxIdNumber}
                  </p>
                </div>
              </div>
              <div className="creditcheck_left flex-column align-items-center">
                <p className="normal_text fw-bold black">Date of Birth</p>
                <p className="normal_text black">
                  {DateHelper.convertUTCtoDateTime2(
                    CreditCheck?.dateOfBirth
                  )}
                </p>
              </div>
              <div className="creditcheck_right">
                <p className="normal_text black">
                  Authorization obtained from the tenant to conduct a credit report
                  check.
                </p>
                <div className="BackgroundlightBlue CommonSquareBackground p-3">
                  <p className="normal_text">
                    {CreditCheck?.consentToRunCheck ? "Yes" : "No"}
                  </p>
                </div>
              </div>
            </div>
          ) : ( <EmptyData mainText="Data not found" /> )
    }</>
  )
}

export default CreditCheckInfo