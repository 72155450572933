import {
  OnboardingApplicationFeeForm,
  OnboardingEmploymentHistoryForm,
  OnboardingQuestionnaireForm,
  OnboardingReferencesForm,
  OnboardingCurrentAddressForm
} from "../typings/onboarding";

export enum OnBoardingRentIncomeEnum {
  "Income" = "Income",
  "Assisted" = "Assisted",
  "Both" = "Both",
}
export type OnBoardingRentIncomeType = keyof typeof OnBoardingRentIncomeEnum;

export enum OnboardingStepsEnums {
  //  Welcome = "Welcome",
  BasicDetails = "BasicDetails",
  CurrentAddress = "CurrentAddress",
  RentIncomeCheck = "RentIncomeCheck",
  CreditCheck = "CreditCheck",
  EmploymentHistory = "EmploymentHistory",
  References = "References",
  Questionnaires= "Questionnaires",
  Signature = "Signature",
  ApplicationFees = "ApplicationFees",
  Thankyou = "Thankyou",
}

// Here is where we can change the sequence of Onboarding steps
export const DefaultOnboardingStepsSequence = [
  //  OnboardingStepsEnums.Welcome,
  OnboardingStepsEnums.BasicDetails,
  OnboardingStepsEnums.CurrentAddress,
  OnboardingStepsEnums.RentIncomeCheck,
  OnboardingStepsEnums.CreditCheck,
  OnboardingStepsEnums.EmploymentHistory,
  OnboardingStepsEnums.References,
  OnboardingStepsEnums.Questionnaires,
  OnboardingStepsEnums.Signature,
  OnboardingStepsEnums.ApplicationFees,
  OnboardingStepsEnums.Thankyou,
];

export const DefaultEmploymentHistory: OnboardingEmploymentHistoryForm = {
  employerName: "",
  startDate: "",
  endDate: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: ""
};
;
export const DefaultReferences: OnboardingReferencesForm = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  referenceType: "Employer",
};

export const DefaultCurrentAddress: OnboardingCurrentAddressForm = {
  unitNumber: "",
  address: {
    streetAddress: "",
    city: "",
    state: "",
    zip: "",
  },
  movingDate: "",
  movingReason: ""
}

export const DefaultQuestionnaire: OnboardingQuestionnaireForm = [];

export const DefaultFeeFormData: OnboardingApplicationFeeForm = {
  feeSubmitOptions: "Card",
  nameOnCard: null,
  accountNumber: null,
  routingNumber: null,
  cardNumber: null,
  billingZipCode: null,
  expiryDate: null,
  cvv: null,
  saveDetails: null,
  applicationFee: null
};
