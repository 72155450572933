import { useState } from 'react'
import RequestBreadcrumb from '../../common/RequestBreadcrumb'
import FeeForm from './FeeForm'
import BasicDetailsCard from '../BasicDetails/common/BasicDetailsCard'

const index = () => {
    const [isEdit, setIsEdit] = useState(false)
    return (
        <div className='basic_details fees_and_commissions_container'>
            <div className="basic_detail_breadcrumb">
                <RequestBreadcrumb
                    firstName={"Dashboard"}
                    link={"/"}
                    currentStep={"Fees and Commissions"}
                />
            </div>
            {!isEdit && <div className="organization_card_container">
                <BasicDetailsCard />
            </div>}
            <div className='d-flex align-items-center justify-content-between px-4'>
                <h3 className='main_heading fw-bold black'>Fees and Commissions</h3>
                {!isEdit && <p className='normal_text blue clickable' onClick={() => setIsEdit(true)}>Edit Details</p>}
            </div>
            <div className='basic_details_container' style={{ height: !isEdit ? "calc(100vh - 230px)" : "" }}>
                <FeeForm isEdit={isEdit} setIsEdit={setIsEdit} />
            </div>
        </div>
    )
}

export default index