import { useEffect, useState } from 'react'
import GenericSearchAbleDropdown from './GenericSearchAbleDropdown';
import StatusTag from './StatusTag';
import DateHelper from '../../utils/DateHelper';
import SeparaterLine from './SeparaterLine';
import { GetImages } from '../../utils/GetImages';
import { useNavigate } from 'react-router';
import DisplayNamewithLeftBorder from './DisplayNamewithLeftBorder';
import EmptyData from './EmptyData';
import { TenantLeaseTypesEnum } from '@propertelligent/client-api';

const LeaseDetails = ({
    allLeaseData,
    options,
    selectedOption,
    handleLeaseChange,
    userData,
    leaseData,
    downloadDocument,
    isUploading,
    isView = false,
    tenantId = undefined
}) => {
    const [selectedManageOption, setSelectedManageOption] = useState("")
    const defaultOptions = [
        { id: "1", name: "Renew Lease" },
        { id: "2", name: "Move Out" },
        { id: "3", name: "Move In" },
    ];
    const [manageOptions, setManageOptions] = useState(defaultOptions);
    const navigate = useNavigate()

    // useEffect(() => {
    //     const status = userData?.currentLeaseInfo?.formatted?.LeaseAgreementStatusTypeId;

    //     if (status === "Pending Tenant Signature") {
    //         setManageOptions([
    //             { id: "1", name: "View Lease" },
    //             { id: "2", name: "Move Out" },
    //         ]);
    //     } else if (status === "Pending Landlord Signature") {
    //         setManageOptions([
    //             { id: "1", name: "Sign Lease" },
    //             { id: "2", name: "Move Out" },
    //         ]);
    //     } else {
    //         setManageOptions(defaultOptions); // Default options for other statuses
    //     }
    // }, [userData]);

    const handleManageChange = (e) => {
        const selectedId = e.target.value;
        setSelectedManageOption(selectedId);
        if (selectedId == 1) {
            navigate(`/tenants/renew-lease/${tenantId}`)
        } else if (selectedId == 2) {
            navigate(`/tenants/move-out/${tenantId}`)
        } else if (selectedId == 3) {
            navigate(`/tenants/move-in/${tenantId}`)
        }
    };

    return (
        <div className="ledgerNotesSection leaseDetailsSection">
            <div className="rentLedgerContainer">
                <div className="d-flex justify-content-between">
                    <div className='d-flex align-items-baseline gap-2'>
                        <DisplayNamewithLeftBorder
                            HeaderName="Lease details"
                        />
                        {userData && userData?.currentLeaseInfo?.formatted?.LeaseAgreementStatusTypeId === "Pending Tenant Signature" ?
                            (
                                <StatusTag
                                    color={"yellow"}
                                    statusText={userData?.currentLeaseInfo?.formatted?.LeaseAgreementStatusTypeId}
                                />
                            ) : userData && userData?.currentLeaseInfo?.formatted?.LeaseAgreementStatusTypeId === "Lease Expiring Soon" ? (
                                <StatusTag
                                    color={"red"}
                                    statusText={userData?.currentLeaseInfo?.formatted?.LeaseAgreementStatusTypeId}
                                />
                            ) : userData && userData?.currentLeaseInfo?.formatted?.LeaseAgreementStatusTypeId === "Pending Landlord Signature" ? (
                                <StatusTag
                                    color={"orange"}
                                    statusText={userData?.currentLeaseInfo?.formatted?.LeaseAgreementStatusTypeId}
                                />
                            ) : null
                        }
                    </div>
                    {!isView &&
                        <div className='d-flex gap-3'>
                            {allLeaseData && allLeaseData?.length > 1 ? (
                                <div style={{ width: "150px" }}>
                                    <GenericSearchAbleDropdown
                                        fieldName={"name"}
                                        initialOptions={options}
                                        name={"selectedOption"}
                                        isFormControl={true}
                                        value={selectedOption}
                                        required={true}
                                        onChange={handleLeaseChange}
                                        placeholder="Select Options"
                                        myClassName="sub_text grey_subtext1"
                                    />
                                </div>
                            ) : null}
                            {leaseData && leaseData.length > 0 &&
                                <div style={{ width: "150px" }}>
                                    <GenericSearchAbleDropdown
                                        fieldName={"name"}
                                        initialOptions={manageOptions}
                                        name={"selectedManageOption"}
                                        isFormControl={true}
                                        value={selectedManageOption}
                                        required={true}
                                        onChange={handleManageChange}
                                        placeholder="Manage"
                                        myClassName="sub_text grey_subtext1"
                                        defaultOption={"Manage"}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
                {leaseData && leaseData.length > 0 ?
                    leaseData && leaseData.map((data, index) => (
                        <div key={index} className="mt-2">
                            <div className={data?.leaseDocumentId ? "leaseDetailDataContainer" : "leaseDataContainer"}>
                                <div>
                                    <p className="sub_text black">
                                        Lease Type
                                    </p>
                                    <span className="normal_text fw-500">
                                        {
                                            Number(data?.dataAttributes?.LeaseType) === TenantLeaseTypesEnum.FixedTermLease
                                                ? "Fixed"
                                                : Number(data?.dataAttributes?.LeaseType) === TenantLeaseTypesEnum.MonthToMonth
                                                    ? "Month to Month"
                                                    : "N/A"
                                        }
                                    </span>
                                </div>
                                <SeparaterLine
                                    height={"48px"}
                                    background={"#e5e7eb"}
                                />
                                <div>
                                    <p className="sub_text">Start Date</p>
                                    <span className="normal_text fw-500">
                                        {DateHelper.convertUTCtoDateTime(data?.dataAttributes?.StartDate)}
                                    </span>
                                </div>
                                <SeparaterLine
                                    height={"48px"}
                                    background={"#e5e7eb"}
                                />
                                <div>
                                    <p className="sub_text">End Date</p>
                                    <span className="normal_text fw-500">
                                        {data?.leaseEnd ? DateHelper.convertUTCtoDateTime(data?.dataAttributes?.EndDate) : "N/A"}
                                    </span>
                                </div>
                                <SeparaterLine
                                    height={"48px"}
                                    background={"#e5e7eb"}
                                />
                                <div>
                                    <p className="sub_text">Security Deposit</p>
                                    <span className="normal_text fw-500">
                                        ${data?.dataAttributes?.SecurityDeposit}
                                    </span>
                                </div>
                                <SeparaterLine
                                    height={"48px"}
                                    background={"#e5e7eb"}
                                />
                                <div>
                                    <p className="sub_text">Rent</p>
                                    <span className="normal_text fw-500">
                                        ${data?.dataAttributes?.Rent}
                                    </span>
                                </div>

                                {data?.leaseDocumentId && (
                                    <div className="d-flex align-items-center gap-3">
                                        <div
                                            onClick={() => {
                                                downloadDocument(
                                                    data?.leaseDocumentId,
                                                    "Lease Agreement"
                                                );
                                            }}
                                            className="newDocumentItem clickable"
                                        >
                                            {isUploading ? (
                                                <div className="d-flex justify-content-center align-items-center w-100">
                                                    <img
                                                        height={40}
                                                        src={GetImages.loadingGif}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="d-flex justify-content-between align-items-center gap-2 p-2">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <img src={GetImages.pdfImage} />
                                                        <p className="sub_text blue">
                                                            Lease Agreement
                                                        </p>
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            downloadDocument(
                                                                data?.leaseDocumentId,
                                                                "Lease Agreement"
                                                            );
                                                        }}
                                                        className="clickable"
                                                    >
                                                        <img
                                                            src={GetImages.blueDownloadIcon}
                                                            style={{ height: "20px" }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))
                    : (
                        <>
                            <EmptyData
                                mainText={"No Data Found"}
                                subText={undefined}
                                button={undefined}
                            />
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default LeaseDetails