import { SVGProps } from "react";
import { GetImages } from "../utils/GetImages";

const Logo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img width="100%" src={GetImages.Logo} />
    </div>
  );
};

export default Logo;
