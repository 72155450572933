import React from 'react';
import CustomInputs from '../../common/Inputs';
import RadioButton from '../../common/RadioButton';
import { GetImages } from '../../../utils/GetImages';
import { useError } from '../../../utils/context/ErrorContext';
import DateHelper from '../../../utils/DateHelper';


const SignatureCard = ({
  clause,
  formData,
  setFormData,
  editable,
  handleChange,
}) => {

  const { errors, setErrors, errorMessages, setErrorMessages } = useError()

  return (
    <div className="signature-card">
      <p className="fst-italic normal_text black">{clause}</p>
      <RadioButton
        label={"I confirm that I have read and reviewed the document."}
        type="checkbox"
        value={formData?.permission}
        checked={formData?.permission}
        onChange={() => { setFormData((prev) => ({ ...prev, permission: !formData?.permission }))}}
        name={undefined}
      />
      {editable && (
        <>
          <div className="authority_form">
            <CustomInputs
              name="fullName"
              type="text"
              value={formData?.fullName}
              labelText={"Name"}
              isFormControl={true}
              onChange={handleChange}
              placeholder={"Name"}
              myClassName={"containerTextField"}
              isError={errors['fullName']}
              errorMessages={"Enter full Nmae"}
            />
            <CustomInputs
              name="signedDateTime"
              type="date" 
              value={DateHelper.convertUTCtoDateTime2(formData?.signedDateTime)}
              labelText={"Date"}
              isFormControl={true}
              onChange={handleChange}             
              placeholder={"Select a date"}
              myClassName={"containerTextField"}
              isError={errors['signedDateTime']}
              errorMessages={"Select Date"}
            />
          </div>
        </>
      )}
      {/* Signature Display */}
      <div className="signature-sign authority_form">
        <div className=" flexColumn">
          <div className="d-flex gap-2">
            <img src={GetImages.signImg} alt="Signature" />
            <p className="signature">
              {formData?.fullName}
            </p>
          </div>
          <div className="signature_border"></div>
        </div>
        <div className="flexColumn">
          <p className="normal_text black py-3">
            {DateHelper.convertUTCtoDateTimeSimple(formData?.signedDateTime)}
          </p>
          <div className="signature_border"></div>
        </div>
      </div>
    </div>
  );
};

export default SignatureCard;
