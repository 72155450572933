import { Modal } from "react-bootstrap";
import { GetImages } from "../../utils/GetImages";
import AddTraspButton from "../firstTimeUserExperience/commonCom/AddTraspButton";
import AddButtonWithArrow from "../firstTimeUserExperience/commonCom/AddButtonWithArrow";

const CommonConfirmation = ({
  onConfirm,
  onClose,
  heading,
  subHeading,
  confirmLabel,
  cancelLabel = "Cancel",
  show,
  Element = undefined,
  buttonCenter = false,
  border = false
}) => {

  return (
    <div className="p-3">
      <Modal
        className="commonConfirmationModal d-flex align-items-center justify-content-center"
        show={show}
        onHide={onClose}
      >
        <div className="confirmation-box">
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="heading black">{heading}</p>
              {!onClose ? "" :
                <img
                  className="clickable"
                  onClick={() => onClose()}
                  src={GetImages.closeIconBlack}
                />
              }
            </div>
            <p style={{ whiteSpace: "wrap" }} className="typeMailPhoneText normal_text mt-2">
              {subHeading}
            </p>
            {Element && <div className="mt-3">{Element}</div>}
          </div>
          <div className={`d-flex ${buttonCenter ? "justify-content-center" : "justify-content-end"} justify-content-end align-items-center gap-3 mt-3`}>
            {!onClose ? "" :
              <AddTraspButton
                type="button"
                bname={cancelLabel}
                onClick={onClose}
                border={border}
                width="48px"
              />
            }
            {onConfirm && (
              <AddButtonWithArrow
                buttonname={confirmLabel}
                onClick={onConfirm}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CommonConfirmation;
