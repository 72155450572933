import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { Col, Container, Form, Row, Image } from "react-bootstrap";
import { updateServiceRequest } from "../../redux/actions/serviceRequestAction";
import { DocumentEntityTypesEnum, DocumentService, DocumentTypesEnum, } from "@propertelligent/client-api";
import { CustomInputTextArea } from "../common/formControls";
import { FORM_CONTROLFIELD_TYPES } from "../../constants/constants";
import apiHelper from "../../utils/apiHelper";
import CustomInputs from "../common/Inputs";
import { GetImages } from "../../utils/GetImages";
import ImageGallery from "../common/ImageGallery";
import AddTraspButton from "../firstTimeUserExperience/commonCom/AddTraspButton";

const InProgressForm = React.memo(
  ({
    id,
    state,
    latestContractor,
    calculateCurrentDate,
    setState,
    handleUpdateServiceRequest,
    showBtn,
    setShowBtn,
    errors,
    errorMessages,
    setErrors,
  }: any) => {
    const dispatch: any = useDispatch();
    const [onHover, setOnHover] = useState(null)
    const dateField = "completeDate";
    const initialDocInfo = {
      EntityTypeId: DocumentEntityTypesEnum.ServiceRequest,
      EntityId: id,
      DocumentTypeId: DocumentTypesEnum.AfterImage,
      IsMain: false,
      FileName: "Service request file",
      Document: null,
      _rawFile: null,
    };

    const [docsArr, setDocsArr] = useState([]);
    const [documentError, setDocumentError] = useState(false);
    const [triedSubmit, setTriedSubmit] = useState(false);
    const [selectedImg, setSelectedImg] = useState<string | null>(null);
    // const [errorMessages, setErrorMessages] = useState({
    //   actualCost: "This field is required",
    //   materialCost: "This field is required",
    //   [dateField]: "This field is required",
    //   document: "This field is required",
    // });
    // const [errors, setErrors] = useState({
    //   actualCost: false,
    //   materialCost: false,
    //   [dateField]: false,
    //   document: false,
    // });

    const handleImageClick = (image: string) => {
      setSelectedImg(image);
    };

    const handleCloseModal = () => {
      setSelectedImg(null);
    };

    console.log("state====", state);


    const getDocuments = () => {
      apiHelper(
        DocumentService.getApiDocumentList,
        { showNotification: false },
        id,
        DocumentEntityTypesEnum.ServiceRequest,
        DocumentTypesEnum.AfterImage
      )
        .then((res) => {
          let documentArray = res?.data?.success;

          if (documentArray) {
            const promises = documentArray.map((item) => {
              const fileNameParts = item.fileName.split(".");
              if (
                fileNameParts.length > 1 &&
                ["png", "jpg", "jpeg"].includes(
                  fileNameParts[fileNameParts.length - 1].toLowerCase()
                )
              ) {
                return DocumentService.getApiDocumentBase641(item.id)
                  .then((res) => {
                    item["Document"] = "data:image/png;base64," + res.success;
                    item["_rawFile"] = "data:image/png;base64," + res.success;
                  })
                  .catch((error) => { });
              }
              return Promise.resolve();
            });
            Promise.all(promises)
              .then(() => {
                setDocsArr(documentArray);
              })
              .catch((error) => { });
          }
        })
        .catch((error) => { });
    };

    const handleChange = (fieldName: string, value: any) => {
      let newValue =
        ["laborCost", "materialCost"].includes(fieldName) && value < 0
          ? 0
          : value;
      setState((prevState: any) => ({ ...prevState, [fieldName]: newValue }));
      // if (triedSubmit) {
      //   setErrors((prevValues) => ({ ...prevValues, [fieldName]: !newValue }));
      // }
    };

    // const handleUpdateServiceRequest = () => {
    //   setTriedSubmit(true);
    //   if (!state?.actualCost) {
    //     setErrors((prevValues) => ({ ...prevValues, actualCost: true }));
    //   }
    //   if (!state?.materialCost) {
    //     setErrors((prevValues) => ({ ...prevValues, materialCost: true }));
    //   }
    //   if (!state?.[dateField]) {
    //     setErrors((prevValues) => ({ ...prevValues, [dateField]: true }));
    //   }
    //   if (!!state?.actualCost && !!state[dateField] && !!state.materialCost) {
    //     dispatch(
    //       updateServiceRequest({
    //         ...state,
    //         [dateField]: `${state[dateField]}T18:59:04.734Z`,
    //         id,
    //       })
    //     );
    //     handleMarkCompleteNShowVerify();
    //     setShowBtn(false);
    //   } else return;
    // };

    const handleUploadServiceRequestDocument = (selectedFile: File | null) => {
      if (selectedFile) {
        setDocumentError(false);
        const newDoc = { ...initialDocInfo };
        const blob = new Blob([selectedFile]);

        Object.assign(newDoc, {
          FileName: selectedFile?.name,
          Document: blob,
          _rawFile: selectedFile,
        });

        const { _rawFile, ...apiDocObj } = newDoc;
        apiHelper(
          DocumentService.postApiDocument,
          { showNotification: false },
          apiDocObj
        )
          .then((docRes) => {
            setDocsArr((prevState: any) => [...prevState, newDoc]);
            getDocuments();
          })
          .catch((docErr) => {
            setDocumentError(true);
          });
      }
    };

    const handleDelete = (e, id) => {
      e.stopPropagation()
      apiHelper(DocumentService.deleteApiDocument, { showNotification: false }, id)
        .then((res) => {
          if (res?.data?.success) {
            getDocuments();
          }
        })
        .catch((error) => { });
    }

    useEffect(() => {
      getDocuments()
    }, [id])

    return (
      <>
        <div className="inProgress-form">
          {showBtn && (
            <div className="newTenantDesciptionContainer">
              <p className="newTenantfixNotesText">FIX DETAILS</p>
            </div>
          )}
          <Row style={{ width: "auto" }}>
            <Col xs={12} md={12} lg={12} sm={12} className="d-flex flex-column" style={{ gap: "20px" }} >
              {showBtn && (
                <>
                  <div className="parentDiv gap-3 w-100" key="div1" id="inProgress-form">
                    <Form.Group style={{ width: "32%" }}>
                      <CustomInputs
                        labelText="Labour Cost"
                        name="laborCost"
                        type={FORM_CONTROLFIELD_TYPES.NUMBER}
                        required
                        value={state?.laborCost}
                        isFormControl
                        onChange={(e) => {
                          handleChange("laborCost", e.target.value)
                          setErrors((prevValues) => ({ ...prevValues, laborCost: false }))
                        }
                        }
                        placeholder="Enter Labour Cost"
                        myClassName="containerTextField w-100"
                        isError={errors.laborCost}
                        errorMessages={errorMessages.laborCost}
                      />
                    </Form.Group>
                    <Form.Group style={{ width: "32%" }}>
                      <CustomInputs
                        labelText="Material Cost (optional)"
                        name="materialCost"
                        type={FORM_CONTROLFIELD_TYPES.NUMBER}
                        isFormControl
                        value={state?.materialCost}
                        onChange={(e) => { handleChange("materialCost", e.target.value) }
                        }
                        placeholder="Enter Material Cost"
                        myClassName="containerTextField w-100"
                        isError={errors.materialCost}
                        errorMessages={errorMessages.materialCost}
                      />
                    </Form.Group>
                    <Form.Group controlId="formDate" style={{ width: "31%" }}>
                      <CustomInputs
                        labelText="Fix Date"
                        name={dateField}
                        type={FORM_CONTROLFIELD_TYPES.DATE}
                        isFormControl
                        value={state[dateField] || null}
                        onChange={(e) => {
                          handleChange(dateField, e.target.value)
                          setErrors((prevValues) => ({ ...prevValues, [dateField]: false }));
                        }
                        }
                        placeholder="Enter Fix Date"
                        myClassName="containerTextField w-100"
                        isError={errors?.[dateField]}
                        errorMessages={errorMessages?.[dateField]}
                        required
                      />
                    </Form.Group>
                  </div>

                  <div>
                    <Form.Label className="normal_text text-start">
                      Fix Note *
                    </Form.Label>
                    <CustomInputTextArea
                      name="description"
                      rows={3}
                      placeholder="Enter Note"
                      required
                      isFormControl
                      value={state?.fixNotes}
                      myClassName="normal_text black"
                      isError={errors?.fixNote}
                      errorMessage={errorMessages?.fixNote}
                      onChange={(e) => {
                        handleChange("fixNotes", e.target.value)
                        setErrors((prevValues) => ({ ...prevValues, fixNote: false }));
                      }
                      }
                    />
                  </div>

                </>
              )}

              {showBtn && (
                <>
                  <div className="nemwTenantDesciptionContainer">
                    <p className="newTenantfixNotesText">
                      UPLOAD WORK IMAGES *
                    </p>
                    <div className="d-flex gap-2">
                      {showBtn && (
                        <>
                          <div
                            className="documentUploadContainer"
                            style={{ marginBottom: "8px" }}
                          >
                            <FileUploader
                              handleChange={handleUploadServiceRequestDocument}
                              classes="documentUploader"
                              children={
                                <>
                                  <div className="fileUploaderTenant">
                                    <img src={GetImages.BlueAdd} />
                                    <p className="sub_text fw-600 blue text-center">
                                      Add Image
                                    </p>
                                  </div>
                                </>
                              }
                            />

                            {documentError && (
                              <p className="errorMessage"> {errorMessages.document} </p>
                            )}
                          </div>
                          <div>
                            <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                              {docsArr.length > 0 &&
                                docsArr.map((doc, index) => (
                                  <div
                                    style={{
                                      width: "80px",
                                      height: "80px",
                                      boxShadow: "0px 1px 3px #aaaaaa",
                                      borderRadius: "8px",
                                      position: "relative",
                                      overflow: "hidden",
                                    }}
                                    key={doc.id}
                                    onMouseEnter={() => setOnHover(doc.id)}
                                    onMouseLeave={() => setOnHover(null)}
                                    onClick={() => handleImageClick(doc?._rawFile)}
                                  >
                                    <img
                                      src={doc?._rawFile}
                                      alt={`Document ${index + 1}`}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "8px",
                                        objectFit: "cover",
                                        filter: onHover === doc.id ? "blur(4px)" : "none",
                                        transition: "filter 0.3s ease",
                                      }}
                                      onClick={() => handleImageClick(doc?._rawFile)}
                                    />
                                    {onHover === doc.id && (
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          width: "100%",
                                          height: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        <AddTraspButton
                                          border={true}
                                          bname={"Delete"}
                                          imageValue={GetImages.deleteIconRed}
                                          onClick={(e) => handleDelete(e, doc.id)}
                                          iconPosition="right"
                                          width="fit-content"
                                          className="rounded-button small_text red"
                                        />
                                      </div>
                                    )}
                                  </div>

                                ))}
                            </div>

                            {selectedImg && (
                              <div
                                style={{
                                  position: "fixed",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(17, 25, 40, 0.9)",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  zIndex: 1000,
                                }}
                                onClick={handleCloseModal}
                              >
                                <div
                                  style={{
                                    position: "relative",
                                    width: "700px",
                                    height: "90vh",
                                    background: "white",
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    gap: "40px",
                                    backgroundColor: "rgba(17, 25, 40, 0)",
                                    justifyContent: "space-between"
                                  }}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <img
                                    src={selectedImg}
                                    alt="Selected"
                                    style={{
                                      maxWidth: "500px",
                                      maxHeight: "375px",
                                    }}
                                  />
                                  <div className="d-flex gap-2 flex-wrap">
                                    {docsArr.length > 0 &&
                                      docsArr.map((doc, index) => (
                                        <div
                                          key={index}
                                          style={{
                                            width: "80px",
                                            height: "80px",
                                            boxShadow: "0px 1px 3px #aaaaaa",
                                            borderRadius: "8px",
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <img
                                            width="100%"
                                            height="126px"
                                            style={{
                                              borderRadius: "8px",
                                              cursor: "pointer",
                                              objectFit: "cover",
                                            }}
                                            src={doc?._rawFile}
                                            alt={`Document ${index + 1}`}
                                            onClick={() => handleImageClick(doc?._rawFile)}
                                          />
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }
);

export default InProgressForm;
