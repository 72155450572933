import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Offcanvas, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { UserLoginData } from "../common/charts/Enums";
import {
  DocumentEntityTypesEnum,
  DocumentService,
  DocumentTypesEnum,
  EntityType,
  NoteService,
  NotesEntityTypesEnum,
  PaymentService,
  PaymentTypesEnum,
  ServiceRequestService,
} from "@propertelligent/client-api";
import { ServiceRequestInfo } from "./serviceRequestInfo";
import { ServiceNotes } from "./serviceNotes";
import InProgressLoader from "./inProgressLoader";
import SingleNoteComponent from "../tenants/SingleNoteComponent";
import InProgressForm from "./inProgressForm";
import { getServiceRequest } from "../../redux/actions/serviceRequestAction";
import PaymentForm from "../common/Payment/PaymentForm";
import AssociateServiceReqWithContractors from "./AssociateServiceReqWithContractors";
import EditServiceRequestHeader from "./EditServiceRequestHeader";
import { CUSTOM_COLORS, URLS } from "../../constants/constants";
import { updateServiceRequest } from "../../redux/actions/serviceRequestAction";
import { GetImages } from "../../utils/GetImages";
import RequestBreadcrumb from "../common/RequestBreadcrumb";
import DateHelper from "../../utils/DateHelper";
import apiHelper from "../../utils/apiHelper";
import CustomInputs from "../common/Inputs";
import AddButtonWithArrow from "../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import { MenuContext } from "../../utils/context/MenuContext";
import { useError } from "../../utils/context/ErrorContext";

const EditServiceRequest = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const serviceReqId = parseInt(id);
  const dispatch: any = useDispatch();
  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
  const [serviceReqInfo, setServiceReqInfo] = useState(null);

  const [contractors, setContractors] = useState([
    {
      id: 1,
      serviceRequestId: serviceReqId,
      contractorId: "",
      estimatedCost: "",
    },
  ]);
  const [contractorDataOptions, setContractorDataOptions] = useState([]);
  const [associatedContractors, setAssociatedContractors] = useState([]);
  const [currentStep, setCurrentStep] = useState("assignKey");
  const [inProgress, setInProgress] = useState(false);
  const [markCompleted, setMarkCompleted] = useState(false);
  const [showVerifyNPay, setShowVerifyNPay] = useState(false);
  const { isOpen } = useContext(MenuContext);
  const [state, setState] = useState({
    laborCost: 0,
    materialCost: 0,
    completeDate: new Date(),
    fixNotes: "",
  });
  const [showModel, setShowModel] = useState(false);
  const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
  const [associateData, setAssociatedData] = useState<any>();
  const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
  const [noteText, setNoteText] = useState("");
  const [noteError, setNoteError] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [latestContractor, setLatestContractor] = useState("--");
  const [docsArr, setDocsArr] = useState([]);
  const [triedSubmit, setTriedSubmit] = useState(false);
  const dateField = "completeDate";
  const [errorMessages, setErrorMessages] = useState({
    laborCost: "Labor Cost is required",
    materialCost: "Material Cost is required",
    [dateField]: "Date is required",
    document: "Document is required",
    fixNote: "Fix Note is required"
  });
  const [errors, setErrors] = useState({
    laborCost: false,
    materialCost: false,
    [dateField]: false,
    document: false,
    fixNote: false,
  });
  const [steps, setSteps] = useState([
    { key: "assignKey", label: "Assign", color: CUSTOM_COLORS.lightGreen },
    {
      key: "progressKey",
      label: "In Progress",
      color: CUSTOM_COLORS.lightGray,
    },
    {
      key: "verifyPayKey",
      label: "Verify & Pay",
      color: CUSTOM_COLORS.lightGray,
    },
  ]);

  const [showBtn, setShowBtn] = useState(true);
  const [reassign, setReassign] = useState(false);

  const { currentRecord } = useSelector((state: any) => state.ServiceRequest);


  const getAllNotes = () => {
    apiHelper(
      NoteService.getApiNoteServicerequestlist,
      { showNotification: false },
      serviceReqId
    )
      .then((servReqNotesRes) => {
        if (servReqNotesRes?.data?.success) {
          setNotesData(servReqNotesRes?.data?.success);
        }
      })
      .catch((err) => { });
  };

  const addNoteHandler = () => {
    if (noteText.trim() == "") {
      setNoteError(true);
    } else {
      const noteObject = {
        noteData: noteText,
        entityId: serviceReqId,
        entityTypeId: NotesEntityTypesEnum.ServiceRequest,
        modifiedBy: localStorage.getItem(UserLoginData.user_name),
      };

      apiHelper(
        NoteService.postApiNote,
        {
          successMessage: "Note posted Successfully",
          failureMessage: "Unable to post note",
          showNotification: true,
        },
        noteObject
      )
        .then((_res) => {
          setNoteText("");
          getAllNotes();
        })
        .catch((err) => { });
    }
  };

  useEffect(() => {
    dispatch(getServiceRequest(serviceReqId));

    getAllNotes();
    getAssociatedContractors();

    apiHelper(
      PaymentService.getApiPaymentMethodOrganizationlist,
      { showNotification: false },
      orgId
    )
      .then((ptRes) => {
        if (ptRes?.data?.success) {
          setPaymentMethodsArr(ptRes?.data?.success);
        }
      })
      .catch((ptErr) => { });

    apiHelper(
      PaymentService.getApiPayeeAccountOrganizationlist,
      { showNotification: false },
      orgId
    )
      .then((ptRes) => {
        if (ptRes?.data?.success) {
          setPayeeAccountsArr(ptRes?.data?.success);
        }
      })
      .catch((ptErr) => { });
  }, []);

  useEffect(() => {
    if (currentRecord) {
      setState(currentRecord);
      if (currentRecord?.formatted?.ServiceType) {
        const serviceType = currentRecord?.formatted?.ServiceType;
        const fullyQualifiedFilters: string | undefined = serviceType
          ? `serviceTypesIds.Any(sti => sti == ${serviceType})`
          : undefined;
        apiHelper(
          ServiceRequestService.getApiServiceRequestAssociatedContractorsLookup,
          { showNotification: false },
          serviceReqId
        )
          .then((response: any) => {
            setContractorDataOptions(response?.data?.success);
          })
          .catch((err) => { });
      }
      if (currentRecord?.formatted?.ServiceRequestStatus == "In Progress") {
        setInProgress(true);
        const newSteps = Object.assign(steps);
        newSteps[0].color = CUSTOM_COLORS.skyDarkBlue;
        setSteps(newSteps);
        newSteps[1].color = CUSTOM_COLORS.lightGreen;
        setSteps(newSteps);
        setCurrentStep("progressKey");
        setLatestContractor(currentRecord?.assignedTo);
        setShowVerifyNPay(false);
        setShowBtn(true);
      } else if (
        currentRecord?.formatted?.ServiceRequestStatus == "Payment Pending"
      ) {
        const newSteps = Object.assign(steps);
        newSteps[0].color = CUSTOM_COLORS.skyDarkBlue;
        setSteps(newSteps);
        newSteps[1].color = CUSTOM_COLORS.skyDarkBlue;
        setSteps(newSteps);
        newSteps[2].color = CUSTOM_COLORS.lightGreen;
        setSteps(newSteps);
        setInProgress(true);
        setShowVerifyNPay(true);
        setMarkCompleted(true);
        setCurrentStep("verifyPayKey");
        setLatestContractor(currentRecord?.assignedTo);
        setShowBtn(false);
      } else if (
        currentRecord?.formatted?.ServiceRequestStatus == "Completed"
      ) {
        const newSteps = Object.assign(steps);
        newSteps[0].color = CUSTOM_COLORS.skyDarkBlue;
        setSteps(newSteps);
        newSteps[1].color = CUSTOM_COLORS.skyDarkBlue;
        setSteps(newSteps);
        newSteps[2].color = CUSTOM_COLORS.lightGreen;
        setSteps(newSteps);
        setInProgress(true);
        setShowVerifyNPay(true);
        setMarkCompleted(true);
        setLatestContractor(currentRecord?.assignedTo);
        setCurrentStep("verifyPayKey");
        setShowBtn(false);
      } else if (currentRecord?.formatted?.ServiceRequestStatus == "Open") {
        setInProgress(false);
        setCurrentStep("assignKey");
        setShowVerifyNPay(false);
      } else if (
        currentRecord?.formatted?.ServiceRequestStatus == "Verification Pending"
      ) {
        const newSteps = Object.assign(steps);
        newSteps[0].color = CUSTOM_COLORS.skyDarkBlue;
        setSteps(newSteps);
        newSteps[1].color = CUSTOM_COLORS.skyDarkBlue;
        setSteps(newSteps);
        newSteps[2].color = CUSTOM_COLORS.lightGreen;
        setSteps(newSteps);
        setInProgress(true);
        setShowVerifyNPay(true);
        setMarkCompleted(true);
        setCurrentStep("verifyPayKey");
        setLatestContractor(currentRecord?.assignedTo);
        setShowBtn(false);
      }
    }
  }, [currentRecord?.id]);

  useEffect(
    () => () => {
      setServiceReqInfo(null);
      setContractors([]);
      setContractorDataOptions([]);
      setPayeeAccountsArr([]);
      setPaymentMethodsArr([]);
      setNotesData([]);
      setSteps([]);
    },
    []
  );

  const getAssociatedContractors = () => {
    apiHelper(ServiceRequestService.getApiServiceRequestAssociatedContractors, { showNotification: false }, serviceReqId)
      .then((asstContRes: any) => {
        if (asstContRes?.data?.success) {
          if (asstContRes?.data?.success?.length > 0) {
            setAssociatedContractors(asstContRes?.data?.success);
          }
        }
      })
      .catch((err) => { });
  };



  const handleAddContractor = (obj) => {
    dispatch(getServiceRequest(id));
    // setAssociatedContractors((prevContractors) => [
    //   ...prevContractors,
    //   {
    //     ...obj,
    //   },
    // ]);
    getAssociatedContractors()
  };

  const handleUpdateContractor = (obj) => {
    setAssociatedContractors((prevContractors) => {
      return prevContractors.map((contractor) => {
        if (contractor.id === obj.contractorId) {
          return { ...contractor, ...obj };
        }
        return contractor;
      });
    });
  };

  const handleRemoveContractor = (associatedContractorObj: any) => {
    apiHelper(ServiceRequestService.deleteApiServiceRequestAssociate,
      {
        successMessage: "Remove Contractor Successfully",
        failureMessage: "Unable to remove contractor",
        showNotification: true
      }, associatedContractorObj?.serviceRequestId, associatedContractorObj?.associateContractorId)
      .then((dltRes: any) => {
        if (dltRes?.data?.success) {
          if (associatedContractors.length > 0) {
            const newContractors = associatedContractors.filter(
              (c) => c.id !== associatedContractorObj?.associateContractorId
            );
            setAssociatedContractors(newContractors);
          }
        }
      })
      .catch((dltErr) => {
        console.error("Error deleting contractor", dltErr);
      });
  };

  const handleContractorChange = (index: number, field: string, value) => {
    setAssociatedContractors((prevContractors) => {
      const updatedContractors = [...prevContractors];
      updatedContractors[index][field] = value;
      return updatedContractors;
    });

    const currentContobj = contractorDataOptions.filter((c) => c.id == value);
    if (Array.isArray(currentContobj) && currentContobj.length > 0) {
      setLatestContractor(
        `${currentContobj[0]?.firstName} ${currentContobj[0]?.lastName}`
      );
    }
  };

  const handleSubmitAssociateContractor = async (index: number) => {
    const { id, ...apiAssociateObj } = associatedContractors[index];
    const shouldSendId = id?.toString()?.includes("local") ? {} : { id };

    return await apiHelper(ServiceRequestService.postApiServiceRequestAssociate, { showNotification: false }, { ...apiAssociateObj, ...shouldSendId, })
  };
  const handleSubmitScheduleFix = async (obj: any, isReassign: boolean) => {
    const successMessage = isReassign
      ? "Contractor Reassigned Successfully"
      : "Contractor ScheduleFix Successfully";
    apiHelper(ServiceRequestService.putApiServiceRequestSchedulefix, { showNotification: false }, obj.serviceRequestId, obj.associateContractorId)
      .then((res: any) => {
        if (res?.data?.success) {
          const currentContobj = contractorDataOptions.filter(
            (c) => c.id == obj.associateContractorId
          );
          if (Array.isArray(currentContobj) && currentContobj.length > 0) {
            setLatestContractor(
              `${currentContobj[0]?.firstName} ${currentContobj[0]?.lastName}`
            );
          }
          const newSteps = Object.assign(steps);
          newSteps[0].color = CUSTOM_COLORS.skyDarkBlue;
          setSteps(newSteps);
          newSteps[1].color = CUSTOM_COLORS.lightGreen;
          setSteps(newSteps);
          setLatestContractor(obj.name);
          setInProgress(true);
          setCurrentStep("progressKey");
          dispatch(getServiceRequest(obj.serviceRequestId));
        }
      })
      .catch((error) => { });
  };

  const handleMarkCompleteNShowVerify = () => {
    const newSteps = Object.assign(steps);
    newSteps[0].color = CUSTOM_COLORS.skyDarkBlue;
    setSteps(newSteps);
    newSteps[1].color = CUSTOM_COLORS.skyDarkBlue;
    setSteps(newSteps);
    newSteps[2].color = CUSTOM_COLORS.lightGreen;
    setSteps(newSteps);
    setShowBtn(false);
    setCurrentStep("verifyPayKey");
    setShowVerifyNPay(true);
    dispatch(getServiceRequest(serviceReqId));
    if (id) {
      getDocuments()
    }
  };

  const handleUpdateServiceRequest = () => {
    console.log("11111");

    setTriedSubmit(true);
    if (!state?.laborCost) {
      setErrors((prevValues) => ({ ...prevValues, laborCost: true }));
    }
    if (!state?.[dateField]) {
      setErrors((prevValues) => ({ ...prevValues, [dateField]: true }));
    }
    if (!state?.fixNotes) {
      setErrors((prevValues) => ({ ...prevValues, fixNote: true }));
    }
    if (!!state?.laborCost && !!state[dateField] && !!state.materialCost) {
      dispatch(
        updateServiceRequest({
          ...state,
          [dateField]: `${state[dateField]}T18:59:04.734Z`,
          id,
        })
      );
      handleMarkCompleteNShowVerify();
      setShowBtn(false);
    } else return;
  };

  const handleSubmitPaymentAccountRecord = ({ values, payvalues }) => {
    const {
      description,
      dueDate,
      amount,
      entityId,
      payeeAccountId,
      gracePeriodInDays,
      paymentDueAccountStatusId,
      isRecordPayment,
      paymentTypeId,
    } = values;
    const { fromPaymentMethodId } = payvalues;

    const updatedValues = {
      amount: amount !== undefined ? amount : Number(state?.laborCost || 0) + Number(state?.materialCost || 0),
      description,
      dueDate: new Date(dueDate).toISOString(),
      entityId,
      entityTypeId: EntityType.ServiceRequest,
      payeeAccountId,
      gracePeriodInDays,
      paymentDueAccountStatusId,
      fromPaymentMethodId,
      isRecordPayment,
      paymentTypeId,
    };

    apiHelper(PaymentService.postApiPaymentDueAccount, { successMessage: "Payment Added Successfully", failureMessage: "Unable to add payment", showNotification: true }, updatedValues)
      .then((res: any) => {
        if (res?.data?.success) {
          setShowModel(false);
          setTimeout(() => {
            navigate(URLS.SERVICE_REQUESTS);
          }, 2000);
        }
      })
      .catch((error) => { });
  };
  const landlordName = localStorage.getItem(UserLoginData.user_name);
  const date = new Date();
  const monthAbbreviation = date.toLocaleString("default", { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();
  const calculateCurrentDate = `${monthAbbreviation} ${day}, ${year}`;

  const handleStepClick = (label: string) => {
    if (label === "Assign") {
      setCurrentStep("assignKey");
      setInProgress(false);
      setShowVerifyNPay(false);
      setReassign(true);
    } else if (label === "In Progress" && latestContractor.trim() !== "--") {
      setCurrentStep("progressKey");
      setInProgress(true);
      setShowVerifyNPay(false);
      setShowBtn(true);
    } else if (label === "Verify & Pay" && latestContractor.trim() !== "--") {
      setCurrentStep("verifyPayKey");
      if (id) {
        getDocuments()
      }
      setInProgress(true);
      setShowVerifyNPay(true);
      setMarkCompleted(true);
      setShowBtn(false);
    }
  };


  const getDocuments = () => {
    apiHelper(
      DocumentService.getApiDocumentList,
      { showNotification: false },
      id,
      DocumentEntityTypesEnum.ServiceRequest,
      DocumentTypesEnum.AfterImage
    )
      .then((res) => {
        let documentArray = res?.data?.success;

        if (documentArray) {
          const promises = documentArray.map((item) => {
            const fileNameParts = item.fileName.split(".");
            if (
              fileNameParts.length > 1 &&
              ["png", "jpg", "jpeg"].includes(
                fileNameParts[fileNameParts.length - 1].toLowerCase()
              )
            ) {
              return DocumentService.getApiDocumentBase641(item.id)
                .then((res) => {
                  item["Document"] = "data:image/png;base64," + res.success;
                  item["_rawFile"] = "data:image/png;base64," + res.success;
                })
                .catch((error) => { });
            }
            return Promise.resolve();
          });
          Promise.all(promises)
            .then(() => {
              setDocsArr(documentArray);
            })
            .catch((error) => { });
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    getDocuments()
  }, [id])

  const verifyAndPay = () => {
    apiHelper(ServiceRequestService.putApiServiceRequestVerify, { successMessage: "Verified Successfully", failureMessage: "Unable to Verify", showNotification: true }, id)
      .then((res) => {
        if (res) {
          setTimeout(() => {
            setShowModel(true)
          }, 1000)
        }

      }).catch((err) => {
        console.log("errr======", err);

      })
  }

  console.log("steps=====", steps);


  return (
    <div id="editServiceRequest" className={` h-100 ${isOpen ? "sidebar-open" : "sidebar-closed"}`}>
      <div
        style={{
          height: "10vh",
        }}
      >
        <RequestBreadcrumb
          firstName={"ServiceRequest"}
          link={"/servicerequest"}
        />
      </div>
      <div
        style={{ height: "calc(100vh - 108px)", overflow: "auto" }}
        className="d-flex flex-column"
      >
        <div
          className="service-request-info-leftside"
        // style={{ borderRight: "1px solid #E5E7EB" }}
        >
          <EditServiceRequestHeader serviceReqInfo={currentRecord} />
          <div className="d-flex gap-3 flex-wrap estimate-stepper normal_text">
            {steps.map((step, index) => (
              <div style={{ minWidth: "max-content" }} key={step.key}>
                <div
                  className="clickable"
                  style={{
                    color:
                      currentStep === step.key ? "#1A56DB" :
                        currentStep > step.key ? "#4B5563" :
                          "#9CA3AF",
                    fontWeight: currentStep === step.key ? "600" :
                      currentStep > step.key ? "400" :
                        "400",
                    display: "flex",
                    gap: "16px",
                  }}
                >
                  <div className="d-flex gap-2 ">
                    <span
                      className="d-flex align-items-center justify-content-center Icon20"
                      style={{
                        borderRadius: "50%",
                        padding: "3px 8px",
                        border:
                          currentStep >= step.key
                            ? currentStep > step.key
                              ? "none"
                              : "1px solid #1A56DB"
                            : "1px solid #9CA3AF",
                        fontSize: "12px",
                      }}
                    >
                      {currentStep > step.key ? (
                        <img
                          src={GetImages.success}
                          alt="Success"
                          className="Icon20"
                        />
                      ) : (
                        index + 1
                      )}
                    </span>
                    <p >
                      {step.label}
                    </p>
                  </div>

                  {index === 0 || index === 1 ? (
                    <span>
                      <img
                        src={GetImages.vector}
                        alt="Vector"
                        style={{
                          filter:
                            currentStep > step.key
                              ? "grayscale(0%)"
                              : currentStep === step.key
                                ? "grayscale(100%)"
                                : "grayscale(100%)",
                        }}
                      />

                    </span>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
          {/* {serviceReqInfo?.unitNumber != "" && (
            <ServiceRequestInfo serviceReqInfo={currentRecord} />
          )} */}
          {/* <ServiceNotes serviceReqInfo={currentRecord?.description} /> */}
          {/* <div className="notesInput d-flex justify-content-between align-items-center">
            <div className="inputCommentContainer m-3">
              <CustomInputs
                value={noteText}
                onChange={(e) => {
                  setNoteText(e.target.value);
                  setNoteError(false);
                }}
                myClassName="messageInput normal_text"
                placeholder="Type your message here...."
                id="customInputBorder"
                width={"100%"}
              />
              <p onClick={addNoteHandler} className="sub_text fw-600 blue clickable ">
                Comment
              </p>
            </div>
          </div>
          {notesData.length > 0 ? (
            <div
              style={{
                height: notesData.length > 0 ? "100%" : "100px",
                maxHeight: notesData.length > 0 && "calc(100vh - 454px)",

                overflowX: "hidden",
                overflowY: "auto",
              }}
              className="messageContainer px-3"
            >
              {notesData.map((item, i) => {
                return (
                  <SingleNoteComponent
                    date={DateHelper.convertUTCtoDateTimeSimple(
                      item?.modified,
                      true
                    )}
                    notesData={item}
                    key={i}
                    name={landlordName}
                  />
                );
              })}
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center flex-column m-5">
              <img src={GetImages.comment} alt="No Contractor" />
              <h3 className="noContractorHeading heading">No comments</h3>
              <p className="normal_text grey_subtext1">
                Add a comment from the text below below
              </p>
            </div>
          )} */}
        </div>
        <div
          className={`${isOpen ? "sidebar-open" : "sidebar-closed"} progress-row`}
          style={{
            maxHeight: "calc(100vh - 158px)",
            height: "100%",
            overflow: "auto",
            // width: "calc(100vw - 260px)"
          }}
        >
          <div style={{ width: "100%" }}>
            <div className="d-flex w-100" id="contractor-detail">
              {!inProgress ? (
                <>
                  <div className="d-flex flex-wrap inprogress-step mw-100">
                    <form className="w-100">
                      <AssociateServiceReqWithContractors
                        setInProgress={setInProgress}
                        setShowBtn={setShowBtn}
                        getAssociatedContractors={getAssociatedContractors}
                        associateData={associateData}
                        associatedContractors={associatedContractors}
                        contractorDataOptions={contractorDataOptions}
                        handleContractorChange={handleContractorChange}
                        handleSubmitScheduleFix={handleSubmitScheduleFix}
                        handleSubmitAssociateContractor={
                          handleSubmitAssociateContractor
                        }
                        handleRemoveContractor={handleRemoveContractor}
                        handleAddContractor={handleAddContractor}
                        setCurrentStep={setCurrentStep}
                        handleUpdateContractor={handleUpdateContractor}
                        reassign={setReassign}
                        latestContractor={latestContractor}
                      />
                    </form>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex flex-wrap inprogress-step">
                    <div className="col-sm-12 col-md-12 col-lg-12 d-flex flex-column gap-3">
                      {inProgress ? (
                        showVerifyNPay ? (
                          <InProgressLoader
                            id={serviceReqId && serviceReqId}
                            calculateCurrentDate={calculateCurrentDate}
                            latestContractor={latestContractor}
                            currentRecord={currentRecord}
                            state={state}
                            setState={setState}
                            handleMarkCompleteNShowVerify={handleMarkCompleteNShowVerify}
                            showBtn={showBtn}
                            setShowBtn={setShowBtn}
                            showVerifyNPay={showVerifyNPay}
                            setMarkCompleted={undefined}
                            steps={steps}
                            setCurrentStep={setCurrentStep}
                            setInProgress={setInProgress}
                            setShowVerifyNPay={setShowVerifyNPay}
                            setReassign={setReassign}
                            docsArr={docsArr}
                            errors={errors}
                            errorMessages={errorMessages}
                            setErrors={setErrors}
                            handleUpdateServiceRequest={handleUpdateServiceRequest}
                          />

                        ) : (
                          <InProgressLoader
                            calculateCurrentDate={calculateCurrentDate}
                            latestContractor={latestContractor}
                            currentRecord={currentRecord}
                            setMarkCompleted={setMarkCompleted}
                            id={serviceReqId && serviceReqId}
                            state={state}
                            setState={setState}
                            showVerifyNPay={false}
                            handleMarkCompleteNShowVerify={
                              handleMarkCompleteNShowVerify
                            }
                            showBtn={showBtn}
                            setShowBtn={setShowBtn}
                            steps={steps}
                            setCurrentStep={setCurrentStep}
                            setInProgress={setInProgress}
                            setShowVerifyNPay={setShowVerifyNPay}
                            setReassign={setReassign}
                            docsArr={[]}
                            handleUpdateServiceRequest={handleUpdateServiceRequest}
                            errors={errors}
                            setErrors={setErrors}
                            errorMessages={errorMessages}
                          />
                        )
                      ) : null}
                    </div>

                  </div>

                  {/* ----------------------------------------Pay and Verify Card --------------------------------------- */}

                  <div className="veridy-Pay-card">
                    {showVerifyNPay ? (
                      <div className="col-sm-12 col-md-12 col-lg-12 ">
                        <div className="rentLedgerContainer m-0">
                          <div
                            className="paymentDetailsBox p-3"
                          >
                            <div>
                              <p className="heading black"
                              >
                                Payment details
                              </p>
                            </div>

                            <div className="d-flex flex-column" style={{ gap: "12px" }}>
                              <div
                                className="d-flex justify-content-between "
                              >
                                <div style={{ flexDirection: "column" }}>
                                  <span className="normal_text black"
                                  >
                                    Labor cost
                                  </span>
                                </div>
                                <div style={{ flexDirection: "column" }}>
                                  <span className="sub_heading black"
                                  >
                                    {`$ ${Number(state?.laborCost || 0)}`}
                                    {/* {`$ ${(
                                      Number(state?.actualCost || 0) -
                                      Number(state?.materialCost || 0)
                                    ).toFixed(2)}`} */}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="d-flex justify-content-between"
                              >
                                <div style={{ flexDirection: "column" }}>
                                  <span className="normal_text black"
                                  >
                                    Material cost
                                  </span>
                                </div>
                                <div style={{ flexDirection: "column" }}>
                                  <span className="sub_heading black"
                                  >
                                    {`$ ${state?.materialCost}` || 0}
                                  </span>
                                </div>
                              </div>
                              <hr className="hr m-0" style={{ borderTop: "2px solid rgba(229, 231, 235, 1)" }} />
                              <div
                                className="d-flex justify-content-between"
                              >
                                <div className="d-flex flex-column">
                                  <span className="normal_text black fw-500"
                                  >
                                    Total Cost
                                  </span>
                                </div>
                                <div className="d-flex flex-column">
                                  <span className="sub_heading black fw-500"
                                  >
                                    {`$ ${(
                                      Number(state?.laborCost || 0) +
                                      Number(state?.materialCost || 0)
                                      // + Number(state?.materialCost || 0)
                                    ).toFixed(2)}`}
                                  </span>
                                </div>
                              </div>
                            </div>

                            {currentRecord?.formatted?.ServiceRequestStatus !==
                              "Completed" && (
                                <div
                                  className="d-flex"
                                >
                                  <div className="d-flex align-items-center gap-4 w-100">
                                    {currentRecord?.formatted
                                      ?.ServiceRequestStatus ===
                                      "Payment Pending" ? (
                                      <AddButtonWithArrow
                                        onClick={() => setShowModel(true)}
                                        buttonname="Pay"
                                        className="w-100"
                                      />
                                    ) : currentRecord?.formatted
                                      ?.ServiceRequestStatus ===
                                      "Verification Pending" && currentRecord?.isInHouse === false ? (
                                      <AddButtonWithArrow
                                        onClick={() => {
                                          verifyAndPay()
                                        }
                                        }
                                        buttonname="Verify & Pay"
                                        className="w-100"
                                      />
                                    ) : currentRecord?.isInHouse === true && (
                                      <>
                                        <AddButtonWithArrow
                                          onClick={() => {
                                            handleUpdateServiceRequest()
                                          }
                                          }
                                          buttonname="Verify & Close"
                                          className="w-100"
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Offcanvas
        className="offcanvas"
        show={showModel}
        onHide={() => setShowModel(false)}
        placement={"end"}
        scroll={true}
      >
        <Offcanvas.Header className="canvasHeader" closeButton>
          <Offcanvas.Title>Add Payment</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <PaymentForm
            showModel={showModel}
            entityId={id && Number(id)}
            payeeAccountsArr={payeeAccountsArr}
            paymentMethodsArr={paymentMethodsArr}
            onClose={() => setShowModel(false)}
            onConfirm={(values: any) =>
              handleSubmitPaymentAccountRecord(values)
            }
            title={"Add Entity Due Payment"}
            selectedPaymentType={PaymentTypesEnum.ServiceRequest}
            amt={
              Number(state?.laborCost || 0) + Number(state?.materialCost || 0)
            }
          // handleUpdateServiceRequest={handleUpdateServiceRequest}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default EditServiceRequest;
