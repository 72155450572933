import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { DocumentEntityTypesEnum, DocumentService, DocumentTypesEnum, PropertyService, PropertyVM } from "@propertelligent/client-api";
import AddressAutocomplete from "./commonCom/AddressAutocomplete";
import Progressbar from "./commonCom/Progressbar";
import FormFooter from "./commonCom/FormFooter";
import { GetImages } from "../../utils/GetImages";
import Uploader from "../common/CustomUploader/Uploader";
import apiHelper from "../../utils/apiHelper";
import { FORM_CONTROLFIELD_TYPES } from "../../constants/constants";
import { validateZipCode } from "../../utils/validateHelper";
import { useError } from "../../utils/context/ErrorContext";
import EditCustomComponent from "../tenants/EditCustomComponent";

const SelectProperty = ({ ptyTypeId, setPtyTypeId, navigateto }) => {
  const [propertyValue, setPropertyvalue] = useState<PropertyVM>();
  const [fileName, setFileName] = useState("");
  const [progress, setProgress] = useState(0);
  const [mainImage, setMainImage] = useState(null);
  const [nextvalue, setNextvalue] = useState(false);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    streetAddress: "",
    city: "",
    state: "",
    zip: ""
  });
  const { errors, errorMessages, setErrors, setErrorMessages, clearErrors } = useError();

  const data = [
    {
      id: 1,
      maintext: "Single Family",
      subText: "Single Family Residential Unit",
      icon: GetImages.SingleFamily,
    },
    {
      id: 2,
      maintext: "Multi Family",
      subText: "Multi Family Residential Units",
      icon: GetImages.MultiFamily,
    },
  ];

  const formDetails = [
    {
      label: "Street Address",
      placeholder: "Enter Street Address",
      value: values.streetAddress,
      name: "streetAddress",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      blurFunction: () => {
        if (values.streetAddress == "") {
          setErrors({ ...errors, streetAddress: true })
        } else {
          setErrors({ ...errors, streetAddress: false })
        }
      },
      errorMessages: errorMessages.streetAddress,
      isError: errors.streetAddress,
      linkWebsite: false,
      width: "289px",
      required: true
    },
    {
      label: "City",
      placeholder: "Enter City",
      value: values.city,
      name: "city",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      blurFunction: () => {
        if (values.city == "") {
          setErrors({ ...errors, city: true })
        } else {
          setErrors({ ...errors, city: false })
        }
      },
      errorMessages: errorMessages.city,
      isError: errors.city,
      linkWebsite: false,
      width: "150px",
      required: true
    },
    {
      label: "State",
      placeholder: "Enter State",
      value: values.state,
      name: "state",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      blurFunction: () => {
        if (values.state == "") {
          setErrors({ ...errors, state: true })
        } else {
          setErrors({ ...errors, state: false })
        }
      },
      errorMessages: errorMessages.state,
      isError: errors.state,
      linkWebsite: false,
      width: "150px",
      required: true
    },
    {
      label: "Zip",
      placeholder: "Enter Zip",
      value: values.zip,
      name: "zip",
      type: FORM_CONTROLFIELD_TYPES.TELEPHONE,
      blurFunction: () => validateZipCode(values.zip, setErrors, setErrorMessages),
      errorMessages: errorMessages.billingZipCode,
      isError: errors.billingZipCode,
      linkWebsite: false,
      width: "150px",
      maxLength: 5,
      required: true
    }
  ]

  const checkEmptyFields = (): boolean => {
    let hasErrors = false;
    clearErrors();

    if (values.streetAddress?.trim() === "" || values.streetAddress?.trim() === undefined) {
      setErrors(({ ...errors, streetAddress: true }));
      hasErrors = true;
    } else {
      setErrors(({ ...errors, streetAddress: false }));
    }

    if (values.state?.trim() === "" || values.state?.trim() === undefined) {
      setErrors((prevErrors) => ({ ...prevErrors, state: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, state: false }));
    }

    if (values.city?.trim() === "" || values.city?.trim() === undefined) {
      setErrors((prevErrors) => ({ ...prevErrors, city: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, city: false }));
    }

    if (values.zip?.trim() === "" || values.zip?.trim() === undefined) {
      setErrors((prevErrors) => ({ ...prevErrors, billingZipCode: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, billingZipCode: false }));
    }

    if (!/^\d{5}$/.test(values.zip?.trim())) {
      setErrors((prevErrors) => ({ ...prevErrors, billingZipCode1: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        billingZipCode1: "Zip code must be 5 digits",
      }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, billingZipCode1: false }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        billingZipCode1: "",
      }));
    }

    return hasErrors;
  };

  const handleDelete = () => {
    setMainImage(null);
    setFileName(null);
    setProgress(0);
  };

  const handleSubmit = async (e: any) => {
    checkEmptyFields();
    if (!values) {
    } else {
      const propertyObject = {
        address: values,
        propertyTypeId: propertyValue.propertyTypeId
      }

      const response = await apiHelper(PropertyService.postApiProperty, { showNotification: false }, propertyObject);
      if (response?.data?.success) {
        if (mainImage) {
          const imgData = {
            EntityTypeId: DocumentEntityTypesEnum.Property,
            EntityId: Number(response?.data?.success?.id),
            DocumentTypeId: DocumentTypesEnum.BeforeImage,
            IsMain: true,
            Document: mainImage,
            FileName: fileName,
          };

          try {
            await apiHelper(DocumentService.postApiDocument,
              {
                successMessage: "Property Added Successfully",
                failureMessage: "Unable to Add Property",
                showNotification: true,
              },
              imgData
            );
            true;
            setTimeout(() => {
              navigate(navigateto, {
                state: { id: response?.data?.success.id },
              });
            }, 3000);
          } catch (imageUploadError) { }
        } else {
          navigate(navigateto, { state: { id: response?.data.success.id } });
        }
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "streetAddress":
        setErrors({ ...errors, streetAddress: false });
        break;
      case "city":
        setErrors({ ...errors, city: false });
        break;
      case "state":
        setErrors({ ...errors, state: false });
        break;
      case "zip":
        setErrors({ ...errors, billingZipCode: false });
        break;
      default:
        break;
    }
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    setPtyTypeId()
    if (checkEmptyFields) {
      clearErrors()
    }
  }, [])

  useEffect(() => {
    if (propertyValue?.address) {
      setValues({
        ...values,
        streetAddress: propertyValue?.address?.streetAddress,
        city: propertyValue?.address?.city,
        state: propertyValue?.address?.state,
        zip: propertyValue?.address?.zip
      })
      setErrors(({ ...errors, streetAddress: false, state: false, city: false, billingZipCode: false }));
    }
  }, [propertyValue])

  return (
    <>
      <div className="AddProperty">
        <div className="progressSection">
          {ptyTypeId && nextvalue ? (
            <Progressbar stepname="Create Property" stepnum="2/3" />
          ) : (
            <Progressbar stepname="Create Property" stepnum="1/3" />
          )}
        </div>
        <div className="formWrapper">
          <p className="main_heading black">
            {ptyTypeId && nextvalue
              ? "Add image and address of property"
              : "Choose the type of the property"}{" "}
          </p>
          {ptyTypeId && nextvalue ? (
            <>
              <div className="addressUploader">
                <div className="uploader clickable">
                  <Uploader
                    name="Add Property Image"
                    setMainImage={setMainImage}
                    mainImage={mainImage}
                    setFileName={setFileName}
                    progress={progress}
                    setProgress={setProgress}
                    Size={{
                      size: "Icon120",
                      iconSize: "Icon32",
                      pSize: 56,
                      uploadimage: "iconstyle18",
                    }}
                  />
                  <div>
                    {mainImage ? (
                      <>
                        <div className="deletereplacemain">
                          <div
                            onClick={() => setMainImage(null)}
                            className="deletereplace"
                          >
                            <img src={GetImages.BlueReplace} className="iconstyle" />
                            <p className="replace">Replace</p>
                          </div>
                          <div>
                            <div className="dividerSmallDiv"></div>
                          </div>
                          <div onClick={handleDelete} className="deletereplace">
                            <img src={GetImages.deleteIconRed} className="iconstyle" />
                            <p className="delete">Delete</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p className="sub_text grey_subtext1">
                        Image format jpeg and png. Max image size 2 MB
                      </p>
                    )}
                    {(errors.ImageType || errors.ImageSize) && (
                      <div style={{ marginTop: "8px" }}>
                        {errors.ImageType && (
                          <p className="errorMessageStyles">{errorMessages.ImageType}</p>
                        )}
                        {errors.ImageSize && (
                          <p className="errorMessageStyles">{errorMessages.ImageSize}</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="addressDetail">
                  <div className="d-flex flex-column gap-2">
                    <AddressAutocomplete
                      propertyValue={propertyValue}
                      setPropertyvalue={setPropertyvalue}
                      showSelectedValue={false}
                    />
                  </div>

                  <div className="office-address-form">
                    <div className="d-flex addContractorInputContainer1">
                      {formDetails?.map((item: any) => (
                        <EditCustomComponent
                          key={item.label}
                          label={item.label}
                          placeholder={item.placeholder}
                          value={item.value}
                          name={item.name}
                          onChangeFunction={handleChange}
                          onBlurFunction={item.blurFunction}
                          errorMessages={item.errorMessages}
                          isError={item.isError}
                          linkWebsite={item.linkWebsite}
                          width={item.width}
                          borderRadius="4px"
                          type={item.type}
                          required={item.required}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="selectPropertyMain">
              {data.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="singleAnswer"
                    style={{
                      border:
                        ptyTypeId === item.id
                          ? "1px solid #1A56DB"
                          : "1px solid  #E5E7EB",
                    }}
                    onClick={() => {
                      setPropertyvalue({ propertyTypeId: item.id });
                      setPtyTypeId(item.id);
                    }}
                  >
                    <div className="answerImage">
                      <img src={item.icon} />
                    </div>
                    <div className="answerText">
                      <p className="normal_text black">{item.maintext}</p>
                      <p className="sub_text  grey_subtext1">{item.subText}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <FormFooter
          handleSubmit={handleSubmit}
          buttonname={
            propertyValue?.propertyTypeId && nextvalue
              ? "Create Property"
              : "Next"
          }
          setNextvalue={setNextvalue}
          nextvalue={nextvalue}
          propertyValue={propertyValue}
          navigateto={undefined}
          showCancelButton={false}
          handleCancel={undefined}
          handleFinish={undefined}
        />
      </div>
    </>
  );
};

export default SelectProperty;
