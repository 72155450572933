import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Form } from "react-bootstrap";
import EditCustomComponent from "../tenants/EditCustomComponent";
import { OrganizationUserRolesEnum, OrganizationUserService } from "@propertelligent/client-api";
import { GetImages } from "../../utils/GetImages";
import apiHelper from "../../utils/apiHelper";
import { useError } from "../../utils/context/ErrorContext";
import { validateEmail, validateFirstName, validateLastName, validateName, validateNumber } from "../../utils/validateHelper";
import AddButtonWithArrow from "../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import AddTraspButton from "../firstTimeUserExperience/commonCom/AddTraspButton";
import RequestBreadcrumb from "../common/RequestBreadcrumb";
import { URLS } from "../../constants/constants";
import RadioButton from "../common/RadioButton";

const Adduser = () => {
  const navigate = useNavigate();
  const [userTypeError, setUserTypeError] = useState(false);
  const [organizationUserTypeId, setOrganizationUserTypeId] = useState(OrganizationUserRolesEnum.Admin);
  const { errors, setErrors, setErrorMessages, errorMessages, clearErrors } = useError();
  const [values, setValues] = useState({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const submitTimeError = () => {
    return (
      errors.title ||
      errors.firstName ||
      errors.lastName ||
      errors.email ||
      errors.phoneNumber ||
      userTypeError ||
      values.title.trim() === "" ||
      values.firstName.trim() === "" ||
      values.lastName.trim() === "" ||
      values.phoneNumber.trim() === "" ||
      values.email.trim() === "" ||
      !organizationUserTypeId
    );
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case "email":
        setErrors({ ...errors, email: false });
        setErrorMessages((prev) => ({ ...prev, email: "" }));
        break;
      case "firstName":
        setErrors({ ...errors, firstName: false });
        setErrorMessages((prev) => ({ ...prev, firstName: "" }));
        break;
      case "lastName":
        setErrors({ ...errors, lastName: false });
        setErrorMessages((prev) => ({ ...prev, lastName: "" }));
        break;
      case "phoneNumber":
        setErrors({ ...errors, phoneNumber: false });
        setErrorMessages((prev) => ({ ...prev, phoneNumber: "" }));
        break;
      case "title":
        setErrors({ ...errors, title: false });
        setErrorMessages((prev) => ({ ...prev, title: "" }));
        break;
    }

    setValues({ ...values, [name]: value });
  };

  const checkEmptyFields = (): boolean => {
    let hasErrors = false;
    clearErrors();
    if (values.email.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, email: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: false }));
    }

    if (values.firstName.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, firstName: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, firstName: false }));
    }

    if (values.lastName.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, lastName: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, lastName: false }));
    }

    if (values.phoneNumber.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: false }));
    }

    if (values.title.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, title: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, title: false }));
    }

    if (!organizationUserTypeId) {
      setUserTypeError(true);
      hasErrors = true;
    }

    return hasErrors;
  };

  useEffect(() => {
    if (checkEmptyFields()) {
      clearErrors();
    }
  }, []);

  const formDetails = [
    {
      label: "Title",
      placeholder: "Title",
      value: values.title,
      name: "title",
      blurFunction: () => {
        if (values.title.trim() == "") {
          setErrors({ ...errors, title: true })
        } else {
          setErrors({ ...errors, title: false })
        }
      },
      errorMessages: errorMessages.title,
      isError: errors.title,
      type: "text",
      required: true
    },
    {
      label: "First Name",
      placeholder: "Enter First Name",
      value: values.firstName,
      name: "firstName",
      blurFunction: () => validateFirstName(values.firstName, setErrors),
      errorMessages: errorMessages.firstName,
      isError: errors.firstName,
      type: "text",
      required: true
    },
    {
      label: "Last Name",
      placeholder: "Enter Last Name",
      value: values.lastName,
      name: "lastName",
      blurFunction: () => validateLastName(values.lastName, setErrors),
      errorMessages: errorMessages.lastName,
      isError: errors.lastName,
      type: "text",
      required: true
    },
    {
      label: "Email Address",
      placeholder: "Enter Email Address",
      value: values.email,
      name: "email",
      blurFunction: () => validateEmail(values.email, setErrors, setErrorMessages),
      errorMessages: errorMessages.email,
      isError: errors.email,
      type: "email",
      required: true
    },
    {
      label: "Phone Number",
      placeholder: "Enter Phone No.",
      value: values.phoneNumber,
      name: "phoneNumber",
      blurFunction: () => validateNumber(values.phoneNumber, setErrors),
      errorMessages: errorMessages.phoneNumber,
      isError: errors.phoneNumber,
      type: "tel",
      required: true,
      maxLength: 10
    },
  ];

  const addUserHandler = (e) => {
    e.preventDefault();
    if (checkEmptyFields() || submitTimeError()) {

    } else {
      const payload = {
        ...values,
        organizationUserTypeId: organizationUserTypeId,
      };

      apiHelper(OrganizationUserService.postApiOrganizationUser,
        {
          successMessage: "User Invited Successfully",
          failureMessage: "Unable to Invite User",
          showNotification: true,
        }, payload).then((res) => {
          if (res?.data?.success) {
            setValues({
              title: "",
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
            });
          }
          setOrganizationUserTypeId(1);
        });
    }
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <Form onSubmit={addUserHandler}>
        <RequestBreadcrumb
          firstName={"Dashboard"}
          link={URLS.HOME}
          lastName={"My Organization"}
          link1={undefined}
          currentStep="User Detail"
        />
        <div
          style={{ marginBottom: "100px" }}
          className="addContractorFormContainer"
        >
          <div className="newTenantFormTopBar d-flex align-items-center">
            <img
              onClick={() => {
                navigate("/organization/manage-users", { state: true });
              }}
              height={28}
              width={28}
              src={GetImages.leftChevronBlack}
              className="clickable"
            />
            <p className="newTenantFormHeading"> Add a user</p>
          </div>
          <div className="newTenantDesciptionContainer mb-3">
            <p className="newTenantDescriptionText sub_text black">Select User Role *</p>
          </div>
          <div>
            <div className="d-flex radioButtonContainer">
              <RadioButton
                label="Organization Admin"
                name="group1"
                id="organizationAdmin"
                value={undefined}
                checked={organizationUserTypeId === 1}
                onChange={() => {
                  setOrganizationUserTypeId(1);
                  setUserTypeError(false);
                }}
              />
              <RadioButton
                label=" Property Manager"
                name="group1"
                id="propertyManager"
                value={undefined}
                checked={organizationUserTypeId === 2}
                onChange={() => {
                  setOrganizationUserTypeId(2);
                  setUserTypeError(false);
                }}
              />
            </div>
            <div>
              {userTypeError && (
                <p className="errorMessage"> Please select user role </p>
              )}
            </div>
          </div>
          <div className="newTenantDesciptionContainer mb-3">
            <p className="newTenantDescriptionText sub_text black">
              Enter basic details of users{" "}
            </p>
          </div>

          <div className="d-flex addContractorInputContainer">
            {formDetails.map((item) => {
              return (
                <EditCustomComponent
                  key={item.label}
                  label={item.label}
                  placeholder={item.placeholder}
                  value={item.value}
                  name={item.name}
                  onChangeFunction={handleChange}
                  onBlurFunction={item.blurFunction}
                  errorMessages={item.errorMessages}
                  isError={item.isError}
                  width="290px"
                  type={item.type}
                  required={item.required}
                  maxLength={item.maxLength}
                />
              );
            })}
          </div>
        </div>
        <div
          style={{ marginTop: "75px", bottom: 0, position: "absolute" }}
          className="newTanentBottomBar">
          <AddTraspButton
            type="button"
            bname="Cancel"
            onClick={() => { navigate("/organization/manage-users") }}
            width="fit-content"
          />
          <AddButtonWithArrow
            type="submit"
            buttonname="Send Invite"
          />
        </div>
      </Form>
    </div>
  );
};

export default Adduser;
