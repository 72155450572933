import React from "react";


const RejectionLetter = ({propertyAddress , ApplicantName}) => {
  return (
    <div className="letter-container">
      <p className="sub_heading fw-400 grey_subtext1">Dear {ApplicantName},</p>

      <p className="sub_heading fw-400 grey_subtext1">
        Thank you for your interest in renting the property at {propertyAddress}. We appreciate the time and effort you put into completing the
        application process.
      </p>

      <p className="sub_heading fw-400 grey_subtext1">
        After careful review, we regret to inform you that your application has
        not been approved at this time.
      </p>

      <p className="heading">Reason for Rejection:</p>
      <ul>
        <li>Credit history does not meet our requirements</li>
        <li>Insufficient income to meet the rental criteria</li>
        <li>Negative reference from a previous landlord</li>
        <li>Incomplete or missing documentation in your application</li>
      </ul>

      <p className="sub_heading fw-400 grey_subtext1">
        Please understand that our decision is based on the criteria established
        for all applicants to ensure a fair and consistent process. If you have
        any questions or believe there is additional information that could help
        in reconsidering your application, please feel free to contact us.
      </p>

      <p className="sub_heading fw-400 grey_subtext1">
        We appreciate your interest in our property, and we wish you the best in
        your search for a new home.
      </p>
    </div>
  );
};

export default RejectionLetter;
