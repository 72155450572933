import React, { useEffect, useState } from 'react'
import OrganizationNameHeader from '../../common/OrganizationNameHeader/OrganizationNameHeader'
import AddButtonWithArrow from '../../firstTimeUserExperience/commonCom/AddButtonWithArrow'
import { LeaseAgreementStatusTypesEnum, LeaseApplicationStatusTypesEnum } from '@propertelligent/client-api'
import { GetImages } from '../../../utils/GetImages'
import { useReactToPrint } from 'react-to-print'
import CustomDropDownMenu from '../../common/CustomDropDownMenu'
import { Offcanvas } from 'react-bootstrap'
import ApprovedScreen from '../approved'
import RejectedScreen from '../rejected'
import SendReminder from '../SendReminder'
import { handleDownloadPdf } from '../../../utils/validateHelper'

const ApplicationDetailsStatusHeader = ({ userData, contentRef, setISPrint, setPreviewLeaseAgreement, isPrint, setInitiateLeaseAgreement, getTenantsData }) => {
    const { currentLeaseInfo, currentLeaseApplicationInfo } = userData || {};
    const [selectOption, setSelectOption] = useState("Approved");
    const [showModel, setShowModel] = useState(false);
    useEffect(() => {
        getTenantsData()
    }, [selectOption, showModel])
    const reactToPrintFn = useReactToPrint({ contentRef });

    const actions = [
        {
            id: 1,
            img: undefined,
            name: "Approve Application",
            status: [LeaseApplicationStatusTypesEnum.InReview, LeaseApplicationStatusTypesEnum.Submitted,],
            onClick: () => { setSelectOption("Approved"); setShowModel(!showModel); },
            linkpath: undefined,
            textClass: 'sub_text fw-600 blue',
        },
        {
            id: 2,
            img: undefined,
            name: "Reject Application",
            status: [LeaseApplicationStatusTypesEnum.InReview, LeaseApplicationStatusTypesEnum.Submitted,],
            onClick: () => { setSelectOption("Rejected"); setShowModel(!showModel); },
            linkpath: undefined,
            textClass: 'sub_text fw-600 blue',
        },
        {
            id: 3,
            img: undefined,
            name: "Close Application",
            status: [
                LeaseApplicationStatusTypesEnum.InReview,
                LeaseApplicationStatusTypesEnum.PaymentPending,
                LeaseApplicationStatusTypesEnum.Invited,
                LeaseApplicationStatusTypesEnum.Submitted,
                LeaseApplicationStatusTypesEnum.InProgress,
            ],
            onClick: () => { setSelectOption("Closed"); setShowModel(!showModel); },
            linkpath: undefined,
            textClass: 'sub_text fw-600 blue',
        },
        {
            id: 4,
            img: undefined,
            name: "Send Reminder",
            status: [LeaseApplicationStatusTypesEnum.Invited,
            LeaseApplicationStatusTypesEnum.PaymentPending,
            LeaseApplicationStatusTypesEnum.InProgress,
            ],
            onClick: () => { setSelectOption("Reminder"); setShowModel(!showModel); },
            linkpath: undefined,
            textClass: 'sub_text fw-600 blue',
        },
        {
            id: 5,
            img: undefined,
            name: "Print",
            status: [
                LeaseApplicationStatusTypesEnum.InProgress,
                LeaseApplicationStatusTypesEnum.PaymentPending,
                LeaseApplicationStatusTypesEnum.Submitted,
                LeaseApplicationStatusTypesEnum.Approved,
                LeaseApplicationStatusTypesEnum.Rejected,
                LeaseApplicationStatusTypesEnum.Closed,
                LeaseApplicationStatusTypesEnum.Withdrawn,
                LeaseApplicationStatusTypesEnum.InReview,
            ],
            onClick: () => {
                setISPrint(true);
                setTimeout(() => { reactToPrintFn(); setISPrint(false) });
            },
            linkpath: undefined,
            textClass: 'sub_text fw-600 blue',
        },
        {
            id: 6,
            img: undefined,
            name: "Save As PDF",
            status: [
                LeaseApplicationStatusTypesEnum.InProgress,
                LeaseApplicationStatusTypesEnum.PaymentPending,
                LeaseApplicationStatusTypesEnum.Submitted,
                LeaseApplicationStatusTypesEnum.Approved,
                LeaseApplicationStatusTypesEnum.Rejected,
                LeaseApplicationStatusTypesEnum.Closed,
                LeaseApplicationStatusTypesEnum.Withdrawn,
                LeaseApplicationStatusTypesEnum.InReview,
            ],
            onClick: () => { handleDownloadPdf(setISPrint, contentRef) },
            linkpath: undefined,
            textClass: 'sub_text fw-600 blue',
        },
    ];
    const filterActions = actions.filter(action => action?.status?.includes(currentLeaseApplicationInfo?.leaseApplicationStatusTypeId))
    const initiateButton = (status) => {
        if (status == null) {
            setInitiateLeaseAgreement(true)
        } else if (status == LeaseAgreementStatusTypesEnum.PendingTenantSignature) {
            setInitiateLeaseAgreement(true)
            setPreviewLeaseAgreement(true)
        }
    }
    const getButtonName = (status) => {
        switch (status) {
            case null:
                return "Initiate Lease Agreement";
            case 1:
                return "View Lease Agreement";
            case 2:
                return "Pending Payment";
            case 3:
                return "Sign Lease Agreement";
            default:
                return "Unknown Status";
        }
    };
    return (
        <>
            <OrganizationNameHeader OrgName={"Application Details"}
                element={
                    <div className='d-flex gap-2'>
                        {(currentLeaseApplicationInfo?.leaseApplicationStatusTypeId == LeaseApplicationStatusTypesEnum.Approved) && (
                            <AddButtonWithArrow
                                className="BackgroundlightBlue border-0"
                                buttonname={currentLeaseInfo == null ? getButtonName(null) : getButtonName(currentLeaseInfo?.leaseAgreementStatusTypeId)}
                                onClick={() => currentLeaseInfo == null ? initiateButton(null) : initiateButton(currentLeaseInfo?.leaseAgreementStatusTypeId)}
                            />
                        )}
                        <div className="actionsDropdownContainer clickable">
                            <CustomDropDownMenu
                                Action={true}
                                Element={<img src={GetImages.BlueChevronDown}
                                alt="BlueChevronDown" />}
                                data={filterActions} />
                        </div>
                    </div>
                }
                headerNameClass="main_heading" OrgImg={GetImages.folder} SubHeaderName=""
                statusClass="blue BackgroundlightBlue p-1 m-1 sub_text fw-700"
                status={undefined}
                statusElement={<div className='statusMain d-flex gap-3 pt-1'>
                <div className='statusMainLeft '>
                 <p className='normal_text fw-bold black'>Lease Application</p>
                 <p className='blue  sub_text fw-700'>{currentLeaseApplicationInfo?.formatted?.LeaseApplicationStatusTypeId} </p>
                </div>
                    {currentLeaseInfo && (<>
                        <div className='dividerMediumDiv' />
                        <div className='statusMainRight'>
                        <p className='normal_text fw-bold black'>Lease Agreement</p>
                        <p className='blue sub_text fw-700'>{currentLeaseInfo?.formatted?.LeaseAgreementStatusTypeId}</p>
                        </div>
                    </>
                    )}
                </div>}
            />

            <Offcanvas
                className="offcanvas serviceRequestForm"
                show={showModel}
                onHide={() => setShowModel(false)}
                placement={"end"}
                scroll={true} >
                <Offcanvas.Header className="canvasHeader" closeButton>
                    <Offcanvas.Title>{selectOption} Application</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {selectOption == "Approved" ? (
                        <ApprovedScreen
                            setInitiateLeaseAgreement={setInitiateLeaseAgreement}
                            leaseApplicationId={currentLeaseApplicationInfo?.id}
                            setShowModel={setShowModel}
                            status={LeaseApplicationStatusTypesEnum.Approved} />
                    ) : selectOption === "Rejected" ? (
                        <RejectedScreen
                            leaseApplicationId={currentLeaseApplicationInfo?.id}
                            setShowModel={setShowModel} />
                    ) : selectOption === "Closed" ? (
                        <ApprovedScreen
                            setInitiateLeaseAgreement={undefined}
                            leaseApplicationId={currentLeaseApplicationInfo?.id}
                            setShowModel={setShowModel}
                            status={LeaseApplicationStatusTypesEnum.Closed} />
                    ) : selectOption === "Reminder" ? (
                        <SendReminder />
                    ) : (<p>Unknown status.</p>)}
                </Offcanvas.Body>
            </Offcanvas>
        </>

    )
}

export default ApplicationDetailsStatusHeader