import { FilterEntityEnum } from '@propertelligent/client-api'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

const PropertiesList = ({ selectedValues, selectedOption }) => {
    const navigate = useNavigate()
    useEffect(() => {
        if (selectedValues.length == 0) {
            navigate(-1)
        }
    }, [])

    return (
        <div className="PropertyList_View_Header">
            <div>
                <div>
                    <p className="sub_heading black text-start mb-3">
                        {selectedOption == FilterEntityEnum.Property ? 'Report generated for Properties:' : 'Report generated for Units:'}

                    </p>

                    <div className='d-flex flex-wrap gap-2'>
                        {selectedValues && selectedValues.map((value, i) => (
                            <p key={i} className='selectedValueLabel normal_text'>{value}</p>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PropertiesList