import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import CapRate from "./CapRate";
import { AnalyticsService, MetricTypesEnum, PropertyMetricTypesEnum } from "@propertelligent/client-api";
import { UserLoginData } from "../components/common/charts/Enums";
import apiHelper from "../utils/apiHelper";

const PerformanceOverview = ({ loadingStates, setLoadingStates }) => {
  const [selectedTab, setSelectedTab] = useState(2);
  const [selectedOption, setSelectedOption] = useState(2);
  const [data, setData] = useState([]);
  const organization_id = Number(
    localStorage.getItem(UserLoginData.organization_id)
  );
  const fetchData = () => {
    let metricType;
    let propertyMetricType;

    if (selectedOption == 2) {
      metricType = MetricTypesEnum.Top5;
    } else if (selectedOption == 3) {
      metricType = MetricTypesEnum.Bottom5;
    }
    if (selectedTab === 2) {
      propertyMetricType = PropertyMetricTypesEnum.Revenue;
    } else if (selectedTab === 3) {
      propertyMetricType = PropertyMetricTypesEnum.Expense;
    }

    apiHelper(AnalyticsService.getApiAnalytics, { showNotification: false }, organization_id, metricType, propertyMetricType)
      .then((res) => {
        setData(res?.data?.success);

        setLoadingStates((loadingStates) => ({
          ...loadingStates,
          performanceOverviewLoading: false,
        }));
      })
      .catch((err) => { });
  };

  useEffect(() => {
    fetchData();
  }, [selectedTab, selectedOption]);

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <div>
      <div className="overviewContainerMain">
        <div className="overviewContainerRightSide pb-0">
          <div
            style={{ borderBottom: "none" }}
            className="overviewContainerRightSideTopComponent pb-0 align-items-center"
          >
            <p className="sub_heading">Performance Overview</p>
            <div className="overviewButtons">
              <Form.Select
                className="sub_text"
                aria-label="Default select Service Sub Type"
                required
                onChange={handleChange}
              >
                <option value="2">Top 5 </option>
                <option value="3">Bottom 5 </option>
              </Form.Select>
            </div>
          </div>
          <div className="overviewContainerRightSideSecondComponent pe-0">
            <div className="singleTenantMultiTabSelect p-0 w-100 justify-content-evenly flex-wrap ">
              <div
                onClick={() => {
                  setSelectedTab(2);
                }}
                style={{ flex: 1 }}
              >
                <p
                  className={
                    selectedTab === 2
                      ? "singleTenantSelectedTab1 normal_text fw-600 blue clickable"
                      : "singleTenantUnselectedTab1 normal_text sub_text1 clickable"
                  }
                >
                  By Revenue{" "}
                </p>
              </div>
              <div
                onClick={() => {
                  setSelectedTab(3);
                }}
                style={{ flex: 1 }}
              >
                <p
                  className={
                    selectedTab === 3
                      ? "singleTenantSelectedTab1 normal_text fw-600 blue clickable"
                      : "singleTenantUnselectedTab1  normal_text sub_text1 clickable"
                  }
                >
                  By Expense
                </p>
              </div>
            </div>
          </div>
          {selectedTab === 1 && <CapRate type="capRate" />}
          {selectedTab === 2 && <CapRate data={data} type="Revenue" />}
          {selectedTab === 3 && <CapRate data={data} type="Expenses" />}
        </div>
      </div>
    </div>
  );
};

export default PerformanceOverview;
