import { Route, Routes } from "react-router";
import EditUnit from "./editunit";
import ContentTopBottom from "../common/listPageLayout/ContentTopBottom";
import TopFixedView from "./listView/topFixedView";
import MainScrollView from "./listView/mainScrollView";
import { useEffect, useState } from "react";
import { infiniteScrollHook } from "../../hooks/infiniteScrollHook";
import { UnitService } from "@propertelligent/client-api";
import "./unit.css";
import "./style.css";
import "./ViewItem/styles.css";

export const Units = () => {
  const [fulllyQualifiedFilter, SetFullyQualifiedFilter] = useState(null);
  const [fulllyQualifiedSort, SetFulllyQualifiedSort] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("all")
  const initialOptions = [
    { name: "All", value: "All" },
    { name: "Vacant", value: "Vacant" },
    { name: "Occupied", value: "Occupied" },
  ]

  const {
    rows,
    loading,
    hasMore,
    rowCount,
    fetchData,
    currentSortDirection,
    handleSort,
    currentPage,
  } = infiniteScrollHook({
    httpFetchDataFn: UnitService.getApiUnitOrganizationlist,
    FullyQualifiedFilters: fulllyQualifiedFilter,
    FullyQualifiedSorts: fulllyQualifiedSort,
  });

  useEffect(() => {
    let filter = "";

    if (selectedOption === "Vacant") {
      filter = `!Tenants.Any()`;
    } else if (selectedOption === "Occupied") {
      filter = `Tenants.Any()`;
    }

    if (searchValue !== "") {
      const searchFilter = `UnitNumber.ToLower().Contains("${searchValue.toLowerCase()}")`;
      filter = filter ? `${filter} && ${searchFilter}` : searchFilter;
    }

    SetFullyQualifiedFilter(filter || null);
  }, [searchValue, selectedOption]);

  useEffect(() => {
    SetFulllyQualifiedSort("UnitNumber " + currentSortDirection);
  }, [currentSortDirection]);

  return (
    <Routes>
      <Route
        index
        element={
          <ContentTopBottom
            FixedTopContent={() => (
              <TopFixedView
                rowCount={rowCount}
                currentSortDirection={currentSortDirection}
                handleSort={handleSort}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                SelectInitialOptions={initialOptions}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                isSelectDropdown={true}
              />
            )}
            FixedBottomContent={() => (
              <MainScrollView
                rows={rows}
                loading={loading}
                hasMore={hasMore}
                fetchData={fetchData}
                currentPage={currentPage}
              />
            )}
            rows={rows}
          />
        }
      />
      <Route path="/edit/:id" element={<EditUnit />} />
    </Routes>
  );
};
