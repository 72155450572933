
import React from "react";
import { useLocation, useNavigate } from "react-router";

const MenuItemCustom = ({ to, primaryText, leftIcon, className, alt = null, isSelected }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const cleanPathname = location.pathname.replace(/^\/+|\/+$/g, "");
  // Define specific organization paths
  const isOrganizationPath = cleanPathname.startsWith("organization/")
  return (
    <div onClick={() => navigate(to)} className={className}>
      <img src={leftIcon} alt={alt} />
      {isOrganizationPath ? (<p className="main_heading black" >
        {primaryText}
      </p>) :
        (<p style={{ color: isSelected ? "#1A56DB" : "#4B5563", fontWeight: isSelected ? "500" : "400" }} >
          {primaryText}
        </p>)}
    </div>

  );
};

export default MenuItemCustom;

