import RequestBreadcrumb from '../../common/RequestBreadcrumb'
import NameAvatarComponent from '../NameAvatarComponent'
import { URLS } from '../../../constants/constants'
import { GetImages } from '../../../utils/GetImages'
import LeaseDetails from '../../common/LeaseDetails'
import MoveOutDetails from './MoveOutDetails'
import FinalPayments from './FinalPayments'
import InstructionsAndInfo from './InstructionsAndInfo'
import { Button } from 'react-bootstrap'
import AddButtonWithArrow from '../../firstTimeUserExperience/commonCom/AddButtonWithArrow'
import { useNavigate, useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { DocumentService, EntityType, MoveTypesEnum, PaidFlowTypesEnum, PayeeAccountTypesEnum, PaymentService, TenantService } from '@propertelligent/client-api'
import apiHelper from '../../../utils/apiHelper'
import { convertStreamAndDownload } from '../../../utils/helper'
import OrganizationNameHeader from '../../common/OrganizationNameHeader/OrganizationNameHeader'
import CustomDropDownMenu from '../../common/CustomDropDownMenu'
import SimpleSpinner from '../../loader'
import { useError } from '../../../utils/context/ErrorContext'
import { UserLoginData } from '../../common/charts/Enums'

const MoveInAndOutContentBlocks = ({ isMoveOut = false }) => {
    const [isUploading, setIsUploading] = useState(false)
    const [pickupInstruction, setPickupInstruction] = useState("")
    const [additionalInfo, setAdditionalInfo] = useState("")
    const [userData, setUserData] = useState<any>([])
    const [loadingStates, setLoadingStates] = useState({ userData: true });
    const [moveOutDate, setMoveOutDate] = useState("");
    const [moveOutReason, setMoveOutReason] = useState("");
    const [formData, setFormData] = useState([
        {
            id: 1,
            paymentType: "",
            amount: "",
            dueDate: "",
            gracePeriods: "",
            description: "",
        }
    ]);
    const navigate = useNavigate()
    const { id } = useParams()
    const { errors, setErrors, errorMessages, setErrorMessages, clearErrors } = useError();
    const orgId = localStorage.getItem(UserLoginData.organization_id)

    const moveOutActions = [
        {
            id: 1,
            img: undefined,
            name: "Send Off Boarding Email",
            onClick: undefined,
            linkpath: undefined,
            textClass: "sub_text fw-600 blue",
        },
        {
            id: 2,
            img: undefined,
            name: "Complete Move Out",
            onClick: undefined,
            linkpath: undefined,
            textClass: "sub_text fw-600 blue",
        },
    ];

    const onboardingActions = [
        {
            id: 1,
            img: undefined,
            name: "Send Welcome Email",
            onClick: undefined,
            linkpath: undefined,
            textClass: "sub_text fw-600 blue",
        },
        {
            id: 2,
            img: undefined,
            name: "Complete Onboarding",
            onClick: undefined,
            linkpath: undefined,
            textClass: "sub_text fw-600 blue",
        },
    ];

    const downloadDocument = (id, fileName) => {
        setIsUploading(true);

        apiHelper(DocumentService.getApiDocumentBase641, { showNotification: false }, id)
            .then((docRes) => {
                //Conversion alogorithm
                convertStreamAndDownload(docRes?.data?.success, fileName);
                setIsUploading(false);
            })
            .catch((docErr) => {
                setIsUploading(false);
            });
    };

    const getTenantsData = async () => {
        const tenantsResponse = await apiHelper(TenantService.getApiTenant1,
            { showNotification: false },
            Number(id)
        );

        if (tenantsResponse?.data?.success) {
            setUserData({
                ...tenantsResponse?.data?.success,
            });
            setLoadingStates((prevLoadingStates) => ({
                ...prevLoadingStates,
                userData: false,
            }));
        }
    };

    useEffect(() => {
        getTenantsData()
    }, [])

    const checkEmptyFields = (): boolean => {
        clearErrors();
        const newErrors = { ...errors };
        const newErrorMessages = { ...errorMessages };
        let hasErrors = false;

        formData.forEach((payment, index) => {
            if (!payment?.dueDate) {
                newErrors[`dueDate-${index}`] = true;
                newErrorMessages[`dueDate-${index}`] = "Due date is required";
                hasErrors = true;
            } else {
                newErrors[`dueDate-${index}`] = false;
            }

            if (!payment.gracePeriods) {
                newErrors[`gracePeriods-${index}`] = true;
                newErrorMessages[`gracePeriods-${index}`] = "Grace period is required";
                hasErrors = true;
            } else {
                newErrors[`gracePeriod-${index}`] = false;
            }

            if (!payment.amount) {
                newErrors[`amount-${index}`] = true;
                newErrorMessages[`amount-${index}`] = "Amount is required";
                hasErrors = true;
            } else {
                newErrors[`amount-${index}`] = false;
            }

            if (!payment.description) {
                newErrors[`description-${index}`] = true;
                newErrorMessages[`description-${index}`] = "Description is required";
                hasErrors = true;
            } else {
                newErrors[`description-${index}`] = false;
            }

            if (!payment.paymentType) {
                newErrors[`paymentType-${index}`] = true;
                newErrorMessages[`paymentType-${index}`] = "Payment Type is required";
                hasErrors = true;
            } else {
                newErrors[`paymentType-${index}`] = false;
            }
        });

        if (isMoveOut) {
            if (moveOutDate.trim() === "") {
                newErrors.moveOutDate = true;
                hasErrors = true;
            } else {
                newErrors.moveOutDate = false;
            }

            if (moveOutReason.trim() === "") {
                newErrors.moveOutReason = true;
                hasErrors = true;
            } else {
                newErrors.moveOutReason = false;
            }
        }

        setErrors(newErrors);
        setErrorMessages(newErrorMessages);
        return hasErrors;
    };


    const submitHandler = async (e) => {
        e.preventDefault();

        if (checkEmptyFields()) {
            return;
        }

        try {
            const response = await apiHelper(PaymentService.getApiPayeeAccountOrganizationlist, { showNotification: false },
                orgId, undefined, undefined, undefined, undefined, undefined, `IsOrganizationAccount==true`
            )

            const paymentPromises = formData.map((_, index) => {
                const updatedValues = {
                    entityTypeId: EntityType.Tenant,
                    paidFlowTypeId: PaidFlowTypesEnum.Receiveable,
                    payeeAccountTypeId: PayeeAccountTypesEnum.Tenant,
                    payeeAccountId: response?.data?.success[0]?.id,
                    paymentTypeId: Number(formData[index].paymentType),
                    description: formData[index].description,
                    amount: Number(formData[index].amount),
                    dueDate: new Date(formData[index].dueDate).toISOString(),
                    gracePeriodInDays: Number(formData[index].gracePeriods),
                };

                return apiHelper(PaymentService.postApiPaymentDueAccount, { showNotification: false }, updatedValues);
            });

            const paymentResults = await Promise.all(paymentPromises);

            const paymentDueAccountIds = paymentResults.filter(res => res?.data?.success).map(res => res.data.success.id);

            if (paymentDueAccountIds.length === formData.length) {
                const tenantMove = {
                    tenantId: id,
                    unitId: userData?.unitId,
                    leaseAgreementId: userData?.currentLeaseInfo?.id,
                    moveTypeId: isMoveOut ? MoveTypesEnum.MoveOut : MoveTypesEnum.MoveIn,
                    keyInstructions: pickupInstruction,
                    additionalInfo: additionalInfo,
                    paymentDueAccountIds,
                    ...(isMoveOut && {
                        moveDate: new Date(moveOutDate).toISOString(),
                        moveReason: moveOutReason,
                    })
                };

                await apiHelper(TenantService.postApiTenantMove,
                    {
                        successMessage: isMoveOut ? "Successfully Move Out" : "Successfully Move In",
                        failureMessage: isMoveOut ? "Unable to move out" : "Unable to move in",
                        showNotification: true
                    }, tenantMove);
            } else {
                console.error("Some payment calls failed");
            }
        } catch (error) {
            console.error("Error in submit handler:", error);
        }
    }

    return (
        <>
            {loadingStates.userData && (
                <div className="loading-container">
                    <SimpleSpinner />
                </div>
            )}

            <div className='move_out_container' id="tenantFormContainer">
                <RequestBreadcrumb
                    firstName={"Tenant"}
                    link={URLS.TENANTS}
                    currentStep={isMoveOut ? "Moveout" : "Onboarding"}
                />
                <div className='moveOutContentSection'>
                    <OrganizationNameHeader
                        SubHeaderName={isMoveOut ? "Move Out Details" : "Move In Details"}
                        OrgName={undefined}
                        OrgImg={GetImages.GrayFile}
                        element={
                            <>
                                <div className="actionsDropdownContainer clickable">
                                    <CustomDropDownMenu
                                        Action={true}
                                        Element={
                                            <img
                                                src={GetImages.BlueChevronDown}
                                                alt="BlueChevronDown"
                                            />
                                        }
                                        data={isMoveOut ? moveOutActions : onboardingActions}
                                    />
                                </div>
                            </>
                        }
                    />
                    <div className='moveOutSectionContainer'>
                        <div className="d-flex newLeftSideBox">
                            <NameAvatarComponent
                                firstName={userData && userData?.firstName}
                                lastName={userData?.lastName}
                                heightWidth={48}
                            />
                            <div>
                                <div className="d-flex align-items-center gap-1">
                                    <p className="sub_heading black mb-1">
                                        {userData && `${userData?.firstName} ${userData?.lastName}`}
                                    </p>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <img height={16} width={16} src={GetImages.homeIconOrange}></img>
                                    <p className="sub_text grey_subtext1">
                                        {userData && userData?.property?.address?.streetAddress}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex gap-3'>
                            <div className="mb-2 d-flex justify-content-start align-items-center gap-2">
                                <img
                                    width={18}
                                    height={18}
                                    src={GetImages.mailIconOrange}
                                />
                                <p className="normal_text black">
                                    {userData && userData?.email}
                                </p>
                            </div>

                            <div className=" d-flex justify-content-start align-items-center gap-2">
                                <img
                                    width={18}
                                    height={18}
                                    src={GetImages.phoneIconOrange}
                                />
                                <p className="normal_text black">
                                    {userData && userData?.phoneNumber}
                                </p>
                            </div>
                        </div>
                    </div>

                    <LeaseDetails
                        allLeaseData={undefined}
                        options={undefined}
                        selectedOption={undefined}
                        handleLeaseChange={undefined}
                        userData={undefined}
                        leaseData={[userData && userData?.currentLeaseInfo]}
                        downloadDocument={downloadDocument}
                        isUploading={isUploading}
                        isView={true}
                    />

                    <form onSubmit={submitHandler}>
                        {isMoveOut && <MoveOutDetails
                            moveOutDate={moveOutDate}
                            setMoveOutDate={setMoveOutDate}
                            moveOutReason={moveOutReason}
                            setMoveOutReason={setMoveOutReason}
                        />}
                        <FinalPayments
                            formData={formData}
                            setFormData={setFormData}
                        />
                        <InstructionsAndInfo
                            pickupInstruction={pickupInstruction}
                            setPickupInstruction={setPickupInstruction}
                            additionalInfo={additionalInfo}
                            setAdditionalInfo={setAdditionalInfo}
                        />

                        <div className="newTanentBottomBar gap-3">
                            <div>
                                <Button onClick={() => navigate(-1)} className="cancelButtonNewTanentBottomBar sub_text fw-600">
                                    Cancel
                                </Button>
                            </div>
                            <div>
                                <AddButtonWithArrow
                                    type="submit"
                                    buttonname="Save"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default MoveInAndOutContentBlocks