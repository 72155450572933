import React, { useCallback, useEffect } from "react";
import "./tenantonboard.css";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";
import { OnboardingForms, } from "../../../typings/onboarding";
import { OnboardingStepsEnums } from "../../../constants/onboarding";
import { DocumentEntityTypesEnum, DocumentTypesEnum, LeaseApplicationEmployerVM, LeaseApplicationRentIncomeVM, RentPayTypesEnum } from "@propertelligent/client-api";
import CustomInputs from "../../common/Inputs";
import RadioButton from "../../common/RadioButton";
import DragAndDropUploader from "../../common/CustomUploader/DragAndDropUploader";
import DisplayNamewithLeftBorder from "../../common/DisplayNamewithLeftBorder";
import DateHelper from "../../../utils/DateHelper";
import { useError } from "../../../utils/context/ErrorContext";


const StepTwo: React.FC<any> = ({
  formData,
  setFormData,
  docsArr,
  setDocsArr,
  leaseApplicationid,
  currentStepIndex,
  RentIncomeCheck
}: {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<OnboardingForms>>;
  docsArr: any;
  setDocsArr: any;
  leaseApplicationid: Number;
  currentStepIndex: any
  RentIncomeCheck: any;
}) => {
  const { errors, setErrors, errorMessages, setErrorMessages } = useError()

  const setdata = useCallback(() => {
    if (!RentIncomeCheck) return;
    setFormData((prevFormData) => ({
      ...prevFormData,
      RentIncomeCheck: prevFormData?.RentIncomeCheck ? prevFormData?.RentIncomeCheck :
        {
          rentPayTypeId: RentPayTypesEnum.Both,
          employerDetails: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            name: "",
            startDate: "",
            endDate: "",
          } as LeaseApplicationEmployerVM,
          assistedDetails: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            name: "",
            startDate: "",
            endDate: "",
          } as LeaseApplicationEmployerVM,
          employmentIncome: 0,
          assistedAmount: 0,
        } as LeaseApplicationRentIncomeVM,
    }));
  }, [currentStepIndex])
  useEffect(() => { setdata() }, [RentIncomeCheck]);

  const handleChange = useCallback((key: string, value: any, subKey?: string) => {
    setFormData((prevData: OnboardingForms) => {
      let updatedRentIncomeCheck = { ...prevData.RentIncomeCheck };
      updatedRentIncomeCheck[key] = subKey
        ? { ...updatedRentIncomeCheck?.[key], [subKey]: value }
        : value;
      const rentPayTypeId = updatedRentIncomeCheck.rentPayTypeId;
      switch (rentPayTypeId) {
        case 1:
          updatedRentIncomeCheck = {
            rentPayTypeId: RentPayTypesEnum.Income,
            employerDetails: updatedRentIncomeCheck?.employerDetails || {
              name: null,
              startDate: null,
              endDate: null,
              firstName: null,
              lastName: null,
              email: null,
              phoneNumber: null
            },
            employmentIncome: updatedRentIncomeCheck?.employmentIncome || null,
          };
          break;
        case 2:
          updatedRentIncomeCheck = {
            rentPayTypeId: RentPayTypesEnum.Assisted,
            assistedDetails: updatedRentIncomeCheck?.assistedDetails || {
              name: null,
              startDate: null,
              endDate: null,
              firstName: null,
              lastName: null,
              email: null,
              phoneNumber: null
            },
            assistedAmount: updatedRentIncomeCheck?.assistedAmount || null,
          };
          break;

        case 3:
          updatedRentIncomeCheck = {
            rentPayTypeId: RentPayTypesEnum.Both,
            employerDetails: updatedRentIncomeCheck?.employerDetails || {
              name: null,
              startDate: null,
              endDate: null,
              firstName: null,
              lastName: null,
              email: null,
              phoneNumber: null
            },
            assistedDetails: updatedRentIncomeCheck?.assistedDetails || {
              name: null,
              startDate: null,
              endDate: null,
              firstName: null,
              lastName: null,
              email: null,
              phoneNumber: null
            },
            employmentIncome: updatedRentIncomeCheck?.employmentIncome || null,
            assistedAmount: updatedRentIncomeCheck?.assistedAmount || null,
          };
          break;

        default:
          break;
      }
      return {
        ...prevData,
        RentIncomeCheck: updatedRentIncomeCheck,
      };
    });

    // Handle error state updates
    const errorKey = subKey ? `${key}.${subKey}` : key;
    setErrors((prevErrors) => ({ ...prevErrors, [errorKey]: false }));
    setErrorMessages((prevMessages) => ({ ...prevMessages, [errorKey]: "" }));
  }, [setFormData, formData?.rentPayTypeId]);

  const handleUploadServiceRequestDocument = async (selectedFile, DocumentTypeId) => {
    if (selectedFile) {
      const newDoc = {
        EntityTypeId: DocumentEntityTypesEnum.LeaseApplication,
        EntityId: leaseApplicationid,
        DocumentTypeId: DocumentTypeId,
        IsMain: false,
        FileName: selectedFile?.name,
        Document: new Blob([selectedFile]),
        _rawFile: selectedFile,
      };
      setDocsArr((prev) => [...prev, newDoc])
    } };

  const handleDelete = (fileNameToDelete) => {
    const updatedDocsArr = docsArr.filter((doc) => doc.FileName !== fileNameToDelete);
    setDocsArr(updatedDocsArr)

  };

  const Radiodata = [
    {
      id: RentPayTypesEnum.Income,
      label: "Income",
      checked: formData?.rentPayTypeId == RentPayTypesEnum.Income,
    },
    {
      id: RentPayTypesEnum.Assisted,
      label: "Assisted",
      checked: formData?.rentPayTypeId == RentPayTypesEnum.Assisted,
    }, {
      id: RentPayTypesEnum.Both,
      label: "Both",
      checked: formData?.rentPayTypeId === RentPayTypesEnum.Both
    }
  ]

  const CurrentEmployee = [
    {
      id: 1,
      name: "name",
      labelText: "Employer Name*",
      placeholder: "Enter Employer Name",
      myClassName: "containerTextField",
      value: formData?.employerDetails?.name,
      errorMessages: errorMessages?.name,
      isError: errors['employerDetails.name'],
      type: FORM_CONTROLFIELD_TYPES.TEXT,
    },
    {
      id: 2,
      name: "employmentIncome",
      value: formData?.employmentIncome,
      labelText: "Gross Monthly Income",
      placeholder: "$ Gross Monthly Income",
      myClassName: undefined,
      isError: errors[`employmentIncome`],
      errorMessages: errorMessages?.amount,
      type: FORM_CONTROLFIELD_TYPES.NUMBER,
    },
    {
      id: 3,
      name: "startDate",
      value: DateHelper.convertUTCtoDateTime2(formData?.employerDetails?.startDate),
      placeholder: "Select a date",
      labelText: "Start Date",
      isError: errors['employerDetails.startDate'],
      errorMessages: errorMessages?.startDate,
      type: FORM_CONTROLFIELD_TYPES.DATE,

    },
    {
      id: 4,
      name: "endDate",
      value: DateHelper.convertUTCtoDateTime2(formData?.employerDetails?.endDate),
      placeholder: "Select a date",
      labelText: "End Date",
      isError: errors['employerDetails.endDate'],
      errorMessages: errorMessages?.endDate,
      type: FORM_CONTROLFIELD_TYPES.DATE,
    }
  ]
  const generateBasicDetails = (sectionName) => [
    {
      id: 1,
      name: "firstName",
      labelText: "First Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.[sectionName]?.firstName,
      placeholder: "Enter first name",
      myClassName: "containerTextField",
      isError: errors?.[`${sectionName}.firstName`],
      errorMessages: errorMessages?.firstName,
    },
    {
      id: 2,
      name: "lastName",
      labelText: "Last Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.[sectionName]?.lastName,
      placeholder: "Enter last name",
      myClassName: "containerTextField",
      isError: errors?.[`${sectionName}.lastName`],
      errorMessages: errorMessages?.lastName,
    },
    {
      id: 3,
      name: "email",
      labelText: "Email",
      type: FORM_CONTROLFIELD_TYPES.EMAIL,
      value: formData?.[sectionName]?.email,
      placeholder: "Enter email",
      myClassName: "containerTextField",
      isError: errors?.[`${sectionName}.email`],
      errorMessages: errorMessages?.email,
    },
    {
      id: 4,
      name: "phoneNumber",
      labelText: "Phone",
      type: FORM_CONTROLFIELD_TYPES.TELEPHONE,
      value: formData?.[sectionName]?.phoneNumber,
      placeholder: "Enter phone no",
      myClassName: "containerTextField",
      isError: errors?.[`${sectionName}.phoneNumber`],
      errorMessages: errorMessages?.phoneNumber,
      maxLength: 10
    },
  ];

  const AuthorityDetail = [
    {
      id: 1,
      name: "name",
      labelText: "Authority Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.assistedDetails?.name,
      placeholder: "Enter last name",
      myClassName: "containerTextField",
      isError: errors['assistedDetails.name'],
      errorMessages: errorMessages?.name,
    },
    {
      id: 2,
      name: "assistedAmount",
      labelText: "Authority Amount",
      type: FORM_CONTROLFIELD_TYPES.NUMBER,
      value: formData?.assistedAmount,
      placeholder: "$ Enter Amount",
      myClassName: "containerTextField",
      isError: errors[`assistedAmount`],
      errorMessages: errorMessages?.amount,
    },
    {
      id: 3,
      name: "startDate",
      value: DateHelper.convertUTCtoDateTime2(formData?.assistedDetails?.startDate),
      placeholder: "Select a date",
      labelText: "Start Date",
      isError: errors['assistedDetails.startDate'],
      errorMessages: errorMessages?.startDate,
      type: FORM_CONTROLFIELD_TYPES.DATE,

    },
    {
      id: 4,
      name: "endDate",
      value: DateHelper.convertUTCtoDateTime2(formData?.assistedDetails?.endDate),
      placeholder: "Select a date",
      labelText: "End Date",
      isError: errors['assistedDetails.startDate'],
      errorMessages: errorMessages?.endDate,
      type: FORM_CONTROLFIELD_TYPES.DATE,
    }
  ]
  return (
    <>
      <div className="onboarding-stepper">
        <div className="flexColumn gap-3">
          <p className="main_heading main_heading py-2">How you will be paying rent?</p>
          <div className="flexRow gap-5 ">
            {Radiodata?.map((item) => (
              <div key={item?.id}>
                <RadioButton
                  id={`${item.id}`}
                  label={item.label}
                  value={formData?.rentPayTypeId || RentPayTypesEnum.Income}
                  name={"rentPayTypeId"}
                  checked={item.checked}
                  onChange={() => handleChange("rentPayTypeId", item.id)}
                />
              </div>
            ))}
          </div>
          {formData?.rentPayTypeId === RentPayTypesEnum.Income ||
            formData?.rentPayTypeId === RentPayTypesEnum.Both ? (
            <div className="onboarding-steppercard">
              <div className="onboardingstepper-form">
                <DisplayNamewithLeftBorder HeaderName={"Current Employer details"} />
                <div className="social-worker py-3">
                  {CurrentEmployee?.map((item) => (
                    <div key={item?.id}>
                      <CustomInputs
                        name={item.name}
                        type={item.type}
                        value={item.value}
                        labelText={item.labelText}
                        isFormControl={true}
                        onChange={(e) => item.name == "employmentIncome" ?
                          handleChange("employmentIncome", e.target.value) :
                          handleChange("employerDetails", e.target.value, item.name)}
                        placeholder={item.placeholder}
                        myClassName={item.myClassName}
                        isError={item.isError}
                        errorMessages={item.errorMessages}
                      />
                    </div>
                  ))}
                </div>
                <div>
                  <DisplayNamewithLeftBorder HeaderName={"CONTACT DETAILS"} />
                  <div className="social-worker py-3">
                    {generateBasicDetails("employerDetails")?.map((item) => (
                      <div key={item?.id}>
                        <CustomInputs
                          name={item.name}
                          type={item.type}
                          value={item.value}
                          labelText={item.labelText}
                          isFormControl={true}
                          onChange={(e) => handleChange("employerDetails", e.target.value, item.name)}
                          placeholder={item.placeholder}
                          myClassName={item.myClassName}
                          isError={item.isError}
                          errorMessages={item.errorMessages}
                          maxLength={item.maxLength}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <DragAndDropUploader handleChange={(filename) => handleUploadServiceRequestDocument(filename, DocumentTypesEnum.IncomeVerification)} docsArr={docsArr.filter((item) => item.DocumentTypeId == DocumentTypesEnum.IncomeVerification)} handleDelete={handleDelete} name="Income Verification Document (W2, 1099, PayStubs etc)" />
              </div>
            </div>
          ) : null}
          {/* Income Verification end here */}

          {/* Assisted Verification start here */}
          {formData?.rentPayTypeId === RentPayTypesEnum.Assisted ||
            formData?.rentPayTypeId === RentPayTypesEnum.Both ? (
            <div className="onboarding-steppercard">
              <div className="onboardingstepper-form">
                <div>
                  <DisplayNamewithLeftBorder HeaderName={"ASSITED DETAILS"} />
                  <div className="social-worker py-3">
                    {AuthorityDetail?.map((item) => (
                      <div key={item?.id}>
                        <CustomInputs
                          name={item.name}
                          type={item.type}
                          value={item.value}
                          labelText={item.labelText}
                          isFormControl={true}
                          onChange={(e) => item.name == "assistedAmount" ?
                            handleChange("assistedAmount", e.target.value) :
                            handleChange("assistedDetails", e.target.value, item.name)}

                          placeholder={item.placeholder}
                          myClassName={item.myClassName}
                          isError={item.isError}
                          errorMessages={item.errorMessages}
                        />
                      </div>
                    ))}
                  </div>
                  <div>
                    <DisplayNamewithLeftBorder HeaderName={"YOUR SOCIAL WORKER DETAILS"} />
                    <div className="social-worker py-3">
                      {generateBasicDetails("assistedDetails")?.map((item) => (
                        <div key={item?.id}>
                          <CustomInputs
                            name={item.name}
                            type={item.type}
                            value={item.value}
                            labelText={item.labelText}
                            isFormControl={true}
                            onChange={(e) => handleChange("assistedDetails", e.target.value, item.name)}
                            placeholder={item.placeholder}
                            myClassName={item.myClassName}
                            isError={item.isError}
                            errorMessages={item.errorMessages}
                            maxLength={item.maxLength}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <DragAndDropUploader handleChange={(filename) => handleUploadServiceRequestDocument(filename, DocumentTypesEnum.AssistanceVerification)} docsArr={docsArr.filter((item) => item.DocumentTypeId == DocumentTypesEnum.AssistanceVerification)} handleDelete={handleDelete} name="Income Verification Document (W2, 1099, PayStubs etc)" />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default StepTwo;

