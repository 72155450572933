import { useEffect, useState } from 'react'
import apiHelper from '../../utils/apiHelper'
import { TenantService } from '@propertelligent/client-api'
import MoveInAndOutContentBlocks from './common/MoveInAndOutContentBlocks'
import { useParams } from 'react-router'
import { Spinner } from 'react-bootstrap'
import SimpleSpinner from '../loader'

const MoveIn = () => {
    return (
        <>
            <MoveInAndOutContentBlocks isMoveOut={false} />
        </>
    )
}

export default MoveIn