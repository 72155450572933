import { DocumentEntityTypesEnum, DocumentService, DocumentTypesEnum } from "@propertelligent/client-api";
import { GetImages } from "../../../utils/GetImages";
import { useEffect, useState } from "react";

export const RentPayInfo = ({ type, data ,leaseApplication}) => {

  const isIncome = type === "Income";
  const [documents, setDocuments] = useState([])
  const getDocuments = (docType) => {
    if (leaseApplication) {
      DocumentService.getApiDocumentList(Number(leaseApplication), DocumentEntityTypesEnum.LeaseApplication,
      docType)
        .then((res) => {
          let documentArray = res?.success;
          if (documentArray) {
            // Create an array of promises for fetching base64 data
            const promises = documentArray.map((item) => {
              if (["png", "jpg", "jpeg"].includes(item?.fileName?.split(".")[1].toLowerCase())) {
                return DocumentService.getApiDocumentBase641(item.id).then((res) => {
                  item["docData"] = "data:image/png;base64," + res.success;
                }).catch((error) => { });
              }
              return Promise.resolve();
            });
            Promise.all(promises)
              .then(() => {
                setDocuments(documentArray);
              }).catch((error) => { });
          }
        }).catch((error) => { });
    }
  };
  useEffect(() => { 
    if(isIncome){
      getDocuments( DocumentTypesEnum.IncomeVerification)
    }else  getDocuments( DocumentTypesEnum.AssistanceVerification)
     }, [leaseApplication])
  
  return (
    <div className="rentpay_information">
      <div className="rentpay_information_income">
        <div className="rentpay_information_income_head">
          <p className="main_heading fw-normal green">{type}</p>
        </div>
        <div className="d-flex justify-content-between">
          <div className="rentpay_information_income_info gap-2">
            <div className="rentpay_information_income_info_card">
              <p className="normal_text black">
                {isIncome ? "Employer Details" : "Authority Name"}
              </p>
            </div>
            <div className="rentpay_information_income_info_card">
              {isIncome ? (
                <>
                  <p className="heading black">{data?.employerDetails?.name}</p>
                  <p className="normal_text black">
                    <i>Income: {data?.employmentIncome}$ /m</i>
                  </p>
                  <p className="normal_text black">
                    Since: {data?.employerDetails?.startDate}
                  </p>
                </>
              ) : (
                <>
                  <p className="heading black">{data?.assistedDetails?.name}</p>
                  <p className="normal_text black">
                    <i>Amount: $ {data?.assistedAmount}/m</i>
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="rentpay_information_income_info gap-2">
            <p className="normal_text black">
              {isIncome ? "Contact Details" : "Social Worker Details"}
            </p>
            <div className="rentpay_information_income_detail_first">
              <p className="sub_heading fw-normal black">
                {`${isIncome ? data?.employerDetails?.firstName : data?.assistedDetails?.firstName} ${isIncome ? data?.employerDetails?.lastName : data?.assistedDetails?.lastName
                  }`}
              </p>
              <p className="normal_text blue">
                {isIncome ? data?.employerDetails?.email : data?.assistedDetails?.email}
              </p>
            </div>
          </div>
          <p className="sub_heading fw-normal black">
            {isIncome ? data?.employerDetails?.phoneNumber : data?.assistedDetails?.phoneNumber}
          </p>
        </div>
        <div>
         {documents?.length > 0 && (
          <div className="FlexRow">
            <p className="normal_text py-2">Documents</p>
         
              <div className="fileDeleteSection">
                {documents
                  .filter((doc) =>
                    isIncome
                      ? doc?.documentTypeId === DocumentTypesEnum.IncomeVerification
                      : doc?.documentTypeId === DocumentTypesEnum.AssistanceVerification
                  ).map((doc, index) => (
                    <div
                      key={index}
                      className="d-flex gap-2 align-items-center "
                    >
                      <img width={20} height={24} src={GetImages.pdfImage} alt="Document" />
                      <p className="licenseDocumentText featureType">{doc?.fileName}</p>
                    </div>
                  ))}
              </div>
             </div>
            )}  
        </div>
      </div>
    </div>
  );
};