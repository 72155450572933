import React, { useEffect, useRef, useState } from "react";
import { renderSteps } from "./RenderSteps";
import Footer from "./Footer";
import { OnboardingForms, } from "../../typings/onboarding";
import { DefaultFeeFormData, DefaultOnboardingStepsSequence, OnboardingStepsEnums, } from "../../constants/onboarding";
import { DocumentService, LeaseApplicationCreditCheckVM, LeaseApplicationMoveOutReasonTypesEnum, LeaseApplicationService, LeaseApplicationStatusTypesEnum, LoginService, OnboardingService, RentPayTypesEnum, TemplateService, TenantService, TenantVM, } from "@propertelligent/client-api";
import { loginResponseHandler } from "../../services/login.service";
import "./tenantOnboard.css"
import CardView from "../units/listView/cardView";
import apiHelper from "../../utils/apiHelper";
import { useNavigate } from "react-router";
import BoardingReview from "./RenderSteps/BoardingReview";
import { useError } from "../../utils/context/ErrorContext";
import { useGeneratePdfContext } from "../../utils/context/GeneratePdfContext";
import { UserLoginData } from "../common/charts/Enums";
import ShowLeaseAgreement from "../tenants/LeaseAgreement/ShowLeaseAgreement";
import ApplicationStatusHeader from "./component/ApplicationStatusHeader";
import FullLoginScreen from "./component/FullLoginScreen";
import { checkEmptyFieldsFunc } from "../../utils/validateHelper";
import Stepper from "../common/Stepper";
import { convertENUMtoJSONArray } from "../../utils/helper";

const TenantOnboard: React.FC<any> = () => {
  const navigate = useNavigate()
  const [leaseApplication, setLeaseApplication] = useState<any>()
  const params = new URLSearchParams(location.search);
  const leaseApplicationToken = params.get("la");
  const tenantData = localStorage.getItem("tenantInfo");
  const tenantObject = JSON.parse(tenantData);
  const [template, setTemplate] = useState<any>()
  const [formData, setFormData] = useState<OnboardingForms>({
    BasicDetails: { firstName: "", lastName: "", email: "", phoneNumber: "", password: "", confirmPassword: "" },
    CurrentAddress: {
      streetAddress: "",
      city: "",
      state: "",
      zip: "",
      unitNumber: "",
      moveOutDate: "",
      moveOutReasonTypeId: LeaseApplicationMoveOutReasonTypesEnum.BetterNeighborhood
    },
    CreditCheck: {
      taxIdNumber: "",
      dateOfBirth: "",
      consentToRunCheck: true
    } as LeaseApplicationCreditCheckVM,
    RentIncomeCheck: { rentPayTypeId: RentPayTypesEnum.Income },
    EmploymentHistory: [],
    References: [],
    Questionnaires: [],
    ApplicationFees: DefaultFeeFormData,
    Signature: {},
  });
  const [steps, setSteps] = useState(DefaultOnboardingStepsSequence);
  const [preStepIndex, setpreStepIndex] = useState(0);
  const [currentStepIndex, setCurrentStepIndex] = useState(tenantData ? 1 : 0);
  // const [currentStepIndex, setCurrentStepIndex] = useState(6);
  const [loading, setLoading] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const [isReLogin, setIsReLogin] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [stepFivePart2, setStepFivePart2] = useState(false);
  const [leaseApplicationid, setLeaseApplicationId] = useState(null);
  const { errors, setErrors, errorMessages, setErrorMessages, clearErrors } = useError();
  const [docsArr, setDocsArr] = useState([]);
  const [OrgDetail, setOrgDetail] = useState<any>()
  const [codeExpired, setCodeExpired] = useState<Boolean>(false)
  const { setOrgDetails, setSubHeaderName } = useGeneratePdfContext();
  const [userData, setUserData] = useState<any>(null);
  const { currentLeaseInfo, currentLeaseApplicationInfo } = userData || {};
  const tenantId = parseInt(localStorage.getItem(UserLoginData.tenant_id));
  const [signLeaseAgreement, setSignLeaseAgreement] = useState(false)
  const [isPrint, setISPrint] = useState(false)
  const contentRef = useRef()
  const [postLeaseAgreement, setPostLeaseAgreement] = useState({ tenantId: tenantId, unitId: currentLeaseApplicationInfo?.unit?.id, templateId: currentLeaseInfo?.templateId, dataAttributes: {}, })

  const getTenantsData = async () => {
    if (tenantId) {
      const tenantsResponse = await apiHelper(TenantService.getApiTenant1, { showNotification: false }, Number(tenantId));
      setUserData(tenantsResponse?.data?.success)
    }
  };

  useEffect(() => { getTenantsData() }, [tenantId])

  const fetchData = () => {
    if (tenantData) {
      const { leaseApplicationId } = tenantObject;
      if (leaseApplicationId) {
        setLeaseApplicationId(leaseApplicationId);
        apiHelper(LeaseApplicationService.getApiLeaseApplicationOrganizationHeaderInfo, { showNotification: false }, leaseApplicationId).then((res) => {
          setOrgDetail(res?.data?.success)
          setOrgDetails(res?.data?.success)
          setSubHeaderName("Tenant Onboarding")
        }).catch((error) => { })
        apiHelper(LeaseApplicationService.getApiLeaseApplication1, { showNotification: false }, leaseApplicationId).then((response: any) => {
          setLeaseApplication(response?.data?.success)
          const getValue = response?.data?.success?.dataSections;
          setFormData({
            ...formData,
            BasicDetails: { firstName: response?.data?.success?.tenant?.firstName, lastName: response?.data?.success?.tenant?.lastName, email: response?.data?.success?.tenant?.email, phoneNumber: response?.data?.success?.tenant?.phoneNumber, password: response?.data?.success?.tenant?.password, confirmPassword: response?.data?.success?.tenant?.password },
            CreditCheck: JSON.parse(getValue?.CreditCheck ? getValue?.CreditCheck : null),
            CurrentAddress: getValue?.CurrentAddress ? JSON.parse(getValue?.CurrentAddress) : {},
            EmploymentHistory: getValue?.EmploymentHistory ? JSON.parse(getValue?.EmploymentHistory) : null,
            Questionnaires: JSON.parse(getValue?.Questionnaires ? getValue?.Questionnaires : null),
            References: JSON.parse(getValue?.References ? getValue?.References : null),
            RentIncomeCheck: getValue?.RentIncomeCheck ? JSON.parse(getValue?.RentIncomeCheck) : null,
            Signature: JSON.parse(getValue?.Signature ? getValue?.Signature : null),
            ApplicationFees: JSON.parse(getValue?.ApplicationFees ? getValue?.ApplicationFees : null),
          });
          apiHelper(TemplateService.getApiTemplate, { showNotification: false }, response?.data?.success?.templateId)
            .then((templete) => {
              setTemplate(templete?.data?.success)
              if (!templete?.data?.success?.leaseApplicationTemplateSections) {
                setSteps(DefaultOnboardingStepsSequence.filter((item) => item !== OnboardingStepsEnums.BasicDetails));
              } else {
                const stepsReceived = Array.from(new Set([
                  OnboardingStepsEnums.BasicDetails,
                  ...Object.keys(templete?.data?.success?.leaseApplicationTemplateSections),
                  OnboardingStepsEnums.Signature,
                  OnboardingStepsEnums.Thankyou,
                ]));
                setSteps(steps.filter((itm) => stepsReceived.includes(itm)));
                setIsReLogin(true);
              }
            }).catch((error) => console.log("error", JSON.stringify(error)))
        }).catch((tenantErr: any) => { console.log("tenantErr", JSON.stringify(tenantErr)) });
      }
    } else {
      if (leaseApplicationToken) {
        apiHelper(OnboardingService.getApiOnboardingTenantOrganizationHeaderInfo, { showNotification: false },
          leaseApplicationToken).then((res) => {
            setOrgDetail(res?.data?.success)
            if (res?.data?.success == null) { setCodeExpired(true) }
          }).catch((error) => { console.log("error?", JSON.stringify(error)) })
      }
    }
  }

  useEffect(() => {
    if (tenantData && !leaseApplicationToken) { setCodeExpired(false) }
    fetchData()
  }, [leaseApplicationid, currentStepIndex, tenantData]);

  const goNextStep = () => {
    if (isEdit) {
      setCurrentStepIndex(preStepIndex);
      setIsEdit(false)
      return;
    } else { setCurrentStepIndex((step) => step + 1); }
  };
  const goPrevStep = () => {
    if (isReLogin && currentStepIndex <= 1) { return; }
    else { setCurrentStepIndex((step) => step - 1); }
  };
  const isEditClick = (index) => {
    setIsEdit(true); setCurrentStepIndex(index);
  };
  const uploadImage = (doc) => {
    const docData = {
      EntityTypeId: doc.EntityTypeId,
      EntityId: doc.EntityId,
      DocumentTypeId: doc.DocumentTypeId,
      IsMain: doc.IsMain,
      FileName: doc.FileName,
      Document: doc.Document,
    };
    return DocumentService.postApiDocument(docData).then((_res) => { }).catch((error) => { console.error("Error uploading document:", JSON.stringify(error)); });
  };
  const uploadAllImages = async (docArray) => { for (let i = 0; i < docArray.length; i++) { await uploadImage(docArray[i]); } };
  const goToApplicationView = () => { setCurrentStepIndex(null); setShowApp(true) };

  const handleSubmit = async () => {
    const currentStep = steps[currentStepIndex];
    const stepsWithDocumentUpload: OnboardingStepsEnums[] = [OnboardingStepsEnums.BasicDetails, OnboardingStepsEnums.RentIncomeCheck,];
    let successMessage = isEdit ? `${currentStep} Update Successfully` : `${currentStep} Added Successfully`
    try {
      setLoading(true);
      let cantGoNext = false;
      let data: any = formData[currentStep] || {}
      if (currentStep == OnboardingStepsEnums.BasicDetails) {
        if (isEdit && tenantData) {
          const userId = Number(localStorage.getItem(UserLoginData.user_id));
          const { tenantId } = tenantObject;
          const editData = {
            id: tenantId,
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            phoneNumber: data?.phoneNumber,
            userId: userId
          } as TenantVM
          if (checkEmptyFieldsFunc(editData, clearErrors, errors, errorMessages, setErrors, setErrorMessages, steps[currentStepIndex])) {
            return
          } else {
            await apiHelper(TenantService.putApiTenant, { showNotification: true, successMessage: successMessage }, editData).then((res) => {
              cantGoNext = true
              fetchData()
              goNextStep();
            }).catch((error) => console.log("error", error))
          }
        } else {
          if (checkEmptyFieldsFunc(data, clearErrors, errors, errorMessages, setErrors, setErrorMessages, steps[currentStepIndex])) {
            return
          } else {

          await apiHelper(LoginService.postApiLoginAcceptLeaseApplicationInvite, {
            showNotification: true, successMessage: `${currentStep} Added succussefully`,
            failureMessage: `${currentStep} Unable to Add`
          }, leaseApplicationToken, data as any)
            .then(async (res) => {
              if (res?.data?.success) {
                loginResponseHandler(res);
                setLeaseApplicationId(res?.data?.success.tenantInfo?.leaseApplicationId);;
                fetchData()
                goNextStep();
              }
              return res;
            }).catch((err) => { err.body; })
          }
        }
      } else {
        const apiFunctionMapping = {
          BasicDetails: LoginService.postApiLoginAcceptLeaseApplicationInvite,
          CurrentAddress: LeaseApplicationService.putApiLeaseApplicationCurrentAddress,
          RentIncomeCheck: LeaseApplicationService.putApiLeaseApplicationRentIncome,
          CreditCheck: LeaseApplicationService.putApiLeaseApplicationCreditCheck,
          EmploymentHistory: LeaseApplicationService.putApiLeaseApplicationEmpploymentHistory,
          References: LeaseApplicationService.putApiLeaseApplicationReferences,
          Questionnaires: LeaseApplicationService.putApiLeaseApplicationQuestionaires,
          Signature: LeaseApplicationService.putApiLeaseApplicationTenantSignature,
        };
        const apiFunction = apiFunctionMapping[currentStep];
        if (checkEmptyFieldsFunc(data, clearErrors, errors, errorMessages, setErrors, setErrorMessages, steps[currentStepIndex])) {
          return
        } else {
          await apiHelper(apiFunction, {
            showNotification: true, successMessage: successMessage, failureMessage: `${currentStep} Unable to Add`
          }, Number(leaseApplicationid), data).then((res) => {
            goNextStep();
            return true
          }).catch((err) => false);
        }
        if (stepsWithDocumentUpload.includes(currentStep as OnboardingStepsEnums)) { await uploadAllImages(docsArr); }
      }
      setLoading(false);
      //  cantGoNext &&
      // goNextStep();
    } catch (error: any) { setLoading(false) }
  };

  useEffect(() => {
    const result = convertENUMtoJSONArray(LeaseApplicationStatusTypesEnum)
    const relevantStatuses = [
      LeaseApplicationStatusTypesEnum.Submitted,
      LeaseApplicationStatusTypesEnum.Approved,
      LeaseApplicationStatusTypesEnum.InReview,
      LeaseApplicationStatusTypesEnum.Rejected,
      LeaseApplicationStatusTypesEnum.Closed,
      LeaseApplicationStatusTypesEnum.Withdrawn,
      LeaseApplicationStatusTypesEnum.PaymentPending,
    ];
    if (isReLogin && relevantStatuses.includes(leaseApplication?.leaseApplicationStatusTypeId)) { goToApplicationView() }
  }, [isReLogin, leaseApplication?.leaseApplicationStatusTypeId]);

  return (
    <>
      {codeExpired && !tenantData ? <FullLoginScreen /> :
        <>
          <div className="outer-container" id="FinalStep">
            {signLeaseAgreement ?
              <ShowLeaseAgreement
                role="tenant"
                userData={userData}
                postLeaseAgreement={postLeaseAgreement}
                setPostLeaseAgreement={setPostLeaseAgreement}
                onCancel={() => setSignLeaseAgreement(false)}
                onClick={undefined}
                buttonName={"Sign and Sent"}
                selectedTemplateid={undefined}
                selectedTemplates={undefined}
              /> : <>
                <div className="onboarding-header">
                  {steps[currentStepIndex] !== OnboardingStepsEnums.Thankyou && (<>
                    <ApplicationStatusHeader
                      OrgDetail={OrgDetail}
                      leaseApplication={leaseApplication}
                      userData={userData}
                      isPrint={isPrint}
                      showApp={showApp}
                      setSignLeaseAgreement={setSignLeaseAgreement}
                      signLeaseAgreement={signLeaseAgreement}
                      contentRef={contentRef} setISPrint={setISPrint}
                    />
                    <CardView
                      item={leaseApplication?.unit}
                      showTarget={false}
                      isRedirect={false}
                    />
                    {!showApp && (
                      <Stepper
                        currentStepIndex={currentStepIndex}
                        steps={steps}
                        totalSteps={steps?.length - 1}
                      />)}
                  </>)}
                </div>
                <>
                  {renderSteps({
                    currentStepIndex,
                    setpreStepIndex,
                    errors,
                    formData,
                    setFormData,
                    steps,
                    isEditClick,
                    isEdit,
                    docsArr,
                    setDocsArr,
                    leaseApplicationid,
                    goToApplicationView,
                    leaseApplicationTemplateSections: template?.leaseApplicationTemplateSections
                  })}
                  {steps[currentStepIndex] !== OnboardingStepsEnums.Thankyou && !showApp && (
                    <Footer
                      goPrevStep={goPrevStep}
                      currentStepIndex={currentStepIndex + 1}
                      stepFivePart2={stepFivePart2}
                      steps={steps} stepsCount={steps.length - 1}
                      handleSubmit={handleSubmit}
                    />)}
                </>
                {showApp && (<section className="onboarding-review" ref={contentRef}>
                  {isPrint && (
                    <>
                      <ApplicationStatusHeader
                        OrgDetail={OrgDetail}
                        leaseApplication={leaseApplication}
                        userData={userData}
                        isPrint={isPrint}
                        setSignLeaseAgreement={setSignLeaseAgreement}
                        signLeaseAgreement={signLeaseAgreement}
                        showApp={showApp}
                        contentRef={contentRef}
                        setISPrint={setISPrint}
                      />
                      <CardView
                        item={leaseApplication?.unit}
                        showTarget={false}
                        isRedirect={false}
                      />
                    </>
                  )}
                  <BoardingReview
                    editable={false}
                    leaseApplication={leaseApplicationid}
                    isEditClick={isEditClick}
                    postData={undefined}
                    setPostData={undefined}
                    formData={formData?.Signature}
                    setFormData={undefined}
                    steps={undefined}
                  />
                </section>)}
              </>
            }
          </div>
        </>
      }
    </>
  );
};
export default TenantOnboard;