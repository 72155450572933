import { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import AddTraspButton from "../../../firstTimeUserExperience/commonCom/AddTraspButton";
import AddButtonWithArrow from "../../../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import RadioButton from "../../../common/RadioButton";
import { GetImages } from "../../../../utils/GetImages";
import { CommunicationService, IntegrationsService } from "@propertelligent/client-api";
import { currentUrl } from "../../../../utils/helper";

const AddGmailAccount = ({ border }) => {
  const [show, setShow] = useState(false);
  const [clientId, setClientId] = useState("");
  const [refreshtoken, setRefreshToken] = useState<Boolean>()

  const handleClose = () => setShow(false);

  useEffect(() => {
    IntegrationsService.postApiIntegrationsKeysGmailClientId()
      .then((res) => {
        setClientId(res?.success)
      })
      .catch((error) => {
        console.error(error)
      })

    CommunicationService.getApiGmailHasRefreshToken()
      .then((res) => {
        setRefreshToken(res?.success)
      }).catch((error) => {
        console.error(error)
      })

  }, [refreshtoken, clientId,])


  function oauthSignIn() {
    var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
    var form = document.createElement('form');
    form.setAttribute('method', 'GET');
    form.setAttribute('action', oauth2Endpoint);

    var scopes = "https://mail.google.com/ https://www.googleapis.com/auth/gmail.addons.current.message.action https://www.googleapis.com/auth/gmail.addons.current.message.readonly https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/userinfo.profile";
    var params = {
      'client_id': `${clientId}`,
      redirect_uri: `${currentUrl}/callback/gmail`,
      'response_type': 'code',
      'scope': scopes,
      'include_granted_scopes': 'false',
      'state': 'pass-through value',
      'access_type': 'offline',
      'prompt': 'consent'
    };


    for (var p in params) {
      var input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', p);
      input.setAttribute('value', params[p]);
      form.appendChild(input);
    }
    document.body.appendChild(form);
    form.submit();

  }

  return (
    <>
      <div
        className={`composeContainer m-4 align-items-center justify-content-center ${!border && "border-0"}`}
        style={{ overflowX: "hidden", overflowY: "auto" }}
      >
        <div className="d-flex flex-column align-items-center justify-content-center gap-5">
          <p className="main_heading black">
            Configure your email account for sending & receiving emails{" "}
          </p>
          <img
            src={GetImages.AddEmail}
            alt="AddGmailImage"
            className="styleicon"
          />
          <div className="d-flex flex-column gap-3  ">
            <button
              type="submit"
              className="emailConnectButton"
              onClick={() => oauthSignIn()}
            >
              <img
                src={GetImages.Google}
                alt="GoogleIcon"
                className="styleicon"
              />
              <p className="normal_text fw-600 text-start black ">Connect with gmail</p>
            </button>
          </div>
        </div>

        <Modal dialogClassName="w-50 h-100 p-4" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="main-heading">Add mail Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <div className="paymentRadioButton gap-2">
                  <RadioButton
                    label="SMTP"
                    value={2}
                    name={undefined}
                    checked={2}
                    onChange={(e) => { }}
                  />
                  <RadioButton
                    label="POP3"
                    value={2}
                    name={undefined}
                    checked={1}
                    onChange={(e) => { }}
                  />
                </div>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label className="sub_text  grey_subtext1">
                      SMTP Server
                    </Form.Label>
                    <Form.Control
                      required
                      className="sub_text  grey_subtext1"
                      value={""}
                      onChange={(e) => { }}
                      type="text"
                      placeholder="Example:mail.test.com"
                    />
                    <Form.Control.Feedback type="invalid" className="invalid-feedback">
                      SMTP Server
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label className="sub_text  grey_subtext1">
                      Please Port
                    </Form.Label>
                    <Form.Select
                      className="sub_text  grey_subtext1"
                      aria-label="Default select Payee Account"
                      required
                      value={235}
                      onChange={(e) => { }}
                    >
                      <option value="">Select Port </option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid" className="invalid-feedback">
                      Please select a Port.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="sub_text  grey_subtext1">UserName</Form.Label>
                <Form.Control
                  required
                  className="sub_text  grey_subtext1"
                  value={""}
                  onChange={(e) => { }}
                  type="text"
                  placeholder="Enter User Name"
                />
                <Form.Control.Feedback type="invalid" className="invalid-feedback">
                  UserName
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label className="sub_text  grey_subtext1">Password</Form.Label>
                <Form.Control
                  required
                  className="sub_text  grey_subtext1"
                  value={""}
                  onChange={(e) => { }}
                  type="text"
                  placeholder="Enter Password"
                />
                <Form.Control.Feedback type="invalid" className="invalid-feedback">
                  Password
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <div className="paymentRadioButton flex-column">
                  <RadioButton
                    label="Secure connection using TLS (Recommendation )"
                    value={2}
                    name={undefined}
                    checked={2}
                    onChange={(e) => { }}
                  />
                  <RadioButton
                    label="Secure connection using SSL"
                    value={2}
                    name={undefined}
                    checked={1}
                    onChange={(e) => { }}
                  />
                </div>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <AddTraspButton
              type="button"
              bname="Cancel"
              onClick={handleClose}
              width="fit-content"
            />
            <AddButtonWithArrow
              type="submit"
              buttonname="Add account"
            />
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default AddGmailAccount