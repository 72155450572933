import React from "react";
import { GetImages } from "../../utils/GetImages";

type Props = {
  tenantId: any;
  loading: boolean;
  tenantData: any;
};

const flexContainerStyle = {
  display: "flex",
  padding: "12px",
  alignItems: "center",
  gap: "var(--2, 8px)",
  alignSelf: "stretch",
  borderRadius: "var(--rounded-lg, 8px)",
  background: "var(--Primary-Next-level-Lighter, #EBF5FF)",
};


const TenantDetails = React.memo(({ tenantId, loading, tenantData }: Props) => {
  return (
    <>
      {tenantData?.id !== undefined ? (
        <div style={flexContainerStyle}>
          <div className="singleTenantNameDetailsLeftSide">
            <div>
              <div className="sub_text fw-500 black mb-2">
                Tenant details
              </div>
              <div>
                <div className="singleTenantUnitOr d-flex align-items-center flex-wrap">
                  <span className="sub_text fw-500 black">
                    <img
                      height={16}
                      width={16}
                      src={GetImages.user_red}
                    />
                    <span className="ps-2">
                      {tenantData?.firstName} {tenantData?.lastName}
                    </span>
                  </span>

                  {tenantData?.property?.address?.streetAddress || tenantData?.property?.name || tenantData?.tenancySince
                    && <span className="sub_text black ms-0">
                      <span style={{ marginRight: 5 }}>
                        <img
                          height={16}
                          width={16}
                          src={GetImages.Time_Red}
                        />
                      </span>
                      <span className="ps-2">
                        {tenantData?.property?.address?.streetAddress ||
                          tenantData?.property?.name ||
                          tenantData?.tenancySince}
                      </span>
                    </span>
                  }

                  <span className="sub_text black ms-0">
                    <img
                      height={16}
                      width={16}
                      src={GetImages.mailIconOrange}
                    />
                    <span className="ps-2">{tenantData?.email}</span>
                  </span>

                  <span className="sub_text black ms-0">
                    <img
                      height={16}
                      width={16}
                      src={GetImages.phoneIconOrange}
                    />
                    <span className="ps-2">{tenantData?.phoneNumber}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        " "
      )}
    </>
  );
});

export default TenantDetails;
