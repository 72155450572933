import { useEffect, useRef, useState } from "react";
import EmptyData from "../../common/EmptyData";

const PieChart = ({ data, fin_monthValue, setFin_MonthValue }) => {
  const canvasRef = useRef(null);
  const [tooltip, setTooltip] = useState({ visible: false, content: "", value: 0, x: 0, y: 0, color: "" });

  useEffect(() => {
    if (!data || data.length === 0) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = Math.min(centerX, centerY);
    const total = data?.reduce((acc, item) => acc + item.value, 0);
    const ringThickness = 20;
    const innerRadius = radius - ringThickness;
    const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;

    let currentAngle = 0;

    data?.forEach((item) => {
      const percentage = (item.value / total) * 360;
      const endAngle = currentAngle + degreesToRadians(percentage);

      // Draw the segment
      context.fillStyle = item.color;
      context.beginPath();
      context.arc(centerX, centerY, radius, currentAngle, endAngle);
      context.arc(centerX, centerY, innerRadius, endAngle, currentAngle, true);
      context.closePath();
      context.fill();

      // Store angles for hover detection
      item.startAngle = currentAngle;
      item.endAngle = endAngle;

      currentAngle = endAngle;
    });
  }, [data, fin_monthValue]);


  const handleMouseMove = (e) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    const angle = Math.atan2(mouseY - centerY, mouseX - centerX);
    const adjustedAngle = angle >= 0 ? angle : 2 * Math.PI + angle;

    const hoveredItem = data?.find(
      (item) =>
        adjustedAngle >= item.startAngle && adjustedAngle <= item.endAngle
    );

    if (hoveredItem) {
      setTooltip({
        visible: true,
        content: `${hoveredItem.name}`,
        value: hoveredItem.value,
        x: e.clientX - 50,
        y: e.clientY + 30,
        color: hoveredItem?.color
      });
    } else {
      setTooltip({ visible: false, content: "", value: 0, x: 0, y: 0, color: "" });
    }
  };

  const tooltipStyle: React.CSSProperties = {
    top: tooltip.y,
    left: tooltip.x,
    visibility: tooltip.visible ? "visible" : "hidden",
  };

  return (
    <>
      <div className="expenseDistribution position-relative">
        <div className="expenseHeader">
          <p className="heading black fw-600">Expense Distribution</p>
          <div className="monthhead w-100 justify-content-">
            <button
              onClick={() => setFin_MonthValue(-1)}
              className={
                fin_monthValue === -1
                  ? "overviewSelectedMonth "
                  : "overviewUnselectedMonth"
              }
            >
              Last Month
            </button>
            <button
              onClick={() => setFin_MonthValue(-6)}
              className={
                fin_monthValue === -6
                  ? "overviewSelectedMonth"
                  : "overviewUnselectedMonth"
              }
            >
              Last 6 Months
            </button>
            <button
              onClick={() => setFin_MonthValue(-12)}
              className={
                fin_monthValue === -12
                  ? "overviewSelectedMonth"
                  : "overviewUnselectedMonth"
              }
            >
              Last 12 Months
            </button>
          </div>
        </div>

        {data?.length > 0 ? (
          <>
            <canvas
              ref={canvasRef}
              width={150}
              height={150}
              className="m-4 clickable"
              onMouseMove={handleMouseMove}
              onMouseLeave={() => setTooltip({ visible: false, content: "", value: 0, x: 0, y: 0, color: "" })}
            />
            {tooltip.visible && (
              <div style={tooltipStyle} className="normal_text grey_subtext1 tooltipStyle">
                <div className="expenseItem flex-wrap">
                  <div className="propertyCardQuantityDesc">
                    <div
                      className="expenseColor"
                      style={{ background: tooltip?.color }}
                    >
                      {" "}
                    </div>
                    <p className="normal_text grey_subtext1"> {tooltip?.content}</p>
                  </div>
                  <div className="expenseValue">
                    <p className="sub_text fw-bold ms-3">
                      {(
                        (tooltip?.value /
                          data?.reduce((acc, item) => acc + item?.value, 0)) *
                        100
                      ).toFixed(2)}
                      % ($ {tooltip?.value})
                    </p>
                  </div>
                </div>
                <div className="caretStyle"></div>
              </div>
            )}
          </>
        ) : (
          <EmptyData
            mainText={"No Data Found"}
            subText={undefined}
            button={undefined}
          />
        )
        }
        <div className="expenseDetails">
          {data?.length > 0 &&
            data?.map((item, index) => (
              <div className="expenseItem" key={index}>
                <div className="propertyCardQuantityDesc">
                  <div
                    className="expenseColor"
                    style={{ background: item?.color }}
                  >
                    {" "}
                  </div>
                  <p className="normal_text grey_subtext1 ms-2 "> {item?.name}</p>
                </div>
                <div className="expenseValue ">
                  <p className="sub_text fw-bold">
                    {(
                      (item?.value /
                        data?.reduce((acc, item) => acc + item?.value, 0)) *
                      100
                    ).toFixed(2)}
                    % ($ {item?.value})
                  </p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </>
  );
};

export default PieChart;
