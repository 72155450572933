import useInfiniteScroll from "react-infinite-scroll-hook";
import "./../tenantStyles.css";
import SimpleSpinner from "../../loader";
import EmptyData from "../../common/EmptyData";
import CardView from "./cardView";
import { GetImages } from "../../../utils/GetImages";

const MainScrollView = (dataHookState) => {
  const loadMoreRecords = () => {
    if (!dataHookState.loading) {
      dataHookState.fetchData(dataHookState.currentPage + 1);
    }
  }

  const [sentryRef] = useInfiniteScroll({
    loading: dataHookState.loading,
    hasNextPage: dataHookState.hasMore, // This can be dynamic based on your API response
    onLoadMore: loadMoreRecords,
    rootMargin: '0px 0px 100px 0px', // Adjust root margin as per your requirement
  });

  return (
    <>
      <div className="tableContainer">
        {(dataHookState?.rows?.length == 0 && !dataHookState.loading) ? (
          <EmptyData
            mainText={"No Tenant Found"}
            subText={undefined}
            button={undefined}
            ImageSrc={GetImages.NoTenantFound}
          />
        ) :
          (
            dataHookState?.rows?.map((item, i) => (
              <CardView key={i} item={item} />
            ))
          )
        }
        {dataHookState.loading && <><SimpleSpinner myClassName={dataHookState?.rows?.length == 0 ? 'Icon50' : 'Icon40'} /></>}
        <div ref={sentryRef} />
      </div>
    </>
  );
};

export default MainScrollView;
