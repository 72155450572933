import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import ContractorOverview from "./ContractorOverview";
import TransactionComponent from "./TransactionComponent";
import ServiceRequestComponent from "./ServiceRequestComponent";
import { Offcanvas } from "react-bootstrap";
import EditContractorForm from "./EditContractorForm";
import {
  ContractorService,
  DocumentService,
  PaymentService,
  AnalyticsService,
  EntityType,
} from "@propertelligent/client-api";
import NameAvatarComponent from "../tenants/NameAvatarComponent";
import { convertStreamAndDownload } from "../../utils/helper";
import { GetImages } from "../../utils/GetImages";
import { UserLoginData } from "../../components/common/charts/Enums";
import { useAnalyticsHook } from "../../../src/hooks/useAnalyticsHook";
import "./contractorStyles.css";
import { MenuContext } from "../../utils/context/MenuContext";
import apiHelper from "../../utils/apiHelper";
import { infiniteScrollHook } from "../../hooks/infiniteScrollHook";
import SeparaterLine from "../common/SeparaterLine";
import RequestBreadcrumb from "../common/RequestBreadcrumb";

const SingleContractorComponent = () => {
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [contractorData, setContractorData] = useState<any>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploading1, setIsUploading1] = useState(false);
  const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
  const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
  const [rev_monthValue, setRev_MonthValue] = useState(-12);
  const [fin_monthValue, setFin_MonthValue] = useState(-12);

  const checkActive = (index, className) => selectedTab === index ? className : "";

  useEffect(() => {
    const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
    apiHelper(PaymentService.getApiPaymentMethodOrganizationlist, { showNotification: false }, orgId)
      .then((ptRes) => {
        if (ptRes?.data?.success) {
          setPaymentMethodsArr(ptRes?.data?.success);
        }
      })
      .catch((ptErr) => { });

    apiHelper(PaymentService.getApiPayeeAccountOrganizationlist, { showNotification: false }, orgId)
      .then((ptRes) => {
        if (ptRes?.data?.success) {
          setPayeeAccountsArr(ptRes?.data?.success);
        }
      })
      .catch((ptErr) => { });
  }, [id]);

  useEffect(() => {
    getContractorData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getContractorData();
    }, 1000);
  }, [showModal]);

  const getContractorData = async () => {
    const contractorResponse = await apiHelper(ContractorService.getApiContractor1, { showNotification: false },
      Number(id)
    )
    if (contractorResponse?.data?.success) {
      setContractorData(contractorResponse?.data?.success);
    }
  };

  const downloadDocument = (id, fileName, loadingState) => {
    loadingState(true);

    apiHelper(DocumentService.getApiDocumentBase641, { showNotification: false }, id)
      .then((docRes) => {
        //Conversion alogorithm
        convertStreamAndDownload(docRes?.data?.success, fileName);
        loadingState(false);
      })
      .catch((docErr) => {
        loadingState(false);
      });
  };

  const { fetchData } = infiniteScrollHook({
    httpFetchDataFn: PaymentService.getApiPaymentDueAccountPropertylist,
    // paginationDataMapper: null,
    parentEntityId: Number(id),
    isDependentEntity: true,
    // FullyQualifiedFilters: null,
  });


  const customMonthField = "monthYear";
  const { fetchAndFormatExpensebycategory } = useAnalyticsHook({
    httpExpensebycategoryFn: AnalyticsService.getApiAnalyticsPropertyExpensebycategory,
    httpCashflowFn: AnalyticsService.getApiAnalyticsPropertyCashflow,
    customMonthField,
    parentEntityId: Number(id),
    fullyQualifiedFilters: {
      rev: `Created>DateTime.UtcNow.AddMonths(${rev_monthValue})`,
      fin: `Created>DateTime.UtcNow.AddMonths(${fin_monthValue})`,
    },
  });

  const handleSubmitPaymentAccountRecord = (data) => {
    const organizationId = parseInt(
      localStorage.getItem(UserLoginData.organization_id)
    );
    Object.assign(data.values, {
      organizationId,
      entityTypeId: EntityType.Contractor,
      dueDate: new Date(data.values?.dueDate).toISOString(),
    });

    if (data.values.paymentDueAccountStatusId === 2) {
      apiHelper(PaymentService.postApiPaymentDueAccount, { showNotification: false }, data.values)
        .then((res: any) => {

          if (res?.data?.success) {
            Object.assign(data.payvalues, {
              payDueAccountId: res?.success.id,
            });

            apiHelper(PaymentService.putApiPaymentDueAccountPay, { successMessage: "Payment Updated Succesfully", failureMessage: "Unable to update payment", showNotification: true }, data.payvalues)

              .then((res: any) => {
                if (res?.success) {
                  setShowPaymentModal(false);
                  fetchData(1);
                  fetchAndFormatExpensebycategory();
                }
              })
              .catch((error) => { });
          }
        })
        .catch((error) => { });
    } else if (data.values.paymentDueAccountStatusId === 1) {
      apiHelper(PaymentService.postApiPaymentDueAccount, { successMessage: "Payment Added Successfully", failureMessage: "Unable to add payment", showNotification: true }, data.values)
        .then((res: any) => {
          if (res?.data?.success) {
            fetchData(1);
            fetchAndFormatExpensebycategory();
            setShowPaymentModal(false);
          }
        })
        .catch((error) => { });
    }
  };

  const { isOpen } = useContext(MenuContext);

  return (
    <>
      <div id="contractorEditForm" className="singleContractorView position-relative">
        <div className={`sticky-top-property ${isOpen ? "sidebar-open" : "sidebar-closed"}`}>
          <RequestBreadcrumb
            firstName="Contractor"
            link="/contractors"
            currentStep="Contractor Detail"
          />
          <div className="mainContractoComponent">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="d-flex gap-2">
                <NameAvatarComponent
                  firstName={contractorData.firstName}
                  lastName={contractorData.lastName}
                  heightWidth={48}
                />
                <div>
                  <p className="sub_heading black mb-1">
                    {`${contractorData.firstName} ${contractorData.lastName}`}
                  </p>
                  <div className="sinceTimePeriod ms-0 d-flex gap-1">
                    <p>
                      {contractorData?.formatted?.ServiceTypesIds?.length > 0
                        ? contractorData.formatted.ServiceTypesIds[0]
                        : "Interior"}
                    </p>
                    <p>•</p>
                    <p>
                      {contractorData?.formatted?.ServiceSubTypesIds?.length > 0
                        ? contractorData.formatted.ServiceSubTypesIds[0]
                        : "Furnishing"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center gap-3">
                <p
                  className="normal_text fw-bold blue clickable"
                  onClick={() => setShowModal(true)}
                >
                  Edit Contractor
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-start w-100 gap-3 flex-wrap">
              <div className="d-flex align-items-end typeMailPhoneText normal_text  gap-1">
                <img width={18} height={18} src={GetImages.orangeContractor} />
                <p>{contractorData.isInHouse ? "In House" : "Third Party"}</p>
              </div>
              <SeparaterLine height={"20px"} background={"#e5e7eb"} />
              <div className="d-flex align-items-end typeMailPhoneText normal_text  gap-1 ">
                <img src={GetImages.mailIconOrange} />
                <p>{contractorData.email}</p>
              </div>
              <SeparaterLine height={"20px"} background={"#e5e7eb"} />
              <div className="d-flex align-items-end typeMailPhoneText normal_text  gap-1">
                <img src={GetImages.phoneIconOrange} />
                <p>{contractorData.phoneNumber}</p>
              </div>
            </div>

            {(contractorData.currentLicenseDocumentId ||
              contractorData.currentInsuranceDocumentId) && (
                <div className="d-flex gap-2 newDocumentSection2 flex-wrap">
                  {contractorData.currentLicenseDocumentId && (
                    <div
                      onClick={() => {
                        downloadDocument(
                          contractorData.currentLicenseDocumentId,
                          "License Document",
                          setIsUploading
                        );
                      }}
                      className="newDocumentItem clickable"
                    >
                      {isUploading ? (
                        <div className="d-flex justify-content-center align-items-center w-100">
                          <img height={40} src={GetImages.loadingGif} />
                        </div>
                      ) : (
                        <div className="d-flex align-items-center gap-3 p-2">
                          <img src={GetImages.pdfImage} />
                          <p className="sub_text blue">License Document</p>
                        </div>
                      )}
                    </div>
                  )}

                  {contractorData.currentInsuranceDocumentId && (
                    <div
                      onClick={() => {
                        downloadDocument(
                          contractorData.currentInsuranceDocumentId,
                          "Insurance Document",
                          setIsUploading1
                        );
                      }}
                      className="newDocumentItem clickable"
                    >
                      {isUploading1 ? (
                        <div className="d-flex justify-content-center align-items-center w-100">
                          <img height={40} src={GetImages.loadingGif} />
                        </div>
                      ) : (
                        <div className="d-flex align-items-center gap-3 p-2">
                          <img src={GetImages.pdfImage} />
                          <p className="sub_text blue">
                            Insurance Document
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
          </div>

          <div className="p-tabs">
            <button
              className={`tab normal_text grey_subtext1 ${checkActive(1, "active")}`}
              onClick={() => setSelectedTab(1)}
            >
              Overview
            </button>
            <button className={`tab normal_text grey_subtext1 ${checkActive(2, "active")}`}
              onClick={() => setSelectedTab(2)}
            >
              Service requests
            </button>
            <button className={`tab normal_text grey_subtext1 ${checkActive(3, "active")}`}
              onClick={() => setSelectedTab(3)}
            >
              Transactions
            </button>
          </div>
        </div>

        <div
          style={{
            marginTop: `${contractorData.currentLicenseDocumentId || contractorData.currentInsuranceDocumentId ? "300px" : "250px"}`,
            height: "calc(100vh - 280px)",
            overflow: "auto",
          }}
          className="bottom-contractor-scrolling"
        >
          {selectedTab === 1 && <ContractorOverview id={Number(id)} />}
          {selectedTab === 2 && <ServiceRequestComponent id={Number(id)} />}
          {selectedTab === 3 && <TransactionComponent id={Number(id)} />}
          <Offcanvas
            className="editTanentOffcanvas"
            show={showModal}
            onHide={() => setShowModal(false)}
            placement={"end"}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Edit Contractor</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {contractorData && (
                <EditContractorForm
                  setShowModal={setShowModal}
                  id={contractorData?.id}
                />
              )}
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </>
  );
};

export default SingleContractorComponent;
