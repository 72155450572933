import React, { useState } from 'react'
import SingleNoteComponent from './SingleNoteComponent';
import DateHelper from '../../utils/DateHelper';
import apiHelper from '../../utils/apiHelper';
import { NotesEntityTypesEnum, NoteService } from '@propertelligent/client-api';
import Search from '../common/search';
import { UserLoginData } from '../common/charts/Enums';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import SimpleSpinner from '../loader';
import CommonConfirmation from '../common/CommonConfirmation';
import CustomInputs from '../common/Inputs';
import { FORM_CONTROLFIELD_TYPES } from '../../constants/constants';

const AddViewNotes = ({
    noteText, setNoteText,
    noteError, setNoteError,
    notesData, addNoteHandler,
    fetchData, tenantId, searchValue,
    setSearchValue, hasMore, currentPage, loading
}) => {
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [noteId, setNoteId] = useState()

    const handleEdit = async (id: number, noteData: string) => {
        let noteObject = {
            id,
            noteData,
            entityTypeId: NotesEntityTypesEnum.Tenant,
            entityId: tenantId,
            modifiedById: Number(localStorage.getItem(UserLoginData.user_id)),
            modifiedBy: localStorage.getItem(UserLoginData.user_name)
        }

        await apiHelper(NoteService.putApiNote,
            {
                successMessage: "Note Updated Successfully",
                failureMessage: "Unable to Update Note",
                showNotification: true,
            },
            noteObject
        ).then(res => {
            if (res?.data?.success) {
                fetchData(1)
            }
        }).catch(error => {
            console.error(error);
        })
    }

    const handleDelete = async () => {
        await apiHelper(NoteService.deleteApiNote,
            {
                successMessage: "Note Deleted Successfully",
                failureMessage: "Unable to Delete Note",
                showNotification: true,
            },
            noteId
        ).then(res => {
            if (res?.data?.success === "true") {
                fetchData(1)
                setShowConfirmation(false)
            }
        }).catch(error => {
            console.error(error);
        })
    }

    const handleDeleteClose = () => setShowConfirmation(false)


    const loadMoreRecords = () => {
        if (!loading) {
            fetchData(currentPage + 1);
        }
    };

    const [sentryRef] = useInfiniteScroll({
        loading: loading,
        hasNextPage: hasMore, // This can be dynamic based on your API response
        onLoadMore: loadMoreRecords,
        rootMargin: "0px 0px 100px 0px", // Adjust root margin as per your requirement
    });

    return (
        <>
            <div className="ledgerNotesSection">
                <div className="notesContainer">
                    <div className='searchContainer'>
                        <div style={{ height: "35px" }} >
                            <Search
                                placeholderName={"Search Notes"}
                                searchValue={searchValue}
                                searchFilterChange={setSearchValue}
                            />
                        </div>
                    </div>
                    <div className="messageContainer px-3" style={{ position: 'relative' }}>
                        <div style={{ marginTop: "50px" }}>
                            {notesData.length > 0 ? (
                                notesData.map((item, i) => {
                                    return (
                                        <SingleNoteComponent
                                            date={DateHelper.convertUTCtoDateTime(
                                                item?.modified,
                                                true
                                            )}
                                            notesData={item}
                                            key={i}
                                            name={item.modifiedBy}
                                            showNotes={true}
                                            handleEdit={handleEdit}
                                            handleDelete={() => {
                                                setShowConfirmation(true)
                                                setNoteId(item?.id)
                                            }}
                                        />
                                    );
                                })
                            ) : (
                                <div className="nothingToShowText normal_text">
                                    Notes history is empty
                                </div>
                            )}
                        </div>
                        {loading && <><SimpleSpinner myClassName={notesData?.length == 0 ? 'Icon50' : 'Icon40'} /></>}
                        <div ref={sentryRef} />
                        {showConfirmation && (
                            <CommonConfirmation
                                show={true}
                                onConfirm={handleDelete}
                                onClose={handleDeleteClose}
                                heading="Delete Note"
                                subHeading={`Are you sure you want to delete your note?`}
                                confirmLabel="Delete"
                            />
                        )}
                    </div>
                </div>
                <div className="tennatNotesInput p-3">
                    {noteError && (
                        <p className=" small_text red fw-300 ps-3 mb-2">Note cannot be empty</p>
                    )}
                    <div className="inputCommentContainer">
                        <input
                            value={noteText}
                            onChange={(e) => {
                                setNoteText(e.target.value);
                                setNoteError(false);
                            }}
                            className="messageInput normal_text"
                            placeholder="Type your notes here..."
                        />
                        <p
                            onClick={addNoteHandler}
                            className="sub_text fw-600 blue clickable "
                        >
                            Add Note
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddViewNotes