import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Row, Offcanvas } from "react-bootstrap";
import { DocumentService, PaymentService, PropertyService, TenantService, UnitService, UnitTypesEnum, UnitVM, EntityType, AnalyticsService } from "@propertelligent/client-api";
import { AvatarWithLoading } from "../common/avator";
import { useParams, useNavigate } from "react-router-dom";
import { UserLoginData } from "../common/charts/Enums";
import { UnitCreate } from "./create";
import { Area, bed, Bath } from "../../assets/index";
import { usePaginationHook } from "../../hooks/usePaginationHook";
import Tabs from "./tabs";
import { debouncedFunction, getSizeString } from "../../utils/helper";
import RequestBreadcrumb from "../common/RequestBreadcrumb";
import { URLS, getBathroomText } from "../../constants/constants";
import { useAnalyticsHook } from "../../hooks/useAnalyticsHook";
import { MenuContext } from "../../utils/context/MenuContext";
import apiHelper from "../../utils/apiHelper";
import SimpleSpinner from "../loader";
import CustomDropDownMenu from "../common/CustomDropDownMenu";
import { GetImages } from "../../utils/GetImages";
import CommonConfirmation from "../common/CommonConfirmation";

const EditUnit = () => {
  const { id } = useParams();
  const unitId = parseInt(id);
  const navigate = useNavigate();

  const initialName = "UnknownDoc";
  const initialTenantValues = {
    id: 0,
    firstName: "Vacant",
    lastName: "",
    currentLeaseId: -1,
  };

  const [show, setShow] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [properties, setProperties] = useState([]);
  const [unitData, setUnitData] = useState<UnitVM | any>({});
  const [tenantData, setTenantData] = useState(initialTenantValues);
  const [mainImage, setMainImage] = useState<any>(null);
  const [mainImageId, setMainImageId] = useState<number | null>(null);
  const [tenantIds, setTenantIds] = useState([]);
  const [editableFields, setEditableFields] = useState<UnitVM | any>({});
  const [selectedProperty, setSelectedPropery] = useState("");
  const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
  const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
  const [currentDocFileName, setCurrentDocoFileName] = useState(initialName);
  const [activeIndex, setActiveIndex] = useState(1);
  const [tenantApiData, setTenantApiData] = useState<any>({});
  const [tenantLoading, setTenantLoading] = useState(true); // Add loading state
  const [rev_monthValue, setRev_MonthValue] = useState(-12);
  const [fin_monthValue, setFin_MonthValue] = useState(-12);
  const [loadingStates, setLoadingStates] = useState({
    overview: true,
    transactions: true,
    serviceRequest: true,
  });
  const [allDataLoading, setAllDataLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false)

  const customMonthField = "monthYear";
  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));

  const { loading, rows, fetchData } = usePaginationHook({
    httpFetchDataFn: PaymentService.getApiPaymentDueAccountUnitlist,
    paginationDataMapper: null,
    parentEntityId: Number(id),
    isDependentEntity: true,
    FullyQualifiedFilters: null,
  });

  useEffect(() => {
    if (!loading) {
      setLoadingStates({
        ...loadingStates,
        overview: false,
        transactions: false,
      });
    }
  }, [loading]);

  useEffect(() => {
    if (
      !loadingStates.overview &&
      !loadingStates.transactions &&
      !loadingStates.serviceRequest
    ) {
      setAllDataLoading(false);
    }
  }, [loadingStates]);

  const { cashFlow, expensebycategoryArr, fetchAndFormatExpensebycategory } = useAnalyticsHook({
    httpExpensebycategoryFn: AnalyticsService.getApiAnalyticsUnitExpensebycategory,
    httpCashflowFn: AnalyticsService.getApiAnalyticsUnitCashflow,
    customMonthField,
    parentEntityId: parseInt(id),
    fullyQualifiedFilters: {
      rev: `Created>DateTime.UtcNow.AddMonths(${rev_monthValue})`,
      fin: `Created>DateTime.UtcNow.AddMonths(${fin_monthValue})`,
    },
  });

  const arrangeCustomEditableFields = useCallback((unitObj: any) => {
    const {
      id,
      propertyId,
      mainImageId,
      unitTypeId,
      unitNumber,
      size,
      numberOfBathrooms,
      numberOfBedrooms,
      isBathAttached,
    } = unitObj;
    setEditableFields({
      id,
      propertyId,
      mainImageId,
      unitTypeId,
      unitNumber,
      size,
      numberOfBathrooms,
      numberOfBedrooms,
      isBathAttached,
    });
  }, []);

  const fetchMainImageInEditPage = useCallback((latestMainImageId: number) => {
    if (
      latestMainImageId &&
      latestMainImageId !== 0 &&
      latestMainImageId !== -1
    ) {
      apiHelper(
        DocumentService.getApiDocumentBase641,
        { showNotification: false },
        latestMainImageId
      )
        .then((imageResponse: any) => {
          const imageData =
            "data:image/*;base64," + imageResponse?.data?.success;
          setMainImage(imageData);
        })
        .catch((imageErr: any) => { });
    }
  }, []);

  const fetchUnitDetails = useCallback(() => {
    // Only fetch data if it hasn't been fetched already
    apiHelper(UnitService.getApiUnit1, { showNotification: false }, unitId)
      .then((unitDataRes: any) => {
        arrangeCustomEditableFields(unitDataRes?.data?.success);
        setMainImageId(unitDataRes?.data?.success?.mainImageId);
        setUnitData(unitDataRes?.data?.success);
        setSelectedPropery(unitDataRes?.data?.success?.propertyId);
        setTenantIds(unitDataRes?.data?.success?.tenantIds);
        fetchMainImageInEditPage(unitDataRes?.data?.success?.mainImageId);
        // dataFetchedRef.current = true; // Mark data as fetched
      })
      .catch((unitErr: any) => { });
  }, [unitId, arrangeCustomEditableFields, fetchMainImageInEditPage]);

  useEffect(() => {
    apiHelper(
      PropertyService.getApiPropertyOrganizationlookup,
      { showNotification: false },
      orgId
    )
      .then((response: any) => {
        setProperties(response?.data?.success);
      })
      .catch((ptysErr) => { });

    fetchUnitDetails();

    apiHelper(
      PaymentService.getApiPaymentMethodUnitlist,
      { showNotification: false },
      unitId
    ).then((res) => {
      if (res?.data?.success) {
        setPaymentMethodsArr(res?.data?.success);
      }
    });

    apiHelper(
      PaymentService.getApiPayeeAccountOrganizationlist,
      { showNotification: false },
      orgId
    )
      .then((ptRes) => {
        if (ptRes?.data?.success) {
          setPayeeAccountsArr(ptRes?.data?.success);
        }
      })
      .catch((ptErr) => { });
  }, [orgId, unitId, fetchUnitDetails]);

  useEffect(() => {
    if (
      tenantData?.currentLeaseId &&
      tenantData?.currentLeaseId !== -1 &&
      tenantData?.currentLeaseId !== 0
    ) {
      debouncedFunction(() => {
        apiHelper(
          DocumentService.getApiDocumentDetails,
          { showNotification: false },
          tenantData?.currentLeaseId
        )
          .then((docDetilsRes: any) => {
            if (docDetilsRes?.data?.success) {
              setCurrentDocoFileName(docDetilsRes?.data?.success?.fileName);
            }
          })
          .catch((docDetilsErr) => { });
      }, 500)();
    }
  }, [tenantData?.currentLeaseId]);

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);
  const handleCloseConfirmation = () => setShowConfirmation(false);

  function getKeyByValue(value: number): string | undefined {
    const keys = Object.keys(UnitTypesEnum).filter(
      (key) => UnitTypesEnum[key as keyof typeof UnitTypesEnum] === value
    );
    return keys.length > 0 ? keys[0] : undefined;
  }

  const handleSetMainImageId = (newMainImageId: number) => {
    setUnitData((oldData) => ({
      ...oldData,
      mainImageId: newMainImageId,
    }));
    setMainImageId(newMainImageId);
  };

  const handleSubmitPaymentAccountRecord = ({ values, payvalues }) => {
    const {
      description,
      dueDate,
      amount,
      entityId,
      payeeAccountId,
      gracePeriodInDays,
      paymentDueAccountStatusId,
      isRecordPayment,
      paymentTypeId,
    } = values;
    const { fromPaymentMethodId } = payvalues;

    const updatedValues = {
      amount,
      description,
      dueDate: new Date(dueDate).toISOString(),
      entityId,
      entityTypeId: EntityType.Unit,
      payeeAccountId,
      gracePeriodInDays,
      paymentDueAccountStatusId,
      fromPaymentMethodId,
      isRecordPayment,
      paymentTypeId,
    };

    apiHelper(
      PaymentService.postApiPaymentDueAccount,
      {
        successMessage: "Payment Added Successfully",
        failureMessage: "Unable to add payment",
        showNotification: true,
      },
      updatedValues
    )
      .then((res: any) => {
        if (res?.data?.success) {
          setShowModel(false);
          setTimeout(() => {
            navigate(URLS.UNITS);
          }, 2000);
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    if (unitData?.tenantIds?.[0]) {
      setTenantLoading(true);
      apiHelper(
        TenantService.getApiTenant1,
        { showNotification: false },
        unitData?.tenantIds?.[0]
      )
        .then((ptRes) => {
          if (ptRes?.data?.success) {
            setTenantApiData(ptRes?.data?.success);
          }
        })
        .catch((ptErr) => { })
        .finally(() => {
          setTenantLoading(false);
        });
    }
  }, [unitData?.tenantIds?.[0]]);

  const tabsArr = useMemo(
    () => [
      { id: 1, name: "Overview" },
      { id: 3, name: "Service Requests" },
      { id: 4, name: "Transactions" },
    ],
    []
  );

  const handleClick = useCallback(
    (index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const checkActive = useCallback(
    (index: number, className: string) =>
      activeIndex === index ? className : "",
    [activeIndex]
  );

  const actions = [
    {
      id: 1,
      img: undefined,
      onClick: () => navigate(URLS.CREATE_PAYMENT + `?entityId=${id && Number(id)}&entityTypeId=${EntityType.Unit}`),
      name: "Add Payment",
      linkpath: undefined,
      textClass: "sub_text fw-600 blue",
    },
    {
      id: 2,
      img: undefined,
      name: "Edit Unit Details",
      onClick: () => handleShow(),
      linkpath: undefined,
      textClass: "sub_text fw-600 blue",
    },
    {
      id: 3,
      img: undefined,
      name: "Delete Unit",
      onClick: () => setShowConfirmation(true),
      linkpath: undefined,
      textClass: "sub_text fw-600 blue",
    }
  ];

  const { isOpen } = useContext(MenuContext);

  const handleDelete = () => {
    apiHelper(UnitService.deleteApiUnit, {
      successMessage: "Unit Deleted Successfuly",
      failureMessage: "Unable to Delete Unit",
      showNotification: true
    }, id).then((res) => {
      if (res?.data?.success) {
        navigate("/units")
        setShowConfirmation(false)
      }
    }).catch((err) => { })
  }

  return (
    <>
      {allDataLoading && ( // Check loading state
        <div className="loading-container">
          <SimpleSpinner />
        </div>
      )}

      <div
        style={{ display: loading == true && "none" }}
        className="property-details-container position-relative"
        id="unitDetail"
      >
        <div className={`sticky-top-property ${isOpen ? "sidebar-open" : "sidebar-closed"}`}>
          <Row>
            <RequestBreadcrumb firstName={"Unit"} link={"/units"} />
          </Row>
          <div
            className="about-property"
            style={{
              borderBottom: "1px solid #E5E7EB",
              paddingBottom: 12,
            }}
          >
            <div className="propertyFirstColumn">
              <div className="p-image">
                <AvatarWithLoading
                  docImage={unitData.mainImageBase64}
                  docNumber={mainImageId || unitData?.mainImageId}
                  avatar={true}
                  Size="Icon60"
                />
              </div>
              <div className="property-details">
                <span className="sub_text grey_subtext1 mb-1">
                  {getKeyByValue(unitData?.unitTypeId)}
                </span>
                <span className="heading black">{unitData?.unitNumber}</span>
                <div className="d-flex justify-content-start mt-2">
                  <div>
                    <div className="p-image" style={{ width: "48px" }}></div>
                    <div className="property-details sub_text grey_subtext1">
                      <div className="d-flex gap-3">
                        {unitData?.unitTypeId !== 3 ? (
                          <>
                            <span style={{ paddingRight: 4, fontSize: 12 }}>
                              <img src={bed} alt="My Icon" />
                              <span style={{ marginLeft: "4px" }}>
                                {unitData?.numberOfBedrooms}{" "}
                                {getBathroomText(
                                  unitData?.numberOfBedrooms,
                                  "Bedroom"
                                )}
                              </span>
                            </span>

                            <span>
                              <img src={Bath} alt="My Icon" />
                              <span style={{ marginLeft: "4px" }}>
                                {unitData?.numberOfBathrooms}{" "}
                                {getBathroomText(
                                  unitData?.numberOfBathrooms,
                                  "Bathroom"
                                )}
                              </span>
                            </span>
                          </>
                        ) : (
                          <span>
                            <img src={Bath} alt="My Icon" className="me-1" />
                            {unitData?.isBathAttached ? "Attach" : "Studio"}
                          </span>
                        )}
                        <span>
                          <img src={Area} alt="My Icon" />
                          <span style={{ marginLeft: "4px" }}>
                            {getSizeString(unitData?.size)}
                          </span>
                        </span>
                        {unitData?.tenantIds !== null ? (
                          <>
                            <div
                              style={{ height: 20, lineHeight: 16 }}
                              className="dividerMediumDiv"
                            ></div>
                            <span className="sub_text fw-500 black">
                              ${unitData?.formatted?.Rent}/m
                              <span className="grey_subtext1 ms-1">
                                ({unitData?.formatted?.LeaseTypeId})
                              </span>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="actionsDropdownContainer clickable">
              <CustomDropDownMenu
                Action={true}
                Element={
                  <img
                    src={GetImages.BlueChevronDown}
                    alt="BlueChevronDown"
                  />
                }
                data={actions}
              />
            </div>
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement={"end"}
              style={{
                position: "fixed",
                top: 0,
                right: 0,
                height: "100vh",
                width: "580px",
                background: "white",
              }}
            >
              <Offcanvas.Header className="canvasHeader" closeButton>
                <Offcanvas.Title>Edit Unit</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <UnitCreate
                  setShow={setShow}
                  unitData={editableFields /*unitData*/}
                  generatedMainImage={mainImage}
                  fetchUnitDetails={fetchUnitDetails}
                  setMainImageId={handleSetMainImageId}
                />
              </Offcanvas.Body>
            </Offcanvas>
          </div>

          <div className="p-tabs" style={{ gap: "20px" }}>
            {tabsArr.map((tab) => (
              <button
                key={`tabBtn${tab.id}`}
                className={`tab normal_text  grey_subtext1 ${checkActive(
                  tab.id,
                  "active"
                )}`}
                onClick={() => handleClick(tab.id)}
              >
                {tab.name}
              </button>
            ))}
          </div>
        </div>
        <div
          className="panels panel-container bottom-container-unit"
          style={{ marginTop: "210px" }}
        >
          <div
            className="rounded-2 bottom-container-unit-inside"
            style={{
              height: "calc(100vh - 300px)",
              // overflow: "auto",
            }}
          >
            <Tabs
              id={id}
              tenantId={unitData?.tenantIds?.[0]}
              paymentMethodsArr={paymentMethodsArr}
              payeeAccountsArr={payeeAccountsArr}
              transactionRows={rows}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              fetchData={fetchData}
              tenantApiData={tenantApiData}
              tenantLoading={tenantLoading}
              expensebycategoryArr={expensebycategoryArr}
              fetchAndFormatExpensebycategory={fetchAndFormatExpensebycategory}
              fin_monthValue={fin_monthValue}
              setFin_MonthValue={setFin_MonthValue}
              loadingStates={loadingStates}
              setLoadingStates={setLoadingStates}
            />
          </div>
        </div>
        {showConfirmation && (
          <CommonConfirmation
            show={true}
            onConfirm={handleDelete}
            onClose={handleCloseConfirmation}
            heading="Delete Property"
            subHeading={"Do you want to delete this property."}
            confirmLabel="Delete"
            cancelLabel="Cancel"
          />
        )}
      </div>
    </>
  );
};

export default EditUnit;
