import { GetImages } from "../../../utils/GetImages";
import AddButtonWithArrow from "../../../components/firstTimeUserExperience/commonCom/AddButtonWithArrow";
import AddTraspButton from "../../../components/firstTimeUserExperience/commonCom/AddTraspButton";

const NoAccount = ({ setShowModal, handleAddAccount }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center h-100">
      <img
        src={GetImages.tenantNoAccountIcon}
        alt="NoAccount"
        className="tenantNoAccountImage"
      />
      <p className="heading black">Begin your transaction by adding account</p>
      <p className="normal_text black">
        Add your account by ACH or Credit Card{" "}
      </p>
      <div className="editFormFooter">
        <AddTraspButton
          type="button"
          bname="Cancel"
          onClick={() => setShowModal(false)}
          width="fit-content"
        />
        <AddButtonWithArrow
          type="submit"
          buttonname="Add An Account"
          onClick={handleAddAccount}
        />
      </div>
    </div>
  );
};

export default NoAccount;
