import EmptyData from '../../common/EmptyData';
import UtilHelper from '../../../utils/UtilHelper';


const PnlStatementTable = ({ statementData, isPrint = undefined }) => {
    return (
        <>
            <table className="table table-hover statement_table">
                <thead className={isPrint ? "statement_table_header_pdf" : "statement_table_header"}>
                    <tr>
                        <th className='normal_text grey_subtext1 fw-500 text-uppercase w-100' scope="col">Amount</th>
                        <th className='normal_text grey_subtext1 text-end fw-500 text-uppercase' scope="col">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {statementData && statementData?.incomeItems?.length > 0 || statementData && statementData?.expenseItems?.length > 0 ? (
                        <>
                            {/* Income Section */}
                            {statementData && statementData?.incomeItems?.length > 0 &&
                                <>
                                    <tr>
                                        <td className='normal_text fw-bold grey_subtext1'>
                                            Income
                                        </td>
                                        <td></td>
                                    </tr>
                                    {statementData && statementData?.incomeItems?.map((data, index) => (
                                        <tr key={index} className='data_row'>
                                            <td className='normal_text grey_subtext1'>{data.description}</td>
                                            <td className='normal_text grey_subtext1 text-end'>
                                                ${data.amount?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            }

                            <tr>
                                <td className='normal_text grey_subtext1 fw-bold'>Total Income</td>
                                <td className='normal_text grey_subtext1 fw-bold text-end'>
                                    ${statementData?.income?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </td>
                            </tr>

                            {/* Expenses Section */}
                            {statementData && statementData?.expenseItems?.length > 0 &&
                                <>
                                    <tr>
                                        <td className='normal_text fw-bold grey_subtext1'>Expenses</td>
                                        <td></td>
                                    </tr>
                                    {statementData && statementData?.expenseItems?.map((data, index) => (
                                        <tr key={index} className='data_row'>
                                            <td className='normal_text grey_subtext1'>{data.description}</td>
                                            <td className='normal_text grey_subtext1 text-end'>
                                                ${data.amount?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            }

                            <tr>
                                <td className='normal_text grey_subtext1 fw-bold'>Total Expenses</td>
                                <td className='normal_text grey_subtext1 fw-bold text-end'>
                                    ${statementData && statementData?.expenses?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </td>
                            </tr>

                            {/* Net Operating Income */}
                            {statementData && statementData?.netIncome !== 0 &&
                                <tr>
                                    <td className='normal_text grey_subtext1 fw-bold'>Net Operating Income</td>
                                    <td className='normal_text grey_subtext1 fw-bold text-end'>
                                        {UtilHelper.formatNegativeValue(statementData && statementData?.netIncome)}
                                    </td>
                                </tr>
                            }
                        </>
                    ) :
                        (
                            <EmptyData mainText={"No Data Found"} subText={undefined} button={undefined} />
                        )
                    }
                </tbody>
            </table>
        </>
    );
}

export default PnlStatementTable;
