import { useEffect, useState } from 'react'
import { GetImages } from '../../../utils/GetImages'
import AddTraspButton from '../../firstTimeUserExperience/commonCom/AddTraspButton'
import NewSelectAuto from '../NewSelectAuto'
import { infiniteScrollHook } from '../../../hooks/infiniteScrollHook'
import { LeaseAgreementDataAttributesEnum, PaymentTypesEnum, TemplateService, TemplateTypeEnum, TenantLeaseTypesEnum } from '@propertelligent/client-api'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import apiHelper from '../../../utils/apiHelper'
import DynamicAddMoreForm from '../../common/DynamicAddMoreForm'
import { convertENUMtoJSONArray } from '../../../utils/helper'
import { FORM_CONTROLFIELD_TYPES } from '../../../constants/constants'
import CustomInputs from '../../common/Inputs'
import { useError } from '../../../utils/context/ErrorContext'
import DisplayNamewithLeftBorder from '../../common/DisplayNamewithLeftBorder'
import AddButtonWithArrow from '../../firstTimeUserExperience/commonCom/AddButtonWithArrow'
import { useNavigate } from 'react-router'

const LeaseAgreementSettings = ({ setInitiateLeaseAgreement, previewLeaseAgreement, setPreviewLeaseAgreement, selectedTemplateid, setSelectedTemplateid,
    setPostLeaseAgreement, formData, setFormData
}) => {
    const navigation = useNavigate()
    const [selectedOption, setSelectedOption] = useState()
    const [filteredInputs, setFilteredInputs] = useState([]);
    const [leaseType, setLeaseType] = useState();
    const { errors, setErrors, errorMessages, setErrorMessages, clearErrors } = useError();
    const headers = ["Payment Type", "Amount ($)", "Due Date", "Grace Period", "Description", ""]
    const dataAttribute = convertENUMtoJSONArray(LeaseAgreementDataAttributesEnum);
    const InputArray = dataAttribute?.map((attribute) => ({
        id: attribute.id,
        name: attribute.name,
        labelText: attribute.name.replace(/([A-Z])/g, " $1").trim(),
        type: /Date|Day|Period/i.test(attribute.name) ? FORM_CONTROLFIELD_TYPES.DATE : FORM_CONTROLFIELD_TYPES.TEXT,
        value: formData[attribute.name],
        isFormControl: true,
        onChange: (e) => handleChange(attribute.name, e.target.value),
        placeholder: `Enter ${attribute.name.replace(/([A-Z])/g, " $1").trim()}`,
        myClassName: "containerTextField",
        isError: false,
        errorMessages: errorMessages[attribute.name],
    }));

    const handleChange = (fieldName, value) => {
        setFormData((prevFormData) =>
            prevFormData.map((item) => item[fieldName] !== undefined ? { ...item, [fieldName]: value } : item)
        );

        setPostLeaseAgreement((prevState) => ({
            ...prevState,
            templateId: selectedTemplateid,
            dataAttributes: { ...prevState.dataAttributes, [fieldName]: value },
        }));

        setFilteredInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.name === fieldName ? { ...input, value } : input
            ));

        const errorKey = `inputFilter-${fieldName}`;
        setErrors((prevErrors) => ({ ...prevErrors, [errorKey]: false }));
        setErrorMessages((prevMessages) => ({ ...prevMessages, [errorKey]: "" }));
    };

    const handleAddPayment = () => {
        setFormData([...formData, { id: formData.length + 1, paymentType: '', amount: 0, dueDate: '', gracePeriods: 0, description: '' }])
    };
    const { loading, rows, fetchData, hasMore, currentPage } = infiniteScrollHook({
        httpFetchDataFn: TemplateService.getApiTemplateOrganizationlist,
        FullyQualifiedFilters: `templateTypeId == ${TemplateTypeEnum.LeaseAgreement}`
    });

    const loadMoreRecords = () => { if (!loading) { fetchData(currentPage + 1); } };
    const [sentryRef] = useInfiniteScroll({ loading: loading, hasNextPage: hasMore, onLoadMore: loadMoreRecords, rootMargin: "0px 0px 100px 0px", });

    const MANDATORY_FIELDS = ["Rent", "SecurityDeposit", "UnitAddress", "StartDate", "LeaseType"];

    const getTempleteData = () => {
        apiHelper(TemplateService.getApiTemplate, { showNotification: false }, Number(selectedTemplateid))
            .then((res) => {
                if (res?.data?.success) {
                    const templateData = res.data.success;
                    const uniqueAttributes = [
                        ...new Set(templateData?.leaseAgreementTemplateSections?.flatMap((section) => section.dataAttributes || [])),
                    ];
                    const matchedInputs = InputArray.filter(
                        (input) => uniqueAttributes.includes(input.id) || MANDATORY_FIELDS.includes(input.name)
                    );
                    setFilteredInputs(matchedInputs);
                }
            }).catch((err) => { console.error("Error fetching template data:", err); });
    };

    useEffect(() => { if (selectedTemplateid) { getTempleteData() } }, [selectedTemplateid, setSelectedTemplateid])

    const checkEmptyFields = (): boolean => {
        clearErrors();
        const newErrors = { ...errors };
        const newErrorMessages = { ...errorMessages };
        let hasErrors = false;

        const fieldsToCheck = [
            { key: "dueDate", message: "Due date is required" },
            { key: "gracePeriods", message: "Grace period is required" },
            { key: "amount", message: "Amount is required" },
            { key: "description", message: "Description is required" },
        ];
        formData?.forEach((payment, index) => {
            fieldsToCheck.forEach(({ key, message }) => {
                const errorKey = `${key}-${index}`;
                if (!payment?.[key]) {
                    newErrors[errorKey] = true;
                    newErrorMessages[errorKey] = message;
                    hasErrors = true;
                } else { newErrors[errorKey] = false; }
            });
        });

        filteredInputs.forEach(({ name, labelText, value }) => {
            const errorKey = `inputFilter-${name}`;
            if (!value?.trim()) {
                newErrors[errorKey] = true;
                newErrorMessages[errorKey] = `${labelText || name} is required`;
                hasErrors = true;
            } else { newErrors[errorKey] = false; }
        });
        setErrors(newErrors);
        setErrorMessages(newErrorMessages);
        return hasErrors;
    };
    return (
        <>
            <div className='leaseAgreementSettings_main p-4' >
                <AddTraspButton
                    onClick={() => setInitiateLeaseAgreement(false)}
                    bname="Lease Agreement Settings"
                    iconPosition="right"
                    imageValue={GetImages.leftChevronBlack}
                    border={false}
                    textclass="main_heading fw-600 black"
                    IconClassName="Icon20"
                    width={0}
                    className="p-0 pb-3"
                />
                <>
                    <DisplayNamewithLeftBorder HeaderName={"Select Lease template"} />
                    <NewSelectAuto
                        disabled={undefined}
                        setDisabled={undefined}
                        selected={selectedTemplateid}
                        setSelected={setSelectedTemplateid}
                        setSelectedOption={setSelectedOption}
                        selectedOption={selectedOption}
                        data={rows && rows}
                        title={"Wait till the data fetches"}
                        type="templete"
                        error={false}
                        setError={() => { }}
                        fromContractor={true}
                        label={undefined}
                    />
                </>
                {selectedTemplateid && (<>
                    <DisplayNamewithLeftBorder HeaderName={"Enter Data attributes"} />
                    <div className="basic-form" >
                        {filteredInputs.map((item) => (
                            <div key={item.id}>
                                {item.labelText === "Lease Type" ? (
                                    <NewSelectAuto
                                        disabled={undefined}
                                        setDisabled={undefined}
                                        selected={leaseType}
                                        setSelected={setLeaseType}
                                        setSelectedOption={(value) => handleChange(item.name, value)}
                                        selectedOption={undefined}
                                        data={convertENUMtoJSONArray(TenantLeaseTypesEnum)}
                                        title={"Wait till the data fetches"}
                                        type="Lease Type"
                                        error={false}
                                        setError={() => { }}
                                        fromContractor={true}
                                        label={"Lease Type"}
                                    />
                                ) : (
                                    <CustomInputs
                                        name={item.name}
                                        type={item.type}
                                        value={item.value}
                                        labelText={item.labelText}
                                        isFormControl
                                        onChange={item.onChange}
                                        placeholder={item.placeholder}
                                        myClassName={item.myClassName}
                                        isError={errors[`inputFilter-${item.name}`]}
                                        errorMessages={errorMessages[`inputFilter-${item.name}`]}
                                        width={"322px"}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                    <div>
                        <DisplayNamewithLeftBorder HeaderName={"Payments Required"} />
                        <DynamicAddMoreForm
                            headers={headers}
                            data={formData}
                            setData={setFormData}
                            initialOptions={convertENUMtoJSONArray(PaymentTypesEnum)}
                            handleAddPayment={handleAddPayment} />
                    </div>
                </>)}
            </div>
            <div className="application-section-bttom-view tenantFormFooter w-100">
                <AddTraspButton
                    type="button"
                    bname={"Cancel"}
                    onClick={() => { navigation(-1) }}
                    border={true}
                />
                <AddButtonWithArrow
                    buttonname={"Preview"}
                    onClick={() => { if (checkEmptyFields()) { } else { setPreviewLeaseAgreement(true) } }}
                    disabled={undefined}
                    iconDirection={"left"}
                    Icon={GetImages.WhiteForwardArrow}
                />
            </div>
        </>
    )
}
export default LeaseAgreementSettings





