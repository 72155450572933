
import CustomInputs from "../../common/Inputs";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";
import NewSelectAuto from "../../tenants/NewSelectAuto";

const Reference = ({ postData, setPostData }) => {
  const data = [
    {
      id: 1,
      name: "1"
    },
    {
      id: 2,
      name: "2"
    },
    {
      id: 3,
      name: "3"
    },

  ]
  
  return (
    <>
      <div className="edit-card">
        <p className="sub_heading">Reference</p>
        <p className="normal_text black">
          <i>
            Kindly input the number of reference you'd like to provide
            for the requested employee history within the app
          </i>
        </p>
        <p className="normal_text black">How many reference you would like to take ?</p>

        <NewSelectAuto
          disabled={undefined}
          setDisabled={undefined}
          selected={postData?.leaseApplicationTemplateSections?.References?.requiredRange}
          setSelected={(value) => setPostData({
            ...postData,
            leaseApplicationTemplateSections: {
              ...postData.leaseApplicationTemplateSections,
              References: {
                ...postData.leaseApplicationTemplateSections.References,
                requiredRange: Number(value),
              },
            },
          })}
          setSelectedOption={undefined}
          selectedOption={undefined}
          data={data}
          title={"Wait till the data fetches"}
          type="Select  Type"
          error={false}
          setError={() => { }}
          fromContractor={true}
          label={undefined}
          width={"290px"}
        />

      </div>
    </>
  );
};

export default Reference;
