import { PaymentTypesEnum } from "@propertelligent/client-api";

class UtilHelper {
    CurrencyFormat = (amount) => {
        const formattedAmount = amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        // Add space after the dollar sign
        return formattedAmount.replace('$', '$ ');
    }

    ExpenseTypeColors(expenseType: number): string {
        const expenseTypeColors: Record<number, string> = {
            [PaymentTypesEnum.Water]: "#3498db",           // Light Blue
            [PaymentTypesEnum.Electric]: "#f1c40f",       // Yellow
            [PaymentTypesEnum.Gas]: "#e67e22",            // Orange
            [PaymentTypesEnum.Sewer]: "#f18288",
            [PaymentTypesEnum.Phone]: "#9b59b6",          // Purple
            [PaymentTypesEnum.Internet]: "#34495e",       // Dark Gray-Blue
            [PaymentTypesEnum.Cable]: "#e74c3c",          // Red
            [PaymentTypesEnum.LandTax]: "#1eb71e",        // Green
            [PaymentTypesEnum.CountyTax]: "#8e44ad",      // Dark Purple
            // [PaymentTypesEnum.TownshipMunicipalTax]: "#00C769",
            [PaymentTypesEnum.StateTax]: "#2980b9",       // Dark Blue
            [PaymentTypesEnum.FederalTax]: "#FFC400",
            // [PaymentTypesEnum.Payroll]: "#27ae60",        // Green
            [PaymentTypesEnum.LiabilityInsurance]: "#7f8c8d",  // Gray
            [PaymentTypesEnum.WorkersCompensation]: "#f39c12", // Gold
            [PaymentTypesEnum.RenterInsurance]: "#16a085",    // Sea Green
            [PaymentTypesEnum.PropertyInsurance]: "#2c3e50",  // Navy Blue
            [PaymentTypesEnum.BankFee]: "#bdc3c7",        // Silver
            [PaymentTypesEnum.Mortgage]: "#ff5733",       // Bright Orange
            // [PaymentTypesEnum.LegalFee]: "#6c3483",       // Deep Purple
            [PaymentTypesEnum.ViolationCharge]: "#cd6155", // Salmon
            [PaymentTypesEnum.HoaFee]: "#f7dc6f",         // Light Yellow
            [PaymentTypesEnum.SecurityDeposit]: "#76d7c4", // Aqua
            [PaymentTypesEnum.Rent]: "#52be80",           // Light Green
            [PaymentTypesEnum.LateFee]: "#f5b041",        // Light Orange
            // [PaymentTypesEnum.Invoice]: "#a569bd",        // Light Purple
            [PaymentTypesEnum.Labor]: "#5dade2",          // Light Blue
            [PaymentTypesEnum.ServiceRequest]: "#3674b3", // Lime Green
            // [PaymentTypesEnum.SecurityDepositReturn]: "#eb984e", // Copper
            [PaymentTypesEnum.Refund]: "#dc7633",         // Burnt Orange
            // [PaymentTypesEnum.OtherSettlement]: "#af7ac5", // Lilac
            [PaymentTypesEnum.Commission]: "#5b2c6f",     // Royal Purple
            [PaymentTypesEnum.Other]: "#34495e",          // Dark Gray-Blue
            // [PaymentTypesEnum.HardwareStore]: "#117a65",  // Dark Teal
            // [PaymentTypesEnum.ApplianceStore]: "#a04000", // Dark Amber
            // [PaymentTypesEnum.FurnitureStore]: "#6e2c00", // Dark Brown
            // [PaymentTypesEnum.OtherStore]: "#1f618d",     // Navy Blue
            [PaymentTypesEnum.ElectricAndGas]: "#FF1F8B",
            // [PaymentTypesEnum.PlatformSubscription]: "#2e86c1", // Sky Blue
        };

        return expenseTypeColors[expenseType as PaymentTypesEnum] || "#7f8c8d"; // Default Gray
    }

    formatNegativeValue = (value) => {
        if (typeof value === 'number') {
            const formattedValue = value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            if (value < 0) {
                return `(${formattedValue.replace('-', '')})`;
            }
            return formattedValue;
        }
        return value;
    };

    getPaymentTypeName(paymentTypeId: number): string | undefined {
        const entry = Object.entries(PaymentTypesEnum).find(
            ([key, value]) => value === paymentTypeId
        );
        return entry ? entry[0] : undefined;
    }

}

export default new UtilHelper();