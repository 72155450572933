import React, { useEffect, useRef, useState } from "react";
import "./tenantonboard.css";
import { OnboardingForms } from "../../../typings/onboarding";
import BoardingReview from "./BoardingReview";
import { GetImages } from "../../../utils/GetImages";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import OrganizationNameHeader from "../../common/OrganizationNameHeader/OrganizationNameHeader";
import { handleDownloadPdf } from "../../../utils/validateHelper";

const SignatureConfirmation: React.FC<any> = ({
  formData,
  setFormData,
  isEditClick,
  leaseApplication,
  currentStepIndex,
  setpreStepIndex,
  steps,
  Signature,
}: {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<OnboardingForms>>;
  isEditClick: any;
  leaseApplication: any;
  currentStepIndex: any;
  setpreStepIndex: any
  steps: any;
  Signature:any
}) => {
  const setdata = ()=>{
     if (!Signature) return;  
    setFormData((prevFormData) => ({
      ...prevFormData,     
      Signature: prevFormData?.Signature ? prevFormData?.Signature : { fullName: "", signedDateTime: "" },
    }));
  }
  useEffect(() => {setdata()}, [Signature]);
  useEffect(() => {
   
    setpreStepIndex(currentStepIndex)
  }, [currentStepIndex])
  const [isPrint, setISPrint] = useState(false)

  const contentRef = useRef()
 
  return (
    <>
      <div className="onboarding-stepper" id="stepSix">
        <div className="flexRow justify-content-between py-3">
          <p className="main_heading">On Boarding Application Review</p>
          <AddTraspButton 
          onClick={() => handleDownloadPdf(setISPrint ,contentRef)} 
          imageValue={GetImages.pdfImage} 
          iconPosition="right" 
          textclass="sub_text blue fw-600 ms-2" 
          bname={"Download Application"} 
          border={true} />
        </div>

        <div className="onboardingstepper-form">
          <section ref={contentRef} className="onboarding-review">
            {isPrint && (  
              <OrganizationNameHeader 
               SubHeaderName={"Testing"}
               OrgName={"Capital"}
               /> 
              )}
            <BoardingReview 
            steps={steps} 
            setFormData={setFormData} 
            formData={formData} 
            editable={true} 
            leaseApplication={leaseApplication} 
            isEditClick={isEditClick} 
            postData={undefined} 
            setPostData={undefined} 
            />            
          </section>
        </div>
      </div>
    </>
  );
};

export default SignatureConfirmation;
