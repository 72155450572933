import { useEffect, useState } from 'react';
import DisplayNamewithLeftBorder from '../../common/DisplayNamewithLeftBorder';
import DynamicAddMoreForm from '../../common/DynamicAddMoreForm';
import apiHelper from '../../../utils/apiHelper';
import { PayeeAccountTypesEnum, PaymentService } from '@propertelligent/client-api';

const FinalPayments = ({ formData, setFormData }) => {
    const headers = ["Payment Type", "Amount ($)", "Due Date", "Grace Period", "Description", ""]
    const [initialOptions, setInitialOptions] = useState([])

    const handleAddPayment = () => {
        setFormData([
            ...formData,
            {
                id: formData.length + 1,
                paymentType: "",
                amount: "",
                dueDate: "",
                gracePeriods: "",
                description: "",
            },
        ]);
    };

    useEffect(() => {
        apiHelper(PaymentService.getApiPayeeAccountPaymentTypes, { showNotification: false }, PayeeAccountTypesEnum.Tenant)
            .then((res) => {
                if (res?.data?.success) {
                    setInitialOptions(res?.data?.success)
                }
            }).catch((err) => { })
    }, [])

    return (
        <div className="ledgerNotesSection leaseDetailsSection">
            <div className="rentLedgerContainer p-3">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-baseline gap-2">
                        <DisplayNamewithLeftBorder
                            HeaderName="Final Payments"
                        />
                    </div>
                </div>

                <DynamicAddMoreForm
                    headers={headers}
                    data={formData}
                    setData={setFormData}
                    initialOptions={initialOptions}
                    handleAddPayment={handleAddPayment} />
            </div>
        </div>
    );
};

export default FinalPayments;
