import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CustomTooltip = ({ id, placement, tooltip, Element }) => {
  return (
    <>
      <OverlayTrigger overlay={<Tooltip className={tooltip}
        id={id}>{placement}</Tooltip>}>
        {Element}
      </OverlayTrigger>
    </>
  )
}

export default CustomTooltip