import { useCallback, useEffect, useRef, useState } from "react";
import { GetImages } from "../../../utils/GetImages";
import { IntegrationsService } from "@propertelligent/client-api";

const AddressAutocomplete = ({ setPropertyvalue, propertyValue, required = true, showSelectedValue = true }) => {
  const searchInput = useRef<HTMLInputElement>(null);
  const [apiKey, setApiKey] = useState("");

  useEffect(() => {
    IntegrationsService.postApiIntegrationsKeysGooglemaps()
      .then((res) => {
        setApiKey(res.success);
      })
      .catch((error) => {
        console.error("API error:", error);
      });
  }, []);

  // functions start
  const loadAsyncScript = useCallback(
    (src: string): Promise<HTMLScriptElement> => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        Object.assign(script, {
          type: "text/javascript",
          async: true,
          src,
        });
        script.addEventListener("load", () => resolve(script));
        document.head.appendChild(script);
      });
    },
    []
  );

  const extractAddress = (place: any) => {
    const address = {
      streetAddress: "",
      city: "",
      state: "",
      zip: "",
    };

    if (!Array.isArray(place?.address_components)) {
    }

    place.address_components.forEach((component: any) => {
      const types = component.types;
      const value = component.long_name;
      ``;
      if (types.includes("street_number")) {
        address.streetAddress = value;
      }

      if (types.includes("route")) {
        address.streetAddress += " " + value;
      }

      if (types.includes("locality")) {
        address.city = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }

      if (types.includes("postal_code")) {
        address.zip = value;
      }
    });

    return address;
  };

  const initMapScript = () => {
    if (window.google) {
      return Promise.resolve();
    }
    const src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  const onChangeAddress = (autocomplete: google.maps.places.Autocomplete) => {
    const place = autocomplete.getPlace();

    setPropertyvalue({ ...propertyValue, address: extractAddress(place) });
  };

  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );

    autocomplete.setFields(["address_component", "geometry"]);

    autocomplete.addListener("place_changed", () => {
      console.log("1");
      onChangeAddress(autocomplete)
      console.log("2");
    }
    );
  };

  useEffect(() => {
    if (apiKey) {
      initMapScript().then(() => initAutocomplete());
    }
  }, [apiKey]);

  return (
    <div className="AddressAutocomplete">
      <div className="search">
        <img
          src={GetImages.Search}
          alt={GetImages.Search}
          className="iconstyle24"
        />

        <input
          className="sub_heading fw-normal grey_subtext1"
          required={required}
          ref={searchInput}
          type="text"
          placeholder="Enter Address...."
        />
        {searchInput?.current?.value ? (
          <button
            onClick={() => {
              searchInput.current.value = "";
              setPropertyvalue({
                ...propertyValue,
                address: { city: "", state: "", zip: "", streetAddress: "" },
              });
            }}
          >
            <img src={GetImages.closeIconBlack} className="iconstyle24" />
          </button>
        ) : (
          ""
        )}
      </div>
      {showSelectedValue &&
        searchInput?.current?.value ? (
        <div className="address">
          <p className="showaddress">
            City: <span>{propertyValue?.address?.city}</span>
          </p>
          <p className="showaddress">
            State: <span>{propertyValue?.address?.state}</span>
          </p>
          <p>
            Zip: <span>{propertyValue?.address?.zip}</span>
          </p>
        </div>
      ) : (
        ""
      )
      }
    </div>
  );
};

export default AddressAutocomplete;
