import {
  electrical,
  plumbing,
  appliance,
  exterior,
  interior,
  other1,
  insurance,
  payroll,
  tax,
  violation
} from "../../assets";

const ServiceIcon = ({ serviceType }) => {
  const serviceTypeToImagePath = {
    Plumbing: plumbing,
    Appliance: appliance,
    Other: other1,
    Exterior: exterior,
    Interior: interior,
    Electrical: electrical,
    Insurance: insurance,
    Payroll: payroll,
    Tax: tax,
    Violation: violation

  };
  const imagePath = serviceTypeToImagePath[serviceType];
  const path = imagePath ? imagePath : other1;

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        minWidth: 40,
        minHeight: 40,
        background: "#FEECDC",
        borderRadius: "50%",
      }}
    >
      <img
        src={path}
        alt={`${serviceType} icon`}
        style={{ width: "20px", height: "20px", flexShrink: 0 }}
      />
    </div>
  );
};

export default ServiceIcon;


// import {
//   electrical,
//   plumbing,
//   appliance,
//   exterior,
//   interior,
//   other1,
//   insurance,
//   payroll,
//   tax,
//   violation
// } from "../../assets";

// const ServiceTypeEnumMapping = {
//   1: 'Organization',
//   2: 'Tenant',
//   3: 'OrganizationUser',
//   4: 'Contractor',
//   5: 'Utility',
//   6: 'Tax',
//   7: 'Insurance',
//   8: 'BankAccount',
//   9: 'CreditCard',
//   10: 'Legal',
//   11: 'Community',
//   12: 'Vendor',
//   999: 'Other',
// };

// const ServiceIcon = ({ serviceType }) => {

//   const payeeAccountType = ServiceTypeEnumMapping[serviceType] || 'Other';


//   const serviceTypeToImagePath = {
//     Plumbing: plumbing,
//     Appliance: appliance,
//     Other: other1,
//     Exterior: exterior,
//     Interior: interior,
//     Electrical: electrical,
//     Insurance: insurance,
//     Payroll: payroll,
//     Tax: tax,
//     Violation: violation

//   };
//   const imagePath = serviceTypeToImagePath[payeeAccountType];
//   const path = imagePath ? imagePath : other1;
//   return (
//     <div
//       className="d-flex align-items-center justify-content-center"
//       style={{
//         width: 40,
//         height: 40,
//         background: "#FEECDC",
//         borderRadius: "50%",
//       }}
//     >
//       <img
//         src={path}
//         alt={`${serviceType} icon`}
//         style={{ width: "20px", height: "20px", flexShrink: 0 }}
//       />
//     </div>
//   );
// };

// export default ServiceIcon;
