import React, { useState } from "react";
import { usePaginationHook } from "../../hooks/usePaginationHook";
import { Table, Button, Row, Col } from "react-bootstrap";
import { PayDueAccountStatusEnum, PaymentDueAccountVM, PaymentService } from "@propertelligent/client-api";
import { UserLoginData } from "./charts/Enums";

type Props = {
  id: string;
  entityTypeId: number;
  paymentMethodsArr?: any[];
  payeeAccountsArr: any[];
  payMethodField: string;
  httpFetchDataFn: any;
  setRefreshList?: (value: number) => void;
};
const EntityPaymentAccountList = React.memo(
  ({
    id,
    entityTypeId,
    payMethodField,
    paymentMethodsArr,
    payeeAccountsArr,
    httpFetchDataFn,
    setRefreshList,
  }: Props) => {
    const [open, setOpen] = useState(false);
    const [openPayModal, setOpenPayModal] = useState(false);
    const [dueAccountId, setDueAccountId] = useState(-1);


    const { rows, fetchData, } = usePaginationHook({
      httpFetchDataFn: httpFetchDataFn,
      paginationDataMapper: null,
      parentEntityId: Number(id),
      isDependentEntity: true,
    });

    const handleSubmitPaymentAccountRecord = (values: PaymentDueAccountVM) => {
      const organizationId = parseInt(
        localStorage.getItem(UserLoginData.organization_id)
      );
      Object.assign(values, {
        organizationId,
        entityTypeId: entityTypeId,
        paymentDueAccountStatusId: PayDueAccountStatusEnum.Due,
        dueDate: values?.dueDate + ":00Z",
      });

      PaymentService.postApiPaymentDueAccount(values)
        .then((res: any) => {
          if (res?.success) {
            setOpen(false);
            setRefreshList && setRefreshList(Math.random());
            fetchData();
          }
        })
        .catch((error) => { });
    };

    const handleSubmitPayPaymentDueAccount = (values: any) => {
      if (dueAccountId > 0) {
        Object.assign(values, {
          payDueAccountId: dueAccountId,
        });

        PaymentService.putApiPaymentDueAccountPay(values)
          .then((res: any) => {
            if (res?.success) {
              setOpenPayModal(false);
              setDueAccountId(-1);
            }
          })
          .catch((error) => { });
      }
    };

    return (
      <>
        <Row className="mb-3">
          <Col xs={12} md={8}>
            <h2 className="mb-0">Payment Due Account</h2>
          </Col>
          <Col xs={12} md={4} className="text-md-end">
            <Button variant="primary" onClick={() => setOpen(true)}>
              Add Payment
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Due Date</th>
                  <th>Category</th>
                  <th>Amount</th>
                  <th>From/to</th>
                  <th>Payment Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr key={row.id}>
                    <td>{row?.dueDate}</td>
                    <td>{row?.formatted?.PaymentTypeIds[0]}</td>
                    <td>{row?.amount}</td>
                    <td>{row?.payerPayeeName}</td>
                    <td>{row.formatted.PaymentTypeIds}</td>
                    <td>
                      <Button
                        variant="info"
                        onClick={() => {
                          setDueAccountId(row.id);
                          setOpenPayModal(true);
                        }}
                      >
                        {row?.isDueToOrganization ? "Pay" : "Send reminder"}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    );
  }
);

export default EntityPaymentAccountList;
