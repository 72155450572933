import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { GetImages } from "../../utils/GetImages";
import DisplayNamewithLeftBorder from "../common/DisplayNamewithLeftBorder";
import { Col, Form, Offcanvas, Row } from "react-bootstrap";
import CustomInputs from "../common/Inputs";
import AddButtonWithArrow from "../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import AddTraspButton from "../firstTimeUserExperience/commonCom/AddTraspButton";
import { FileUploader } from "react-drag-drop-files";
import apiHelper from "../../utils/apiHelper";
import { DocumentEntityTypesEnum, DocumentService, DocumentTypesEnum, EntityType, PaidFlowTypesEnum, PayeeAccountTypesEnum, PaymentMethodTypesEnum, PaymentService, PropertyService, TenantService, UnitService } from "@propertelligent/client-api";
import { UserLoginData } from "../common/charts/Enums";
import { useError } from "../../utils/context/ErrorContext";
import DynamicAddMoreForm from "../common/DynamicAddMoreForm";
import LastTransaction from "./Modals/LastTransaction";
import GenericSearchAbleDropdown from "../common/GenericSearchAbleDropdown";
import DateHelper from "../../utils/DateHelper";
import { useSearchParams } from "react-router-dom";
import { PaymentContext } from "../../utils/context/PaymentContext";
import { getTenantList } from "../../redux/actions/unitAction";
import AddEditPayeeAccount from "../my-organization/PayeeAccounts/AddEditPayeeAccount";

const AddPaymentForm = () => {
  const [paymentMode, setPaymentMode] = useState("Payables");
  const [show, setShow] = useState(false);
  const [transactionModal, setTransactionModal] = useState(false);
  const [duePaymentModal, setDuePaymentModal] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState("");
  const [propertyData, setPropertyData] = useState<any>([]);
  const [unitData, setUnitData] = useState<any>([]);
  const [tenantData, setTenantData] = useState<any>([]);
  const [payeeAccountId, setPayeeAccountId] = useState("");
  const { errors, setErrors, errorMessages, setErrorMessages, clearErrors } = useError();
  const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
  const [fileName, setFileName] = useState<any>([]);
  const [uploadDocument, setUploadDocument] = useState<any>(null);
  const [selectEntityTypeId, setSelectEntityTypeId] = useState<any>();
  const [refrenceNum, setRefrenceNum] = useState("")
  const [paidFlowTypeId, setPaidFlowTypeId] = useState(undefined)
  const [payeeAccountTypeId, setPayeeAccountTypeId] = useState()
  const { id } = useParams()
  const [searchParams] = useSearchParams();
  const [initialOptions, setInitialOptions] = useState([])
  const { setSelectedItems, setSelectedOption, selectEntityId, setSelectEntityId, setPayeeAccountsArr2, setSelectedOption2 } = useContext(PaymentContext);

  const navigate = useNavigate();
  const accountOptions = [
    {
      name: "Organization"
    },
    {
      name: "Property"
    },
    {
      name: "Unit"
    },
  ];
  const headers = ["Payment Type", "Amount ($)", "Due Date", "Grace Period", "Description", ""]
  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
  const [formData, setFormData] = useState<any>([
    {
      id: 1,
      dueDate: "",
      paymentType: '',
      amount: undefined,
      gracePeriods: undefined,
      description: "",
    },
  ]);

  const getPaymentDueAccount = () => {
    apiHelper(PaymentService.getApiPaymentDueAccount, { showNotification: false }, id)
      .then((res) => {
        const successData = res?.data?.success;
        if (successData) {
          setFormData([
            {
              ...formData,
              id: successData?.id,
              dueDate: DateHelper.convertUTCtoDateTime2(successData?.dueDate),
              amount: successData?.amount,
              gracePeriods: successData?.gracePeriodInDays,
              description: successData?.description,
              paymentType: successData?.paymentTypeId,
            }
          ])
          setPayeeAccountTypeId(successData?.payeeAccountTypeId)
          setPayeeAccountId(successData?.payeeAccountId)
          setSelectEntityId(successData?.entityId)
          setPaidFlowTypeId(successData?.paidFlowTypeId)
          setSelectEntityTypeId(successData?.entityTypeId)
        }
        if (successData?.referenceNumber === "") {
          setRefrenceNum("")
        } else {
          setRefrenceNum(successData?.referenceNumber)
        }
        // setPaymentTypeId(successData?.paymentTypeId)
        setSelectedAttachment(successData?.formatted?.EntityTypeId)
        setPayeeAccountId(successData?.payeeAccountId)
        if (successData?.formatted?.PaidFlowTypeId === "Payable") {
          setPaidFlowTypeId(1)
        } else {
          setPaidFlowTypeId(2)
        }
      }).catch((err) => {
      })
  }

  useEffect(() => {
    if (id) {
      getPaymentDueAccount()
    }
  }, [id]);

  const getTenantsData = async () => {
    try {
      const res = await apiHelper(TenantService.getApiTenantOrganizationlookup, { showNotification: false }, orgId);
      setTenantData(res?.data?.success);
    } catch (error) {
    }
  };

  const handleClose = () => {
    setShow(false);
    setTransactionModal(false)
    setDuePaymentModal(false)
  };

  useEffect(() => {
    if (paidFlowTypeId === 1) {
      setPaymentMode("Payables")
    } else if (paidFlowTypeId === 2 && selectEntityTypeId === EntityType.Tenant) {
      setPaymentMode("Receivables")
    }
  }, [id, paidFlowTypeId])

  useEffect(() => {
    if (selectEntityId === undefined) {
      setSelectEntityId("")
      setSelectEntityTypeId("")
    }
  }, [selectEntityId])

  const handleSubmitPaymentAccountRecord = async (value: string = "save") => {
    if (checkEmptyFields()) {
    } else if (id) {
      formData.map((item, index: any) => {
        const updatedValues = {
          id: Number(id),
          amount: Number(formData[index].amount),
          description: formData[index].description,
          dueDate: new Date(formData[index].dueDate).toISOString(),
          entityId: Number(selectEntityId),
          entityTypeId: Number(selectEntityTypeId),
          payeeAccountId: Number(payeeAccountId) || 0,
          gracePeriodInDays: Number(formData[index].gracePeriods),
          referenceNumber: refrenceNum,
          paymentTypeId: Number(formData[index].paymentType),
          paidFlowTypeId: paidFlowTypeId,
          organizationId: 507
        };
        apiHelper(
          PaymentService.putApiPaymentDueAccount,
          {
            successMessage: "Payment updated successfully",
            failureMessage: "Unable to update payment",
            showNotification: true,
          },
          updatedValues
        )
          .then((res: any) => {
            if (res?.data?.success) {
              if (uploadDocument) {
                const docData = {
                  EntityTypeId: DocumentEntityTypesEnum.Payment,
                  EntityId: res?.data?.success?.id,
                  DocumentTypeId: DocumentTypesEnum.PaymentReference,
                  IsMain: true,
                  FileName: fileName,
                  Document: uploadDocument,
                };

                apiHelper(DocumentService.postApiDocument, { showNotification: false }, docData)
                  .then((res) => {
                  })
                  .catch((error) => { });
              }
            }
            setSelectedAttachment(" ")
            setRefrenceNum("")
            setUploadDocument(null)
          })
          .catch((error) => { });
      })
    } else if (paymentMode === "Receivables") {
      await apiHelper(
        PaymentService.getApiPayeeAccountOrganizationlist,
        { showNotification: false },
        orgId, undefined, undefined, undefined, undefined, undefined, `IsOrganizationAccount==true`
      )
        .then((res: any) => {
          if (res?.data?.success) {
            setPayeeAccountsArr(res?.data?.success);
            setPayeeAccountId(res?.data?.success[0]?.id)
            formData.map((item, index: any) => {

              const updatedValues = {
                amount: Number(formData[index].amount),
                description: formData[index].description,
                dueDate: new Date(formData[index].dueDate).toISOString(),
                entityId: Number(selectEntityId),
                entityTypeId: Number(selectEntityTypeId),
                payeeAccountId: Number(res?.data?.success[index]?.id) || 0,
                gracePeriodInDays: Number(formData[index].gracePeriods),
                referenceNumber: refrenceNum,
                paymentTypeId: PayeeAccountTypesEnum.Tenant,
                paidFlowTypeId: PaidFlowTypesEnum.Receiveable
              };
              if (paymentMode === "Receivables") {
                delete updatedValues.referenceNumber
              }
              apiHelper(
                PaymentService.postApiPaymentDueAccount,
                {
                  successMessage: "Payment added successfully",
                  failureMessage: "Unable to add payment",
                  showNotification: true,
                },
                updatedValues
              )
                .then((res2: any) => {
                  if (res2?.data?.success) {
                    const responseData = Array.isArray(res2?.data?.success)
                      ? res2?.data?.success
                      : [res2?.data?.success];
                    setSelectedOption(prevSelectedOption => ({
                      ...prevSelectedOption,
                      ...responseData
                    }));
                    setPayeeAccountsArr2(prevSelectedOption => ({
                      ...prevSelectedOption,
                      ...responseData
                    }));
                    if (uploadDocument) {
                      const docData = {
                        EntityTypeId: DocumentEntityTypesEnum.Payment,
                        EntityId: res?.data?.success?.id,
                        DocumentTypeId: DocumentTypesEnum.PaymentReference,
                        IsMain: true,
                        FileName: fileName,
                        Document: uploadDocument,
                      };

                      apiHelper(DocumentService.postApiDocument, { showNotification: false }, docData)
                        .then((res) => {
                          if (res?.data?.success && formData.length == index + 1) {
                            if (value === "settle") {
                              navigate(`/payment/settle-payment/${res2?.data?.success?.payeeAccountId}`, { state: { selectedPayment: res2?.data?.success?.payeeAccountId, paymentMode } });
                            }
                          }
                        })
                        .catch((error) => { });
                    } else {
                      if (formData.length == index + 1) {
                        if (value === "settle") {
                          navigate(`/payment/settle-payment/${res2?.data?.success?.payeeAccountId}`, { state: { selectedPayment: res2?.data?.success?.payeeAccountId, paymentMode } });
                        }
                      }
                    }
                  }
                  setFormData([{
                    ...formData,
                    id: 1,
                    dueDate: "",
                    amount: undefined,
                    gracePeriods: undefined,
                    paymentType: "",
                    description: "",
                  }])
                  setSelectedAttachment("Organization")
                  setRefrenceNum("")
                  setUploadDocument(null)

                })
                .catch((error) => { });
            })
          }
        })
        .catch((error) => {
          JSON.stringify(error)
        });
    } else {
      formData.map((item, index: any) => {
        const updatedValues = {
          amount: Number(formData[index].amount),
          description: formData[index].description,
          dueDate: new Date(formData[index].dueDate).toISOString(),
          entityId: Number(selectEntityId),
          entityTypeId: Number(selectEntityTypeId),
          payeeAccountId: Number(payeeAccountId) || 0,
          gracePeriodInDays: Number(formData[index].gracePeriods),
          referenceNumber: refrenceNum,
          paymentTypeId: Number(formData[index].paymentType),
          paidFlowTypeId: PaidFlowTypesEnum.Payable
        };
        apiHelper(
          PaymentService.postApiPaymentDueAccount,
          {
            successMessage: "Payment added successfully",
            failureMessage: "Unable to add payment",
            showNotification: value === "save" ? true : false,
          },
          updatedValues
        )
          .then((res: any) => {
            if (res?.data?.success) {
              const responseData = Array.isArray(res?.data?.success)
                ? res?.data?.success
                : [res?.data?.success];
              setSelectedItems(prevSelectedItems => [
                ...prevSelectedItems,
                ...responseData
              ]);

              if (uploadDocument) {
                const docData = {
                  EntityTypeId: DocumentEntityTypesEnum.Payment,
                  EntityId: res?.data?.success?.id,
                  DocumentTypeId: DocumentTypesEnum.PaymentReference,
                  IsMain: true,
                  FileName: fileName,
                  Document: uploadDocument,
                };

                apiHelper(DocumentService.postApiDocument, { showNotification: false }, docData)
                  .then((res) => {
                    if (res?.data?.success && formData.length == index + 1) {
                      if (value === "settle") {
                        navigate(`/payment/settle-payment/${payeeAccountId}`, { state: { selectedPayment: payeeAccountId, paymentMode } });
                      }
                    }
                  })
                  .catch((error) => { });
              } else {
                if (formData.length == index + 1) {
                  if (value === "settle") {
                    navigate(`/payment/settle-payment/${payeeAccountId}`, { state: { selectedPayment: payeeAccountId, paymentMode } });
                  }
                }
              }
            }
            setFormData({
              ...formData,
              id: 1,
              dueDate: "",
              amount: 0,
              gracePeriods: 0,
              description: "",
              paymentType: ""
            });
            setSelectedAttachment(" ")
            setRefrenceNum("")
            setUploadDocument(null)
          })
          .catch((error) => { });
      })
    }
  };

  useEffect(() => {
    getPayeeList();
    getTenantsData();
    const entityId = searchParams.get("entityId");
    const entityTypeId = searchParams.get("entityTypeId");
    setSelectEntityId(entityId)
    setSelectEntityTypeId(entityTypeId)
  }, []);

  const getPayeeList = async () => {
    let FullyQualifiedFilters: `IsOrganizationAccount==true`
    await apiHelper(
      PaymentService.getApiPayeeAccountOrganizationlist,
      { showNotification: false },
      orgId, undefined, undefined, undefined, undefined, undefined, FullyQualifiedFilters
    )
      .then((res: any) => {
        if (res?.data?.success) {
          setPayeeAccountsArr(res?.data?.success);
        }
      })
      .catch((error) => { });
  };

  const getPropertyList = async () => {
    await apiHelper(PropertyService.getApiPropertyOrganizationlookup, { showNotification: false }, orgId)
      .then((res) => {
        setPropertyData(res?.data?.success);
        if (EntityType.Property === Number(selectEntityTypeId)) {
          setSelectedAttachment("Property")
        }
      })
      .catch((error) => { });
  };

  const getUnitList = async () => {
    await apiHelper(UnitService.getApiUnitOrganizationlookup, { showNotification: false }, orgId)
      .then((res) => {
        setUnitData(res?.data?.success);
        if (EntityType.Unit === Number(selectEntityTypeId)) {
          setSelectedAttachment("Unit")
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    getPropertyList();
    getUnitList();
    getTenantList()
  }, [selectEntityId, selectEntityTypeId])

  const handleAddPayment = () => {
    setFormData([
      ...formData,
      {
        id: formData.length + 1,
        amount: undefined,
        dueDate: '',
        gracePeriods: undefined,
        description: '',
        paymentType: "",
      },
    ]);
  };

  const handleReferenceNumberChange = (e) => {
    setRefrenceNum(e.target.value)
    setErrors({ ...errors, refrenceNum: false })
  };

  const handleImageChange = (selectedFile: File | null) => {
    if (!selectedFile || !["image/png", "image/jpg", "application/pdf"].includes(selectedFile.type)) {
      setErrors({ ...errors, FileType: true })
      return;
    }

    // const maxSize = 5 * 1024 * 1024;
    // if (selectedFile.size > maxSize) {
    //   setErrors({ ...errors, FileType: false, FileSize: true })
    //   return;
    // }

    setErrors({ ...errors, leaseApplication: false, FileType: false, FileSize: false });

    if (["jpg", "png", "jpeg"].includes(selectedFile?.name.split(".")[1])) {
      setFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadDocument(blob);
    } else {
      setFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadDocument(blob);
    }
  };

  const checkEmptyFields = (): boolean => {
    clearErrors();
    const newErrors = { ...errors };
    const newErrorMessages = { ...errorMessages };
    let hasErrors = false;
    formData.forEach((payment, index) => {
      if (!payment?.dueDate) {
        newErrors[`dueDate-${index}`] = true;
        newErrorMessages[`dueDate-${index}`] = "Due date is required";
        hasErrors = true;
      } else {
        newErrors[`dueDate-${index}`] = false;
      }

      if (!payment.paymentType) {
        newErrors[`paymentType-${index}`] = true;
        newErrorMessages[`paymentType-${index}`] = "Payment Type is required";
        hasErrors = true;
      } else {
        newErrors[`paymentType-${index}`] = false;
      }

      if (!payment.amount) {
        newErrors[`amount-${index}`] = true;
        newErrorMessages[`amount-${index}`] = "Amount is required";
        hasErrors = true;
      } else {
        newErrors[`amount-${index}`] = false;
      }

      if (!payment.description) {
        newErrors[`description-${index}`] = true;
        newErrorMessages[`description-${index}`] = "Description is required";
        hasErrors = true;
      } else {
        newErrors[`description-${index}`] = false;
      }
    });

    if (selectedAttachment === "" && paymentMode === "Payables") {
      newErrors.attachment = true;
      hasErrors = true;
    } else {
      newErrors.attachment = false;
    }

    if (selectEntityId === undefined || selectEntityId === "") {
      if (selectEntityTypeId === EntityType.Tenant || !selectedAttachment) {
        newErrors.tenant = true;
      } else if (selectEntityTypeId === EntityType.Property || selectedAttachment === "Property") {
        newErrors.property = true;
      } else if (selectEntityTypeId === EntityType.Unit || selectedAttachment === "Unit") {
        newErrors.unit = true;
      }
      hasErrors = true
    }
    if (payeeAccountId === "") {
      newErrors.payee = true;
      newErrorMessages.payee = "Please select Payee Account"
    }
    setErrors(newErrors);
    setErrorMessages(newErrorMessages);
    return hasErrors;
  };

  useEffect(() => {
    if (selectedAttachment === "Organization") {
      setSelectEntityId(orgId)
      setSelectEntityTypeId(EntityType.Organization)
    }
  }, [selectedAttachment])

  useEffect(() => {
    if (checkEmptyFields()) {
      clearErrors()
    }
  }, [paymentMode])

  const handleDeleteDocument = () => {
    // setMainImage(null);
    setUploadDocument(null);
  };

  useEffect(() => {
  }, [selectEntityId]);

  useEffect(() => {
    if (paymentMode !== "Payables") {
      apiHelper(PaymentService.getApiPayeeAccountPaymentTypes, { showNotification: false }, PayeeAccountTypesEnum.Tenant)
        .then((res) => {
          if (res?.data?.success) {
            setInitialOptions(res?.data?.success)
          }
        }).catch((err) => { })
    }
    else if (paymentMode === "Payables") {
      apiHelper(PaymentService.getApiPayeeAccountPaymentTypes, { showNotification: false }, payeeAccountTypeId)
        .then((res) => {
          if (res?.data?.success) {
            setInitialOptions(res?.data?.success)
          }
        }).catch((err) => { })
    }
  }, [paymentMode, payeeAccountId, payeeAccountTypeId])

  // useEffect(() => {
  //   if (paymentMode === "Payables") {
  //     apiHelper(PaymentService.getApiPayeeAccountPaymentTypes, { showNotification: false }, payeeAccountTypeId)
  //       .then((res) => {
  //         if (res?.data?.success) {
  //           setInitialOptions(res?.data?.success)
  //         }
  //       }).catch((err) => { })
  //   }
  // }, [payeeAccountId, payeeAccountTypeId])

  return (
    <>
      <div className="addPayment">
        <div className="newTenantFormTopBar d-flex align-items-center">
          <img
            onClick={() => { navigate(-1) }}
            height={28}
            width={28}
            src={GetImages.leftChevronBlack}
            className="clickable"
          />
          <p className="main_heading black">{id ? "Edit Payment" : "Add Payment"}</p>
        </div>

        <DisplayNamewithLeftBorder
          HeaderName="BASIC DETAIL"
          className="sub_text ps-1"
        />
        {
          !id &&
          <div className="monthhead pt-3">
            <button
              onClick={() => setPaymentMode("Payables")}
              className={
                paymentMode === "Payables"
                  ? "overviewSelectedButton normal_text fw-500 black"
                  : "overviewUnselectedButton normal_text fw-500 black"
              }
            >
              <img src={GetImages.Payables} alt="payables" />
              Payables
            </button>
            <button
              onClick={() => setPaymentMode("Receivables")}
              className={
                paymentMode === "Receivables"
                  ? "overviewSelectedButton normal_text fw-500 black"
                  : "overviewUnselectedButton normal_text fw-500 black"
              }
            >
              <img src={GetImages.Receivables} alt="Receivables" />
              Receivables
            </button>
          </div>
        }
        <div className="settlePaymentLeft">
          <Form className="paymentForm">
            {paymentMode === "Payables" && (
              <Row className="paymentinputrow p-0">
                <div className="d-flex flex-column">
                  <div className="selectPayee">
                    <div>
                      <Col className="paymentinputCol p-0">
                        <Form.Label className="normal_text">
                          Select Payee*
                        </Form.Label>
                        <Form.Group className="mb-2">
                          <GenericSearchAbleDropdown
                            fieldName={"accountName"}
                            initialOptions={payeeAccountsArr}
                            name={"accountName"}
                            isFormControl={true}
                            value={payeeAccountId}
                            onChange={(e) => {
                              const selectedAccountId = e.target.value;
                              const selectedAccount = payeeAccountsArr.find(account => account.id === parseInt(selectedAccountId, 10));

                              setPayeeAccountId(selectedAccountId);
                              setPayeeAccountTypeId(selectedAccount?.payeeAccountTypeId || null);

                              // setPayeeAccountId(e.target.value)

                              setErrors({ ...errors, payee: false })
                            }}
                            placeholder="Select Payee"
                            myClassName="normal_text"
                            style={{ height: "46px", border: errors.payee ? "1px solid #F05252" : "1px solid #e5e7eb" }}
                            isError={errors.payee}
                            errorMessage={errorMessages.payee}
                          />
                        </Form.Group>
                      </Col>
                    </div>
                    {
                      payeeAccountId && payeeAccountId !== "" ?
                        <>
                          <div className="transactionBtn">
                            <AddTraspButton bname="View Last 5 Transactions" border={true} type="button" onClick={() => { setTransactionModal(true) }} />
                            <AddTraspButton bname="View All Dues" border={true} type="button" onClick={() => { setDuePaymentModal(true) }} />
                          </div>
                        </>
                        : ""
                    }
                    {transactionModal &&
                      <LastTransaction
                        onClose={handleClose}
                        heading={"Last 5 Transactions"}
                        show={true}
                        payeeAccountId={payeeAccountId}
                        duePayment={false}
                      />
                    }
                    {duePaymentModal &&
                      <LastTransaction
                        payeeAccountId={payeeAccountId}
                        onClose={handleClose}
                        heading={"Due Payments"}
                        show={true}
                        duePayment={true} />
                    }

                  </div>

                  <Form.Label className="sub_text  grey_subtext1 mb-0">
                    Can’t see the payee? To create new,{" "}
                    <span className="navigateMe" onClick={() => setShow(true)}>
                      {" "}
                      click here
                    </span>
                    {/* {show ? (
                      <AddPayee
                        onClose={handleClose}
                        heading="Add Payee"
                        show={true}
                      />
                    ) : (
                      ""
                    )} */}
                  </Form.Label>
                  <Offcanvas
                    className="editTanentOffcanvas"
                    show={show}
                    onHide={() => setShow(false)}
                    placement={"end"}
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>Add Payee</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <AddEditPayeeAccount
                        setShowModal={setShow}
                        fetchData={getPayeeList}
                        setPayeeAccountId={setPayeeAccountId}
                      />
                    </Offcanvas.Body>
                  </Offcanvas>
                </div>
              </Row>
            )}

            <div>
              <Row className="paymentinputrow d-flex p-0" style={{ alignItems: errors.FileType || errors.attachment ? "baseline" : "center" }}>
                {
                  paymentMode === "Payables" && (
                    <Col className="paymentinputCol p-0 ol-xl-3 col-md-6 col-12 c">
                      <Form.Group controlId="paymentMethodSelect">
                        <Form.Label className="normal_text black">
                          Attach Payment to
                        </Form.Label>
                        <GenericSearchAbleDropdown
                          fieldName={"name"}
                          initialOptions={accountOptions}
                          name={"accountOptions"}
                          isFormControl={true}
                          value={selectedAttachment}
                          onChange={(e) => {
                            setSelectedAttachment(e.target.value)
                            setErrors({ ...errors, attachment: false })
                          }}
                          placeholder=" Attach Payment to"
                          myClassName="normal_text"
                          style={{ height: "46px", border: errors.attachment ? "1px solid #F05252" : "1px solid #e5e7eb" }}
                          isError={errors.attachment}
                          errorMessage={errorMessages.attachment}
                        />
                      </Form.Group>
                    </Col>
                  )
                }

                {paymentMode === "Receivables" && (
                  <Col className="paymentinputCol ol-xl-3 col-md-6 col-12 c">
                    <Form.Group className="mb-2">
                      <Form.Label className="normal_text  grey_subtext1">
                        Select  Tenant
                      </Form.Label>
                      <GenericSearchAbleDropdown
                        fieldName={"name"}
                        initialOptions={tenantData}
                        name={"lastName"}
                        isFormControl={true}
                        value={selectEntityId}
                        onChange={(e) => {
                          setSelectEntityId(e.target.value);
                          sessionStorage.setItem("tenantId", selectEntityId)
                          setSelectEntityTypeId(EntityType.Tenant)
                          setErrors({ ...errors, tenant: false })
                        }}
                        placeholder="Select Tenant"
                        myClassName="normal_text"
                        style={{ height: "46px" }}
                        isError={errors.tenant}
                        errorMessage={errorMessages.tenant}
                      />
                    </Form.Group>
                  </Col>
                )}

                {selectedAttachment === "Property" && paymentMode !== "Receivables" ? (
                  <Col className="paymentinputCol col-xl-3 col-md-6 col-12" >
                    <Form.Group controlId="paymentMethodSelect">
                      <Form.Label className="normal_text black">
                        Select Property
                      </Form.Label>
                      <GenericSearchAbleDropdown
                        fieldName={"name"}
                        initialOptions={propertyData}
                        name={"propertyData"}
                        isFormControl={true}
                        value={selectEntityId}
                        onChange={(e) => {
                          setSelectEntityId(e.target.value);
                          setErrors({ ...errors, property: false })
                          setSelectEntityTypeId(EntityType.Property)
                        }}
                        placeholder="Select Property"
                        myClassName="normal_text"
                        style={{ height: "46px" }}
                        isError={errors.property}
                        errorMessage={errorMessages.property}
                      />
                    </Form.Group>
                  </Col>
                ) : ""}

                {selectedAttachment === "Unit" && paymentMode !== "Receivables" ? (
                  <Col className="paymentinputCol ol-xl-3 col-md-6 col-12 c">
                    <Form.Group controlId="paymentMethodSelect">
                      <Form.Label className="normal_text black">
                        Select Unit
                      </Form.Label>
                      <GenericSearchAbleDropdown
                        fieldName={"name"}
                        initialOptions={unitData}
                        name={"unitData"}
                        isFormControl={true}
                        value={selectEntityId}
                        onChange={(e) => {
                          setSelectEntityId(e.target.value);
                          setErrors({ ...errors, unit: false })
                          setSelectEntityTypeId(EntityType.Unit)
                        }}
                        placeholder="Select Unit"
                        myClassName="normal_text"
                        style={{ height: "46px" }}
                        isError={errors.unit}
                        errorMessage={errorMessages.unit}
                      // onClick={getUnitList}
                      />
                    </Form.Group>
                  </Col>
                ) : ""}

                <Col className="paymentinputCol px-3 ol-xl-3 col-md-6 col-12 c">
                  <div
                    className="d-flex flex-column justify-content-center w-100"
                    style={{ maxWidth: "240px", marginBottom: "11px" }}
                  >
                    {
                      !uploadDocument &&
                      <>
                        <div className="labelContainer">
                          <p className="normal_text black mb-2">Upload Document</p>
                        </div>

                        <FileUploader
                          classes="documentUploaderContractor w-100"
                          handleChange={handleImageChange}
                          children={
                            <>
                              <div className="fileUploaderTenantDocument normal_text w-100">
                                <img
                                  width={24}
                                  height={24}
                                  src={GetImages.upload}
                                />
                                <p>
                                  Drag & Drop or{" "}
                                  <span className="normal_text blue"> Browse</span>
                                </p>
                              </div>
                            </>
                          }
                        />
                      </>
                    }
                    {errors.document && (
                      <div style={{ marginTop: "8px" }}>
                        <p className="errorMessageStyles">{errorMessages.document}</p>
                      </div>
                    )}
                    {(errors.FileType) && (
                      <div style={{ margin: "8px 0px" }}>
                        {errors.FileType && (
                          <p className="errorMessageStyles">{errorMessages.FileType}</p>
                        )}
                        {errors.FileSize && (
                          <p className="errorMessageStyles">{errorMessages.FileSize}</p>
                        )}
                      </div>
                    )}
                  </div>
                  {uploadDocument && (
                    <div className="d-flex justify-content-between documentPreview" style={{ marginTop: "28px" }}>
                      <div className="d-flex gap-1">
                        <img width={21} height={24} src={GetImages.pdfImage} />
                        <p className="normal_text grey_subtext1">{fileName ? fileName : "Document"}</p>
                      </div>
                      <div onClick={handleDeleteDocument}>
                        <img width={20} height={20} src={GetImages.deleteIconRed} />
                      </div>

                    </div>
                  )}
                </Col>

                {paymentMode === "Payables" && (
                  <Col className="paymentinputCol col-xl-3 col-md-6 col- " style12={{ padding: "0 10px" }}>
                    <Form.Group controlId="payeeAccountSelect">
                      <CustomInputs
                        myClassName="mb-0"
                        labelText="Reference No."
                        isFormControl={true}
                        placeholder="Enter Reference No."
                        name="refrenceNo"
                        value={refrenceNum}
                        onChange={handleReferenceNumberChange}
                        isError={errors.refrenceNum}
                        errorMessages={errorMessages.refrenceNum}
                        InputsWrapperClass="mb-0"
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>
            </div>

            <div className="rentLedgerContainer">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-baseline gap-2">
                  <DisplayNamewithLeftBorder
                    HeaderName="PAYMENT DETAILS"
                    className="sub_text black mb-3"
                  />
                </div>
              </div>

              <DynamicAddMoreForm
                id={id}
                headers={headers}
                data={formData}
                setData={setFormData}
                initialOptions={initialOptions}
                handleAddPayment={handleAddPayment}
              />
            </div>

            {
              !!id ? (
                <>
                  <Row className="paymentinputrow p-0">
                    <Col>
                      <div className="tenantFormFooter">
                        <div className="footerRightButtons d-flex ">
                          <AddTraspButton
                            type="button"
                            bname={"Cancel"}
                            onClick={() => navigate("/payment")}
                            border={false}
                            width="fit-content"
                          />
                          <AddButtonWithArrow
                            type="button"
                            buttonname={"Save Changes"}
                            onClick={handleSubmitPaymentAccountRecord}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row className="paymentinputrow p-0">
                    <Col>
                      <div className="tenantFormFooter">
                        <div className="footerRightButtons d-flex justify-content-between">
                          <AddTraspButton
                            type="button"
                            bname={"Save Payment"}
                            onClick={handleSubmitPaymentAccountRecord}
                            border={true}
                          />
                          <AddButtonWithArrow
                            type="button"
                            buttonname={"Settle Payment"}
                            onClick={() => { handleSubmitPaymentAccountRecord("settle") }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )
            }
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddPaymentForm;

