import React, { useState } from "react";
import { Card, Form } from "react-bootstrap";
import CustomInputs from "../../common/Inputs";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";

const FeeTemplete = ({ postData, setPostData, }) => {

  return (
    <>
      <div className="edit-card feeCard gap-4">
        <p className="sub_heading">Application Fees</p>
        <div className="flexColumn gap-3">
          <CustomInputs
            name={undefined}
            type={FORM_CONTROLFIELD_TYPES?.TEXT}
            value={postData?.leaseApplicationTemplateSections?.ApplicationFees?.fee}
            labelText={"Application Fee*"}
            isFormControl
            onChange={
              (e) => {
                setPostData((prev) => ({
                  ...prev, leaseApplicationTemplateSections: {
                    ...prev?.leaseApplicationTemplateSections,
                    ApplicationFees: {
                      ...prev.leaseApplicationTemplateSections.ApplicationFees,
                      fee: Number(e.target.value),
                    },
                  }
                }))

              }
            }
            disabled={undefined}
            placeholder={undefined}
            myClassName={undefined}
            isError={undefined}
            errorMessages={undefined}
            width={"322px"}
          />
          <p className="normal_text black"> Application Fee Clause</p>
          <Form.Control
            as="textarea"
            rows={3}
            value={postData?.leaseApplicationTemplateSections?.ApplicationFees?.clause}

            onChange={(e) => {
              setPostData({
                ...postData,
                leaseApplicationTemplateSections: { ...postData.leaseApplicationTemplateSections, ApplicationFees: { ...postData?.leaseApplicationTemplateSections?.ApplicationFees, clause: e.target.value } }

              });
            }}
            placeholder="This fee is non-refundable and will be used to process your application for background checks, income verification, and credit report"
          />

        </div>
      </div>
    </>
  );
};

export default FeeTemplete;
