import { LeaseApplicationMoveOutReasonTypesEnum, LeaseApplicationReferenceTypesEnum } from "@propertelligent/client-api";

export const debouncedFunction = (
  fn: (...args: any[]) => void,
  delay: number
) => {
  let timer: string | number | NodeJS.Timeout;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(fn, delay);
  };
};

export const mapArrayFields = (entityArr: any[], fieldName: string) => {
  entityArr?.map((item) => {
    item["label"] = item?.[fieldName];
    item["value"] = item?.id;
  });
  return entityArr;
};

export const insertUniqKeyInArr = (rows: any[]) => {
  rows?.map((item, index) => Object.assign(item, { id: index }));
  return rows;
};

export const isValidPositiveNumber = (val: any): number | null => {
  return Number(val) && Number(val) > 0 ? Number(val) : null;
};

export const convertStreamAndDownload = (stream: string, fileName: string) => {
  let byteCharacters = atob(stream);
  let byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  let byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], {
    type: "application/octet-stream",
  }); //Type is important
  const url = window.URL.createObjectURL(blob);

  // Creating the hyperlink and auto click it to start the download
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
};

export const convertENUMtoJSONArray = <
  T extends Record<string, string | number>
>(
  ENUM: T
) => {
  const doubleArr = Object.keys(ENUM).map((key) => ({
    id: ENUM[key],
    name: key,
  }));
  const jsonArr =
    Array.isArray(doubleArr) && doubleArr.length > 0
      ? doubleArr.slice(doubleArr.length / 2, doubleArr.length)
      : [];
  return jsonArr;
};

export const sortBySearching = (arr: any[], fieldName: string) =>{
  arr.sort((a,b) =>{
    const fieldName1 = a?.[fieldName]?.toUpperCase();
    const fieldName2 = b?.[fieldName]?.toUpperCase();

    if (fieldName1 < fieldName2) {
      return -1;
    }
    if (fieldName1 > fieldName2) {
      return 1;
    }
    return 0; // names are equal
  });
  return arr;
}

export const getSizeString = (size : string) => {
  return size ? size + " Sqft" : "- Sqft"
}

export function getEnumKeyByValue<T>(enumObj: T, value: number): string | undefined {
  const key = Object.keys(enumObj).find(key => enumObj[key as keyof T] === value);
  return key;
}

export const currentUrl = window.location.origin

export const staticTemplates = {
  currentAddress: {
    unitNumber: "205",
    streetAddress: "5250 Truman Dr",
    city: "Decatur",
    state: "GA",
    zip: "30035",
    moveOutDate: "Jun 29, 2023",
    moveOutReasonTypeId: LeaseApplicationMoveOutReasonTypesEnum.BetterNeighborhood,
  },
  basicDetails:{
    firstName: "Murtaza", 
    lastName: "Abdeali",
     email: "murtaza.abdeali@gmail.com", 
     phoneNumber: "+1-111-222-3333"},
     creditCheck:{
      taxIdNumber: "123-123-1234",
      dateOfBirth: "Jun 29, 2001",
      consentToRunCheck: true,
    },
  contact: {
    firstName: "Murtaza",
    lastName: "Abdeali",
    email: "murtaza.abdeali@gmail.com",
    phoneNumber: "+1-111-222-3333",
    name: "Propertelligent",
    startDate: "Jun 29, 2023",
    endDate: "Jun 29, 2023",
  },
  reference: {
    firstName: "Khurram",
    lastName: "Hameed",
    email: "khurram@gmail.com",
    phoneNumber: "+1-111-222-3333",
    referenceTypeId: LeaseApplicationReferenceTypesEnum.Landlord,
  },
  employment: {
    employerName: "Hill & Associates",
    startDate: "2024-11-30",
    endDate: "2024-11-31",
    firstName: "Khurram",
    lastName: "Hameed",
    email: "khurram@gmail.com",
    phoneNumber: "+1-111-222-3333",
  },
};