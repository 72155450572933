import { useContext, useEffect, useState } from 'react';
import RangeDatePicker from '../common/formControls/RangeDatePicker';
import { StaticRange } from 'react-date-range';
import { Range } from 'react-date-range';
import { subMonths, startOfMonth, endOfMonth } from 'date-fns';
import CustomDropDownMenu from '../common/CustomDropDownMenu';
import { GetImages } from '../../utils/GetImages';
import FilterDropdown from './filterDropdown';
import TransactionTable from './TransactionTable';
import apiHelper from '../../utils/apiHelper';
import { OrganizationService } from '@propertelligent/client-api';
import { UserLoginData } from '../common/charts/Enums';
import DateHelper from '../../utils/DateHelper';
import { GeneratePdfContext } from '../../utils/context/GeneratePdfContext';

type SelectedCategories = {
  [key: string]: string[];
};

const TransactionView = () => {
  const [relativeDateString, setRelativeDateString] = useState("")
  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
  const [selectedCategories, setSelectedCategories] = useState<SelectedCategories>({});
  const [filterData, setFilterData] = useState()
  const [fullyQualifiedFilters, setFullyQualifiedFilters] = useState(null);
  const [organizationDataTX, setOrganizationDataTX] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [propertyDataTX, setPropertyDataTX] = useState([]);
  const [show, setShow] = useState(false)
  const [selectedPropertyId, setSelectedPropertyId] = useState(null)
  const { selectedOption, selectedIds, setSelectedOption, setSelectedIds, setSelectedValues, selectedValues } = useContext(GeneratePdfContext);
  const [orgLoading, setOrgLoading] = useState(false)
  const [propertyLoading, setPropertyLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [unitLoading, setUnitLoading] = useState(false)
  const [selectedUnitId, setSelectedUnitId] = useState(null)
  const [range, setRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [formValidated, setFormValidated] = useState({
    isSelectedProperties: false,
    isSelectDuration: false
  })

  const customRanges: StaticRange[] = [
    {
      label: 'Last Month',
      isSelected: () => false,
      range: () => ({
        startDate: startOfMonth(subMonths(new Date(), 1)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
      }),
    },
    {
      label: 'Last 3 Months',
      isSelected: () => false,
      range: () => ({
        startDate: startOfMonth(subMonths(new Date(), 4)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
      }),
    },
    {
      label: 'Last 6 Months',
      isSelected: () => false,
      range: () => ({
        startDate: startOfMonth(subMonths(new Date(), 7)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
      }),
    },
    {
      label: 'Last Year',
      isSelected: () => false,
      range: () => ({
        startDate: new Date(new Date().getFullYear() - 1, 0, 1),
        endDate: new Date(new Date().getFullYear() - 1, 11, 31),
      }),
    },
    {
      label: 'Custom Range',
      isSelected: () => false,
      range: () => ({
        startDate: range[0].startDate,
        endDate: range[0].endDate,
      }),
    },
  ];

  const onChangeHandler = (value) => {
    setRange(value);
    setFormValidated({
      ...formValidated,
      isSelectDuration: false,
    });
  }

  const filterResponse = () => {
    apiHelper(
      OrganizationService.getApiOrganizationTransactionOrganizationFilters,
      { showNotification: false },
      orgId
    ).then((res) => {
      if (res?.data?.success) {
        setFilterData(res?.data?.success)
      }
    }).catch((err) => {
      console.log("Error fetching property data:", err);
    });
  };

  useEffect(() => {
    filterResponse()
  }, [])

  const resetFilters = () => {
    setSelectedCategories({});
    setFullyQualifiedFilters(null)
    setSelectedOption(null)
    setSelectedIds([])
    setSelectedValues([])
  };

  useEffect(() => {
    if (selectedOption === null) {
      organizationsTXResponse()
    }
  }, [selectedOption, selectedIds])


  const handleCategoryChange = (event, column) => {
    const category = event.target.value;
    if (category) {
      setSelectedCategories((prevSelected) => {
        const updatedColumnCategories = prevSelected[column] || [];
        if (updatedColumnCategories.includes(category)) {
          const updatedCategories = updatedColumnCategories.filter(
            (c) => c !== category
          );
          const newSelectedCategories = {
            ...prevSelected,
            [column]:
              updatedCategories.length > 0 ? updatedCategories : undefined,
          };

          Object.keys(newSelectedCategories).forEach((key) => {
            if (
              newSelectedCategories[key] === undefined ||
              newSelectedCategories[key].length === 0
            ) {
              delete newSelectedCategories[key];
            }
          });
          return newSelectedCategories;
        } else {
          return {
            ...prevSelected,
            [column]: [...updatedColumnCategories, category],
          };
        }
      });
    }
  };

  useEffect(() => {
    const filterClauses = Object.entries(selectedCategories)
      .map(([column, values]) => {
        if (values && (values as string[]).length > 0) {
          const valueClauses = (values as string[])
            .map((value) => `${column}=="${value}"`)
            .join(" or ");
          return valueClauses ? `(${valueClauses})` : "";
        } else {
          return null;
        }
      })
      .filter(Boolean);

    let newFullyQualifiedFilters =
      filterClauses.length > 0 ? filterClauses.join(" and ") : null;
    if (newFullyQualifiedFilters) {
      newFullyQualifiedFilters =
        [...filterClauses].join("and")
    }
    setFullyQualifiedFilters(newFullyQualifiedFilters);
  }, [selectedCategories, fullyQualifiedFilters]);


  const organizationsTXResponse = () => {
    setLoading(true)
    apiHelper(
      OrganizationService.getApiOrganizationTransactionOrganizationlistTxGroups,
      { showNotification: false },
      orgId, undefined, undefined, undefined,
      selectedOption, selectedIds,
      undefined, undefined,
      relativeDateString !== "" ? DateHelper.getDateFilterType(relativeDateString) : undefined,
      relativeDateString !== "" ? DateHelper.getClientDate() : undefined,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].startDate.toISOString()}` : null,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].endDate.toISOString()}` : null,
    )
      .then((res) => {
        if (res?.data?.success) {
          setOrganizationDataTX(res?.data?.success);
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log("Error fetching organization transactions:", err);
      });
  };

  const organizationsResponse = (id) => {
    setOrgLoading(true)
    apiHelper(
      OrganizationService.getApiOrganizationTransactionOrganizationlist,
      { showNotification: false },
      id, undefined, undefined, undefined,
      selectedOption, selectedIds,
      fullyQualifiedFilters, undefined,
      relativeDateString !== "" ? DateHelper.getDateFilterType(relativeDateString) : undefined,
      relativeDateString !== "" ? DateHelper.getClientDate() : undefined,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].startDate.toISOString()}` : null,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].endDate.toISOString()}` : null,
    )
      .then((res) => {
        if (res?.data?.success) {
          setOrganizationData(res?.data?.success);
          setOrgLoading(false)
        }
      })
      .catch((err) => {
        console.log("Error fetching organization details:", err);
      });
  };

  const propertyResponse = (id) => {
    setPropertyLoading(true)
    if (!id) return;
    apiHelper(
      OrganizationService.getApiOrganizationTransactionPropertylist,
      { showNotification: false }, id, undefined, undefined, undefined,
      selectedOption, selectedIds,
      fullyQualifiedFilters, undefined,
      relativeDateString !== "" ? DateHelper.getDateFilterType(relativeDateString) : undefined,
      relativeDateString !== "" ? DateHelper.getClientDate() : undefined,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].startDate.toISOString()}` : null,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].endDate.toISOString()}` : null,

    ).then((res) => {
      if (res?.data?.success) {
        setPropertyData(res?.data?.success);
        setPropertyLoading(false)
      }
    }).catch((err) => {
      console.log("Error fetching property data:", err);
    });
  };

  const unitResponse = (id) => {
    setUnitLoading(true)
    if (!id) return;
    apiHelper(
      OrganizationService.getApiOrganizationTransactionUnitlist,
      { showNotification: false },
      id, undefined, undefined, undefined,
      selectedOption, selectedIds,
      fullyQualifiedFilters,
      undefined,
      relativeDateString !== "" ? DateHelper.getDateFilterType(relativeDateString) : undefined,
      relativeDateString !== "" ? DateHelper.getClientDate() : undefined,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].startDate.toISOString()}` : null,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].endDate.toISOString()}` : null,
    ).then((res) => {
      if (res?.data?.success) {
        setUnitData(res?.data?.success);
        setUnitLoading(false)
      }
    }).catch((err) => {
      console.log("Error fetching property data:", err);
    });
  };

  const propertyResponseTX = (id) => {
    if (!id) return;
    apiHelper(
      OrganizationService.getApiOrganizationTransactionPropertylistTxGroups,
      { showNotification: false },
      id, undefined, undefined, undefined, selectedOption, selectedIds, undefined, undefined,
      relativeDateString !== "" ? DateHelper.getDateFilterType(relativeDateString) : undefined,
      relativeDateString !== "" ? DateHelper.getClientDate() : undefined,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].startDate.toISOString()}` : null,
      relativeDateString == "Custom Range" || relativeDateString == "Custom" ? `${range[0].endDate.toISOString()}` : null,
    )
      .then((res) => {
        if (res?.data?.success) {
          setPropertyDataTX(res?.data?.success);
        }
      })
      .catch((err) => {
        console.log("Error fetching property transaction data:", err);
      });
  };

  useEffect(() => {
    organizationsTXResponse();
  }, []);

  return (
    <>
      <div className='mb-3'>
        <div className="transactionHeader border-bottom-0 d-flex flex-column">
          <div className="d-flex justify-content-between">
            <p className="top_header black">Transactions</p>
            <div className="monthhead gap-3">
              <div>
                <RangeDatePicker
                  range={range}
                  customRanges={customRanges}
                  onChangeHandler={onChangeHandler}
                  relativeDateString={relativeDateString}
                  setRelativeDateString={setRelativeDateString}
                  fetchData={organizationsTXResponse}
                  applyButton={true}
                />
              </div>
              <div>
                <CustomDropDownMenu
                  data={[]}
                  show={show}
                  Element={
                    <div className="d-flex align-items-center normal_text gap-2 px-2" onClick={() => setShow(!show)}>
                      <img src={GetImages.Filter} className="iconstyle" alt="Filter" />
                      <p>Filter</p>
                    </div>
                  }
                  content={
                    <FilterDropdown
                      setShow={setShow}
                      filters={filterData}
                      selectedCategories={selectedCategories}
                      handleCategoryChange={handleCategoryChange}
                      organizationsResponse={() => organizationsResponse(orgId)}
                      propertyResponse={() => propertyResponse(selectedPropertyId)}
                      unitResponse={() => unitResponse(selectedUnitId)}
                      resetFilters={resetFilters}
                      organizationsTXResponse={() => organizationsTXResponse()}
                      propertyResponseTX={() => propertyResponseTX(selectedPropertyId)}
                      loading={loading}
                      setLoading={setLoading}
                      organizationDataTX={organizationDataTX}
                      setOrganizationDataTX={setOrganizationDataTX}
                    />
                  }
                />
              </div>
            </div>
          </div>

          <div>
            <TransactionTable
              organizationDataTX={organizationDataTX}
              organizationData={organizationData}
              propertyData={propertyData}
              unitData={unitData}
              setOrganizationData={setOrganizationData}
              setPropertyData={setPropertyData}
              setUnitData={setUnitData}
              propertyDataTX={propertyDataTX}
              organizationsResponse={organizationsResponse}
              propertyResponse={propertyResponse}
              unitResponse={unitResponse}
              selectedPropertyId={selectedPropertyId}
              selectedUnitId={selectedUnitId}
              setSelectedPropertyId={setSelectedPropertyId}
              setSelectedUnitId={setSelectedUnitId}
              propertyResponseTX={propertyResponseTX}
              organizationsTXResponse={organizationsTXResponse}
              orgLoading={orgLoading}
              unitLoading={unitLoading}
              propertyLoading={propertyLoading}
              loading={loading}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default TransactionView