import { useState, useEffect } from "react";
import RecentServiceRequestComponent from "./RecentServiceRequestComponent";
import { ServiceRequestService } from "@propertelligent/client-api";
import RecentFiveTransactionsComponent from "./RecentFiveTransactionsComponent";
import apiHelper from "../../utils/apiHelper";

const ContractorOverview = ({ id }) => {
  const [serviceRequestData, setServiceRequestData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response =
          await apiHelper(ServiceRequestService.getApiServiceRequestContractorlist, { showNotification: false }, id)
        setServiceRequestData(response?.data?.success);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div className="overviewContainer">
      <div className=" pt-0 flex-wrap serviceRequestTransactionOverviewContainer">
        <div className="recentServiceRequestContainer">
          <p className="heading mb-3">
            Recent 5 service request
          </p>
          <RecentServiceRequestComponent data={serviceRequestData} />
        </div>
        <div className="recentTransactionContainer">
          <p className="heading mb-3">Recent 5 Transactions </p>
          <RecentFiveTransactionsComponent id={id} />
        </div>
      </div>
    </div>
  );
};

export default ContractorOverview;
