import React, { useEffect, useRef, useState } from "react";
import EmptyData from "../components/common/EmptyData";
import { Form } from "react-bootstrap";

const ExpenseDistributionDashboard = ({
  data,
  fin_monthValue,
  setFin_MonthValue,
}) => {
  const canvasRef = useRef(null);
  const [tooltip, setTooltip] = useState({ visible: false, content: "", value: 0, x: 0, y: 0, color: "" });

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = Math.min(centerX, centerY);
    const total = data?.reduce((acc, item) => acc + item.value, 0);
    const ringThickness = 30;
    const innerRadius = radius - ringThickness;
    const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;

    context.clearRect(0, 0, canvas.width, canvas.height);

    let currentAngle = 0;
    data?.forEach((item) => {
      const percentage = (item?.value / total) * 360;
      const endAngle = currentAngle + degreesToRadians(percentage);

      // Draw the segment
      context.fillStyle = item.color;
      context.beginPath();
      context.arc(centerX, centerY, radius, currentAngle, endAngle);
      context.arc(centerX, centerY, innerRadius, endAngle, currentAngle, true);
      context.closePath();
      context.fill();

      // Store angles for hover detection
      item.startAngle = currentAngle;
      item.endAngle = endAngle;

      currentAngle = endAngle;
    });
  }, [data, fin_monthValue]);


  const handleMouseMove = (e) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    const angle = Math.atan2(mouseY - centerY, mouseX - centerX);
    const adjustedAngle = angle >= 0 ? angle : 2 * Math.PI + angle;

    const hoveredItem = data?.find(
      (item) =>
        adjustedAngle >= item.startAngle && adjustedAngle <= item.endAngle
    );

    if (hoveredItem) {
      setTooltip({
        visible: true,
        content: `${hoveredItem.name}`,
        value: hoveredItem.value,
        x: e.clientX - 50,
        y: e.clientY + 30,
        color: hoveredItem?.color
      });
    } else {
      setTooltip({ visible: false, content: "", value: 0, x: 0, y: 0, color: "" });
    }
  };

  const tooltipStyle: React.CSSProperties = {
    top: tooltip.y,
    left: tooltip.x,
    visibility: tooltip.visible ? "visible" : "hidden",
  };

  return (
    <div className="overviewContainerMain">
      <div className="overviewContainerRightSide">
        <div
          style={{ borderBottom: "none" }}
          className="overviewContainerRightSideTopComponent"
        >
          <p className="heading">Expense Distribution</p>
          <div className="overviewButtons">
            <Form.Select
              className="sub_text grey_subtext1"
              aria-label="Default select Service Sub Type"
              required
              onChange={(e) => {
                setFin_MonthValue(e.target.value);
              }}
              value={fin_monthValue}
            >
              <option value={-1}>Last month </option>
              <option value={-3}>Last 3 months </option>
              <option value={-6}>Last 6 months </option>
              <option value={-12}>Last 12 months </option>
            </Form.Select>
          </div>
        </div>
        <div className="justify-content-center d-flex align-items-center flex-wrap expanseDistributionDashboardSubContainer">
          <canvas
            style={{ display: data?.length === 0 && "none" }}
            ref={canvasRef}
            width={188}
            height={188}
            className="m-3 clickable"
            onMouseMove={handleMouseMove}
            onMouseLeave={() => setTooltip({ visible: false, content: "", value: 0, x: 0, y: 0, color: "" })}
          />
          {tooltip.visible && (
            <div style={tooltipStyle} className="normal_text grey_subtext1 tooltipStyle">
              <div className="expenseItem flex-wrap">
                <div className="propertyCardQuantityDesc">
                  <div
                    className="expenseColor"
                    style={{ background: tooltip?.color }}
                  >
                    {" "}
                  </div>
                  <p className="normal_text grey_subtext1"> {tooltip?.content}</p>
                </div>
                <div className="expenseValue">
                  <p className="sub_text fw-bold ms-3">
                    {(
                      (tooltip?.value /
                        data?.reduce((acc, item) => acc + item?.value, 0)) *
                      100
                    ).toFixed(2)}
                    % (${tooltip?.value})
                  </p>
                </div>
              </div>
              <div className="caretStyle"></div>
            </div>
          )}
          <div className="expenseDetails1">
            {data?.length === 0 ? (
              <>
                <EmptyData
                  mainText={"No Data Found"}
                  subText={undefined}
                  button={undefined}
                />
              </>
            ) : (
              data?.map((item, index) => (
                <div className="expenseItem" key={index}>
                  <div className=" d-flex flex-row align-items-center justify-content-between ">
                    <div
                      className="expenseColor "
                      style={{ background: item?.color }}
                    >
                      {" "}
                    </div>
                    <p className="sub_text grey_subtext1 ms-2"> {item?.name}</p>
                  </div>
                  <div className="expenseValue ">
                    <p className="sub_text fw-bold grey_subtext1">
                      {(
                        (item?.value /
                          data?.reduce((acc, item) => acc + item?.value, 0)) *
                        100
                      ).toFixed(2)}
                      % (${item?.value})
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseDistributionDashboard;
